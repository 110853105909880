<template>
  <main>
    <div class="container">
      <header class="d-flex flex-wrap justify-content-between mb-4">
        <a class="d-flex align-items-center text-dark text-decoration-none">
          <img src="../../assets/logo.png" class="logo" alt="" />
        </a>
        <div class="text-end">
          <!-- <button type="button" class="btn sign me-2 mt-6">{{ $t("new") }}</button> -->
          <router-link to="/login">
            <button type="button" class="log btn btn-outline me-2 mt-6">
              {{ $t("log") }}
            </button>
          </router-link>
          <select
            style="color: black"
            class="btn me-2 mt-6"
            aria-label="Default select example"
            v-model="langue"
            @change="changelanguage()"
          >
            <option value="fr">FR</option>
            <option value="en">EN</option>
          </select>
        </div>
        <v-progress-linear :value="progress" height="6"></v-progress-linear>
      </header>
    </div>
    <div class="container-100">
      <div class="signup">
        <div class="text-center">
          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            absolute
            centered
            color="success"
            right
          >
            {{ $t("user.succes") }}

            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
        <div class="text-center">
          <v-snackbar
            v-model="error"
            :timeout="timeout"
            absolute
            bottom
            color="red"
            right
          >
            {{ $t("error.error") }}

            <template v-slot:action="{ attrs }">
              <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              {{ $t("step.one") }}</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">
              {{ $t("step.two") }}</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3">
              {{ $t("step.tree") }}</v-stepper-step
            >
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form ref="personalInfoForm">
                <v-card class="px-4 py-5">
                  <v-text-field
                    v-model="nom"
                    :rules="rules"
                    prepend-inner-icon="mdi-account"
                    name="nom"
                    :label="$t('headers.nom')"
                    type="text"
                    clearable
                    outlined
                    ref="clear"
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-model="prenom"
                    class="mt-2"
                    :rules="rules"
                    prepend-inner-icon="mdi-account"
                    name="prenom"
                    :label="$t('headers.prenom')"
                    type="text"
                    ref="clear1"
                    clearable
                    dense
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="username"
                    class="mt-2"
                    :rules="usernameRules"
                    prepend-inner-icon="mdi-account"
                    name="username"
                    :label="$t('headers.username')"
                    type="text"
                    @input="existusername"
                    clearable
                    outlined
                    ref="clear2"
                    dense
                    :error-messages="messages"
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="email"
                    class="mt-2"
                    :rules="emailRules"
                    prepend-inner-icon="mdi-email"
                    name="email"
                    :label="$t('headers.email')"
                    type="email"
                    :error-messages="messagesemail"
                    @input="existemail"
                    clearable
                    ref="clear3"
                    dense
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    class="mt-2"
                    :rules="passwordRules"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show1 = !show1"
                    :type="show1 ? 'text' : 'password'"
                    prepend-inner-icon="mdi-key"
                    name="password"
                    outlined
                    ref="clear4"
                    dense
                    :label="$t('headers.password')"
                    clearable
                  ></v-text-field>
                  <v-text-field
                    v-model="tel"
                    class="mt-2"
                    prepend-inner-icon="mdi-phone"
                    :rules="rules"
                    name="tel"
                    :label="$t(`headers.telephone`)"
                    type="text"
                    @input="existtel"
                    :error-messages="messagestel"
                    outlined
                    ref="clear5"
                    dense
                    clearable
                  ></v-text-field>
                  <v-autocomplete
                    v-model="allpay"
                    class="mt-2"
                    prepend-inner-icon="mdi-earth"
                    :rules="rules"
                    name="pays"
                    :label="$t('headers.pays')"
                    outlined
                    ref="clear6"
                    item-text="nom"
                    item-value="id"
                    :items="allpays"
                    return-object
                    dense
                  ></v-autocomplete>
                  <div class="text-right">
                    <v-btn color="primary" @click="validateStep1()">
                      {{ $t("step.next") }}</v-btn
                    >
                  </div>
                </v-card>
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-form ref="bankInfoForm">
                <v-text-field
                  class="mt-2"
                  v-model="institution"
                  outlined
                  clearable
                  dense
                  :label="$t(`headers.institution`)"
                  :rules="rules"
                ></v-text-field>
                <v-text-field
                  v-model="intitule"
                  outlined
                  clearable
                  dense
                  :label="$t(`headers.intitule`)"
                  :rules="rules"
                ></v-text-field>
                <v-text-field
                  v-model="numCompte"
                  outlined
                  clearable
                  dense
                  :label="$t('headers.numcompte')"
                  :rules="rules"
                ></v-text-field>
                <v-btn color="primary" @click="validateStep2">
                  {{ $t("step.next") }}</v-btn
                >
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-form ref="pinInfoForm">
                <v-text-field
                  class="mt-2"
                  v-model="pin"
                  outlined
                  clearable
                  dense
                  label="Code PIN"
                  :rules="pinRules"
                ></v-text-field>
                <v-btn color="primary" @click="validateStep3">
                  {{ $t("step.end") }}</v-btn
                >
              </v-form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <v-dialog v-model="dialog" max-width="500">
          <v-card>
            <v-card-title>Information Client</v-card-title>
            <v-card-text>
              <p>Nom : {{ nom }}</p>
              <p>Prenom : {{ prenom }}</p>
              <p>Email : {{ email }}</p>
              <p>Nom de la Banque : {{ institution }}</p>
              <p>Numero de Compte : {{ numCompte }}</p>
              <p>Code PIN : {{ pin }}</p>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="dialog = false">Fermer</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import { baseURL } from "../../main";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      langue: "",
      show1: "",
      snackbar: false,
      error: false,
      recap: false,
      timeout: 2500,
      nom: "",
      username: "",
      email: "",
      password: "",
      allpay: "",
      prenom: "",
      tel: "",
      dialog: false,
      step: 1,
      adresse: "",
      intitule: "",
      institution: "",
      numCompte: "",
      codecvc: "",
      pin: "",
      progress: 10,
      messages: null,
      messagestel: null,
      messagesemail: null,
    };
  },
  created() {
    if (localStorage.getItem("lang") == null) {
      localStorage.setItem("lang", "fr");
    }
    this.langue = localStorage.getItem("lang");
  },
  computed: {
    ...mapGetters(["roles", "allpays"]),
    rules() {
      return [(value) => !!value || "Required.", (v) => !!v || "Ce champs est requis"];
    },
    usernameRules() {
      return [
        (v) => !!v || this.$t("require.username"),
        (v) =>
          /^(?=.*[a-zA-Z].*)(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/.test(v) ||
          this.$t("rule.username"),
      ];
    },
    passwordRules() {
      return [
        (v) => !!v || this.$t("require.pass"),
        (v) =>
          /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()–[{}]).{6,40}/.test(v) ||
          this.$t("rule.pass"),
      ];
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("require.email"),
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || this.$t("rule.mail"),
      ];
    },
    pinRules() {
      return [
        (v) => !!v || this.$t("require.pin"),
        (v) => /^\d{6}$/.test(v) || this.$t("rule.pin"),
      ];
    },
    form() {
      return {
        nom: this.nom,
        prenom: this.prenom,
        username: this.username,
        email: this.email,
        tel: this.tel,
        password: this.password,
        pays: this.allpay.id,
        institution: this.institution,
        intitule: this.intitule,
        numCompte: this.numCompte,
        pin: this.pin,
      };
    },
  },
  mounted() {
    this.$store.dispatch("getallpays");
  },
  methods: {
    validateStep1() {
      const valid = this.$refs.personalInfoForm.validate();
      console.log(valid);
      if (valid == true) {
        this.step = 2;
        this.progress = this.progress + 38;
      }
    },
    validateStep2() {
      const valid = this.$refs.bankInfoForm.validate();
      console.log(valid);
      if (valid == true) {
        this.step = 3;
        this.progress = this.progress + 45;
      }
    },
    validateStep3() {
      const valid = this.$refs.pinInfoForm.validate();
      console.log(valid);
      if (valid == true) {
        localStorage.setItem("tk", this.$store.getters.token);
        this.$store.dispatch("sign", this.form).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.snackbar = true;
            this.reset();
            localStorage.removeItem("status");
            const timer = () => {
              this.$router.push("/login");
            };
            setTimeout(timer, 1 * 1000, 1);
          } else if (status == 400) {
            this.error = true;
            localStorage.removeItem("status");
          }
        });
      }
    },
    nextStep() {},
    reset() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
      this.$refs.clear2.reset();
      this.$refs.clear3.reset();
      this.$refs.clear4.reset();
      this.$refs.clear5.reset();
      this.$refs.clear6.reset();
    },
    changelanguage() {
      localStorage.setItem("lang", this.langue);
      window.location.reload();
    },
    save() {
      if (this.$refs.formsaveuser.validate()) {
        localStorage.setItem("tk", this.$store.getters.token);
        this.$store.dispatch("sign", this.form).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.snackbar = true;
            this.reset();
            localStorage.removeItem("status");
            const timer = () => {
              this.$router.push("/");
            };
            setTimeout(timer, 2.5 * 1000, 2.5);
          } else if (status == 400) {
            this.error = true;
            localStorage.removeItem("status");
          }
        });
      }
    },
    async existemail() {
      if (this.email) {
        await axios.get(baseURL + "auth/check/email/" + this.email).then((response) => {
          if (response.data == true) {
            this.messagesemail = "cet adresse email existe deja";
          } else {
            this.messagesemail = null;
          }
        });
      }
    },
    async existtel() {
      if (this.tel) {
        await axios.get(baseURL + "auth/check/tel/" + this.tel).then((response) => {
          if (response.data == true) {
            this.messagestel = "ce nom  existe deja";
          } else {
            this.messagestel = null;
          }
        });
      }
    },
    async existusername() {
      if (this.username) {
        await axios
          .get(baseURL + "auth/check/username/" + this.username)
          .then((response) => {
            if (response.data == true) {
              this.messages = "ce nom d'utilisateur existe deja";
            } else {
              this.messages = null;
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.logo {
  width: 8rem;
}
.log {
  background-color: #2b2e83;
  color: white;
}
.sign {
  background-color: #e30613;
  color: white;
}
.container-100 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.signup {
  width: 50rem;
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 70px;
}

.form-title {
  font-size: 30px;
  color: #333333;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  display: block;
}
</style>
