import { render, staticRenderFns } from "./pin.vue?vue&type=template&id=35447044&scoped=true&"
import script from "./pin.vue?vue&type=script&lang=js&"
export * from "./pin.vue?vue&type=script&lang=js&"
import style0 from "./pin.vue?vue&type=style&index=0&id=35447044&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35447044",
  null
  
)

/* custom blocks */
import block0 from "./pin.vue?vue&type=custom&index=0&blockType=Aside&%3Aprofile=one"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardText,VDialog,VForm,VSnackbar,VTextField})
