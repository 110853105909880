<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <div class="col-lg-9 mb-4">
        <div class="col-sm-6 col-md-5">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-2 row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("preuve.preuvelist") }}</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.fichiers`]="{ item }">
            <td v-if="item.fichiers.length > 0">
              <div v-for="file in item.fichiers" :key="file.id">
                <img
                  @click="zoom(item.fichiers)"
                  :src="baseURL + filepath + file.name"
                  alt="preuve"
                  height="60px"
                />
              </div>
            </td>
            <td v-else>
              <p>Vide</p>
            </td>
          </template>
          <template v-slot:[`item.montant`]="{ item }">
            <td v-if="item.tauxEchange">
              {{
                item.montant * item.tauxEchange.taux +
                " " +
                item.tauxEchange.deviseRec.libelle
              }}
            </td>
            <td v-else>
              {{ item.montant }}
            </td>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <p>{{ date(item.createdAt) }}</p>
          </template>
          <template v-slot:[`item.used`]="{ item }">
            <td v-if="item.used == true">
              <v-icon class="ms-6" color="green" x-large
                >mdi-check-decagram-outline</v-icon
              >
            </td>
            <td v-else>
              <v-btn @click="confirms(item)" color="#277da1" style="color: white">
                Valider</v-btn
              >
              <!-- <v-icon color="red" x-large> mdi-alert-decagram </v-icon> -->
            </td>
          </template>
          <template v-slot:[`item.annuler`]="{ item }">
            <td v-if="item.annuler == false && item.used == false">
              <v-btn @click="reject(item)" color="red" style="color: white">
                Annuler</v-btn
              >
            </td>
            <td class="text-center" v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="indigo" v-bind="attrs" v-on="on" x-large class="ms-6">
                    mdi-decagram
                  </v-icon>
                </template>
                <!-- <span>Preuve rejeter</span> -->
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
        <v-dialog v-model="zooms" transition="dialog-top-transition" max-width="780">
          <template>
            <v-img :src="zoomedImg" v-if="zoomedImg" class="imgStyle"></v-img>
          </template>
        </v-dialog>
        <v-dialog v-model="confirm" transition="dialog-top-transition" max-width="450">
          <template v-slot:default="dialog">
            <v-form ref="formconfirm" @submit.prevent="confirms">
              <v-card>
                <v-card-text>
                  <div class="text-center"></div>
                  <div class="form-group">
                    <div class="mt-2">
                      <v-textarea
                        outlined
                        clearable
                        clear-icon="mdi-close-circle"
                        name="input-7-4"
                        solo
                        auto-grow
                        label="Motifs"
                        rows="4"
                        row-height="40"
                        v-model="motifs"
                      ></v-textarea>
                    </div>
                  </div>
                </v-card-text>
                <div class="d-flex justify-content-between">
                  <v-btn
                    class="mt-2 mb-2 ms-2"
                    style="background-color: #dc2f02; color: white"
                    text
                    @click="dialog.value = false"
                    >Close</v-btn
                  >
                  <v-btn
                    class="mt-2 mb-2 me-2"
                    text
                    style="background-color: #003566; color: white"
                    @click="annuler"
                    >Save</v-btn
                  >
                </div>
              </v-card>
            </v-form>
          </template>
        </v-dialog>
        <v-dialog v-model="finish" transition="dialog-top-transition" max-width="450">
          <template>
            <v-card>
              <v-card-text class="text-center" style="color: #2d2d2d">
                <v-card-title class="headline"> Validation </v-card-title>
                <span class="h3">
                  <p v-if="forvalid">
                    Valider vous la preuve de recharge de :
                    <span class="h2" style="color: #003566; text-transform: uppercase">
                      {{ forvaliduser.nom + "" + forvaliduser.prenom }}
                    </span>
                  </p>
                  <p>
                    D'un montant de: <br />
                    <span class="h2" style="color: green">
                      {{ forvalid.montant + " " + one.user.pays.devise.libelle }}
                    </span>
                  </p>
                  <p>Saisisez votre code pin</p>
                  <v-text-field
                    class="mt-2 ms-2 me-2"
                    v-model="pin"
                    name="indicatif"
                    type="number"
                    label="code pin"
                    ref="clear2"
                    @blur="checkpins"
                    prepend-inner-icon="mdi-numeric"
                    :rules="[rules.required]"
                    :error-messages="messagepin"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                  <v-icon x-large color="white">mdi-check</v-icon>
                </span>
                <div class="text-center d-flex justify-center">
                  <v-btn left style="color: white" color="#2B2E83" @click="valid"
                    >Enregistrer</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import { baseURL } from "../../../main";
import Aside from "../../../components/aside.vue";
export default {
  components: { Aside },
  data() {
    return {
      items: [],
      zooms: false,
      snackbar: false,
      confirm: false,
      motifs: null,
      pin: "",
      messagepin: null,
      timeout: 2000,
      forvalid: {},
      forvaliduser: {},
      id: null,
      loading: false,
      filepath: "downloadFile/",
      loading: false,
      baseURL,
      imgStyle: {
        maxWidth: "100%",
        maxHeight: "100%",
      },
      zoomedImg: null,
      search: null,
      current_page: 1,
      finish: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: "Username",
          align: "start",
          sortable: false,
          value: "user.username",
        },
        {
          text: this.$t("headers.nom"),
          align: "start",
          sortable: false,
          value: "user.nom",
        },
        {
          text: this.$t("headers.prenom"),
          align: "start",
          sortable: false,
          value: "user.prenom",
        },
        {
          text: this.$t("headers.telephone"),
          align: "start",
          sortable: false,
          value: "user.tel",
        },
        {
          text: this.$t("headers.email"),
          align: "start",
          sortable: false,
          value: "user.email",
        },
        {
          text: this.$t("headers.montant"),
          align: "start",
          sortable: false,
          value: "montant",
        },
        {
          text: this.$t("headers.fichier"),
          align: "start",
          sortable: false,
          value: "fichiers",
        },
        {
          text: this.$t("headers.agence"),
          align: "start",
          sortable: false,
          value: "agence.nom",
        },
        {
          text: this.$t("headers.Etat"),
          align: "start",
          sortable: false,
          value: "used",
        },
        {
          text: this.$t("headers.Anuller"),
          align: "start",
          sortable: false,
          value: "annuler",
        },
        {
          text: this.$t("headers.date"),
          align: "start",
          sortable: false,
          value: "createdAt",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "allpreuve",
      "token",
      "one",
      "agencebyuser",
      "user",
      "me",
      "checkpin",
    ]),
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.allpreuve && this.allpreuve.last_page) {
        return this.allpreuve.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.allpreuve != null && this.allpreuve.data) {
        return this.allpreuve.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search() {
      if (this.search == "" || this.search.trim() == null) {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getallpreuve", {
          id: this.agencebyuser.id,
          page: this.current_page,
        });
      }
    },
  },
  methods: {
    ici(row) {},
    checkpins() {
      localStorage.setItem("tk", this.token);
      const pin = {};
      pin.pin = this.pin;
      this.$store.dispatch("getcheckpin", { id: this.user.id, pin: pin }).then(() => {
        const timer = () => {
          if (this.checkpin == false) {
            this.messagepin = "Le code pin ne correspond pas";
            this.$refs.clear2.$el.querySelector("input").focus();
          } else {
            this.messagepin = null;
          }
        };
        setTimeout(timer, 0.1 * 100, 0.1);
      });
    },
    valid() {
      if (this.checkpin == true) {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getvalide", this.id);
        const timer = () => {
          this.finish = false;
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("getoneportefeuille", this.user.id);
          this.$store.dispatch("getallpreuve", {
            id: this.agencebyuser.id,
            page: this.current_page,
          });
        };
        setTimeout(timer, 0.1 * 100, 0.1);
      }
    },
    confirms(item) {
      if (item.used == false) {
        this.id = item.id;
        this.forvalid = item;
        this.forvaliduser = item.user;
        this.finish = true;
      }
    },
    reject(item) {
      this.confirm = true;
      this.id = item.id;
    },
    annuler() {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("rejected", { motif: this.motifs, preuve: this.id });
      this.$store.dispatch("getallpreuve", {
        id: this.agencebyuser.id,
        page: this.current_page,
      });
    },
    date(item) {
      return new Date(item).toLocaleDateString();
    },
    zoom(items) {
      this.zoomedImg = this.baseURL + this.filepath + items[0].name;
      this.zooms = true;
    },
    handleResize() {},
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getallpreuve", {
          id: this.agencebyuser.id,
          page: this.current_page,
        });
      } else {
        this.$store.dispatch("searchallpreuve", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {
      if (this.search) {
        if (this.search.trim() == "") {
          localStorage.setItem("tk", this.token);
          this.current_page = 1;
          this.$store.dispatch("getallpreuve", {
            id: this.agencebyuser.id,
            page: this.current_page,
          });
        } else {
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("searchallpreuve", {
            page: this.current_page,
            search: this.search.trim(),
          });
          if (this.$store.state.preuve.allpreuve.data) {
            this.current_page = this.$store.state.preuve.allpreuve.current_page;
          } else this.current_page = 1;
        }
      }
    },
  },
  mounted() {
    this.loading = true;
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getclearpin");
    this.$store.dispatch("getme").then(() => {
      const timer = () => {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getagencebyuser", this.me.id).then(() => {
          const timer = () => {
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getallpreuve", {
              id: this.agencebyuser.id,
              page: this.current_page,
            });
            this.loading = false;
          };
          setTimeout(timer, 0.1 * 100, 0.1);
        });
      };
      setTimeout(timer, 0.1 * 100, 0.1);
    });
  },
 
  created() {
    // console.log(this.$store.state.preuve.allpreuve.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
</style>
