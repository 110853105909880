<template>
  <main class="container">
    <v-dialog v-model="confirm" transition="dialog-top-transition" max-width="450">
      <template v-slot:default="dialog">
        <v-form ref="formupdate" @submit.prevent="update">
          <v-card>
            <v-card-text class="text-center">
              <span class="h1">
                Valider vous ce <span />
                <span style="color: #2b2e83">
                  {{ pins }}
                </span>
                <span>code pin ? </span>
              </span>
            </v-card-text>
            <div class="d-flex justify-content-between">
              <v-btn class="mt-2 mb-2" color="#e30613" text @click="dialog.value = false"
                >non</v-btn
              >
              <v-btn @click="valid()" class="mt-2 mb-2" color="#275462" text>oui</v-btn>
            </div>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
    <div class="row">
      <Aside :profile="one" />
      <div class="col-lg-7">
        <v-card class="signup">
          <v-card-text>
            <div class="text-center">
              <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                center
                absolute
                color="#1e824c"
              >
                {{ $t("Transaction.succes") }}

                <template v-slot:action="{ attrs }">
                  <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
            <div class="text-center">
              <v-snackbar
                v-model="error"
                :timeout="timeout"
                absolute
                bottom
                color="red"
                right
              >
                {{ $t("devis.error") }}

                <template v-slot:action="{ attrs }">
                  <v-btn color="#cf000f" text v-bind="attrs" @click="snackbar = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
            <v-form @submit.prevent="save" ref="formsaveuser">
              <h2 class="text-center mt-4">
                {{ $t("admin.defpin") }}
              </h2>
              <div class="row mt-8">
                <div class="col-md-12">
                  <div class="form-group">
                    <v-text-field
                      v-model="pin"
                      type="number"
                      :rules="[rules.required]"
                      prepend-inner-icon="mdi-dialpad"
                      :label="$t('admin.pin')"
                      clearable
                      @input="checkpins"
                      :error-messages="messagepin"
                      ref="clear"
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <v-text-field
                      v-model="pins"
                      type="number"
                      :rules="[rules.required]"
                      prepend-inner-icon="mdi-dialpad"
                      :label="$t('admin.pin')"
                      clearable
                      ref="clear1"
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="form-group">
                  <button class="w-100 btn log save" @click="save" :disabled="ok">
                    {{ $t("admin.def") }}
                  </button>
                </div>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import { baseURL } from "../../../main";
import Aside from "../../../components/aside.vue";
import { mapGetters } from "vuex";

export default {
  components: { Aside },
  data: () => {
    return {
      show2: "",
      ok: true,
      pin: null,
      pins: null,
      confirm: false,
      solde: false,
      oui: false,
      eye: false,
      eyes: true,
      snackbar: false,
      error: false,
      timeout: 2000,
      messagepin: null,
      montant: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
    };
  },
  computed: {
    ...mapGetters(["token", "compteadmin", "one", "checkpin"]),
    form() {
      return {
        receveur: this.compteadmin.numero,
        typeOperation: 3,
        montant: this.montant,
      };
    },
  },

  methods: {
    checkpins() {
      localStorage.setItem("tk", this.token);
      const pin = {};
      pin.pin = this.pin;
      this.$store.dispatch("getcheckpin", { id: this.one.user.id, pin: pin }).then(() => {
        const timer = () => {
          if (this.checkpin == false) {
            this.messagepin = "Le code pin ne correspond pas";
            this.ok = true;
            this.$refs.clear.$el.querySelector("input").focus();
          } else {
            this.messagepin = null;
            this.ok = false;
          }
        };
        setTimeout(timer, 0.1 * 100, 0.1);
      });
    },
    show() {
      this.eye = false;
      this.solde = false;
    },
    shows() {
      this.eye = true;
      this.solde = true;
    },
    valid() {
      this.oui = true;
      this.conf();
    },
    conf() {
      if (this.oui == true) {
        this.confirm = false;
        localStorage.setItem("tk", this.$store.getters.token);
        const codepin = {};
        codepin.pin = this.pins;
        this.$store
          .dispatch("updatepin", {
            id: this.$store.getters.user.id,
            value: codepin,
          })
          .then(() => {
            const status = localStorage.getItem("status");
            if (status == 200) {
              this.$refs.clear.reset();
              this.$refs.clear1.reset();
              this.snackbar = true;
              localStorage.removeItem("status");
            } else if (status == 400) {
              this.error = true;
              localStorage.removeItem("status");
            }
          });
        this.oui = false;
      }
    },
    save() {
      if (this.$refs.formsaveuser.validate() && this.pins != null) {
        this.confirm = true;
      }
    },
  },
  mounted() {
    // this.$store.dispatch("getroles");
    localStorage.setItem("tk", this.$store.getters.token);
    this.$store.dispatch("getoneportefeuille", this.$store.getters.user.id);
  },
};
</script>
<style scoped>
.num {
  font-variant-numeric: tabular-nums !important;
  color: #e30613;
}
.container-100 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 100px 0px 0px 0px;
}
.save {
  background-color: #2b2e83;
  color: #eee7ce;
}
.back {
  background-color: whitesmoke;
}
.signup {
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 70px;
}
</style>
<Aside :profile="one" />
