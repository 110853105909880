<template>
  <main>
    <div class="container">
      <header class="d-flex flex-wrap justify-content-between mb-4 border-bottom">
        <a class="d-flex align-items-center text-dark text-decoration-none">
          <img src="../assets/logo.png" class="logo" alt="" />
        </a>
        <div class="text-end">
          <router-link to="/login">
            <button type="button" class="log btn btn-outline me-2 mt-6">
              {{ $t("log") }}
            </button>
          </router-link>
          <select
            style="color: black"
            class="btn me-2 mt-6"
            aria-label="Default select example"
            v-model="langue"
            @change="changelanguage()"
          >
            <option value="fr">FR</option>
            <option value="en">EN</option>
          </select>
        </div>
      </header>
      <section class="section shadow-md py-4">
        <div class="">
          <div class="hero-bg">
            <div class="py-5">
              <div class="container">
                <div class="row">
                  <div
                    class="col-lg-4 col-xl-4 my-auto text-center text-lg-left pb-4 pb-lg-0"
                  >
                    <h2 class="text-17 text-white">
                      <span class="font-weight-400 text-15">{{ $t("home.span") }} </span>
                      <br />
                      {{ $t("home.span2") }}
                    </h2>
                    <p class="text-4 text-white mb-4">
                      {{ $t("home.h2") }}
                    </p>
                    <router-link to="/login">
                      <button class="btn btn-outline-light me-2 mt-6">
                        {{ $t("home.btn") }}
                      </button>
                    </router-link>
                  </div>
                  <div class="col-lg-8 col-xl-8 my-auto">
                    <v-card class="mx-auto">
                      <v-card-text>
                        <div class="row mt-3">
                          <div class="col-md-6">
                            <v-autocomplete
                              v-model="send"
                              prepend-inner-icon="mdi-earth"
                              :label="$t('home.envoie')"
                              :items="itemListpays"
                              item-text="nom"
                              item-value="id"
                              ref="clear"
                              dense
                              return-object
                              outlined
                            ></v-autocomplete>
                          </div>
                          <div class="col-md-6">
                            <v-autocomplete
                              v-model="receive"
                              prepend-inner-icon="mdi-earth"
                              :label="$t('home.receive')"
                              :items="itemListpays"
                              item-text="nom"
                              item-value="id"
                              ref="clear1"
                              dense
                              return-object
                              outlined
                            ></v-autocomplete>
                          </div>
                          <div class="col-md-7">
                            <v-text-field
                              v-model="money"
                              prepend-inner-icon="mdi-cash"
                              :label="$t('home.montant')"
                              dense
                              type="number"
                              clearable
                              outlined
                            >
                            </v-text-field>
                          </div>
                          <div class="col-md-5" v-if="send">
                            <v-chip class="curency">
                              {{ send.devise.libelle }}
                            </v-chip>
                          </div>
                          <div class="col-md-12" v-show="show == 1">
                            <v-autocomplete
                              v-model="villebysend"
                              :items="itemville"
                              :label="$t('home.city')"
                              prepend-inner-icon="mdi-city"
                              dense
                              item-text="nom"
                              item-value="id"
                              return-object
                              outlined
                            >
                            </v-autocomplete>
                          </div>
                          <!-- <div class="col-md-12" v-show="show2 == 1">
                            <v-autocomplete
                              v-model="agencebysend"
                              :items="itemagence"
                              prepend-inner-icon="mdi-store"
                              dense
                              item-text="nom"
                              item-value="id"
                              return-object
                              outlined
                            >
                            </v-autocomplete>
                          </div> -->
                          <div class="col-md-12" v-show="show3 == 1">
                            <v-autocomplete
                              v-model="moyenrecup"
                              :items="itemrecup"
                              prepend-inner-icon="mdi-arrow-left-right"
                              dense
                              :label="$t('home.recup')"
                              item-text="libelle"
                              item-value="id"
                              return-object
                              outlined
                            >
                            </v-autocomplete>
                          </div>
                        </div>
                        <div>
                          <div v-if="money || tauxechange || moyenrecup">
                            <ul class="list-unstyled">
                              <li>
                                {{ $t("home.montant") }}:
                                <span class="float-end fees" v-if="send">
                                  {{ money + " " + send.devise.libelle }}
                                </span>
                              </li>
                              <li>
                                {{ $t("home.change") }}:
                                <span class="fees float-end" v-if="tauxechange">
                                  {{
                                    tauxechange.taux + " " + tauxechange.deviseRec.libelle
                                  }}
                                </span>
                              </li>
                              <li>
                                {{ $t("home.Frais") }}:
                                <span
                                  class="fees float-end"
                                  v-if="moyenrecup && tauxechange"
                                >
                                  {{
                                    (
                                      parseFloat(moyenrecup.frais) /
                                      parseFloat(tauxechange.taux)
                                    ).toFixed(2) +
                                    " " +
                                    send.devise.libelle
                                  }}
                                </span>
                              </li>
                            </ul>
                            <ul
                              class="list-unstyled"
                              v-if="money || tauxechange || moyenrecup"
                            >
                              <li>
                                <span class="total">{{ $t("home.montant2") }}: </span>
                                <span class="total float-end" v-if="moyenrecup">
                                  {{
                                    (
                                      parseFloat(money) +
                                      parseFloat(moyenrecup.frais) /
                                        parseFloat(tauxechange.taux)
                                    ).toFixed(2) +
                                    " " +
                                    send.devise.libelle
                                  }}
                                </span>
                              </li>
                              <li>
                                <span class="total">{{ $t("home.montant3") }}: </span>
                                <span class="total float-end" v-if="moyenrecup">
                                  {{
                                    parseFloat(money) * parseFloat(tauxechange.taux) +
                                    " " +
                                    moyenrecup.pays.devise.libelle
                                  }}
                                </span>
                              </li>
                            </ul>
                            <!-- <span class="text">
                                <span v-if="send"> </span>
                                <br />
                              </span>
                              <span class="fees float-end">
                                <br />
                              </span>
                              Frais de transaction:
                              <span class="fees float-end" v-if="moyenrecup">
                              </span>
                              <br />
                            </span> -->
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section bg">
        <div class="container head">
          <div class="mb-4">
            <h2>{{ $t("home.h2") }}</h2>
          </div>
          <div class="row g-4">
            <div class="col-lg-4">
              <div class="featured-box style-3">
                <div class="featured-box-icon text-light">
                  <span class="w-100 text-20 font-weight-500">1</span>
                </div>
                <h3>{{ $t("home.h3") }}</h3>
                <p class="text-3">
                  {{ $t("home.headerp") }}
                </p>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="featured-box style-3">
                <div class="featured-box-icon text-light">
                  <span class="w-100 text-20 font-weight-500">2</span>
                </div>
                <h3>{{ $t("home.featuredh3") }}</h3>
                <p class="text-3">
                  {{ $t("home.featuredp") }}
                </p>
              </div>
            </div>
            <div class="col-lg-4 mb-4 mb-sm-0">
              <div class="featured-box style-3">
                <div class="featured-box-icon text-light">
                  <span class="w-100 text-20 font-weight-500">3</span>
                </div>
                <h3>{{ $t("home.featuredboxh3") }}</h3>
                <p class="text-3">
                  {{ $t("home.featuredboxp") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-bg">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
            <div class="support">
              <img src="../assets/main.jpg" alt="" />
            </div>
          </div>
          <div class="col-md-12 col-lg-6 col-sm-12">
            <div class="right-caption">
              <div class="title mb-50">
                <span class="other">{{ $t("home.sectionspan") }}</span>
                <h2 class="other">{{ $t("home.sectionh2") }}</h2>
              </div>
              <div class="caption">
                <p class="other">
                  {{ $t("home.landing") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
    </div>
    <Footer />
    <v-dialog v-model="avert" max-width="400">
      <v-card>
        <v-card-title class="headline">{{ $t("avert.one") }}</v-card-title>
        <v-card-text> {{ $t("avert.avert") }} </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="avert = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import Footer from "../components/Footer.vue";
export default {
  components: { Footer },
  data() {
    return {
      langue: "",
      html: "",
      send: "",
      receive: "",
      villebysend: "",
      agencebysend: "",
      money: "",
      moyenrecup: "",
      avert: false,
      show: 0,
      show2: 0,
      show3: 0,
    };
  },
  watch: {
    send() {
      // if (this.send.id > 0) {
      //   this.show = 1;
      //   this.$store.dispatch("getvillebypays", this.send.id);
      // }
    },
    receive() {
      if (this.receive.id > 0) {
        this.show = 1;
        this.$store.dispatch("getvillebypays", this.receive.id);
        this.$store
          .dispatch("gettauxbydevise", {
            send: this.send.devise.id,
            receive: this.receive.devise.id,
          })
          .then(() => {
            const timer = () => {
              const status = localStorage.getItem("status");
              if (status == 200) {
                this.$store.dispatch("getmoyenrecupbypays", this.send.id);
                if (this.tauxechange.taux == undefined) {
                  this.avert = true;
                  this.$refs.clear.reset();
                  this.$refs.clear1.reset();
                }
                localStorage.removeItem("status");
              } else if (status == 400) {
              }
            };
            setTimeout(timer, 0.1 * 100, 0.1);
          });
      }
    },
    villebysend() {
      if (this.villebysend.id > 0) {
        this.show2 = 1;
        this.$store.dispatch("getagencebyville", this.villebysend.id);
        this.$store.dispatch("getmoyenrecupbypays", this.receive.id);
        this.show3 = 1;
      }
    },
    // agencebysend() {
    //   if (this.agencebysend.id > 0) {
    //   }
    // },
  },
  created() {
    if (localStorage.getItem("lang") == null) {
      localStorage.setItem("lang", "fr");
    }
    this.langue = localStorage.getItem("lang");
  },
  computed: {
    ...mapGetters(["allpays", "villebypays", "agencebyville", "recup", "tauxechange"]),
    itemville() {
      if (this.villebypays != null) {
        return this.villebypays;
      } else {
        return [];
      }
    },
    itemListpays() {
      if (this.allpays != null) {
        return this.allpays.filter((item) => item.devise != null);
      }
    },
    itemagence() {
      if (this.agencebyville != null) {
        return this.agencebyville;
      } else {
        return [];
      }
    },
    itemrecup() {
      if (this.recup != null) {
        return this.recup;
      } else {
        return [];
      }
    },
    // html() {
    //   return DOMPurify.sanitize(md.render(this.markdown));
    // },
  },
  methods: {
    changelanguage() {
      localStorage.setItem("lang", this.langue);
      window.location.reload();
    },
  },
  mounted() {
    this.show = 0;
    this.show2 = 0;
    this.show3 = 0;
    this.$store.dispatch("getallpays");
    this.$store.state.ville.villebypays = null;
    this.$store.state.devise.tauxechange = 0;
    this.$store.state.agence.agencebyville = null;
    this.$store.state.agence.recup = null;
    if (this.villebypays == null) {
      this.$store.state.ville.villebypays = [];
    }
    if (this.agencebyville == null) {
      this.$store.state.agence.agencebyville = [];
    }
    if (this.recup == null) {
      this.$store.state.agence.recup = [];
    }
  },
};
</script>
<style scoped>
ul {
  padding-left: 0 !important;
  line-height: 1.8rem;
}
.text {
  text-align: center;
  text-justify: auto;
}
.bg {
  background-color: white;
}
.fees {
  color: #7941b9;
  font-size: 25px;
}

.featured-box.style-3 {
  padding-left: 90px;
  padding-top: 0px;
}
.featured-box {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.featured-box.style-3 .featured-box-icon {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 30px;
  justify-content: center !important;
  text-align: center;
}

.fw-500 {
  font-weight: 500 !important;
}
.text-20 {
  font-size: 92px !important;
  font-size: 5.75rem !important;
  color: #2b2e83;
}
.total {
  color: green;
  font-size: 25px;
  text-align: center;
}
.logo {
  width: 8rem;
}
.log {
  background-color: #2b2e83;
  color: white;
}
.head {
  background-image: url("../assets/section.jpg");
}
.curency {
  background-color: #16453e !important;
  font-size: 1.5rem !important;
  color: white;
}
.head h2 {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 17px;
  color: #2d2d2d;
  display: inline-block;
  position: relative;
  padding-left: 68px;
}
.section {
  position: relative;
  padding: 104px 0;
  padding: 6.5rem 0;
}
.section2 {
  position: relative;
}

@media only screen and (max-width: 768px) {
  .section {
    padding: 3.5rem 0;
  }
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px !important;
  }
}
.hero-bg {
  background-image: url("../assets/2.jpg") !important;
  background-position: center;
}
.section-bg {
  /* background-image: url("../assets/section2.jpg") !important; */
  padding-top: 9.5rem;
  padding-bottom: 9rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
}
.section-bg .title h2 {
  font-size: 50px;
  display: block;
  color: #2b2e83;
  font-weight: 900;
}
.section-bg .caption p {
  font-weight: 420;
  font-size: 15px;
  text-align: left;
  color: #2d2d2d;
}
.section-bg .title span {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 17px;
  color: #2d2d2d;
  display: inline-block;
  position: relative;
  padding-left: 68px;
}
.section-bg .title span::before {
  position: absolute;
  content: "";
  width: 54px;
  height: 2px;
  background: #2b2e83;
  top: 0;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.support img {
  width: 100%;
}
.section-bg .right-caption {
  right: -20px;
}

@media (max-width: 575px) {
  .section-bg .right-caption {
    right: 0px;
    margin-bottom: 40px;
    padding-top: 50px;
  }
  .title {
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-bg.right-caption {
    right: -25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-bg .right-caption {
    right: 0px;
    margin-bottom: 40px;
    padding-top: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-bg.right-caption {
    right: 0px;
    margin-bottom: 40px;
    padding-top: 50px;
  }
}
/* @media screen and (max-width: 1254px) {
  .other {
    color: whitesmoke !important;
  }
} */
</style>
