import axios from "axios";
import { baseURL } from "../../main";

const state = {
  allpays:null,
  pays: null,
  searchpays: null,
  removedevise:null,
};
const getters = {
  allpays: (state) => state.allpays,
  pays: (state) => state.pays,
  searchpays: (state) => state.searchpays,
  removedevise: (state) => state.removedevise
};

const actions = {

  async removecountrydevise({commit},id)
	{
		let token = localStorage.getItem("tk");
    await axios
      .delete(baseURL + "pays/devise/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setremovedevise", data);
        }
      });
	},
  async updatepays({commit},{id, value})
	{
		let token = localStorage.getItem("tk");
    await axios
      .put(baseURL + "pays/" + id, value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setupdatepays", data);
        }
      });
	},
  async addpays({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "pays", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          data
          localStorage.removeItem("tk");
          commit("setnews", data);
        }
      });
  },
  async getallpays({ commit }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "auth/pays" ,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    data
    commit("setallpays", data);
  },
  async getpays({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "pays/page/" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    data
    commit("setpays", data);
  },
  async searchpays({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "pays/search/page/" + page + "/" + search,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setpays", data);
  },
};
const mutations = {
  setpays(state, pays) {
    state.pays = pays;
  },
  setupdatepays(state, updatepays) {
    state.updatepays = updatepays;
  },
  setallpays(state, allpays) {
    state.allpays = allpays;
  },
  setnews(state, newpays) {
    state.newpays = newpays;
  },
  setsearchpays(state, searchpays)
  {
    state.searchpays= searchpays
  },
  setremovedevise(state, removedevise)
  {
    state.removedevise= removedevise
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
