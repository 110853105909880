export default {
  home: {
    h2: "Envoyer votre argent en toute sécurité",
    h3: "Connexion à votre compte",
    envoie: "Sélectionnez le pays d'envoi.",
    headerp:
      "Devenez d'abord un utilisateur enregistré, puis connectez-vous à votre compte et entrez les coordonnées nécessaires.",
    featuredh3: "Sélectionnez votre destinataire",
    featuredp:
      "Saisissez les coordonnées de votre destinataire, puis ajoutez un montant avec la devise à envoyer en toute sécurité.",
    featuredboxh3: "Envoyez l'argent",
    featuredboxp:
      " Après avoir envoyé de l'argent, le destinataire sera informé du transfert de l'argent sur son compte et le recupera dans l'agence le plus proche de chez lui.",
    sectionspan: "Envoyez de l'argent n'a jamais été aussi facile",
    sectionh2: "La disponibilité",
    montant: "Montant",
    receive: "Sélectionnez le pays de réception.",
    city: "Ville du destinataire",
    recup: "Moyen de récupération",
    btn: "Faite une simulation",
    h2: " Envoyez de l'argent avec un meilleur taux de change et évitez les frais bancaires excessifs.",
    span: "Une meilleure façon",
    span2: "d'envoyer de l'argent",
    landing:
      "Les transactions sont également traçables, ce qui ajoute une couche supplémentaire de sécurité.Les frais de transfert d'argent par le biais de s2apay sont moins élevés que les frais de transfert d'argent via d'autres méthodes, telles que les virements bancaires ou les chèques.Nous offront  des options pour les transferts internationaux, avec des taux de change compétitifs et des frais raisonnables.Flexibilité de paiement  par d'autres méthodes de paiement telles que les portefeuilles électroniques.En somme, Nous offrons une solution pratique et efficace pour les transferts d'argent rapides, sûrs et abordables.",
    montant: "Montant",
    montant2: "Montant  a envoyé",
    change: "Taux de change",
    Frais: "Frais de transaction",
    montant3: "Montant à recevoir",
  },
  avert: {
    one: "Avertissement",
    avert: "vous ne pouvez pas envoyer de l'argent dans ce pays",
    averts: "vous ne pouvez pas envoyer de l'argent a cet utilisateur",
  },
  agence: {
    nous: "Nos agence",
    user: "Affectation d'agent ",
    detail: "Voir plus",
    avert: "Avertissement",
    avert1: "Cet utilisateur est déjà affecté à une agence. ",
    save: "Enregistrer",
    updates: "Modifier",
    close: "Fermer",
    delete: "Supprimer",
    stop: "Annuler",
    update: "Mise à jour effectuée avec succès",
    add: "Agence créée avec succès",
    liste: "L'agence",
    user: "Ajouter un agent dans l'agence",
    new: "Nouvelle agence",
    confirm: "Supprimer la confirmation",
    confirm2: "Êtes-vous sûr de vouloir supprimer cet agent ?",
  },
  step: {
    one: "Information personnelle",
    two: "Information bancaire",
    tree: "Code Pin",
    next: "Suivant",
    end: "Enregistrer",
  },
  require: {
    pass: "Mots de passe requis",
    username: "Nom d'utilsateur requis",
    pin: "Code pin obligatoire",
    email: "L'adresse email est requis",
  },
  rule: {
    pin: "Le code PIN doit contenir exactement 6 chiffres",
    pass: "Le mot de passe doit contenir au moins un chiffre, une lettre minuscule, une lettre majuscule et un caractère spécial parmi ces caractères : !@#&()-[{]}. De plus, il doit être compris entre 6 et 40 caractères de longueur",
    username:
      "Le nom d'utilisateur doit contenir au moins une lettre, ne doit pas contenir de caractères spéciaux autres que les points (.) et les underscores (_), doit être compris entre 4 et 20 caractères de longueur et ne doit pas commencer ni se terminer par un point ou un underscore. De plus, il ne doit pas contenir deux points ou deux underscores consécutifs.",
    mail: "Adresse Email non valide",
  },
  action: {
    close: "Fermer",
    save: "Enregistrer",
  },
  confirm: {
    one: "Confirmation de d'operation",
    two: "Confirmer la reception de:",
  },
  dialog: {
    agence: "Agence",
    bank: "Nom de la banque",
    nomcompte: "Intitule compte",
    numcompte: "Numero du compte",
    code: "Code",
    delete: "Suppression effectuer",
    message: "Êtes-vous sûr de vouloir supprimer cet élément ?",
    title: "Confirmation de suppression",
    aucun: "Aucun moyen de retrait",
    aucun2:
      " Malheureusement, vous n'avez definis aucun moyen de retrait, Veuillez en creer un.",
    aucun3: "definir",
  },
  recharge: "Recharger Compte",
  montant: "Montant",
  login: {
    error: "Une erreur a été détecter, vérifier les informations saisies",
    succes: "Compte créer avec succès ! vous allez être redirigé",
  },
  reversement: {
    reversement: "reversement",
  },
  admin: {
    pin: "Code Pin",
    defpin: "Definir votre code pin",
    defpassword: "Modifier votre mots de passe",
    def: "Definir",
  },
  retrait: {
    retrait: "Retrait",
    ligne: "Retrait en agence",
    banque: "Retrait en ligne",
  },
  solde: {
    error: "Solde insuffisent pour effectuer cette transaction",
  },
  menu: {
    money: "Monnaie",
    list: "Les Monnaie",
    operatiiontype: "Type operation",
  },
  devise: {
    devise: "Devise",
  },
  kiosk: {
    kiosk: "Kiosk",
    list: "Liste des kiosks",
  },
  recup: {
    recup: "Moyen de recuperation",
  },
  taux: {
    taux: "Taux",
  },
  documentation: {
    documentation: "documentation",
  },
  pays: {
    pays: "Pays",
    modif: "Modification Effectuer ",
    list: "Les pays",
  },
  historique: {
    historique: "Historique",
    transfert: "Transfert",
    valider: "Transfert valider",
    kiosk: "Benefice",
  },
  ville: {
    ville: "Ville",
    update: "Modication Effectuer",
    add: "Ville Ajouter avec success",
    list: "Les Villes",
  },
  update: {
    update: "Modifier",
  },
  preuve: {
    preuve: "Preuve",
    recharge: "Recharge",
    transfert: "Transfert",
    preuvelist: "Les preuves",
  },
  request: {
    request: "Demande",
    retrait: "Retrait",
    un: "1- Choisissez une agence parmi les agences  affichées  dans le champ agence",
    deux: "2- Faites un dépôt sur le compte bancaire de l'agence Sélectionner",
    trois: "3- Enfin téléverser la preuve et valider",
    nb: "NB: votre solde sera rechargé lorsque l'agence traitera et validera votre requête.",
  },
  operation: {
    typelist: "Type operation",
  },
  user: {
    user: "Utilisateur",
    useradd: "Ajouter  Utilsateurs",
    userlist: "Liste  Utilsateurs",
    adminlist: "Administrateur",
    clientlist: "Clients",
    bank: "Informations bancaire",
    agentlist: "Liste des Agents",
    developerlist: "Liste des Developer",
    agent: "Liste des agents dans l'agence",
    succes: "Utilisateur a été enregistré !",
    profil: "Profil",
    solde: "Solde disponible",
    info: "Information personelle",
    setting: "Paramètre du compte",
    compte: "Numéro du compte",
    request: "Rechargement",
    montant: "Montant",
    benefice: "Bénéfice",
    moyens: "Informations de retrait",
    requestagence: "Requête de rechargement de Mon Portefeuille",
    requestretrait: "Requête Pour Retrait d'argent",
  },
  pin: {
    pins: "Code pin",
    pin: "Erreur de code pin",
    succes: "Opérations effectuer !",
  },
  Transaction: {
    all: "Toutes",
    send: "Debit",
    receive: "Credit",
    save: "Enregistrer",
    succes: "Opérations effectuer !",
    save: "Créditer",
    Devisadd: "Nouveaux devis",
    national: "Intrazone",
    international: "Extrazone",
    sends:
      "Dépôt effecteur, veuillez demander à l'agent de confirmer la transaction",
  },
  Transfert: {
    Transfert: "Transfert",
    receive: "Nom et prenom du receveur",
    adresse: "Adresse du recepteur",
    contact: "Contact du recepteur",
    montant: "Montant",
    city: "Votre ville",
    countryrecv: "Pays du recepteur",
    cityrecv: "Ville du recepteur",
    moyen: "Moyen de recuperation",
    intitule: "Intitule compte",
    institution: "Nom banque",
    numcompte: "Numero compte",
    pin: "Code pin",
    paiement: " Moyen de paiement",
    extrazone: "Transfert International",
    intrazone: "Transfert Zone dememe devise",
    pop: "Transfère de l'argent.",
    detail: "Details Transaction",
    total: "Total",
    send: "Destinataire",
    name: "Nom/Prenom",
    phone: "Telephone",
    country: "Pays",
    status: "Status",
    valide: "Valider",
    wait: "En attente",
    pin: "Code pin",
    wallet: "Wallet du receveur",
  },
  Portefeuille: {
    Portefeuille: "Portefeuille",
    Portefeuillelist: "Portefeuilles",
    Portefeuilleagent: "Portefeuilles Agents",
    Portefeuilleadmin: "Charger mon portefeuille",
    Portefeuilleadd: "Nouvelle Portefeuille",
    Portefeuilleuser: " Portefeuilles Clients",
  },
  headers: {
    code: "Code",
    depart: "Depart",
    arriver: "Arriver",
    agent: "Agent",
    nom: "Nom",
    client: "Client",
    kiosk: "Kiosk",
    agent: "Agent",
    admin: "Admin",
    moyen: "Moyen",
    detail: "Details",
    dev: "Developpeur",
    march: "Marchands",
    libelle: "Libelle",
    pourcentage: "Pourcentage",
    statut: "Statut",
    description: "Description",
    nomproprio: "Nom du propriétaire",
    prenomproprio: "prenom du propriétaire",
    adresse: "Adresse",
    prenom: "Prenom",
    pays: "Pays",
    old: "Ancien mots de passe",
    news: "Nouveaux mots de passe",
    password: "Mot de passe",
    confirmpassword: "Confirmer le mot de passe",
    details: "Details",
    ville: "Ville",
    telephone: "Telephone",
    email: "Email",
    recups: "Moyen de retrait",
    pointlivraison: "Point de livraison",
    username: "Nom d'utilisateur",
    indicatif: "Indicatif",
    role: "Roles",
    solde: "Solde",
    add: "Ajouter",
    cni: "Cartes nationales d'identité ou passeport",
    deplacement: "Avez-vous un moyen de déplacement ?",
    envoyeurN: "Nom Envoyeur",
    envoyeurP: "Prenom Envoyeur",
    envoyeurU: "Username Envoyeur",
    intitule: "Intitule compte",
    institution: "Nom banque",
    numcompte: "Numero compte",
    codecvc: "Code banque",
    receiveN: "Nom Receveur",
    receiveP: "Prenom Receveur",
    receiveU: "Username Receveur",
    operation: "Operation",
    montant: "Montant",
    date: "Date",
    fichier: "Fichier",
    agence: "Agence",
    Etat: "Etat",
    Anuller: "Annuler",
    action: "Actions",
    devise: "Devise",
    villereception: "Ville de reception",
    moyenrecup: "Moyen recuperation",
    frais: "Frais",
    taux: "Taux",
    concat: "Nom et prenom",
    valider: "Valider",
    id: "Id",
  },
  conditions: "Condition d'utilisation",
  condition1: "Nouveaux clients uniquement,",
  condition2: "une offre par client",
  condition3:
    "offre à durée limitée, les taux indiqués sont susceptibles de changer.",
  condition4: "Consultez les conditions d'utilisation pour en savoir plus.",
  Save: "Enregistrer",
  create: "Créer le compte",
  new: "Inscription",
  log: "Connexion",
  hey: "Salut",
  account: "Compte",
  define:
    "Veuillez définir votre code pin avant de pouvoir effectuer des transactions",
  android:
    "Veuillez télécharger la version mobile de s2apay sur le google play store.",
  ios: "Veuillez télécharger la version mobile de s2apay sur le apple play store.",
  logout: "Déconnexion",
  wallet: "Portefeuille",
  condition: {
    1: "Bienvenue sur notre plateforme de paiement mobile S2APay",
    2: " Veuillez lire attentivement ces conditions d'utilisation avant d'utiliser notre application mobile. En utilisant notre application mobile, vous acceptez les termes et les conditions énoncés dans ce document.",
    titre: "Politique de Confidentialité",
    x1: "S2APAY respecte votre vie privée. Cette politique de confidentialité explique quelles informations nous collectons, comment nous les utilisons, et les choix que vous avez en ce qui concerne ces informations.",
    x2: "Informations que nous collectons",
    x2t: "Nous collectons les types d'informations suivants :",
    x2c: "Informations personnelles : Lors de l'utilisation de notre application s2apay, nous pouvons vous demander de fournir certaines informations personnelles identifiables, telles que votre nom, adresse e-mail, numéro de téléphone, et autres informations similaires.",
    x2c1: "Informations de Contact List : Notre application peut accéder à votre liste de contacts pour lancer l'appel ou discuter par sms ou Whatsapp",
    x3: "Utilisation des Informations",
    x3t: "Nous utilisons les informations collectées pour les finalités suivantes :",
    x3c: "Fournir et améliorer nos services : Nous utilisons vos informations pour exploiter, maintenir, améliorer et fournir toutes les fonctionnalités de notre application.",
    x3c1: "Communication : Nous pouvons utiliser vos informations de contact pour vous envoyer des notifications, des mises à jour, ou des messages promotionnels.",
    x4: "Sécurité des Informations",
    x4t: "Nous mettons en œuvre des mesures de sécurité techniques et organisationnelles pour protéger vos informations contre l'accès non autorisé, la perte, l'utilisation abusive ou la divulgation. Cependant, aucune méthode de transmission ou de stockage n'est totalement sécurisée, nous ne pouvons donc garantir une sécurité absolue.",
    x5: "Vos Droits et Choix",
    x5t: "Vous avez le droit de :",
    x5c: "Accéder à vos informations personnelles que nous détenons.",
    x5c1: "Demander la rectification ou la suppression de vos informations.",
    x5c2: "Retirer votre consentement à tout moment pour l'utilisation de vos informations.",
    x6: "Modifications de la Politique de Confidentialité",
    x6t: "Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront publiées sur cette page avec une date de mise à jour. Nous vous encourageons à consulter régulièrement cette politique pour rester informé.",
    3: "Règles et responsabilités de l'utilisateur",
    4: " Utiliser l'application mobile uniquement à des fins légales et conformément aux lois applicables.",
    5: "Ne pas tenter de pirater ou d'altérer l'application mobile ou son contenu.",
    6: " Ne pas utiliser notre application mobile pour effectuer des transactions frauduleuses.",
    7: "Utilisation de l'argent sur notre plateforme",
    8: "  L'argent qui transite sur notre plateforme de paiement mobile est soumis aux conditions suivantes :",
    9: " Nous ne sommes pas responsables de la perte d'argent due à une erreur de  l'utilisateur.",
    10: " Nous ne sommes pas responsables de la perte d'argent due à une transaction non autorisée ou frauduleuse.",
    11: "Nous ne sommes pas responsables de la perte d'argent due à une perturbation du service.",
    12: " Nous ne sommes pas responsables de la perte d'argent due à une incapacité de notre part à retirer les fonds de votre compte.",
    13: "Propriété intellectuelle",
    14: " Tous les droits d'auteur, les marques de commerce, les brevets et autres droits de propriété intellectuelle liés à notre plateforme de paiement mobile sont la propriété exclusive de notre entreprise. Vous acceptez de ne pas utiliser, reproduire ou distribuer ces éléments sans notre autorisation écrite.",
    15: "Limites de responsabilité",
    16: "Notre entreprise ne sera pas tenue responsable des pertes ou dommages subis par l'utilisateur en raison de l'utilisation de notre plateforme de paiement mobile, y compris les pertes financières, les pertes de données, les violations de la vie privée, etc.",
    17: "Résiliation du contrat",
    18: " Nous nous réservons le droit de résilier le contrat entre l'utilisateur et notre entreprise à tout moment, sans préavis et sans responsabilité envers l'utilisateur. Vous pouvez également résilier le contrat en cessant d'utiliser notre application mobile.",
    19: "Loi applicable et juridiction compétente",
    20: " Ces conditions d'utilisation sont régies par les lois applicables du Togo. Tout litige découlant de l'utilisation de notre plateforme de paiement mobile sera soumis à la juridiction compétente du Togo.",
    21: "En utilisant notre plateforme de paiement mobile, vous acceptez ces conditions d'utilisation. Si vous n'acceptez pas ces termes, veuillez ne pas utiliser notre application mobile.",
    22: "Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Les modifications seront effectives dès leur publication sur notre site Web. Veuillez vérifier régulièrement les conditions d'utilisation pour vous assurer que vous êtes informé des mises à jour.",
    23: "Si vous avez des questions concernant ces conditions d'utilisation, veuillez nous contacter à l'adresse e-mail suivante :",
  },
  reset: {
    password: "Réinitialisation de mots de passe",
    notmatch: "Les deux mots de passe ne correspondent pas",
    info: "Saisissez votre adresse e-mail et les instructions vous seront envoyées !",
    success: "Consulter votre adresse email pour suivre les instructions",
    remember: "Vous en souvenez-vous ?",
    click: "Cliquez ici !",
    new: "Créez votre mot de passe",
    error: "Cet utilisateur n'existe pas",
  },
};
