<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <div class="col-lg-5">
        <v-card class="signup">
          <v-card-text>
            <div class="text-center">
              <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                center
                absolute
                bottom
                color="#1e824c"
              >
                {{ $t("Transaction.succes") }}

                <template v-slot:action="{ attrs }">
                  <v-btn color="#cf000f" text v-bind="attrs" @click="snackbar = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
            <div class="text-center">
              <v-snackbar
                v-model="error"
                :timeout="timeout"
                absolute
                bottom
                color="red"
                center
              >
                {{ $t("devis.error") }}

                <template v-slot:action="{ attrs }">
                  <v-btn color="#cf000f" text v-bind="attrs" @click="snackbar = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
            <v-form @submit.prevent="save" ref="formsaveuser">
              <h2 class="text-center mt-4">
                {{ $t("user.requestagence") }}
              </h2>
              <div class="row mt-8">
                <div class="col-md-12">
                  <div class="form-group">
                    <v-text-field
                      v-model="montant"
                      class="ms-2 me-2"
                      type="number"
                      :rules="[rules.required]"
                      prepend-inner-icon="mdi-dialpad"
                      :label="$t('user.montant')"
                      clearable
                      ref="clear"
                      outlined
                      dense
                    ></v-text-field>
                    <v-file-input
                      class="mt-4 ms-2 me-2"
                      accept="images/png, image/jpeg, image/bmp"
                      v-model="preuve"
                      label="preuve"
                      :rules="[rules.required]"
                      ref="clear1"
                      outlined
                      dense
                    ></v-file-input>
                    <v-autocomplete
                      class="mt-4 ms-2 me-2"
                      v-model="city"
                      prepend-inner-icon="mdi-earth"
                      :items="villeList"
                      item-text="nom"
                      item-value="id"
                      label="Votre ville"
                      ref="clear2"
                      :rules="[rules.required]"
                      return-object
                      outlined
                      dense
                    >
                    </v-autocomplete>
                    <v-autocomplete
                      class="mt-4 ms-2 me-2"
                      v-model="agences"
                      prepend-inner-icon="mdi-earth"
                      :items="agenceList"
                      item-text="nom"
                      item-value="id"
                      label="Les agences"
                      ref="clear3"
                      :rules="[rules.required]"
                      return-object
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="form-group">
                  <button class="w-100 btn log save" type="submit" value="register">
                    {{ $t("admin.def") }}
                  </button>
                </div>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
      <div class="col-lg-2">
        <v-card color="#2d2d2d">
          <v-card-title><h1 class="blck">Guide</h1></v-card-title>
          <v-card-text>
            <h5>
              {{ $t("request.un") }}
            </h5>
            <h5>
              {{ $t("request.deux") }}
              <router-link to="/dashboard/agence">
                <a>{{ $t("agence.nous") }}</a>
              </router-link>
            </h5>
            <h5>{{ $t("request.trois") }}</h5>
            <span class="blck">
              {{ $t("request.nb") }}
            </span>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import { baseURL } from "../../../main";
import Aside from "../../../components/aside.vue";
import { mapGetters } from "vuex";

export default {
  components: { Aside },
  data: () => {
    return {
      show2: "",
      pins: null,
      confirm: false,
      solde: false,
      oui: false,
      agences: "",
      city: "",
      eye: false,
      eyes: true,
      snackbar: false,
      preuve: null,
      error: false,
      timeout: 2000,
      montant: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
    };
  },
  computed: {
    ...mapGetters(["one", "agencebyville", "villebypays", "me", "token"]),
    agenceList() {
      if (this.agencebyville != null) {
        // console.log(this.agencebyville.filter((item) => item.typeAgence.id == 1));
        return this.agencebyville.filter((item) => item.typeAgence.id == 1);
      } else {
        return [];
      }
    },
    villeList() {
      if (this.villebypays != null) {
        return this.villebypays;
      } else {
        return [];
      }
    },
  },
  watch: {
    // alert(1);
    city() {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("getagencebyville", this.city.id);
    },
  },
  methods: {
    reset() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
      this.$refs.clear2.reset();
      this.$refs.clear3.reset();
    },
    show() {
      this.eye = false;
      this.solde = false;
    },
    shows() {
      this.eye = true;
      this.solde = true;
    },
    valid() {
      this.oui = true;
      this.conf();
    },

    form() {},
    save() {
      if (this.$refs.formsaveuser.validate()) {
        let request = new FormData();
        request.append("images", this.preuve, this.preuve.name);
        request.append("montant", this.montant);
        request.append("agence", this.agences.id);
        localStorage.setItem("tk", this.$store.getters.token);
        this.$store.dispatch("adduserpreuve", request).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.snackbar = true;
            this.reset();
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getoneportefeuille", this.$store.getters.user.id);
            localStorage.removeItem("status");
          } else if (status == 400) {
            this.error = true;
            localStorage.removeItem("status");
          }
        });
      }
    },
  },
  mounted() {
    // this.$store.dispatch("getroles");
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getoneportefeuille", this.$store.getters.user.id);
    this.$store.dispatch("getvillebypays", this.one.user.pays.id);
    this.$store.dispatch("getagencebypays", this.one.user.pays.id);
  },
};
</script>
<style scoped>
.num {
  font-variant-numeric: tabular-nums !important;
  color: #e30613;
}
.container-100 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 100px 0px 0px 0px;
}
.save {
  background-color: #16453e;
  color: #eee7ce;
}
.back {
  background-color: whitesmoke;
}
.signup {
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 70px;
}
h5 {
  line-height: 1.4;
  color: white;
}
.blck {
  color: white;
}
</style>
