<template>
  <main class="container-fluid">
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-9 mb-4">
        <div class="row">
          <div class="col-md-4 mb-4">
            <v-toolbar>
              <v-text-field
                class="search"
                v-model.lazy="search"
                single-line
                hide-details
                label="search"
                @input="searchresult"
                append-icon="mdi-magnify"
              >
              </v-text-field>
            </v-toolbar>
          </div>
          <div class="col-md-6">
            <v-radio-group v-model="row" row>
              <v-radio :label="$t('headers.client')" value="3"></v-radio>
              <v-radio :label="$t('headers.agent')" value="4"></v-radio>
              <v-radio :label="$t('headers.kiosk')" value="5"></v-radio>
              <v-radio :label="$t('headers.admin')" value="1"></v-radio>
              <v-radio :label="$t('headers.dev')" value="2"></v-radio>
              <v-radio :label="$t('headers.march')" value="6"></v-radio>
            </v-radio-group>
          </div>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-2 row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("Portefeuille.Portefeuillelist") }}</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      items: [],
      snackbar: false,
      timeout: 2000,
      loading: false,
      search: null,
      row: null,
      current_page: 1,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: "Username",
          align: "start",
          sortable: false,
          value: "user.username",
        },
        {
          text: this.$t("headers.nom"),
          align: "start",
          sortable: false,
          value: "user.nom",
        },
        {
          text: this.$t("headers.prenom"),
          align: "start",
          sortable: false,
          value: "user.prenom",
        },
        {
          text: this.$t("headers.telephone"),
          align: "start",
          sortable: false,
          value: "user.tel",
        },
        {
          text: this.$t("headers.email"),
          align: "start",
          sortable: false,
          value: "user.email",
        },
        {
          text: this.$t("headers.solde"),
          align: "start",
          sortable: false,
          value: "solde",
        },
        { text: "numero", align: "start", sortable: false, value: "numero" },
      ],
    };
  },
  computed: {
    ...mapGetters(["compte", "token", "roles", "me"]),
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.compte && this.compte.last_page) {
        return this.compte.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.compte != null && this.compte.data) {
        return this.compte.data.filter(
          (item) => item.user.pays.id === this.me.pays.id && item.user.pays != null
        );
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search() {
      if (this.search == "") {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getallportefeuille", this.current_page);
      }
    },
    row() {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("getportefeuillebyrole", {
        id: parseInt(this.row),
        page: this.current_page,
      });
    },
  },
  methods: {
    ici(row) {},

    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getallportefeuille", this.current_page);
      } else {
        this.$store.dispatch("searchcompte", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {
      if (this.search) {
        if (this.search.trim() == "") {
          localStorage.setItem("tk", this.token);
          this.current_page = 1;
          this.$store.dispatch("getallportefeuille", this.current_page);
        } else {
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("searchcompte", {
            page: this.current_page,
            search: this.search.trim(),
          });
          if (this.$store.state.portefeuille.compte.data) {
            this.current_page = this.$store.state.portefeuille.compte.current_page;
          } else this.current_page = 1;
        }
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getallportefeuille", this.current_page);
    this.$store.dispatch("getme");
  },
  created() {
    // console.log(this.$store.state.portefeuille.compte.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
</style>
