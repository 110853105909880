<template>
  <main class="container">
    <v-dialog v-model="modif" transition="dialog-top-transition" max-width="380">
      <template v-slot:default="dialog">
        <v-form ref="formupdate" @submit.prevent="update">
          <v-card>
            <v-card-text>
              <v-text-field
                v-model="libelle"
                name="libelle"
                type="text"
                label="libelle"
                outlined
                clearable
                dense
              >
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="dialog.value = false">Close</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="yellow accent-8" @click="update">update</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
    <v-dialog v-model="add" transition="dialog-top-transition" max-width="380">
      <template v-slot:default="dialog">
        <v-form ref="formadd" @submit.prevent="add">
          <v-card>
            <v-card-text>
              <v-text-field
                v-model="libelle"
                name="libelle"
                type="text"
                label="libelle"
                outlined
                clearable
                dense
              >
              </v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="dialog.value = false">Close</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="#2B2E83" style="color: white" @click="adddevise"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-9 mb-4">
        <div class="col-md-5 mb-4">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-1 shadow-sm rounded row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <div class="col-md-4">
                <v-toolbar-title>{{ $t("user.userlist") }}</v-toolbar-title>
              </div>
              <div class="col-md-8 d-flex mt-4 justify-content-end">
                <v-btn fab small color="#2d2d2d">
                  <!-- <v-btn fab small color="#26a151"> -->
                  <v-icon color="white" @click="close"> mdi-plus </v-icon>
                </v-btn>
              </div>
            </v-toolbar>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      id: "",
      libelle: "",
      taux: "",
      deviseExp: "",
      deviseRecp: "",
      Roles: "",
      username: "",
      add: false,
      modif: false,
      items: [],
      snackbar: false,
      timeout: 2000,
      loading: false,
      search: null,
      current_page: 1,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: "Id",
          align: "center",
          sortable: false,
          value: "id",
        },
        {
          text: this.$t("headers.libelle"),
          align: "center",
          sortable: false,
          value: "libelle",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["token", "alldevise"]),
    itemdevise() {
      if (this.alldevise != null) {
        return this.alldevise;
      } else {
        return [];
      }
    },
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.alldevise && this.alldevise.last_page) {
        return this.alldevise.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.alldevise != null && this.alldevise.data) {
        return this.alldevise.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search() {
      if (this.search == "" || this.search.trim() == null) {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getalldevises", this.current_page);
      }
    },
  },
  methods: {
    ici(row) {
      this.modif = true;
      this.id = row.id;
      this.libelle = row.libelle;
    },
    close() {
      this.libelle = "";
      this.add = true;
    },
    adddevise() {
      if (this.$refs.formadd.validate()) {
        const add = {};
        add.libelle = this.libelle.toUpperCase();
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("adddevise", add).then(() => {
          const statuts = localStorage.getItem("status");
          if (statuts == 200) {
            this.add = false;
            this.libelle = "";
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getalldevises", this.current_page);
          }
        });
      }
    },
    update() {
      if (this.$refs.formupdate.validate()) {
        const update = {};
        update.libelle = this.libelle.toUpperCase();
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("updatedevise", { id: this.id, value: update }).then(() => {
          const statuts = localStorage.getItem("status");
          if (statuts == 200) {
            this.modif = false;
            this.libelle = "";
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getalldevises", this.current_page);
          }
        });
      }
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getalldevises", this.current_page);
      } else {
        this.$store.dispatch("searchdevise", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {
      if (this.search) {
        if (this.search.trim() == "") {
          localStorage.setItem("tk", this.token);
          this.current_page = 1;
          this.$store.dispatch("getalldevises", this.current_page);
        } else {
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("searchdevise", {
            page: this.current_page,
            search: this.search.trim(),
          });
          if (this.$store.state.devise.alldevise.data) {
            this.current_page = this.$store.state.devise.alldevise.current_page;
          } else this.current_page = 1;
        }
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getalldevises", this.current_page);
  },
  created() {
    // console.log(this.$store.state.user.users.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
</style>
