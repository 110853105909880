import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'
import Dashboard from '../views/dashboard/main.vue'
import main from '../views/dashboard/index.vue'
import Login from "../views/auth/login.vue"
import Signup from "../views/auth/signup.vue"
import Recover from '../views/auth/reset.vue';
import Reset from '../views/auth/new.vue';
import All from "../views/dashboard/transaction/all.vue"
import Send from "../views/dashboard/transaction/send.vue"
import Receive from "../views/dashboard/transaction/receive.vue"
import Alloperation from "../views/dashboard/operation/all.vue"
import Sendoperation from "../views/dashboard/operation/send.vue"
import Receiveoperation from "../views/dashboard/operation/receive.vue"
import Marchoperation from "../views/dashboard/operation/receive.vue"
import Typeoperation from "../views/dashboard/operation/typeoperation.vue"
import Useradd from '../views/dashboard/user/add.vue'
import Userlist from '../views/dashboard/user/list.vue'
import Operation from '../views/dashboard/user/operation.vue'
import Userrequest from '../views/dashboard/user/request.vue'
import Userretrait from '../views/dashboard/user/retrait.vue'
import Userbanq from '../views/dashboard/user/banque.vue'
import Profil from '../views/dashboard/user/profil.vue'
import Agentlist from '../views/dashboard/agent/list.vue'
import Adminpin from '../views/dashboard/admin/pin.vue'
import Adminpassword from '../views/dashboard/admin/pass.vue'
import PreuveList from '../views/dashboard/preuve/list.vue'
import AddKiosk from '../views/dashboard/kiosk/add.vue'
import PreuveTransfertList from '../views/dashboard/preuve/listtransfert.vue'
import AgenceList from '../views/dashboard/agence/list.vue'
import MoneyListdevise from '../views/dashboard/money/deviselist.vue'
import MoneyListtaux from '../views/dashboard/money/tauxlist.vue'
import MoneyListrecup from '../views/dashboard/money/recuplist.vue'
import Pays from '../views/dashboard/pays/list.vue'
import Ville from '../views/dashboard/ville/list.vue'
import Moyen from '../views/dashboard/moyen/moyen.vue'
import Portefeuillelist from '../views/dashboard/portefeuille/list.vue'
import Portefeuilleadmin from '../views/dashboard/portefeuille/admin.vue'
import Addother from '../views/dashboard/user/addbyother.vue'
import Foruser from '../views/dashboard/agence/foruser.vue'
import Historique from'../views/dashboard/historique/historique.vue'
import Docs from '../views/dashboard/doc/doc.vue'
import Home from '../views/home.vue'
import Reversement from '../views/dashboard/revers/reversement.vue'
import Retraitcash from '../views/dashboard/devs/retrait.vue'
import Retraitbanque from '../views/dashboard/devs/retraitbanque.vue'
import Historiquevalider from '../views/dashboard/historique/valider.vue'
import Historiquebenefice from '../views/dashboard/historique/benefice.vue'
import Historiquetransfert from '../views/dashboard/historique/transfert.vue'
import Historiquereversement from '../views/dashboard/historique/reversement.vue'
import Condition from '../views/condition.vue'
Vue.use(VueRouter)

const token=localStorage.getItem("token")

const routes = [
	{
		path:'/dashboard',
		component: Dashboard,
		meta:{requiresAuth:true},
		children:[
			{
				path:'main',
				component:main
			},
			{
				path:'operationtype',
				component:Typeoperation
			},
			{
				path:'profil/moyen',
				component:Moyen,
			},
			{
				path:'profil/pin',
				component:Adminpin,
			},
			{
				path:'profil/pass',
				component:Adminpassword
			},
			{
				path:'profil/banq',
				component:Userbanq
			},
			{
				path:'payslist',
				component:Pays
			},
			{
				path:'villelist',
				component:Ville
			},
			{
				path:'user/useradd',
				component:Useradd
			},
			{
				path:'user/userlist',
				component:Userlist
			},
			{
				path:'profil/me',
				component:Profil
			},
			{
				path:'portefeuille/list',
				component:Portefeuillelist
			},
			{
				path:'portefeuille/admin',
				component:Portefeuilleadmin
			},
			{
				path:'portefeuille/agent',
				component:Agentlist
			},
			{
				path:'portefeuille/user',
				component: Operation
			},
			{
				path:'preuve/recharge',
				component:PreuveList
			},
			{
				path:'preuve/transfert',
				component:PreuveTransfertList
			},
			{
				path:'agencelist',
				component:AgenceList
			},
			{
				path:'money/devise',
				component:MoneyListdevise
			},
			{
				path:'money/taux',
				component:MoneyListtaux
			},
			{
				path:'money/recup',
				component:MoneyListrecup
			},
			{
				path:'operation/all',
				component:Alloperation
			},
			{
				path:'operation/send',
				component:Sendoperation
			},
			{
				path:'operation/receive',
				component:Receiveoperation
			},
			{
				path:'operations/march/receive',
				component:Marchoperation
			},
			{
				path:'transaction/all',
				component:All
			},
			{
				path:'transaction/send',
				component:Send
			},
			{
				path:'transaction/receive',
				component:Receive
			},
			{
				path:'request/user',
				component:Userrequest
			},
			{
				path:'request/retrait',
				component:Userretrait
			},
			{
				path:'kiosk/add',
				component:AddKiosk
			},
			{
				path:'other/add',
				component:Addother
			},
			{
				path:'agence',
				component:Foruser
			},
			{
				path:'historique',
				component:Historique
			},
			{
				path:'historique/valider',
				component:Historiquevalider
			},
			{
				path:'historique/transfert',
				component:Historiquetransfert
			},
			{
				path:'historique/benefice',
				component:Historiquebenefice
			},
			{
				path:'historique/reversement',
				component:Historiquereversement
			},
			{
				path:'docs',
				component:Docs
			},
			{
				path:'reversement',
				component:Reversement
			},
			{
				path:'retrait/cash',
				component:Retraitcash
			},
			{
				path:'retrait/banque',
				component:Retraitbanque
			}
			
		]
	},
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: { requiresNotAuth: true }
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: { requiresNotAuth: true }
	},
	{
		path: '/signup',
		name: 'signup',
		component: Signup,
		meta: { requiresNotAuth: true }
	},
	{
		path: '/reset',
		name: 'reset',
		component: Recover,
		meta: { requiresNotAuth: true }
	},
	{
		path: '/resetpassword/:key',
		name: 'resets',
		component: Reset,
		meta: { requiresNotAuth: true },
		beforeEnter: (to, from, next) => {
			const resetKey = to.params.key;
			store.dispatch('checkResetToken',{resetKey, next})
		}
	},
	{
		path: '/condition',
		name: 'condition',
		component: Condition,
		meta: { requiresNotAuth: true }
	},

]

const router = new VueRouter({
  mode: 'history',
  routes,
	scrollBehavior() {
		let $navbar = document.getElementById('navbar');
		if($navbar){
			$navbar.scrollIntoView()
		}
	}
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if ( store.getters.token  != null ) {
      next();
			return;
    }
		if(from.path == "/" || from.matched.some((record) => record.meta.requiresAuth)){
			next('/');
		}
		return;
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresNotAuth)) {
	// racoursi pour vider les states 
	// store.state.livraison.livraisonclient=null
	// store.state.client.clientbyuser=null
	// store.state.driver.driverbyuser=null
	// store.state.deliver.deliverbyuser=null
	
	store.state.operation.sendoperation=null
	store.state.operation.receiveoperation=null
	store.state.user.users=null
	if (store.getters.token == null) {
      next();
			return;
    }
		next('/dashboard');
		return;
  } else {
    next();
  }
});

export default router
