<template>
  <main class="container-fluid">
    <v-dialog v-model="modif" transition="dialog-top-transition" max-width="450">
      <template v-slot:default="dialog">
        <v-form ref="formupdate" @submit.prevent="update">
          <v-card>
            <v-card-text>
              <div class="text-center">
                <v-snackbar
                  v-model="solde"
                  :timeout="timeout"
                  absolute
                  bottom
                  color="red"
                  right
                >
                  {{ $t("solde.error") }}

                  <template v-slot:action="{ attrs }">
                    <v-btn color="black" text v-bind="attrs" @click="solde = false">
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </div>
              <div class="text-center">
                <v-snackbar
                  v-model="succes"
                  :timeout="timeout"
                  absolute
                  bottom
                  color="#1e824c"
                  right
                >
                  {{ $t("Transaction.succes") }}

                  <template v-slot:action="{ attrs }">
                    <v-btn color="black" text v-bind="attrs" @click="succes = false">
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </div>
              <div class="text-center">
                <v-snackbar
                  v-model="pinerror"
                  :timeout="timeout"
                  absolute
                  bottom
                  color="blue"
                  right
                >
                  <span style="color: black">
                    {{ $t("login.error") }}
                  </span>

                  <template v-slot:action="{ attrs }">
                    <v-btn color="black" text v-bind="attrs" @click="pinerror = false">
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 mt-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="montant"
                        prepend-inner-icon="mdi-cash"
                        :rules="[rules.required]"
                        name="tel"
                        step="0.001"
                        dense
                        ref="clear"
                        @blur="checkmontant"
                        :error-messages="messagemontant"
                        label="montant"
                        type="number"
                        outlined
                        clearable
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <v-text-field
                        v-model="pin"
                        :rules="[rules.required]"
                        type="number"
                        ref="clear1"
                        dense
                        @blur="checkpins"
                        :error-messages="messagepin"
                        prepend-inner-icon="mdi-dialpad"
                        :label="$t('admin.pin')"
                        clearable
                        outlined
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
            <div class="d-flex justify-content-between">
              <v-btn class="mt-2 mb-2" text @click="(dialog.value = false), reset()"
                >Close</v-btn
              >
              <v-btn class="mt-2 mb-2" text color="#275462" @click="transaction"
                >Save</v-btn
              >
            </div>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-9 mb-4">
        <div class="col-md-5 mb-4">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-2 row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("agent.list") }}</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      items: [],
      modif: false,
      operations: "",
      montant: "",
      id: "",
      nom: "",
      pin: "",
      snackbar: false,
      error: false,
      solde: false,
      timeout: 2000,
      loading: false,
      pinerror: false,
      succes: false,
      search: null,
      messagepin: null,
      messagemontant: null,
      compte: "",
      current_page: 1,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: "Username",
          align: "start",
          sortable: false,
          value: "username",
        },
        { text: "Nom", align: "start", sortable: false, value: "nom" },
        { text: "Prenom", align: "start", sortable: false, value: "prenom" },
        { text: "telephone", align: "start", sortable: false, value: "tel" },
        { text: "email", align: "start", sortable: false, value: "email" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "agent",
      "token",
      "roles",
      "one",
      "operation",
      "compteadmin",
      "errors",
      "checkpin",
      "me",
    ]),
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.agent && this.agent.last_page) {
        return this.agent.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.agent != null && this.agent.data) {
        return this.agent.data.filter((item) => item.pays.id == this.one.user.pays.id);
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    ici(row) {
      alert(row);
    },
    search() {
      if (this.search == null || this.search.trim() == "") {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getallagent", this.current_page);
      } else {
        this.$store.dispatch("searchagent", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
  },
  methods: {
    reset() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
    },
    transaction() {
      if (this.messagemontant == null && this.messagepin == null) {
        const op = {};
        op.envoyeur = this.compteadmin.numero;
        op.receveur = this.one.numero;
        op.typeOperation = 3;
        op.montant = this.montant;
        op.pin = this.pin;
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("setactioncompteagent", op).then(() => {
          const timer = () => {
            const status = localStorage.getItem("status");
            if (status == 200) {
              this.succes = true;
              this.reset();
              this.modif = false;
              localStorage.removeItem("status");
            } else if (this.errors.message == 500) {
              this.pinerror = true;
            }
          };
          setTimeout(timer, 0.2 * 200, 0.2);
        });
      }
    },
    checkmontant() {
      if (this.montant > this.compteadmin.solde) {
        this.messagemontant = "Solde insufissant";
        this.$refs.clear.$el.querySelector("input").focus();
      } else if (this.montant <= 0) {
        this.messagemontant = "Montant invalide";
        this.$refs.clear.$el.querySelector("input").focus();
      } else {
        this.messagemontant = null;
      }
    },
    checkpins() {
      localStorage.setItem("tk", this.token);
      const pin = {};
      pin.pin = this.pin;
      this.$store.dispatch("getcheckpin", { id: this.me.id, pin: pin }).then(() => {
        const timer = () => {
          if (this.checkpin == false) {
            this.messagepin = "Le code pin ne correspond pas";
            this.$refs.clear1.$el.querySelector("input").focus();
          } else {
            this.messagepin = null;
          }
        };
        setTimeout(timer, 0.1 * 100, 0.1);
      });
    },
    ici(row) {
      this.id = row.id;
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("getoneportefeuille", this.id);
      this.modif = true;
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getallagent", this.current_page);
      } else {
        this.$store.dispatch("searchagent", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {
      if (this.search) {
        if (this.search.trim() == "") {
          localStorage.setItem("tk", this.token);
          this.current_page = 1;
          this.$store.dispatch("getallagent", this.current_page);
        } else {
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("searchagent", {
            page: this.current_page,
            search: this.search.trim(),
          });
          if (this.$store.state.agent.agent.data) {
            this.current_page = this.$store.state.agent.agent.current_page;
          } else this.current_page = 1;
        }
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getme");
    this.$store.dispatch("getportefeuilleadmin", this.me.id);
    this.$store.dispatch("getoperation");
    this.$store.dispatch("getallagent", this.current_page);
    // console.log(this.one.user.id);
  },
  created() {
    // console.log(this.$store.state.agent.agent.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
</style>
