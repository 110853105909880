import axios from "axios";
import { baseURL } from "../../main";

const state = {
  operation: null,
  alloperation: null,
  sendoperation: null,
  searchsendoperation: null,
  receiveoperation: null,
  actioncompte: null,
  actionagent: null,
  actionuser: null,
  errors: null,
  valid:null,
  alldevs:null,
  opedevs:null,
  typeoperation:null,
  updatetypeoperation:null,
  nextoperation:null,
};

const getters = {
  operation: (state) => state.operation,
  actioncompte: (state) => state.actioncompte,
  actionagent: (state) => state.actionagent,
  actionuser: (state) => state.actionuser,
  alloperation: (state) => state.alloperation,
  sendoperation: (state) => state.sendoperation,
  receiveoperation: (state) => state.receiveoperation,
  errors: (state) => state.errors,
  valider:(state) => state.valider,
  historique:(state) => state.historique,
  alldevs:(state) => state.alldevs,
  opdevs:(state) => state.opdevs,
  typeoperation:(state)=> state.typeoperation,
  updatetypeoperation:(state)=> state.updatetypeoperation,
  nextoperation:(state)=> state.nextoperation
};

const actions = {
  async validoperation({ commit },id) {
    let token = localStorage.getItem("tk");
     await axios.get(baseURL + "operation/valider/"+id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setvalidoperation", data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  async getoperation({ commit }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "type/operations", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setoperation", data);
  },
  async getlastoperation({ commit }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "operation/last", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setlastoperation", data);
  },
  async getalltypeoperation({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "type/operations/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setalltypeoperation", data);
  },
  async updatetypeoperation({ commit }, { id, value }) {
    let token = localStorage.getItem("tk");
    await axios
      .put(baseURL + "type/operation/" + id, value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setupdatetypeoperation", data);
        }
      });
  },
  async getalloperation({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "operations/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setalloperation", data);
  },
  async getoperationdevs({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "developer/operations/envoie/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setalldevs", data);
  },
  async searchalloperation({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");
    console.log(baseURL + "operations/search/page/" + page + "/" + search);
    let response = await axios.get(
      baseURL + "operations/search/page/" + page + "/" + search,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setalloperation", data);
  },
  async getsendoperation({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "operations/envoie/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setsendoperation", data);
  },
  async searchsendoperation({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");
    console.log(
      baseURL + "operations/envoie/search/page/" + page + "/" + search
    );
    let response = await axios.get(
      baseURL + "operations/envoie/search/page/" + page + "/" + search,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setsendoperation", data);
  },
  async getreceiveoperation({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "operations/recu/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setreceiveoperation", data);
  },
  async setretraitdevs({ commit },value) {
    let token = localStorage.getItem("tk");
    let response = await axios.post(baseURL + "developer/operation/retrait" , value, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setopedevs", data);
  },
  // async setretraitcompte({ commit }, value) {
  //   let token = localStorage.getItem("tk");
  //   let response = await axios
  //     .post(baseURL + "developer/operation/retrait", value, {
  //       headers: {
  //         Authorization: "Bearer " + token,
  //       },
  //     })
  //     .then((response) => {
  //       if (response == undefined) {
  //         localStorage.setItem("status", 400);
  //       } else {
  //         localStorage.setItem("status", 200);
  //         let data = response.data;
  //         localStorage.removeItem("tk");
  //         commit("setcompte", data);
  //       }
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
  // },
  async searchreceiveoperation({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");
    console.log(baseURL + "operations/recu/search/page/" + page + "/" + search);
    let response = await axios.get(
      baseURL + "operations/recu/search/page/" + page + "/" + search,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setreceiveoperation", data);
  },
  async setactioncompte({ commit }, value) {
    let token = localStorage.getItem("tk");
    let response = await axios
      .post(baseURL + "operation/admin", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setcompte", data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  async setactioncompteagent({ commit }, value) {
    commit("clearError");
    let token = localStorage.getItem("tk");
    try {
      const response = await axios.post(baseURL + "operation", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.status == 200) {
        localStorage.setItem("status", 200);
        let data = response.data;
        localStorage.removeItem("tk");
        commit("setcompteagent", data);
      } else if (response.status == 500) {
      } else {
        localStorage.setItem("status", 400);
      }
    } catch (error) {
      if (error.response) {
        commit("setError", { message: error.response.data.message });
      } else {
        localStorage.setItem("status", 500);
        commit("setError", { message: 500 });
      }
    }
  },
  async setactioncompteuser({ commit }, value) {
    commit("clearError");
    let token = localStorage.getItem("tk");
    try {
      const response = await axios.post(baseURL + "operation", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.status == 200) {
        localStorage.setItem("status", 200);
        let data = response.data;
        commit("setcompteuser", data);
      } else if (response.status == 500) {
      } else {
        localStorage.setItem("status", 400);
      }
    } catch (error) {
      if (error.response) {
        commit("setError", { message: error.response.data.message });
      } else {
        localStorage.setItem("status", 500);
        commit("setError", { message: 500 });
      }
    }
  },
};

const mutations = {
  clearError(state) {
    state.error = null;
  },
  setoperation(state, operation) {
    state.operation = operation;
  },
  setlastoperation(state, nextoperation) {
    state.nextoperation = nextoperation;
  },
  setalloperation(state, alloperation) {
    state.alloperation = alloperation;
  },
  setalltypeoperation(state, typeoperation) {
    state.typeoperation = typeoperation;
  },
  setupdatetypeoperation(state, updatetypeoperation) {
    state.updatetypeoperation = updatetypeoperation;
  },
  setvalidoperation(state,valider)
  {
    state.valider= valider
  },
  setalldevs(state,alldevs)
  {
    state.alldevs= alldevs
  },
  setopedevs(state,opedevs)
  {
    state.opdevs= opedevs
  },
  setsendoperation(state, sendoperation) {
    state.sendoperation = sendoperation;
  },
  setreceiveoperation(state, receiveoperation) {
    state.receiveoperation = receiveoperation;
  },
  setcompte(state, actioncompte) {
    state.actioncompte = actioncompte;
  },
  setcompteagent(state, actionagent) {
    state.actionagent = actionagent;
  },
  setcompteuser(state, actionuser) {
    state.actionuser = actionuser;
  },
  setError(state, errors) {
    state.errors = errors;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
