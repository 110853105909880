export default {
  home: {
    envoie: "Select the country of sending",
    h2: "Send your money safely",
    h3: "Login to your account",
    send: "Select the country you want to send money to.",
    headerp:
      "First become a registered user, then log in to your account and enter the necessary details.",
    featuredh3: "Select your recipient",
    featuredp:
      "Enter your recipient's details, then add an amount with the currency to send safely.",
    featuredboxh3: "Send the money",
    featuredboxp:
      "After sending money, the recipient will be informed about the money transfer to his account and will collect it from the nearest branch",
    sectionspan: "Sending money has never been easier",
    sectionh2: "Availability",
    montant: "Amount",
    receive: "Select the country of receipt",
    city: "Receiver's city",
    recup: "Means of recovery",
    btn: "Make a simulation",
    h2: "Send money with a better exchange rate and avoid excessive bank fees",
    span: "A better way",
    span2: "to send money",
    landing:
      "Transactions are also traceable, which adds an extra layer of security.Fees for transferring money through s2apay are lower than fees for transferring money via other methods, such as bank transfers or cheques.We offer options for international transfers, with competitive exchange rates and reasonable fees.Flexibility to pay via other payment methods such as e-wallets.All in all, we offer a convenient and effective solution for fast, secure and affordable money transfers",
    montant: "Amount",
    montant2: "Total amount sent",
    montant3: "Total amount to receive",
    change: "Exchange rate",
    Frais: "Transaction fees",
  },
  recharge: "Account recharge ",
  montant: "Amount",
  confirm: {
    one: "Operation confirmation",
    two: "Do you Accept this: ",
  },
  operation: {
    typelist: "Operation Type",
  },
  dialog: {
    agence: "Agency",
    bank: "Bank name",
    nomcompte: "Account name",
    numcompte: "Account number",
    code: "Code",
    delete: "delete done!",
    title: "Delete confirmation",
    message: "Are you sure you want to delete this item?",
    aucun: "No means of withdrawal",
    aucun2:
      "Unfortunately, you have not defined any withdrawal method, please create one",
    aucun3: "define",
  },
  avert: {
    one: "Warning",
    avert: "you can't send money to this country",
    averts: "you can't send money to this user",
  },
  step: {
    one: "Personal data",
    two: "Bank data",
    tree: "Pin code",
    next: "next",
    end: "Save",
    prev: "Previous",
  },
  require: {
    pass: "password required",
    username: "Username required",
    pin: "Pin code required",
    email: "Email adresse are required",
  },
  rule: {
    pin: "The PIN code must be composed of 6 digits",
    pass: "The password field must contain at least one number, one lowercase letter, one uppercase letter and one special character among these characters: !@#&()-[{]}. In addition, it must be between 6 and 40 characters in length",
    username:
      "The username must contain at least one letter, must not contain any special characters other than periods (.) and underscores (_), must be between 4 and 20 characters long and must not begin or end with a period or underscore. In addition, it must not contain two consecutive periods or underscores.",
    mail: "The Email adresse are not valid",
  },
  login: {
    error: "An error has been detected, please check the information ",
    succes: "Account created successfully! you will be redirected",
  },
  admin: {
    pin: "Pin Code",
    defpin: "Set your pin code",
    def: "define",
    defpassword: "Update password",
  },
  solde: {
    error: "Insufficient balance to complete this transaction",
  },
  action: {
    save: "Save",
    close: "Close",
  },
  reversement: {
    reversement: "reversal",
  },
  menu: {
    money: "Currency",
    list: "Currencies",
    operationtype: "Operation Type",
  },
  documentation: {
    documentation: "documentation",
  },
  kiosk: {
    kiosk: "Kiosk",
    list: "Liste des kiosks",
  },
  devise: {
    devise: "Currency",
  },
  recup: {
    recup: "Withdrawal type",
  },
  taux: {
    taux: "Taux",
  },
  historique: {
    historique: "history",
    transfert: "Transfer",
    valider: "Transfer validate",
    kiosk: "Benefice",
  },
  pays: {
    pays: "Country",
    modif: "Update made successfully",
    list: "the countries",
  },
  ville: {
    ville: "City",
    update: "Update are made successfully",
    add: "Update made successfully",
    list: "the cities",
  },
  update: {
    update: "Update",
  },
  preuve: {
    recharge: "recharge",
    transfert: "transfert",
    preuvelist: "List of Proof",
    preuve: "Proof",
  },
  agence: {
    nous: "Our agency",
    agence: "Agency",
    user: "Agent assignment ",
    detail: "See more",
    avert: "Warning",
    avert1: "This user is already assigned to an agency. ",
    save: "Save",
    updates: "Modify",
    close: "Close",
    delete: "Delete",
    stop: "Cancel",
    update: "Update made successfully",
    add: "Agency create successfully",
    list: "The Agency",
    user: "Add agent in agency",
    new: "New agency",
    confirm: "Delete Confirmation",
    confirm2: "Are you sure you want to delete this agent?",
  },
  user: {
    user: "User",
    useradd: "Add Users",
    userlist: "List of Users",
    adminlist: "List of Administrators",
    clientlist: "List of Clients",
    agentlist: "List of Agents",
    agent: "Add agent in agency",
    developerlist: "List of Developers",
    succes: "User has been registered !",
    profil: "Profile",
    solde: "Available Balance",
    info: "Personal data",
    setting: "Account Settings",
    bank: "Bank data",
    moyens: "Withdraw data",
    compte: "Account Number",
    request: "Transferring money to my wallet",
    guide1: "",
    montant: "Amount",
    requestagence: "Request for transferring money to my wallet",
    requestretrait: "Request for withdraw money",
  },
  request: {
    request: "request",
    retrait: "Withdraw money",
    un: "1- Choose an agency from the agencies displayed in the agency field",
    deux: "2- Make a deposit on the bank account of the selected agency",
    trois: "3- Finally upload the proof and validate",
    nb: "NB: your balance will be recharged when the agency processes and validates your request.",
  },
  retrait: {
    retrait: "Withdraw",
  },
  pin: {
    pins: "Pin code",
    pin: "pin error",
    succes: "Operation made successfully",
  },
  Transfert: {
    Transfert: "Transfert",
    receive: "Name and FirstName of receiver",
    adresse: "Adress of receiver",
    contact: "Contact of receiver",
    montant: "Amount",
    city: "Your city",
    countryrecv: "Country of receiver",
    cityrecv: "City of receiver",
    moyen: "method of retrivial",
    intitule: "Account name",
    institution: "Bank name",
    numcompte: "Account number",
    pin: "Pin code",
    paiement: "Payment method",
    extrazone: "International transfert",
    intrazone: "National devise area transfert",
    pop: "Transfer money.",
    detail: "Details Transaction",
    total: "Total",
    send: "Recipient",
    name: "Name/Firstname",
    phone: "Telephone",
    country: "Country",
    status: "Status",
    valide: "Validate",
    wait: "Waiting",
    pin: "pin code",
    wallet: "Receiver Wallet",
  },
  Transaction: {
    succes: "Transaction performed !",
    save: "Credit",
    send: "Send",
    all: "All",
    receive: "Receive",
    national: "Intrazone",
    international: "Extrazone",
    Transaction: "Transaction",
    sends: "Deposit made succesfully, please ask to confirm the transaction",
  },
  Portefeuille: {
    Portefeuille: "Wallet",
    Portefeuillelist: "List of wallet",
    Portefeuilleagent: "Operation  agent Wallet",
    Portefeuilleadmin: "Recharge wallet",
    Portefeuilleadd: "New wallet",
    Portefeuilleuser: "Operation agent Wallet",
  },
  headers: {
    code: "Code",
    depart: "Depart",
    arriver: "Arrival",
    nom: "Name",
    prenom: "First name",
    password: "Password",
    confirmpassword: "Confirm Password",
    ville: "City",
    client: "Client",
    kiosk: "Kiosk",
    agent: "Agent",
    admin: "Admin",
    moyen: "Methods",
    details: "Details",
    dev: "Developpers",
    march: "Marchands",
    description: "Description",
    statut: "Status",
    telephone: "Telephone",
    old: "Old passwords",
    news: "New passwords",
    devise: "currency",
    add: "new",
    action: "Actions",
    libelle: "Libelle",
    recups: "Withdrawal method",
    detail: "Details",
    pourcentage: "Percentage",
    adresse: "Adresse",
    nomproprio: "Name of the owner",
    prenomproprio: "First name owner",
    adresse: "Adresse",
    email: "Email",
    pointlivraison: "Delivery point",
    username: "Username",
    indicatif: "Call sign",
    role: "Roles",
    solde: "Ammount",
    pays: "Country",
    actions: "Actions",
    details: "Details",
    cni: "National identity cards or passports",
    deplacement: "Do you have a means of travel?",
    montant: "Amount",
    envoyeurN: "Name Sender",
    envoyeurP: "First name Sender",
    envoyeurU: "Username Sender",
    intitule: "Intitule compte",
    institution: "Nom banque",
    numcompte: "Numero compte",
    receiveN: "Nom Receiver",
    receiveP: "Prenom Receiver",
    receiveU: "Username Receiver",
    operation: "Operation",
    villereception: "Reception city",
    moyenrecup: "withdraw type",
    date: "Date",
    valider: "valid",
    frais: "Fees",
    agence: "Agency",
    fichier: "File",
    concat: "Name and Firstname",
    agent: "agent",
    Etat: "State",
    Anuller: "Reject",
  },
  Save: "Save",
  create: "Signin",
  new: "Sign-up",
  log: "Login",
  hey: "Hello",
  account: "Account",
  conditions: "Terms of use",
  condition1: "New customers only,",
  condition2: "one offer per customer",
  condition3: "limited time offer, rates shown are subject to change",
  condition4: "See the terms and conditions for more information",
  define: "Please set your pin code before you can make transactions",
  android:
    "Please download the mobile version of s2apay from the google play store.",
  ios: "Please download the mobile version of s2apay from the apple play store.",
  logout: "Log off",
  wallet: "Wallet",
  condition: {
    1: "Welcome to our mobile payment platform S2APay",
    2: "Please read these terms of use carefully before using our mobile application. By using our mobile application, you agree to the terms and conditions set forth in this document",
    titre: "Privacy Policy",
    x1: "S2APAY respects your privacy. This Privacy Policy explains what information we collect, how we use it, and the choices you have regarding this information.",
    x2: "Information We Collect",
    x2t: "We collect the following types of information:",
    x2c: "Personal Information: When using our s2apay application, we may ask you to provide certain personally identifiable information, such as your name, email address, telephone number, and other similar information.",
    x2c1: "Contact List Information: Our application can access your contact list to initiate the call or chat by SMS or Whatsapp",
    x3: "Use of Information",
    x3t: "We use the information collected for the following purposes:",
    x3c: "Providing and improving our services: We use your information to operate, maintain, improve and provide all features of our application.",
    x3c1: "Communication: We may use your contact information to send you notifications, updates, or promotional messages.",
    x4: "Information Security",
    x4t: "We implement technical and organizational security measures to protect your information against unauthorized access, loss, misuse or disclosure. However, no method of transmission or storage is completely secure, so we cannot guarantee absolute security.",
    x5: "Your Rights and Choices",
    x5t: "You have the right to:",
    x5c: "Access your personal information that we hold.",
    x5c1: "Request rectification or deletion of your information.",
    x5c2: "Withdraw your consent at any time for the use of your information.",
    x6: "Changes to the Privacy Policy",
    x6t: "We reserve the right to modify this privacy policy at any time. Changes will be posted on this page with an updated date. We encourage you to review this policy regularly to stay informed.",
    3: "User rules and responsibilities",
    4: "Use the mobile application only for lawful purposes and in accordance with applicable laws.",
    5: "Do not attempt to hack or tamper with the mobile application or its contents",
    6: "Do not use our mobile application to conduct fraudulent transactions",
    7: "Use of money on our platform",
    8: "Money that passes through our mobile payment platform is subject to the following conditions:",
    9: "We are not responsible for the loss of money due to user error.",
    10: "We are not responsible for any loss of money due to unauthorised or fraudulent transactions",
    11: "We are not responsible for loss of money due to disruption of service.",
    12: "We are not responsible for loss of money due to our failure to withdraw funds from your account",
    13: "Intellectual Property",
    14: "All copyrights, trademarks, patents and other intellectual property rights related to our mobile payment platform are the exclusive property of our company. You agree not to use, reproduce or distribute these materials without our written permission",
    15: "Limitations of liability",
    16: "Our company shall not be liable for any loss or damage suffered by the user as a result of using our mobile payment platform, including financial loss, loss of data, violation of privacy, etc.",
    17: "Termination of the contract",
    18: "We reserve the right to terminate the contract between the user and our company at any time, without notice and without liability to the user. You may also terminate the contract by ceasing to use our mobile application",
    19: "Applicable law and jurisdiction",
    20: "These terms of use are governed by the applicable laws of Togo. Any dispute arising from the use of our mobile payment platform shall be submitted to the competent jurisdiction of Togo",
    21: "By using our mobile payment platform, you agree to these terms of use. If you do not accept these terms, please do not use our mobile application",
    22: "We reserve the right to change these terms of use at any time. Changes will be effective upon posting on our website. Please check the terms of use regularly to ensure that you are aware of any updates.",
    23: "If you have any questions about these terms of use, please contact us at the following email address:",
  },
  reset: {
    password: "Reset password",
    notmatch: "The two passwords don't match!",
    info: "Enter your Email and instructions will be sent to you!",
    remember: "Remember It ?",
    click: "Click here !",
    new: "Create Your Password",
    error: "This user dont exist",
  },
};
