import axios from "axios";
import { baseURL } from "../../main";

const state = {
  allkiosk:null,
  kiosk: null,
  searchkiosk: null,
};
const getters = {
  allkiosk: (state) => state.allkiosk,
  kiosk: (state) => state.kiosk,
  searchkiosk: (state) => state.searchkiosk,
};

const actions = {
  async updatekiosk({commit},{id, value})
	{
		let token = localStorage.getItem("tk");
    await axios
      .put(baseURL + "agence/kiosk/" + id, value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setupdatekiosk", data);
        }
      });
	},
  async addnewkiosk({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "agence/kiosk", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          data
          localStorage.removeItem("tk");
          commit("setnews", data);
        }
      });
  },
  async getallkiosk({ commit }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "auth/kiosk" ,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    data
    commit("setallkiosk", data);
  },
  async getkiosk({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "agences/kiosk/page/" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    data
    commit("setkiosk", data);
  },
  async searchkiosk({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "agences/kiosk/search/page/" + page + "/" + search,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setkiosk", data);
  },
};
const mutations = {
  setkiosk(state, kiosk) {
    state.kiosk = kiosk;
  },
  setupdatekiosk(state, updatekiosk) {
    state.updatekiosk = updatekiosk;
  },
  setallkiosk(state, allkiosk) {
    state.allkiosk = allkiosk;
  },
  setnews(state, newkiosk) {
    state.newkiosk = newkiosk;
  },
  setsearchkiosk(state, searchkiosk)
  {
    state.searchkiosk= searchkiosk
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
