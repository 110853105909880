<template>
  <div class="container">
    <div class="row mt-4">
      <h4>Filtre</h4>
      <div class="col-md-4 col-lg-3">
        <v-autocomplete
          v-model="SelectedCountry"
          prepend-inner-icon="mdi-earth"
          label="Selectionez le pays"
          :items="itemListpays"
          item-text="nom"
          item-value="id"
          ref="clear"
          dense
          @change="pays"
          return-object
          outlined
        ></v-autocomplete>
      </div>
      <div class="col-md-4 col-lg-3">
        <v-autocomplete
          v-model="SelectedVille"
          :items="itemville"
          label="Selectionez la ville"
          prepend-inner-icon="mdi-city"
          dense
          item-text="nom"
          item-value="id"
          return-object
          @change="lesvilles"
          outlined
        >
        </v-autocomplete>
      </div>
      <div class="col-md-3 col-lg-4">
        <v-btn @click="pres" color="#2B2E83" style="color: white" large
          >Pres de chez moi</v-btn
        >
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-3 mb-3" v-for="agency in itemListAgence" :key="agency.id">
        <div v-if="agencebyville.length > 0" class="card">
          <img
            clas="card-img-top"
            :src="`https://via.placeholder.com/500x250/2B2E83/FFFFFF?text=${agency.ville.pays.nom}`"
          />
          <v-card-title>{{ agency.nom }}, {{ agency.typeAgence.libelle }}</v-card-title>
          <v-card-subtitle
            >{{ agency.ville.nom }}, {{ agency.ville.pays.nom }}
            <br />
            <div class="mt-2">Contact: {{ agency.telephone }}</div>
          </v-card-subtitle>
          <hr />
          <v-card-text>
            <h4>Information bancaire</h4>
            <p>Banque: {{ agency.institutionAgence }}</p>
            <p>Intitule: {{ agency.intituleAgence }}</p>
            <p>Numero Compte: {{ agency.numCompteAgence }}</p>
          </v-card-text>
        </div>
      </div>
    </div>
    <div v-show="ok == true" class="row mt-4">
      <img
        clas="img img-responsive"
        src="https://via.placeholder.com/1500x250/000000/FFFFFF?text=No Agency"
      />
    </div>
    <!-- <div v-show="start == true" class="row mt-4">
      <img
        clas="img img-responsive"
        src="https://via.placeholder.com/1500x250/000000/FFFFFF?text=Selectioner un pays"
      />
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      selectedCountry: null,
      SelectedCountry: null,
      SelectedVille: null,
      ok: null,
      start: true,
    };
  },
  computed: {
    ...mapGetters(["allpays", "villebypays", "agencebyville", "token", "me"]),
    itemListpays() {
      if (this.allpays != null) {
        return this.allpays.filter((item) => item.devise != null);
      }
    },
    itemville() {
      if (this.villebypays != null) {
        return this.villebypays;
      } else {
        return [];
      }
    },
    itemListAgence() {
      if (this.agencebyville != null && this.agencebyville.length > 0) {
        this.ok = false;
        return this.agencebyville;
      } else {
        this.ok = true;
        return [];
      }
    },
    Cardlist() {
      return {
        transactionDetails: this.getTransactionDetails(),
      };
    },
  },
  methods: {
    pres() {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("getagencebypayslistonville", this.me.pays.id);
    },
    lesvilles() {
      this.$store.dispatch("getagencebyville", this.SelectedVille.id);
    },
    pays() {
      this.$store.dispatch("getvillebypays", this.SelectedCountry.id);
    },
    filterAgencies() {
      this.filteredAgencies = this.agencies.filter(
        (agency) => agency.country === this.selectedCountry
      );
    },
  },
  mounted() {
    this.$store.dispatch("getallpays");
    this.$store.dispatch("getclearville");
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getallsagence");
    this.$store.dispatch("getme");
  },
};
</script>
<style>
.card {
  width: 100%;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-img-top {
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
}

.card-text {
  margin-bottom: 1.25rem;
}

@media screen and (max-width: 768px) {
  .card {
    display: flex;
    flex-direction: column;
  }

  .media {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }

  .media-content {
    text-align: center;
  }
}
</style>
