<template>
  <main>
    <Navbar />
    <router-view></router-view>
    <!-- <Footer /> -->
  </main>
</template>
<script>
import Navbar from "../../components/navbar.vue";
import Footer from "../../components/Footer.vue";
export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {};
  },
  created() {
    localStorage.setItem("tk", this.$store.getters.token);
    this.$store.dispatch("getportefeuilleadmin", this.$store.getters.user.id);
    // this.userdevices()
  },
  methods: {},
};
</script>
<style>
.main {
  background-color: #f1f5f6;
}
.v-messages__message {
  line-height: 2rem !important;
}
</style>
