<template>
  <main>
    <nav class="navbar navbar-expand-lg back rounded">
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbars"
          aria-controls="navbars"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="showNav = !showNav"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse justify-content-md-center"
          id="navbars"
          :class="{ show: showNav }"
        >
          <ul class="navbar-nav">
            <li class="nav-item" v-show="isagents == true || isadmins == true">
              <router-link to="/dashboard/profil/me">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("account") }}</span>
                </a>
              </router-link>
            </li>
            <li
              v-show="isclients == true || iskiosks == true || isdevs == true"
              class="nav-item"
            >
              <router-link to="/dashboard/profil/me">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("account") }}</span>
                </a>
              </router-link>
            </li>
            <li v-show="isadmins == true" class="nav-item dropdown">
              <a
                class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span class="nav-link navs">Monetique</span></a
              >
              <ul class="dropdown-menu">
                <li class="nav-item">
                  <router-link to="/dashboard/money/devise">
                    <a
                      style="color: #2d2d2d"
                      class="nav-link"
                      aria-current="page"
                      @click="showNav = false"
                    >
                      {{ $t("menu.money") }}
                    </a>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/dashboard/operationtype">
                    <a
                      style="color: #2d2d2d"
                      class="nav-link"
                      aria-current="page"
                      @click="showNav = false"
                    >
                      {{ $t("menu.operationtype") }}
                    </a>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/dashboard/payslist">
                    <a
                      style="color: #2d2d2d"
                      class="nav-link"
                      aria-current="page"
                      @click="showNav = false"
                    >
                      {{ $t("pays.pays") }}
                    </a>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/dashboard/villelist">
                    <a
                      style="color: #2d2d2d"
                      class="nav-link"
                      aria-current="page"
                      @click="showNav = false"
                    >
                      {{ $t("ville.ville") }}
                    </a>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/dashboard/agencelist">
                    <a
                      style="color: #2d2d2d"
                      class="nav-link"
                      aria-current="page"
                      @click="showNav = false"
                    >
                      {{ $t("agence.agence") }}
                    </a>
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link v-if="isadmins == true" to="/dashboard/operation/all">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("Transaction.Transaction") }}</span>
                </a>
              </router-link>
              <router-link
                v-if="
                  isagents == true ||
                  isclients == true ||
                  iskiosks == true ||
                  ismarchs == true
                "
                to="/dashboard/operation/send"
              >
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("Transaction.Transaction") }}</span>
                </a>
              </router-link>
            </li>
            <li
              v-show="isagents == true || isclients == true || iskiosks == true"
              class="nav-item"
            >
              <router-link v-if="isadmins == true" to="/dashboard/transaction/all">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("Transfert.Transfert") }}</span>
                </a>
              </router-link>
              <router-link v-else to="/dashboard/transaction/send">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("Transfert.Transfert") }}</span>
                </a>
              </router-link>
            </li>
            <li v-show="isagents == true" class="nav-item">
              <router-link to="/dashboard/other/add">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("user.user") }}</span>
                </a>
              </router-link>
            </li>
            <li v-show="isadmins == true" class="nav-item">
              <router-link to="/dashboard/transaction/all">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs">{{ $t("Transfert.Transfert") }}</span>
                </a>
              </router-link>
            </li>
            <li v-show="isagents == true" class="nav-item">
              <router-link to="/dashboard/preuve/recharge">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs">{{ $t("preuve.preuve") }}</span>
                </a>
              </router-link>
            </li>
            <li v-show="isadmins == true" class="nav-item">
              <router-link to="/dashboard/user/userlist">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("user.user") }}</span>
                </a>
              </router-link>
            </li>
            <li v-show="isagents == true" class="nav-item">
              <router-link to="/dashboard/kiosk/add">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("kiosk.kiosk") }}</span>
                </a>
              </router-link>
            </li>
            <li v-show="isclients == true || iskiosks == true" class="nav-item">
              <router-link to="/dashboard/request/user">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("request.request") }}</span>
                </a>
              </router-link>
            </li>
            <li v-show="isadmins == true" class="nav-item">
              <router-link to="/dashboard/portefeuille/list">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs">{{ $t("wallet") }}</span>
                </a>
              </router-link>
            </li>
            <li v-show="isclients == true" class="nav-item">
              <router-link to="/dashboard/agence">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs">Nos Agences</span>
                </a>
              </router-link>
            </li>
            <li v-show="isagents == true || iskiosks == true" class="nav-item">
              <router-link to="/dashboard/historique/benefice">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("historique.historique") }}</span>
                </a>
              </router-link>
            </li>
            <li v-show="isclients == true || isdevs == true" class="nav-item">
              <router-link to="/dashboard/historique">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("historique.historique") }}</span>
                </a>
              </router-link>
            </li>

            <li v-show="iskiosks == true || isadmins == true" class="nav-item">
              <router-link to="/dashboard/reversement">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("reversement.reversement") }}</span>
                </a>
              </router-link>
            </li>
            <li v-show="isdevs == true" class="nav-item">
              <router-link to="/dashboard/docs">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs">
                    {{ $t("documentation.documentation") }}</span
                  >
                </a>
              </router-link>
            </li>
            <li v-show="isdevs == true" class="nav-item">
              <router-link to="/dashboard/retrait/cash">
                <a
                  class="nav-link sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
                  @click="showNav = false"
                >
                  <span class="nav-link navs"> {{ $t("retrait.retrait") }}</span>
                </a>
              </router-link>
            </li>
            <li
              class="nav-item sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
            >
              <a style="color: white" class="ms-2 me-2 nav-link navs" @click="logout()">
                {{ $t("logout") }}</a
              >
            </li>
            <li
              class="nav-item sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-uppercase"
            >
              <div>
                <select
                  style="color: white"
                  aria-label="Default select example"
                  class="nav-link navs"
                  v-model="langue"
                  @change="changelanguage()"
                >
                  <option value="fr">FR</option>
                  <option value="en">EN</option>
                </select>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="pb-4">
      <!-- <Tabs :menu="submenu" v-show="$route.path === '/'" /> -->
      <Tabs
        :menu="operationadmin"
        v-show="$route.path.startsWith('/dashboard/operation/') && isadmins == true"
      />
      <Tabs
        :menu="operation"
        v-show="$route.path.startsWith('/dashboard/operation/') && isadmins == false"
      />
      <Tabs
        :menu="operationmarch"
        v-show="$route.path.startsWith('/dashboard/operations/') && ismarchs == true"
      />
      <Tabs
        :menu="transactionadmin"
        v-show="$route.path.startsWith('/dashboard/transaction') && isadmins == true"
      />
      <Tabs :menu="utilisateur" v-show="$route.path.startsWith('/dashboard/user/')" />
      <Tabs :menu="pays" v-show="$route.path.startsWith('/dashboard/payslist')" />
      <Tabs :menu="ville" v-show="$route.path.startsWith('/dashboard/villelist')" />
      <Tabs :menu="money" v-show="$route.path.startsWith('/dashboard/money')" />
      <!-- <Tabs :menu="profil" v-show="$route.path.startsWith('/dashboard/profil')" /> -->
      <Tabs :menu="request" v-show="$route.path.startsWith('/dashboard/request')" />
      <Tabs :menu="preuve" v-show="$route.path.startsWith('/dashboard/preuve')" />
      <Tabs
        :menu="profil"
        v-show="$route.path.startsWith('/dashboard/profil') && isclients == false"
      />
      <Tabs
        :menu="profilclient"
        v-show="$route.path.startsWith('/dashboard/profil') && isclients == true"
      />
      <Tabs
        :menu="historique"
        v-show="$route.path.startsWith('/dashboard/historique') && isclients == false"
      />
      <Tabs
        :menu="historiqueclient"
        v-show="$route.path.startsWith('/dashboard/historique') && isclients == true"
      />
      <Tabs :menu="retrait" v-show="$route.path.startsWith('/dashboard/retrait')" />
      <Tabs
        :menu="reversement"
        v-show="$route.path.startsWith('/dashboard/reversement')"
      />
      <Tabs
        :menu="transaction"
        v-show="$route.path.startsWith('/dashboard/transaction') && isadmins == false"
      />
      <Tabs
        :menu="portefeuille"
        v-show="$route.path.startsWith('/dashboard/portefeuille')"
      />
    </div>
    <!-- <router-view></router-view> -->
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import Tabs from "./tabs.vue";
export default {
  components: { Tabs },
  data() {
    return {
      langue: "",
      isadmins: false,
      isclients: false,
      isagents: false,
      iskiosks: false,
      isdevs: false,
      ismarchs: false,
      screenWidth: 0,
      showNav: false,
      profil: [
        {
          id: 0,
          nom: "account",
          url: "/dashboard/profil/me",
        },
        {
          id: 1,
          nom: "pin.pins",
          url: "/dashboard/profil/pin",
        },
        {
          id: 2,
          nom: "password",
          url: "/dashboard/profil/pass",
        },
      ],
      profilclient: [
        {
          id: 0,
          nom: "account",
          url: "/dashboard/profil/me",
        },
        {
          id: 1,
          nom: "pin.pins",
          url: "/dashboard/profil/pin",
        },
        {
          id: 2,
          nom: "password",
          url: "/dashboard/profil/pass",
        },
        {
          id: 3,
          nom: "bank",
          url: "/dashboard/profil/banq",
        },
        {
          id: 4,
          nom: "headers.recups",
          url: "/dashboard/profil/moyen",
        },
      ],
      retrait: [
        {
          id: 0,
          nom: "retrait.retrait",
          url: "/dashboard/retrait/cash",
        },
      ],
      reversement: [
        {
          id: 0,
          nom: "reversement.reversement",
          url: "/dashboard/reversement",
        },
      ],
      historiqueclient: [
        {
          id: 0,
          nom: "historique.historique",
          url: "/dashboard/historique",
        },
      ],
      historique: [
        {
          id: 0,
          nom: "historique.kiosk",
          url: "/dashboard/historique/benefice",
        },
        {
          id: 1,
          nom: "historique.valider",
          url: "/dashboard/historique/valider",
        },
        {
          id: 2,
          nom: "historique.transfert",
          url: "/dashboard/historique/transfert",
        },
      ],
      request: [
        {
          id: 0,
          nom: "user.request",
          url: "/dashboard/request/user",
        },
        {
          id: 1,
          nom: "request.retrait",
          url: "/dashboard/request/retrait",
        },
      ],
      money: [
        {
          id: 0,
          nom: "devise.devise",
          url: "/dashboard/money/devise",
        },
        {
          id: 1,
          nom: "taux.taux",
          url: "/dashboard/money/taux",
        },
        {
          id: 2,
          nom: "recup.recup",
          url: "/dashboard/money/recup",
        },
      ],
      pays: [
        {
          id: 0,
          nom: "pays.pays",
          url: "/dashboard/payslist",
        },
      ],
      ville: [
        {
          id: 0,
          nom: "ville.ville",
          url: "/dashboard/villelist",
        },
      ],
      utilisateur: [
        {
          id: 0,
          nom: "user.userlist",
          url: "/dashboard/user/userlist",
        },
        {
          id: 1,
          nom: "user.useradd",
          url: "/dashboard/user/useradd",
        },
      ],
      operationadmin: [
        {
          id: 0,
          nom: "Transaction.all",
          url: "/dashboard/operation/all",
        },
        {
          id: 1,
          nom: "Transaction.send",
          url: "/dashboard/operation/send",
        },
        {
          id: 2,
          nom: "Transaction.receive",
          url: "/dashboard/operation/receive",
        },
      ],
      operation: [
        {
          id: 0,
          nom: "Transaction.send",
          url: "/dashboard/operation/send",
        },
        {
          id: 1,
          nom: "Transaction.receive",
          url: "/dashboard/operation/receive",
        },
      ],
      operationmarch: [
        {
          id: 0,
          nom: "Transaction.receive",
          url: "/dashboard/operation/march/receive",
        },
      ],
      portefeuille: [
        {
          id: 0,
          nom: "Portefeuille.Portefeuillelist",
          url: "/dashboard/portefeuille/list",
        },
        {
          id: 1,
          nom: "Portefeuille.Portefeuilleagent",
          url: "/dashboard/portefeuille/agent",
        },
        {
          id: 2,
          nom: "Portefeuille.Portefeuilleadmin",
          url: "/dashboard/portefeuille/admin",
        },
      ],
      preuve: [
        {
          id: 0,
          nom: "preuve.recharge",
          url: "/dashboard/preuve/recharge",
        },
        {
          id: 1,
          nom: "preuve.transfert",
          url: "/dashboard/preuve/transfert",
        },
      ],
      transaction: [
        {
          id: 0,
          nom: "Transaction.national",
          url: "/dashboard/transaction/send",
        },
        {
          id: 1,
          nom: "Transaction.international",
          url: "/dashboard/transaction/receive",
        },
      ],
      transactionadmin: [
        {
          id: 0,
          nom: "Transaction.all",
          url: "/dashboard/transaction/all",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["logged", "user"]),
    currentMenus() {},
  },
  created() {
    if (localStorage.getItem("lang") == null) {
      localStorage.setItem("lang", "fr");
    }
    this.langue = localStorage.getItem("lang");
  },
  mounted() {
    console.log(this.isadmin());
    console.log(this.isclient());
    console.log(this.isagent());
    console.log(this.iskiosk());
    console.log(this.isdev());
    console.log(this.ismarch());

    // Définit une fonction qui change le bord de l'élément span
  },
  methods: {
    changelanguage() {
      localStorage.setItem("lang", this.langue);
      window.location.reload();
    },
    logout() {
      this.$store.dispatch("logout");
    },
    isadmin() {
      if (this.user.role.id == 1) {
        return (this.isadmins = true);
      } else return (this.isadmins = false);
    },
    isclient() {
      if (this.user.role.id == 3) {
        return (this.isclients = true);
      } else return (this.isclients = false);
    },
    isagent() {
      if (this.user.role.id == 4) {
        return (this.isagents = true);
      } else return (this.isagents = false);
    },
    iskiosk() {
      if (this.user.role.id == 5) {
        return (this.iskiosks = true);
      } else return (this.iskiosks = false);
    },
    isdev() {
      if (this.user.role.id == 2) {
        return (this.isdevs = true);
      } else return (this.isdevs = false);
    },
    ismarch() {
      if (this.user.role.id == 6) {
        return (this.ismarchs = true);
      } else return (this.ismarchs = false);
    },
  },
};
</script>
<style>
body {
  font-size: 0.875rem;
}

/*
 * Sidebar
 */
.dropdown:hover .dropdown-menu {
  display: block;
}
a:hover {
  color: #e30613 !important;
}

.dropdown-menu[data-display="static"] {
  display: none;
}
.back {
  background-color: #2b2e83;
}
.s2 {
  color: #e30613;
  text-transform: uppercase;
  font-size: 3rem;
}
.pay {
  color: #2b2e83;
  text-transform: uppercase;
  font-size: 3rem;
}
/*
 * Navbar
 */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #2d2d2d;
  box-shadow: inset -1px 0 0 #2d2d2d;
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
}

.form-control-dark {
  color: #fff;
  background-color: #2d2d2d;
  border-color: #2d2d2d;
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px #2d2d2d;
}
.nav-link:hover {
  color: #e30613 !important;
}
.navs {
  color: white;
}
@media (max-width: 992px) {
  .navs {
    background-color: #2b2e83;
  }
  .nav-link:hover {
    color: #e30613 !important;
  }
}
</style>
