<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <div class="col-lg-5">
        <v-card class="signup">
          <v-card-text>
            <div class="text-center">
              <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                center
                absolute
                bottom
                color="#1e824c"
              >
                {{ $t("Transaction.succes") }}

                <template v-slot:action="{ attrs }">
                  <v-btn color="#cf000f" text v-bind="attrs" @click="snackbar = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
            <div class="text-center">
              <v-snackbar
                v-model="error"
                :timeout="timeout"
                absolute
                bottom
                color="red"
                center
              >
                {{ $t("devis.error") }}

                <template v-slot:action="{ attrs }">
                  <v-btn color="#cf000f" text v-bind="attrs" @click="snackbar = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
            <v-form @submit.prevent="save" ref="formrevers">
              <h2 class="text-center mt-4">
                {{ $t("reversement.reversement") }}
              </h2>
              <div class="row mt-8">
                <div class="col-md-12">
                  <div class="form-group">
                    <v-text-field
                      v-show="me.role.id != 1"
                      v-model="montant"
                      class="ms-2 me-2"
                      type="number"
                      :rules="[rules.required]"
                      prepend-inner-icon="mdi-dialpad"
                      :label="$t('user.montant')"
                      @blur="checkmontant"
                      :error-messages="messagemontant"
                      clearable
                      ref="clear"
                      outlined
                      dense
                    ></v-text-field>
                    <v-autocomplete
                      class="mt-4 ms-2 me-2"
                      v-model="agences"
                      prepend-inner-icon="mdi-earth"
                      :items="agenceList"
                      item-text="nom"
                      item-value="id"
                      label="Les agences"
                      ref="clear1"
                      :rules="[rules.required]"
                      return-object
                      outlined
                      dense
                    >
                    </v-autocomplete>
                    <v-text-field
                      v-show="me.role.id == 1"
                      v-model="montant"
                      class="ms-2 me-2"
                      type="number"
                      :rules="[rules.required]"
                      prepend-inner-icon="mdi-dialpad"
                      :label="$t('user.montant')"
                      @blur="checkmontant2"
                      :error-messages="messagemontant"
                      clearable
                      ref="clear"
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="form-group">
                  <button class="w-100 btn log save" type="submit" value="register">
                    {{ $t("admin.def") }}
                  </button>
                </div>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
      <v-dialog v-model="finisher" transition="dialog-top-transition" max-width="450">
        <template>
          <v-card>
            <v-card-text class="text-center" style="color: #2d2d2d">
              <v-card-title class="headline"> Validation </v-card-title>
              <span class="h3">
                <v-text-field
                  class="mt-2 ms-2 me-2"
                  v-model="pin"
                  name="indicatif"
                  type="number"
                  label="code pin"
                  ref="clear2"
                  @input="checkpins"
                  prepend-inner-icon="mdi-dialpad"
                  :rules="[rules.required]"
                  :error-messages="messagepin"
                  outlined
                  clearable
                  dense
                >
                </v-text-field>
                <v-icon x-large color="white">mdi-check</v-icon>
              </span>
              <div class="text-center d-flex justify-center">
                <v-btn
                  left
                  style="color: white"
                  color="#2B2E83"
                  :disabled="!validPin"
                  @click="valider"
                  >Enregistrer</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
      <div class="col-lg-3" v-show="showagence == true">
        <v-card color="#212e61" v-if="oneagence">
          <v-card-title
            ><h1 class="blck">{{ oneagence.nom }}</h1></v-card-title
          >
          <v-card-text>
            <h5 v-if="oneagence.ville">
              Solde
              {{ $t("user.benefice") + ":" }}
              <span class="float-end">
                {{ oneagence.benefice + " " + oneagence.ville.pays.devise.libelle }}
              </span>
            </h5>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </main>
</template>
<script>
import Aside from "../../../components/aside.vue";
import { mapGetters } from "vuex";

export default {
  components: { Aside },
  data: () => {
    return {
      show2: "",
      finisher: false,
      messagepin: null,
      pins: null,
      confirm: false,
      solde: false,
      oui: false,
      agences: "",
      showagence: false,
      city: "",
      eye: false,
      eyes: true,
      pin: "",
      messagemontant: null,
      snackbar: false,
      preuve: null,
      error: false,
      timeout: 2000,
      validPin: false,
      montant: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
    };
  },
  computed: {
    ...mapGetters([
      "one",
      "agencebypays",
      "me",
      "token",
      "agencebyuser",
      "checkpin",
      "oneagence",
    ]),
    agenceList() {
      if (this.agencebypays != null) {
        return this.agencebypays;
      } else {
        return [];
      }
    },
  },
  watch: {
    agences() {
      if (this.me.role.id == 1) {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getoneagence", this.agences.id);
        this.showagence = true;
      }
    },
  },
  methods: {
    reset() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
    },
    show() {
      this.eye = false;
      this.solde = false;
    },
    shows() {
      this.eye = true;
      this.solde = true;
    },
    valid() {
      this.oui = true;
      this.conf();
    },
    checkpins() {
      localStorage.setItem("tk", this.token);
      const pin = {};
      pin.pin = this.pin;
      this.$store.dispatch("getcheckpin", { id: this.me.id, pin: pin }).then(() => {
        const timer = () => {
          if (this.checkpin == false) {
            this.messagepin = "Le code pin ne correspond pas";
            this.$refs.clear2.$el.querySelector("input").focus();
            this.validPin = false;
          } else {
            this.messagepin = null;
            this.validPin = true;
          }
        };
        setTimeout(timer, 0.1 * 100, 0.1);
      });
    },
    checkmontant() {
      if (this.montant > this.agencebyuser.benefice || this.montant <= 0) {
        this.messagemontant = "Solde inssufisant";
        this.$refs.clear.$el.querySelector("input").focus();
      } else {
        this.messagemontant = null;
      }
    },
    checkmontant2() {
      console.log(this.montant, this.oneagence.benefice);
      if (this.montant > this.oneagence.benefice || this.montant <= 0) {
        this.messagemontant = "Solde inssufisant";
        this.$refs.clear.$el.querySelector("input").focus();
      } else {
        this.messagemontant = null;
      }
    },
    form() {},
    save() {
      const valid = this.$refs.formrevers.validate();
      if (valid == true && this.messagemontant == null) {
        this.finisher = true;
      }
    },
    valider() {
      if (this.messagepin == null) {
        const revers = {};
        revers.montant = this.montant;
        revers.agence = this.agences.id;
        localStorage.setItem("tk", this.token);
        if (this.me.role.id == 1) {
          this.$store.dispatch("reversementbyadmin", revers).then(() => {
            const status = localStorage.getItem("status");
            if (status == 200) {
              this.finisher = false;
              this.snackbar = true;
              this.reset();
              localStorage.setItem("tk", this.token);
              this.$store.dispatch("getoneportefeuille", this.me.id);
              this.$store.dispatch("getagencebyuser", this.me.id);
              this.showagence = true;
              localStorage.removeItem("status");
            } else if (status == 400) {
              this.error = true;
              localStorage.removeItem("status");
            }
          });
        } else
          this.$store.dispatch("reversementbykiosk", revers).then(() => {
            const status = localStorage.getItem("status");
            if (status == 200) {
              this.finisher = false;
              this.snackbar = true;
              this.reset();
              localStorage.setItem("tk", this.token);
              this.$store.dispatch("getoneportefeuille", this.me.id);
              this.$store.dispatch("getagencebyuser", this.me.id);
              localStorage.removeItem("status");
            } else if (status == 400) {
              this.error = true;
              localStorage.removeItem("status");
            }
          });
      }
    },
  },
  mounted() {
    // this.$store.dispatch("getroles");
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getme");
    this.$store.dispatch("getoneportefeuille", this.me.id);
    this.$store.dispatch("getagencebyuser", this.me.id);
    this.$store.dispatch("getagencebypays", this.one.user.pays.id);
    this.$store.dispatch("getvillebypays", this.one.user.pays.id);
  },
};
</script>
<style scoped>
.num {
  font-variant-numeric: tabular-nums !important;
  color: #e30613;
}
.container-100 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 100px 0px 0px 0px;
}
.save {
  background-color: #212e61;
  color: #eee7ce;
}
.blck {
  color: #edf3ff;
  text-align: center !important;
  text-decoration: underline;
}
.back {
  background-color: whitesmoke;
}
.signup {
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 70px;
}
h5 {
  line-height: 1.4;
  color: white;
}
</style>
