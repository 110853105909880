<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <div class="col-lg-9 mb-4">
        <div class="col-sm-6 col-md-5 form-group">
          <!-- <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" @click="modal = true" class="mx-2" fab dark>
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>
            <span>Filtre</span>
          </v-tooltip> -->
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-2 row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("Transaction.Transaction") }}</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <td>{{ date(item.createdAt) }}</td>
          </template>
          <template v-slot:[`item.valider`]="{ item }">
            <td v-if="item.valider == true">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" large color="green"
                    >mdi-check-circle-outline
                  </v-icon>
                </template>
                <span>Effectuer</span>
              </v-tooltip>
            </td>
            <td v-else>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="yellow" large>
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <span>En attente</span>
              </v-tooltip>
            </td>
          </template>
          <template v-slot:[`item.montant`]="{ item }">
            <th style="color: red" v-if="item.envoyeur">
              {{ item.montant + " " + item.envoyeur.user.pays.devise.libelle }}
            </th>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
    </div>
    <v-dialog v-model="details" max-width="720">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-sm-5 d-flex justify-content-center rounded-start py-4 custom">
              <div class="my-auto text-center">
                <div class="text-17 text-white my-3">
                  <v-icon x-large color="white">mdi-bank</v-icon>
                </div>
                <h3
                  class="text-4 text-white fw-400 my-3"
                  v-if="
                    objdetails.destination &&
                    objdetails.provenance &&
                    objdetails.destination.pays.id == objdetails.provenance.pays.id &&
                    objdetails.typeTransaction
                  "
                >
                  Transaction {{ objdetails.typeTransaction.libelle }}
                </h3>
                <h3
                  class="text-4 text-white fw-400 my-3"
                  v-else-if="
                    objdetails.destination &&
                    objdetails.provenance &&
                    objdetails.destination.pays.id != objdetails.provenance.pays.id &&
                    objdetails.typeTransaction
                  "
                >
                  Transaction
                  {{ objdetails.typeTransaction.libelle }} Intrazone
                </h3>
                <div
                  v-if="objdetails.moyenRecuperation && objdetails.provenance"
                  class="money other text-white my-4"
                >
                  {{
                    (parseFloat(objdetails.montant) + parseFloat(objdetails.frais))
                      .toFixed(2)
                      .toString() +
                    " " +
                    objdetails.provenance.pays.devise.libelle
                  }}
                </div>
                <p class="text-white">
                  {{ date(objdetails.createdAt) }}
                </p>
              </div>
            </div>
            <div class="col-sm-7">
              <h5 class="text-5 fw-400 m-3">Transaction details</h5>
              <hr />
              <div class="px-3">
                <ul class="list-unstyled">
                  <li class="mb-2" v-if="objdetails.provenance">
                    Montant
                    <span class="float-end end">{{
                      objdetails.montant + " " + objdetails.provenance.pays.devise.libelle
                    }}</span>
                  </li>
                  <li
                    class="mb-2"
                    v-if="objdetails.moyenRecuperation && objdetails.provenance"
                  >
                    Frais
                    <span class="float-end end">
                      {{
                        parseFloat(objdetails.frais).toFixed(2) +
                        " " +
                        objdetails.provenance.pays.devise.libelle
                      }}
                    </span>
                  </li>
                </ul>
                <hr class="mb-2" />
                <p class="d-flex align-items-center other mb">
                  Total
                  <span
                    class="end ms-auto"
                    v-if="objdetails.moyenRecuperation && objdetails.provenance"
                  >
                    {{
                      (parseFloat(objdetails.montant) + parseFloat(objdetails.frais))

                        .toFixed(2)
                        .toString() +
                      " " +
                      objdetails.provenance.pays.devise.libelle
                    }}
                  </span>
                </p>
                <hr class="mb-4 mt-2" />
                <ul class="list-unstyled">
                  <li class="other">Montant a recevoir</li>
                  <li class="mt-2" v-if="objdetails.destination">
                    <span class="end">{{
                      objdetails.total + " " + objdetails.destination.pays.devise.libelle
                    }}</span>
                  </li>
                </ul>
                <ul class="list-unstyled">
                  <li class="other">Numero de transaction</li>
                  <li class="mt-2">
                    <span class="text-muted">{{ objdetails.numero }}</span>
                  </li>
                </ul>
                <ul class="list-unstyled">
                  <li class="other">Moyen de paiyement:</li>
                  <li
                    class="text-4 text-white fw-400 my-3"
                    v-if="objdetails.preuve == null"
                  >
                    <span class="text-muted"> Portefeuille Numerique </span>
                  </li>
                  <li
                    class="text-4 text-white fw-400 my-3"
                    v-else-if="objdetails.preuve != null"
                  >
                    <span class="text-muted"> Virement bancaire </span>
                  </li>
                  <li>
                    <!-- <span class="text-muted">{{ objdetails.preuve }}</span> -->
                  </li>
                </ul>
                <ul class="list-unstyled">
                  <li class="other">Destinataire</li>
                  <li class="mt-2">
                    Nom/Prenom
                    <span class="text-muted float-end">{{ objdetails.recepteur }} </span>
                  </li>
                  <li class="mt-2">
                    Telephone
                    <span class="text-muted float-end"
                      >{{ objdetails.telRecepteur }}
                    </span>
                  </li>
                  <li class="mt-2">
                    Adresse
                    <span class="text-muted float-end"
                      >{{ objdetails.adrRecepteur }}
                    </span>
                  </li>
                  <li class="mt-2">
                    Pays/ville
                    <span class="text-muted float-end" v-if="objdetails.destination">
                      {{
                        objdetails.destination.nom + " " + objdetails.destination.pays.nom
                      }}</span
                    >
                  </li>
                </ul>
                <ul class="list-unstyled" v-if="objdetails.institution">
                  <li class="other">Information bancaire destinataire</li>
                  <li class="mt-2">
                    {{ $t("headers.institution") }}
                    <span class="text-muted float-end"
                      >{{ objdetails.institution }}
                    </span>
                  </li>
                  <li class="mt-2">
                    {{ $t("headers.intitule") }}
                    <span class="text-muted float-end">{{ objdetails.intitule }} </span>
                  </li>
                  <li class="mt-2">
                    {{ $t("headers.numcompte") }}
                    <span class="text-muted float-end">{{ objdetails.numCompte }} </span>
                  </li>
                </ul>

                <ul class="list-unstyled">
                  <li class="other">Status</li>
                  <li class="text-muted" v-if="objdetails.valider == true">
                    Valider
                    <span class="end ms-1 float-end">
                      <v-icon color="green ">mdi-check-circle-outline</v-icon>
                    </span>
                  </li>
                  <li v-else class="text-muted">
                    En attente
                    <span class="end ms-1 float-end">
                      <v-icon color="yellow"> mdi-dots-horizontal </v-icon>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modal" max-width="720">
      <v-card>
        <v-card-title>
          <h3>Selectionner les dates</h3>
        </v-card-title>
        <v-card-text>
          <div class="row mt-2">
            <div class="col-lg-6">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="col-lg-4"
                    v-model="dateDebut"
                    label="Date debut"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateDebut" @input="menu = false"></v-date-picker>
              </v-menu>
            </div>
            <div class="col-lg-6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFin"
                    label="Date fin"
                    class="col-lg-4"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateFin" @input="menu2 = false"></v-date-picker>
              </v-menu>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="modal = false">Fermer</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="#2B2E83" style="color: white" @click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import Aside from "../../../components/aside.vue";
export default {
  components: { Aside },
  data() {
    return {
      items: [],
      modal: false,
      menu: false,
      menu2: false,
      dateDebut: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      snackbar: false,
      timeout: 2000,
      modal: false,
      loading: false,
      confirm: false,
      details: false,
      objdetails: {},
      id: "",
      search: null,
      current_page: 1,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: this.$t("headers.date"),
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: this.$t("headers.description"),
          align: "start",
          sortable: false,
          value: "typeTransaction.libelle",
        },
        {
          text: this.$t("headers.statut"),
          align: "start",
          sortable: false,
          value: "valider",
        },
        {
          text: this.$t("headers.montant"),
          align: "start",
          sortable: false,
          value: "montant",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["allhistoriquetransfert", "token", "user", "one"]),
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.allhistoriquetransfert && this.allhistoriquetransfert.last_page) {
        return this.allhistoriquetransfert.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.allhistoriquetransfert != null && this.allhistoriquetransfert.data) {
        return this.allhistoriquetransfert.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search(newVal) {
      if (newVal.trim() == "") {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("gethistoriquetransfertagent", this.current_page);
      } else {
        localStorage.setItem("tk", this.token);
        this.$store
          .dispatch("searchhistoriquetransfertagent", {
            page: this.current_page,
            search: this.search.trim(),
          })
          .then(() => {
            this.searchresult();
          });
      }
    },
  },
  methods: {
    ici(row) {
      this.objdetails = row;
      this.details = true;
    },
    valid() {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("validoperation", this.id).then(() => {
        const status = localStorage.getItem("status");
        if (status == 200) {
          this.confirm = false;
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("gethistoriquetransfertagent", this.current_page);
          this.$store.dispatch("getoneportefeuille", this.user.id);
          localStorage.removeItem("status");
        } else if (status == 400) {
          this.error = true;
          localStorage.removeItem("status");
        }
      });
    },
    date(item) {
      const date = new Date(item);
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleDateString("fr-FR", options);
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("gethistoriquetransfertagent", this.current_page);
      } else {
        this.$store.dispatch("searchreceiveoperation", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {},
    openPicker(dateType) {
      if (dateType === "dateDebut") {
        this.$refs.menu.save(this.dateDebut);
        this.menu = true;
      } else if (dateType === "dateFin") {
        this.$refs.menu2.save(this.dateFin);
        this.menu2 = true;
      }
    },
    closeModal() {
      this.modal = false;
    },
    save() {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("gethistoriquetransfertagentbydate", {
        start: this.dateDebut,
        end: this.dateFin,
        page: this.current_page,
      });
      this.modal = false;
    },
    updateDate() {
      if (this.selectedDate && this.selectedDate !== "") {
        if (this.dateDebut === "") {
          this.dateDebut = this.selectedDate;
        } else if (this.dateFin === "") {
          this.dateFin = this.selectedDate;
        } else {
          const diff =
            new Date(this.selectedDate).getTime() - new Date(this.dateDebut).getTime();
          if (diff < 0) {
            this.dateDebut = this.selectedDate;
          } else {
            this.dateFin = this.selectedDate;
          }
        }
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("gethistoriquetransfertagent", this.current_page);
    this.$store.dispatch("getoneportefeuille", this.user.id);
  },
  created() {},
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.custom {
  background-color: #2b2e83;
  color: white;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
.infos {
  color: blue;
  font-size: 24px;
  text-transform: uppercase;
}
ul {
  padding-left: 0 !important;
}
.recap p {
  font-size: 1.25rem !important;
}
.other {
  font-weight: 500 !important;
}
.one {
  color: #2b2e83;
  margin-top: 4px;
}
.details p {
  font-size: 2rem;
}
.details span {
  font-size: 2rem;
  text-transform: uppercase;
}
.somme {
  margin: 4rem 4rem 0rem 0rem;
}
.somme p {
  font-size: 5rem !important;
}
.money {
  font-size: 2rem !important;
}
.small {
  font-size: 1.3125rem !important;
}
.end {
  font-size: 1rem !important;
}
</style>
