<template>
  <div class="text-center">
    <v-snackbar
      v-model="modelerror"
      :timeout="timeout"
      absolute
      bottom
      color="black"
      right
    >
      {{ name }}

      <template v-slot:action="{ attrs }">
        <v-btn color="black" text v-bind="attrs" @click="modelerror = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  props: {
    name: "",
    modelerror: false,
  },
  data() {},
};
</script>
