<template>
  <main class="container">
    <v-dialog v-model="modif" transition="dialog-top-transition" max-width="380">
      <template v-slot:default="dialog">
        <v-form ref="formupdate" @submit.prevent="modif">
          <v-card>
            <v-card-text>
              <div class="text-center">
                <v-snackbar
                  v-model="addsucces"
                  :timeout="timeout"
                  absolute
                  top
                  color="#1e824c"
                >
                  <span style="color: white">
                    {{ $t("ville.update") }}
                  </span>
                  <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="addsucces = false">
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </div>
              <v-text-field
                v-model="nom"
                name="nom"
                type="text"
                label="nom"
                :rules="[rules.required]"
                prepend-inner-icon="mdi-alphabetical"
                outlined
                clearable
                dense
              >
              </v-text-field>
              <v-autocomplete
                v-model="country"
                prepend-inner-icon="mdi-earth"
                :items="allpays"
                item-text="nom"
                item-value="id"
                label="pays"
                :rules="[rules.required]"
                return-object
                outlined
                dense
              >
              </v-autocomplete>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="dialog.value = false">Close</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="yellow accent-4" @click="updateville" style="color: black">{{
                $t("update.update")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
    <v-dialog v-model="add" transition="dialog-top-transition" max-width="380">
      <template v-slot:default="dialog">
        <v-form ref="formadd" @submit.prevent="add">
          <v-card>
            <v-card-text>
              <div class="text-center">
                <v-snackbar
                  v-model="addsucces"
                  :timeout="timeout"
                  absolute
                  top
                  color="#1e824c"
                >
                  <span style="color: white">
                    {{ $t("ville.add") }}
                  </span>
                  <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="addsucces = false">
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </div>
              <v-text-field
                v-model="nom"
                name="nom"
                type="text"
                label="nom"
                ref="clear"
                :rules="[rules.required]"
                prepend-inner-icon="mdi-alphabetical"
                outlined
                clearable
                dense
              >
              </v-text-field>
              <v-autocomplete
                v-model="country"
                prepend-inner-icon="mdi-earth"
                :items="allpays"
                item-text="nom"
                item-value="id"
                label="pays"
                ref="clear1"
                :rules="[rules.required]"
                return-object
                outlined
                dense
              >
              </v-autocomplete>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="(dialog.value = false), reset">Close</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="#2b2e83" @click="addville" style="color: white"
                >enregistré</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-9 mb-4">
        <div class="col-md-5 mb-4">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-1 shadow-sm rounded row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <div class="col-md-4">
                <v-toolbar-title>{{ $t("ville.list") }}</v-toolbar-title>
              </div>
              <!-- <div class="col-md-8 d-flex mt-4 justify-content-end">
                <v-btn fab small color="#2d2d2d">
                  <v-icon color="white" @click="openadd"> mdi-plus </v-icon>
                </v-btn>
              </div> -->
            </v-toolbar>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="#1A4478"
            x-large
            fab
            dark
            fixed
            bottom
            right
          >
            <v-icon @click="openadd">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>add</span>
      </v-tooltip>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      id: "",
      nom: "",
      country: "",
      add: false,
      items: [],
      snackbar: false,
      timeout: 2000,
      loading: false,
      search: null,
      addsucces: false,
      modif: false,
      current_page: 1,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: this.$t("headers.indicatif"),
          align: "center",
          sortable: false,
          value: "pays.indicatif",
        },
        {
          text: this.$t("headers.ville"),
          align: "center",
          sortable: false,
          value: "nom",
        },
        {
          text: this.$t("headers.pays"),
          align: "start",
          sortable: false,
          value: "pays.nom",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["token", "ville", "allpays"]),
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.ville && this.ville.last_page) {
        return this.ville.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.ville != null && this.ville.data) {
        return this.ville.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search() {
      if (this.search == "" || this.search.trim() == null) {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getville", this.current_page);
      }
    },
  },
  methods: {
    close() {
      this.reset();
    },
    reset() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
    },
    ici(row) {
      this.modif = true;
      this.id = row.id;
      this.nom = row.nom;
      this.country = row.pays.id;
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getville", this.current_page);
      } else {
        this.$store.dispatch("searchville", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    openadd() {
      this.add = true;
    },
    updateville() {
      if (this.$refs.formupdate.validate()) {
        if (this.country.id == undefined) {
          const add = {};
          add.nom = this.nom;
          add.pays = this.country;
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("updateville", { id: this.id, value: add }).then(() => {
            const status = localStorage.getItem("status");
            if (status == 200) {
              this.addsucces = true;
              const timer = () => {
                this.modif = false;
                this.nom = "";
                this.country = 0;
                localStorage.setItem("tk", this.token);
                this.$store.dispatch("getville", this.current_page);
              };
              setTimeout(timer, 1 * 1000, 1);
            }
          });
        } else {
          const add = {};
          add.nom = this.nom;
          add.pays = this.country.id;
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("updateville", { id: this.id, value: add }).then(() => {
            const status = localStorage.getItem("status");
            if (status == 200) {
              this.addsucces = true;
              const timer = () => {
                this.modif = false;
                this.nom = "";
                this.country = 0;
                localStorage.setItem("tk", this.token);
                this.$store.dispatch("getville", this.current_page);
              };
              setTimeout(timer, 1 * 1000, 1);
            }
          });
        }
      }
    },
    addville() {
      if (this.$refs.formadd.validate()) {
        const add = {};
        add.nom = this.nom;
        add.pays = this.country.id;
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("newville", add).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.addsucces = true;
            const timer = () => {
              this.add = false;
              this.nom = "";
              this.country = 0;
              this.reset();
              localStorage.setItem("tk", this.token);
              this.$store.dispatch("getville", this.current_page);
            };
            setTimeout(timer, 1 * 1000, 1);
          }
        });
      }
    },
    searchresult() {
      if (this.search) {
        if (this.search.trim() == "") {
          localStorage.setItem("tk", this.token);
          this.current_page = 1;
          this.$store.dispatch("getville", this.current_page);
        } else {
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("searchville", {
            page: this.current_page,
            search: this.search.trim(),
          });
          if (this.$store.state.ville.ville.data) {
            this.current_page = this.$store.state.ville.ville.current_page;
          } else this.current_page = 1;
        }
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getville", this.current_page);
    this.$store.dispatch("getallpays");
  },
  created() {
    // console.log(this.$store.state.user.users.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
</style>
