<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import "/public/js/bootstrap.bundle.min";
import "/public/js/slick.min";

export default {
  name: "App",
  data: () => ({
    //
  }),

  mounted: function () {},
};
</script>
<style>
p {
  line-height: 1.8;
}
.primary {
  background-color: #16453e !important;
  border-color: #16453e !important;
}
</style>
