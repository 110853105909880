import axios from "axios";
import { baseURL } from "../../main";

const state = {
  ville: null,
  villes: null,
  allville: null,
  villebypays: null,
  villebypays2: [],
  addville: null,
};
const getters = {
  allville: (state) => state.allville,
  ville: (state) => state.ville,
  villebypays:(state) => state.villebypays,
  villebypays2:(state) => state.villebypays2,
  villes: (state) => state.villes,
  addville: (state) => state.addville,
};

const actions = {
  async updateville({ commit }, { id, value }) {
    let token = localStorage.getItem("tk");
    await axios
      .put(baseURL + "ville/" + id, value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setupdateville", data);
        }
      });
  },
  async newville({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "ville", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setnews", data);
        }
      });
  },
  async getallville({ commit }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "auth/villes", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    data;
    commit("setallville", data);
  },
  async getvillebypays({ commit }, id) {
    return await axios.get(baseURL + "auth/villes/" + id).then((response) => {
      let data = response.data;
      localStorage.removeItem("tk");
      commit("setvillebypays", data);
    });
  },
  async getvillebypays2({ commit }, id) {
    return await axios.get(baseURL + "auth/villes/" + id).then((response) => {
      let data = response.data;
      localStorage.removeItem("tk");
      commit("setvillebypays2", data);
    });
  },
  async getville({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "villes/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setville", data);
  },
  async searchville({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "villes/search/page/" + page + "/" + search,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setville", data);
  },
  getclearville({commit})
  {
    commit("clearville")
  }
 
};
const mutations = {
  clearville(state) {
    state.villebypays = null;
  },
  setville(state, ville) {
    state.ville = ville;
  },
  setupdateville(state, updateville) {
    state.updateville = updateville;
  },
  setallville(state, allville) {
    state.allville = allville;
  },
  setvillebypays(state, villebypays) {
    state.villebypays = villebypays;
  },
  setvillebypays2(state, villebypays2) {
    state.villebypays2 = villebypays2;
  },
  setnews(state, addville) {
    state.addville = addville;
  },
  setsearchville(state, searchville) {
    state.searchville = searchville;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
