<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <div class="col-md-8">
        <h2 class="text-center">
          {{ $t("headers.recups") }}
        </h2>
        <v-card class="info-card" v-show="usermoyens.length == 0">
          <v-card-title class="info-card-title">{{ $t("dialog.aucun") }}</v-card-title>
          <v-card-text class="info-card-text">
            {{ $t("dialog.aucun2") }}
          </v-card-text>
          <v-card-actions class="info-card-actions">
            <v-btn class="info-card-button" color="#1A4478" @click="add = true">{{
              $t("dialog.aucun3")
            }}</v-btn>
          </v-card-actions>
        </v-card>
        <v-list class="pa-4 colis-list">
          <template>
            <v-row>
              <v-col cols="12" md="3" v-for="(item, index) in itemList" :key="index">
                <v-card max-width="300">
                  <v-img
                    class="white--text align-end"
                    height="200px"
                    v-if="item.fichier != null"
                    :src="url + item.fichier.name"
                  >
                  </v-img>
                  <v-img
                    v-else
                    height="200px"
                    src="https://via.placeholder.com/290x250/2B2E83/FFFFFF?text=Vide"
                  />

                  <v-card-subtitle class="pb-0">
                    {{ item.recuperation }}
                  </v-card-subtitle>

                  <v-card-text class="text--primary">
                    <div>{{ item.moyenRecuperation.libelle }}</div>

                    <div>{{ item.libelle }}</div>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn @click="modifier(item)" color="orange" text>Edit</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="openconfirmdialog(item.id)" color="red" text>
                      remove
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-list>
      </div>
      <v-dialog v-model="add" transition="dialog-to-transition" max-width="480">
        <template v-slot:default="dialog">
          <v-form ref="formadd" @submit.prevent="add">
            <v-card>
              <v-card-text>
                <div class="text-center">
                  <v-snackbar
                    v-model="snackbar"
                    :timeout="timeout"
                    center
                    absolute
                    color="#1e824c"
                  >
                    {{ $t("bank.succes") }}

                    <template v-slot:action="{ attrs }">
                      <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                </div>
                <div class="text-center">
                  <v-snackbar
                    v-model="error"
                    :timeout="timeout"
                    absolute
                    bottom
                    color="red"
                    right
                  >
                    {{ $t("devis.error") }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="#cf000f"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                </div>
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <v-autocomplete
                      class="mt-2 ms-2 me-2"
                      v-model="moyenrecup"
                      prepend-inner-icon="mdi-bank-transfer-out"
                      :items="recupList"
                      item-text="libelle"
                      item-value="id"
                      label="Moyen de recuperation"
                      ref="clear"
                      :rules="[rules.required]"
                      return-object
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </div>
                  <div class="col-md-12">
                    <v-text-field
                      v-model="recuperation"
                      name="recepteur"
                      type="text"
                      ref="clear1"
                      label="Description"
                      outlined
                      clearable
                      :rules="[rules.required]"
                      dense
                    >
                    </v-text-field>
                  </div>
                  <div class="col-md-12">
                    <v-file-input
                      accept="images/png, image/jpeg, image/bmp"
                      v-model="image"
                      label="image"
                      ref="clear2"
                      outlined
                      dense
                    ></v-file-input>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  @click="
                    dialog.value = false;
                    reset1();
                  "
                  >Close</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn style="color: wheat" color="#1A4478" @click="save">Ajouter</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </template>
      </v-dialog>
      <v-dialog v-model="modif" transition="dialog-to-transition" max-width="480">
        <template v-slot:default="dialog">
          <v-form ref="formupdate" @submit.prevent="update">
            <v-card>
              <v-card-text>
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <v-autocomplete
                      class="mt-2 ms-2 me-2"
                      v-model="moyenrecup"
                      prepend-inner-icon="mdi-bank-transfer-out"
                      :items="recupList"
                      item-text="libelle"
                      item-value="id"
                      label="Moyen de recuperation"
                      ref="clear3"
                      :rules="[rules.required]"
                      return-object
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </div>
                  <div class="col-md-12">
                    <v-text-field
                      v-model="recuperation"
                      name="recepteur"
                      type="text"
                      ref="clear4"
                      label="Recuperation"
                      outlined
                      clearable
                      :rules="[rules.required]"
                      dense
                    >
                    </v-text-field>
                  </div>
                  <div class="col-md-12">
                    <v-file-input
                      accept="images/png, image/jpeg, image/bmp"
                      v-model="image"
                      label="image"
                      ref="clear5"
                      outlined
                      dense
                    ></v-file-input>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  @click="
                    dialog.value = false;
                    reset2();
                  "
                  >Close</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn color="yellow accent-9" @click="update">update</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </template>
      </v-dialog>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="#1A4478"
            @click="add = true"
            x-large
            fab
            dark
            fixed
            bottom
            right
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("headers.add") }}</span>
      </v-tooltip>
      <v-dialog v-model="confirmdialog" max-width="400px">
        <template v-slot:default="dialog">
          <v-card class="confirmation-card">
            <v-card-title class="confirmation-card-title text message">
              {{ $t("dialog.title") }}
            </v-card-title>
            <v-card-text class="text message">
              {{ $t("dialog.message") }}
            </v-card-text>
            <v-card-actions>
              <v-btn class="text back2" text @click="dialog.value = false">Annuler</v-btn>
              <v-spacer></v-spacer>
              <v-btn class="text back1" @click="deleted" text>Confirmer</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </main>
</template>
<script>
import Aside from "../../../components/aside.vue";
import { mapGetters } from "vuex";
import { baseURL } from "../../../main";

export default {
  components: { Aside },
  data: () => {
    return {
      show2: "",
      old: null,
      news: null,
      confirm: false,
      show1: "",
      show2: "",
      confirmdialog: false,
      solde: false,
      modif: false,
      oui: false,
      Libelle: "",
      moyenrecup: "",
      none: false,
      recuperation: "",
      url: "",
      image: null,
      id: "",
      add: false,
      snackbar: false,
      error: false,
      timeout: 2000,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
    };
  },
  computed: {
    ...mapGetters(["usermoyens", "one", "me", "token", "recup"]),
    itemList() {
      if (this.usermoyens != null) {
        return this.usermoyens;
      } else {
        return [];
      }
    },
    recupList() {
      if (this.recup != null) {
        return this.recup.filter(
          (item) => item.libelle != "VIREMENT BANCAIRE" && item.libelle != "ESPECE"
        );
      }
    },
  },

  methods: {
    save() {
      if (this.$refs.formadd.validate()) {
        const recupuser = new FormData();
        if (this.image != null) {
          recupuser.append("image", this.image, this.image.name);
        } else {
          const emptyBlob = new Blob();
          recupuser.append("image", emptyBlob, "vide");
        }
        recupuser.append("recuperation", this.recuperation);
        recupuser.append("moyenRecuperation", this.moyenrecup.id);
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("addmoyenuser", recupuser).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.add = false;
            this.reset1();
            this.snackbar = true;
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getme");
            this.$store.dispatch("getallmoyenuser");
          } else if (status === 400) {
            this.error = true;
            localStorage.removeItem("status");
          }
        });
      }
    },
    reset1() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
      this.$refs.clear2.reset();
    },
    reset2() {
      this.$refs.clear3.reset();
      this.$refs.clear4.reset();
      this.$refs.clear5.reset();
    },
    openconfirmdialog(item) {
      this.confirmdialog = true;
      this.id = item;
    },
    modifier(item) {
      this.modif = true;
      this.id = item.id;
      this.moyenrecup = item.moyenRecuperation.id;
      this.recuperation = item.recuperation;
      this.image = item.fichier;
    },
    update() {
      const recupuser = new FormData();
      if (this.image.id) {
        const file = new File([], this.image.name, {
          type: "image/jpeg",
        });
        recupuser.append("image", file, file.name);
      } else {
        recupuser.append("image", this.image, this.image.name);
      }

      if (this.moyenrecup.id) {
        recupuser.append("moyenRecuperation", this.moyenrecup.id);
      } else {
        recupuser.append("moyenRecuperation", this.moyenrecup);
      }
      recupuser.append("recuperation", this.recuperation);
      localStorage.setItem("tk", this.token);
      this.$store
        .dispatch("updatemoyenuser", { id: this.id, value: recupuser })
        .then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.modif = false;
            this.reset2();
            this.snackbar = true;
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getme");

            this.$store.dispatch("getallmoyenuser");
          } else if (status === 400) {
            this.error = true;
            localStorage.removeItem("status");
          }
        });
    },
    deleted() {
      localStorage.setItem("tk", this.$store.getters.token);
      this.$store.dispatch("removemoyenuser", this.id).then(() => {
        this.confirmdialog = false;
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getallmoyenuser");
      });
    },
  },
  mounted() {
    this.url = baseURL + "downloadFile/";
    localStorage.setItem("tk", this.$store.getters.token);
    this.$store.dispatch("getme");
    this.$store.dispatch("getmoyenrecupbypays", this.me.pays.id);
    this.$store.dispatch("getallmoyenuser");
  },
};
</script>
<style scoped>
.info-card {
  max-width: 500px;
  margin: 0 auto;
}

.info-card-title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}

.info-card-text {
  font-size: 18px;
  text-align: center;
}

.info-card-actions {
  justify-content: center;
}

.info-card-button {
  font-size: 15px;
  padding: 10px 32px;
  color: white;
}
.num {
  font-variant-numeric: tabular-nums !important;
  color: #e30613;
}
.container-100 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 100px 0px 0px 0px;
}
.save {
  background-color: #2b2e83;
  color: #eee7ce;
}
.back {
  background-color: whitesmoke;
}
.signup {
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 70px;
}
.colis-list {
  /* background-color: #f8f8f8; */
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.colis-list-item {
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.colis-list-item:hover {
  background-color: #f0f0f0;
  transform: translateY(-2px);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.colis-list-item-title {
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 8px;
}

.colis-list-item-subtitle {
  font-size: 1.6rem;
  color: #888888;
}

.colis-list-item-action {
  display: flex;
  align-items: center;
}
.confirmation-card {
  max-width: 400px;
  margin: 0 auto;
  background-color: #0f1214;
}
.text {
  color: #f0f2f4 !important;
}
.back1 {
  background-color: #032b07;
}
.message {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  line-height: 36px;
  vertical-align: baseline;
  letter-spacing: normal;
  word-spacing: 0px;
  margin: 24px 0px;
  padding: 0px;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: rgb(249, 231, 231);
  text-align: start;
  text-indent: 0px;
}
.back2 {
  background-color: #1d2126;
}
.confirmation-card-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  background-color: #0f1214;
  padding: 16px;
  margin-bottom: 16px;
}

.v-btn {
  margin: 8px;
}
</style>
