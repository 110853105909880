<template>
  <main class="container">
    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        center
        absolute
        multi-line
        color="success"
        left
      >
        {{ $t("user.succes") }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </div>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-9 mb-4">
        <div class="col-md-5 mb-4">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-2 row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("user.userlist") }}</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
    </div>
    <v-dialog v-model="news" max-width="720" persistent>
      <v-card>
        <v-card-title class="headline d-flex flex-column" style="color: Red">
        </v-card-title>
        <v-card-text class="text-center">
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">{{
                $t("step.one")
              }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2">{{
                $t("step.two")
              }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 3" step="3">{{
                $t("step.tree")
              }}</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form ref="personalInfoForm">
                  <v-card class="px-4 py-5">
                    <v-text-field
                      v-model="nom"
                      :rules="[rules.required]"
                      prepend-inner-icon="mdi-account"
                      name="nom"
                      :label="$t('headers.nom')"
                      type="text"
                      clearable
                      outlined
                      ref="clear"
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="prenom"
                      class="mt-2"
                      :rules="[rules.required]"
                      prepend-inner-icon="mdi-account"
                      name="prenom"
                      :label="$t('headers.prenom')"
                      type="text"
                      ref="clear1"
                      clearable
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      v-model="username"
                      class="mt-2"
                      :rules="usernameRules"
                      prepend-inner-icon="mdi-account"
                      name="username"
                      :label="$t('headers.username')"
                      type="text"
                      @input="existusername"
                      clearable
                      outlined
                      ref="clear2"
                      dense
                      :error-messages="messages"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="email"
                      class="mt-2"
                      :rules="emailRules"
                      prepend-inner-icon="mdi-email"
                      name="email"
                      :label="$t('headers.email')"
                      type="email"
                      :error-messages="messagesemail"
                      @input="existemail"
                      clearable
                      ref="clear3"
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      v-model="password"
                      class="mt-2"
                      :rules="passwordRules"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show1 = !show1"
                      :type="show1 ? 'text' : 'password'"
                      prepend-inner-icon="mdi-key"
                      name="password"
                      outlined
                      ref="clear4"
                      dense
                      :label="$t('headers.password')"
                      clearable
                    ></v-text-field>
                    <v-text-field
                      v-model="tel"
                      class="mt-2"
                      prepend-inner-icon="mdi-phone"
                      :rules="[rules.required]"
                      name="tel"
                      :label="$t('headers.telephone')"
                      type="text"
                      @input="existtel"
                      :error-messages="messagestel"
                      outlined
                      ref="clear5"
                      dense
                      clearable
                    ></v-text-field>
                    <v-autocomplete
                      v-model="allpay"
                      class="mt-2"
                      prepend-inner-icon="mdi-earth"
                      :rules="[rules.required]"
                      name="pays"
                      :label="$t('headers.pays')"
                      outlined
                      ref="clear6"
                      item-text="nom"
                      item-value="id"
                      :items="PaysList"
                      return-object
                      dense
                    ></v-autocomplete>
                    <v-autocomplete
                      v-model="Roles"
                      :rules="[rules.required]"
                      prepend-inner-icon=""
                      name="Role"
                      label="Role"
                      :items="RoleList"
                      item-value="id"
                      ref="clear6"
                      item-text="libelle"
                      outlined
                      dense
                      return-object
                    >
                    </v-autocomplete>
                    <div class="d-flex justify-content-between">
                      <div>
                        <v-btn
                          color="red"
                          style="color: white"
                          @click="(news = false), reset()"
                          >fermer</v-btn
                        >
                      </div>
                      <div>
                        <v-btn
                          color="#2b2e83"
                          style="color: white"
                          @click="validateStep1()"
                          >{{ $t("step.next") }}</v-btn
                        >
                      </div>
                    </div>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form ref="bankInfoForm">
                  <v-text-field
                    class="mt-2"
                    v-model="institution"
                    outlined
                    clearable
                    dense
                    :label="$t(`headers.institution`)"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    v-model="intitule"
                    outlined
                    clearable
                    dense
                    :label="$t(`headers.intitule`)"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    v-model="numCompte"
                    outlined
                    clearable
                    dense
                    :label="$t('headers.numcompte')"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <div class="d-flex justify-content-between">
                    <v-btn color="#3433cf" style="color: white" @click="step = 1">{{
                      $t("step.prev")
                    }}</v-btn>
                    <v-btn color="#2b2e83" style="color: white" @click="validateStep2">{{
                      $t("step.next")
                    }}</v-btn>
                  </div>
                </v-form>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-form ref="pinInfoForm">
                  <v-text-field
                    class="mt-2"
                    v-model="pin"
                    outlined
                    clearable
                    dense
                    label="Code PIN"
                    :rules="pinRules"
                  ></v-text-field>
                  <div class="d-flex justify-content-between">
                    <v-btn color="#3433cf" style="color: white" @click="step = 2">{{
                      $t("step.prev")
                    }}</v-btn>
                    <v-btn color="#2b2e83" style="color: white" @click="validateStep3">{{
                      $t("step.end")
                    }}</v-btn>
                  </div>
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="#1A4478"
          x-large
          fab
          dark
          fixed
          bottom
          right
          @click="add"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("user.useradd") }}</span>
    </v-tooltip>
  </main>
</template>
<script>
import axios from "axios";

import { mapGetters } from "vuex";
import { baseURL } from "../../../main";
export default {
  data() {
    return {
      id: "",
      nom: "",
      news: false,
      prenom: "",
      tel: "",
      email: "",
      Roles: "",
      username: "",
      step: 1,
      password: "",
      allpay: "",
      pin: "",
      show1: "",
      institution: "",
      value: null,
      options: ["list", "of", "options"],
      intitule: "",
      numCompte: "",
      ville: "",
      modif: false,
      items: [],
      snackbar: false,
      timeout: 2000,
      loading: false,
      search: null,
      current_page: 1,
      messages: null,
      messagestel: null,
      messagesemail: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Username",
          align: "start",
          sortable: false,
          value: "username",
        },
        {
          text: this.$t("headers.nom"),
          align: "start",
          sortable: false,
          value: "nom",
        },
        {
          text: this.$t("headers.prenom"),
          align: "start",
          sortable: false,
          value: "prenom",
        },
        {
          text: this.$t("headers.telephone"),
          align: "start",
          sortable: false,
          value: "tel",
        },
        {
          text: this.$t("headers.email"),
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: this.$t("headers.role"),
          align: "start",
          sortable: false,
          value: "role.libelle",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["users", "token", "roles", "me", "one", "allpays"]),
    usernameRules() {
      return [
        (v) => !!v || this.$t("require.username"),
        (v) =>
          /^(?=.*[a-zA-Z].*)(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/.test(v) ||
          this.$t("rule.username"),
      ];
    },
    passwordRules() {
      return [
        (v) => !!v || this.$t("require.pass"),
        (v) =>
          /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()–[{}]).{6,40}/.test(v) ||
          this.$t("rule.pass"),
      ];
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("require.email"),
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || this.$t("rule.mail"),
      ];
    },
    pinRules() {
      return [
        (v) => !!v || this.$t("require.pin"),
        (v) => /^\d{6}$/.test(v) || this.$t("rule.pin"),
      ];
    },
    form() {
      return {
        nom: this.nom,
        prenom: this.prenom,
        username: this.username,
        email: this.email,
        tel: this.tel,
        password: this.password,
        role: this.Roles.id,
        pays: this.allpay.id,
        institution: this.institution,
        intitule: this.intitule,
        numCompte: this.numCompte,
        pin: this.pin,
      };
    },
    PaysList() {
      if (this.allpays != null) {
        return this.allpays.filter((item) => item.id == this.me.pays.id);
      }
    },
    RoleList() {
      if (this.roles != null) {
        return this.roles.filter((item) => item.id == 2 || item.id == 6);
      }
    },
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.users && this.users.last_page) {
        return this.users.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.users != null && this.users.data) {
        return this.users.data.filter(
          (item) =>
            (item.pays?.id == this.one.user.pays.id && item.role.id == 3) ||
            item.role.id == 6 ||
            item.role.id == 2
        );
        // return this.users.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search() {
      if (this.search == "" || this.search.trim() == null) {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getallusers", this.current_page);
      }
    },
  },
  methods: {
    reset() {
      this.news = false;
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
      this.$refs.clear2.reset();
      this.$refs.clear3.reset();
      this.$refs.clear4.reset();
      this.$refs.clear5.reset();
      this.$refs.clear6.reset();
    },
    add() {
      this.news = true;
    },
    validateStep1() {
      const valid = this.$refs.personalInfoForm.validate();
      if (valid == true && this.Roles.id == 4) {
        this.step = 3;
      } else if (valid == true && this.Roles.id != 4) {
        this.step = 2;
      }
    },
    validateStep2() {
      const valid = this.$refs.bankInfoForm.validate();
      if (valid == true) {
        this.step = 3;
      }
    },
    validateStep3() {
      const valid = this.$refs.pinInfoForm.validate();
      if (valid == true) {
        localStorage.setItem("tk", this.$store.getters.token);
        this.$store.dispatch("adduser", this.form).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.snackbar = true;
            this.reset();
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getallusers", this.current_page);
            localStorage.removeItem("status");
          } else if (status == 400) {
            this.error = true;
            localStorage.removeItem("status");
          }
        });
      }
    },
    async existemail() {
      if (this.email) {
        await axios.get(baseURL + "auth/check/email/" + this.email).then((response) => {
          if (response.data == true) {
            this.messagesemail = "cet adresse email existe deja";
          } else {
            this.messagesemail = null;
          }
        });
      }
    },
    async existtel() {
      if (this.tel) {
        await axios.get(baseURL + "auth/check/tel/" + this.tel).then((response) => {
          if (response.data == true) {
            this.messagestel = "ce nom  existe deja";
          } else {
            this.messagestel = null;
          }
        });
      }
    },
    async existusername() {
      if (this.username) {
        await axios
          .get(baseURL + "auth/check/username/" + this.username)
          .then((response) => {
            if (response.data == true) {
              this.messages = "ce nom d'utilisateur existe deja";
            } else {
              this.messages = null;
            }
          });
      }
    },
    update() {
      if (this.$refs.formupdate.validate()) {
        if (this.Roles.id == undefined) {
          const update = {};
          update.nom = this.nom;
          update.prenom = this.prenom;
          update.tel = this.tel;
          update.email = this.email;
          update.username = this.username;
          update.role = this.Roles;
          localStorage.setItem("tk", this.token);
          this.$store
            .dispatch("updateuser", {
              id: this.id,
              value: update,
            })
            .then(() => {
              const status = localStorage.getItem("status");
              if (status == 200) {
                this.modif = false;
                this.snackbar = true;
                this.id = "";
                this.nom = "";
                this.prenom = "";
                this.tel = "";
                this.email = "";
                this.username = "";
                this.Roles = 0;
                localStorage.setItem("tk", this.token);
                this.$store.dispatch("getallusers", this.current_page);
              }
            });
        } else {
          const update = {};
          update.nom = this.nom;
          update.prenom = this.prenom;
          update.tel = this.tel;
          update.email = this.email;
          update.username = this.username;
          update.role = this.Roles.id;
          localStorage.setItem("tk", this.token);
          this.$store
            .dispatch("updateuser", {
              id: this.id,
              value: update,
            })
            .then(() => {
              const status = localStorage.getItem("status");
              if (status == 200) {
                this.modif = false;
                this.snackbar = true;
                this.id = "";
                this.nom = "";
                this.prenom = "";
                this.tel = "";
                this.email = "";
                this.username = "";
                this.Roles = 0;
                localStorage.setItem("tk", this.token);
                this.$store.dispatch("getallusers", this.current_page);
              }
            });
        }
      }
    },
    ici(row) {
      this.id = row.id;
      this.nom = row.nom;
      this.prenom = row.prenom;
      this.tel = row.tel;
      this.email = row.email;
      this.username = row.username;
      this.Roles = row.role.id;
      this.modif = true;
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getallusers", this.current_page);
      } else {
        this.$store.dispatch("searchusers", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {
      if (this.search) {
        if (this.search.trim() == "") {
          localStorage.setItem("tk", this.token);
          this.current_page = 1;
          this.$store.dispatch("getallusers", this.current_page);
        } else {
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("searchusers", {
            page: this.current_page,
            search: this.search.trim(),
          });
          if (this.$store.state.user.users.data) {
            this.current_page = this.$store.state.user.users.current_page;
          } else this.current_page = 1;
        }
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getme");
    this.$store.dispatch("getallusers", this.current_page);
    this.$store.dispatch("getroles");
  },
  created() {
    // console.log(this.$store.state.user.users.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
</style>
