<template>
  <main>
    <!-- <nav class="toc">
      <ul>
        <li>
          <a href="#section-1">{{ sections[0].title }}</a>
        </li>
        <li>
          <a href="#section-2">{{ sections[1].title }}</a>
        </li>
        <li>
          <a href="#section-3">{{ sections[2].title }}</a>
        </li>
      </ul>
    </nav> -->

    <div>
      <v-navigation-drawer v-model="drawer" right app temporary>
        <v-list>
          <v-subheader class="matiere">
            Table des matières
            <hr />
          </v-subheader>
          <v-list-item v-for="section in sections" :key="section.id" link>
            <v-list-item-content class="mt-4">
              <li>
                <v-icon>{{ section.icon }}</v-icon>
                <a :href="`#` + section.id" class="menu ms-2">
                  {{ section.title }}
                </a>
              </li>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <div class="api-doc mt-4">
        <h1>
          <img
            src="https://s2apay.com/favicon.ico"
            alt="Markdown Here logo"
            style="height: 2em; margin-right: 0.5em"
          />Documentation
        </h1>
        <h2 id="section-0">{{ sections[0].title }}</h2>
        <p class="endpoint">
          <span class="endpoints text2">
            https://shopymanager.com:8443/s2apay/web/service/
          </span>
        </p>

        <h2 id="section-1">{{ sections[1].title }}</h2>
        <p class="endpoint">
          <span class="endpoints text2"> POST </span>
          <span class="json">auth/signin </span>
        </p>

        <p>&#x1F44E;Vous devez fournir username et le password</p>

        <pre><code class="json">
          {
            <span class="text">"username":</span>"******",
            <span class="text">"password":</span>"******",
          }
  </code></pre>

        <h2 id="section-2">{{ sections[2].title }}s</h2>
        <p class="endpoint">
          <span class="endpoints text2"> GET </span>
          <span class="json">users/me</span>
        </p>
        <p>Permet d'avoir toute les vos informations</p>
        <p>&#x1F44E; code JSON :</p>
        <pre>
          <code class="json">
            {
              <span class="text">"id"</span>:"" 
              <span class="text">"username"</span>: "",
              <span class="text">"email"</span>: "",
              <span class="text">"nom"</span>: "",
              <span class="text">"prenom"</span>: "",
              <span class="text">"tel"</span>: "",
              <span class="text">"avatar"</span>: ,
              <span class="text">"pin"</span>: "",
              <span class="text">"role"</span>: 
              {
                 <span class="text2">"id"</span>: "",
                 <span class="text2">"libelle"</span>: ""
              },
              <span class="text">"pays"</span>: 
              {
                <span class="text2">"id"</span>:"" ,
                <span class="text2">"nom" </span>: "",
                <span class="text2">"code" </span>: "",
                <span class="text2">"indicatif"</span>: "",
                <span class="text2">"devise" </span>: 
                  {
                      "id": ,
                      "libelle": ""
                  }
              },
              <span class="text">"banques"</span>: [],
              <span class="text">"mobiles"</span>: [],
              <span class="text">"tokenDevice"</span>: "",
              <span class="text">"createdAt"</span>:
            }
         </code>
        </pre>
        <h2 id="section-3">{{ sections[3].title }}</h2>
        <p class="endpoint">
          <span class="endpoints text2"> GET </span>
          <span class="json">developer/users/portefeuille/{id}</span>
        </p>
        <p>&#x1F44E; Permet de recuperer les informations concernant mon portefeuilles</p>

        <pre>
          <code class="json">
            {
              <span class="text">"id"</span>:"",
              <span class="text">"user"</span>:{},
              <span class="text">"numero"</span>: "",
              <span class="text">"solde"</span>:"" 
            }
          </code>
        </pre>
        <h2 id="section-4">{{ sections[4].title }}</h2>
        <p class="endpoint">
          <span class="endpoints text2"> POST </span>
          <span class="json">developer/operation</span>
        </p>
        <p>&#x1F44E; Recevoir un payement</p>

        <pre>
          <code class="json">
            {
              <span class="text">"envoyeur"</span>:"",
              <span class="text">"montant"</span>:"",
              <span class="text">"code pin"</span>: "",
            }
          </code>
        </pre>
        <h2 id="section-5">{{ sections[5].title }}</h2>
        <p>
          En utilisant cette URL, il est possible de récupérer des tableaux de résultats
          pour les operations qui correspondent à la page spécifiée. Le nombre de
          résultats par page est de 10
        </p>
        <p class="endpoint">
          <span class="endpoints text2"> GET </span>
          <span class="json">developer/operations/recu/page/{page}</span>
        </p>
        <h2 id="section-6">{{ sections[6].title }}</h2>
        <p>
          En utilisant cette URL, il est possible de récupérer des résultats de recherche
          des operation qui correspondent aux critères de recherche spécifiés par le
          paramètre "{s}". Le numéro de page pour les résultats peut être spécifié en
          utilisant le paramètre "{page}"
        </p>
        <p class="endpoint">
          <span class="endpoints text2"> GET </span>
          <span class="json">developer/operations/recu/search/page/{page}/{s}</span>
        </p>
        <h2 id="section-7">{{ sections[7].title }}</h2>
        <p>
          En utilisant cette URL, il est possible de récupérer une liste de tableaux
          d'operation(credit et retrait) . Le nombre de résultats par page est de 10
        </p>
        <p class="endpoint">
          <span class="endpoints text2"> GET </span>
          <span class="json">developer/operations/user/page/{page}</span>
        </p>
        <h2 id="section-8">{{ sections[8].title }}</h2>
        <p>
          En utilisant cette URL, il est possible de récupérer des résultats de recherche
          des operations de qui correspondent aux critères de recherche spécifiés par le
          paramètre "{s}". Le numéro de page pour les résultats peut être spécifié en
          utilisant le paramètre "{page}"
        </p>
        <p class="endpoint">
          <span class="endpoints text2"> GET </span>
          <span class="json">developer/operations/user/page/{page}/{s}</span>
        </p>
      </div>
      <v-btn color="#1A4478" x-large fab dark fixed bottom right @click="drawer = true">
        <v-icon>mdi-format-list-bulleted</v-icon>
      </v-btn>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      items: [
        { title: "Home", icon: "mdi-home-city" },
        { title: "My Account", icon: "mdi-account" },
        { title: "Users", icon: "mdi-account-group-outline" },
      ],
      sections: [
        {
          id: "section-0",
          title: "BaseUrl",
          icon: "mdi-link",
        },
        { id: "section-1", title: "Authentification", icon: "mdi-lock" },
        {
          id: "section-2",
          title: "Information Utilisateur",
          icon: "mdi-account-outline",
        },
        {
          id: "section-3",
          title: "Information portefeuille",
          icon: "mdi-wallet-outline",
        },
        {
          id: "section-4",
          title: "Operation",
          icon: "mdi-exit",
        },
        {
          id: "section-5",
          title: "Transactions reçue ",
          icon: "mdi-text-search",
        },
        {
          id: "section-6",
          title: "Recherche des Transactions reçue",
          icon: "mdi-text-search",
        },
        {
          id: "section-7",
          title: "Toute les Transactions ",
          icon: "mdi-text-search",
        },
        {
          id: "section-8",
          title: "Recherche des Transactions ",
          icon: "mdi-text-search",
        },
      ],
    };
  },
  watch: {},
  methods: {},
  mounted() {},
};
</script>
<style scoped>
@media (min-width: 600px) {
  .v-navigation-drawer {
    width: 80%;
  }
}

@media (max-width: 599px) {
  .v-navigation-drawer {
    width: 100%;
  }
}
@media (max-width: 599px) {
  .matiere {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .menu {
    font-size: 14px;
  }

  .endpoint {
    font-size: 14px;
  }

  .json {
    font-size: 12px;
  }
}
.menu {
  color: rgba(0, 0, 0, 0.87);
}
.matiere {
  background-color: #2d2d2d;
  color: whitesmoke;
  font-size: 1.5rem;
  line-height: 1.8;
  text-transform: uppercase;
  font-weight: 500 !important;
}
.toc {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 0 0 20%; /* Largeur de la table des matières */
  padding-right: 1em;
  overflow-y: auto; /* Ajouter une barre de défilement si nécessaire */
}

.content {
  flex: 1;
}

.toc ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.toc li {
  margin-bottom: 0.5em;
}

.toc a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}

/* Style pour le lien actif dans la table des matières */
.toc a.active {
  color: #4caf50;
}
.api-doc {
  max-width: 800px !important;
  margin: 0 auto !important;
  font-family: Arial, sans-serif !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
}

h1 {
  font-size: 32px;
  font-weight: bold;
  margin: 50px 0 20px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 30px 0 10px;
  color: #2b2e83 !important;
}

p {
  margin-bottom: 20px;
}

pre {
  background-color: #2d2d2d;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
}

code {
  font-size: 14px;
  font-family: Consolas, monospace !important;
}

.text {
  color: #ccc;
}
.text2 {
  color: #7ec669;
}
.endpoint {
  background-color: #2d2d2d;
  color: white;
  border-radius: 4px;
  font-size: 1.2rem !important;
  font-weight: bold !important;
  margin-top: 1.5rem !important;
}
.endpoints {
  margin-left: 10%;
}
.json {
  color: #f08d49 !important;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.4;
}

/* Page header styles */
h1 {
  background-color: #2d2d2d;
  color: #ffffff;
  margin: 0;
  padding: 1em;
  display: flex;
  align-items: center;
  font-weight: 500;

  font-size: 1.5em;
}

img {
  display: inline-block;
  vertical-align: middle;
}

.content .endpoints {
  background-color: #2ecc71;
  padding: 0.2rem 0.5rem;
  color: #fff;
  border-radius: 5px;
}
</style>
