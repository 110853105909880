import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import user from './modules/user';
import role from './modules/role';
import agent from './modules/agent';
import operation from './modules/operation';
import preuve from './modules/preuve';
import portefeuille from './modules/portefeuille';
import ville from './modules/ville';
import pays from './modules/pays'
import devise from './modules/devise';
import agence from './modules/agence';
import taux from './modules/tauxechange'
import moyen from './modules/moyen';
import transfert from './modules/transfert';
import kiosk from './modules/kiosk';
import reversement from './modules/reversement';
import historique from './modules/historique';
import reset from './modules/reset';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({

actions:{
 
},
  modules: {
    auth,
    user,
    role,
    portefeuille,
    agent,
    operation,
    preuve,
    pays,
    devise,
    ville,
    agence,
    taux,
    moyen,
    transfert,
    kiosk,
    reversement,
    historique,
    reset
  },
  
  plugins: [createPersistedState()]
});