<template>
  <main>
    <div class="row">
      <main class="bg">
        <div class="container d-flex justify-content-center">
          <ul v-for="menus in menu" v-bind:key="menus.id" class="nav secondary-nav">
            <router-link v-bind:to="{ path: menus.url }">
              <li class="nav-item">
                <a
                  class="nav-link is"
                  :class="{ active: activeLink === menus.id, 'nav-link': true }"
                  @click="setActiveLink(menus.id)"
                  >{{ $t(menus.nom) }}</a
                >
              </li>
            </router-link>
          </ul>
        </div>
      </main>
    </div>
  </main>
</template>
<script>
export default {
  props: {
    menu: Array,
  },
  data() {
    return {
      activeLink: 0,
    };
  },
  methods: {
    setActiveLink(id) {
      this.activeLink = id;
    },
  },
  watch: {
    activeLink() {},
  },
};
</script>
<style>
.bg {
  background-color: #2d2d2d;
}
.secondary-nav.nav {
  padding-left: 8px;
}
.secondary-nav.nav .nav-link {
  text-align: center;
  font-size: 16px;
  padding: 1rem 20px;
  white-space: nowrap;
  color: rgba(250, 250, 250, 0.9);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.secondary-nav.nav .nav-link:hover {
  color: white;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.secondary-nav.nav .nav-link span {
  display: block;
  font-size: 30px;
  margin-bottom: 5px;
}
.secondary-nav.nav .nav-item .nav-link.active {
  margin-top: 7px;
  background: #2b2e83;
  color: #fff;
}
.secondary-nav.nav.alternate .nav-link {
  color: red(0, 0, 0, 0.6);
}
.secondary-nav.nav.alternate .nav-link:hover {
  color: red;
}
.secondary-nav.nav.alternate .nav-item .nav-link.active {
  background-color: transparent;
  color: red;
  border-bottom: 3px solid red;
}

@media (max-width: 1199px) {
  .secondary-nav.nav {
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
}
/* @media (max-width: 992px) {
  .is {
  }
} */
</style>
