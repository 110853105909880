import axios from "axios";
import { baseURL } from "../../main";

const state = {
  adddevise: null,
  devisetaux: null,
  devise: null,
  alldevise: null,
  devisebypays: null,
  searchdevisebypays: null,
  updatesdevise: null,
  tauxechange: null,
};

const getters = {
  adddevise: (state) => state.adddevise,
  devisetaux: (state) => state.devisetaux,
  alldevise: (state) => state.alldevise,
  devises: (state) => state.devises,
  devisebypays: (state) => state.devisebypays,
  searchdevisebypays: (state) => state.searchdevisebypays,
  updatedevise: (state) => state.updatedevise,
  tauxechange: (state) => state.tauxechange,
};

const actions = {
  async updatedevise({ commit }, { id, value }) {
    let token = localStorage.getItem("tk");
    await axios
      .put(baseURL + "devise/" + id, value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setupdatedevise", data);
        }
      });
  },
  async adddevise({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "devise", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          data;
          localStorage.removeItem("tk");
          commit("setnews", data);
        }
      });
  },

  async getdevise({ commit }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "devises", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    data;
    localStorage.removeItem("tk");
    commit("setdevises", data);
  },
  async getonedevise({ commit }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "devise", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    data;
    localStorage.removeItem("tk");
    commit("setonedevise", data);
  },
  async getalldevises({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "devises/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setdevise", data);
  },
  async searchdevise({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");

    let response = await axios.get(
      baseURL + "devises/search/page/" + page + "/" + search,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;

    localStorage.removeItem("tk");
    commit("setdevise", data);
  },
  async gettauxbydevise({ commit }, { send, receive }) {
    return await axios
      .get(baseURL + "auth/taux/echange/" + send + "/" + receive)
      .then((response) => {
        if(response == undefined)
        {
          localStorage.setItem("status", 400);
        }else
        {
          localStorage.setItem("status", 200);
          let data = response.data;
          commit("settauxbydevise", data);
        }
      });
  },
  async addtauxechange({ commit }, value) {
    return await axios
      .post(baseURL + "taux/echange/", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let data = response.data;
        localStorage.removeItem("tk");
        commit("settauxecahnge", data);
      });
  },
  async getdevisebypays({ commit }, id) {
    return await axios
      .get(baseURL + "auth/pays/devise/" + id)
      .then((response) => {
        let data = response.data;
        commit("setdevisebypays", data);
      });
  },
  async searchdevisebypays({ commit }, { id, page }) {
    let token = localStorage.getItem("tk");

    let response = await axios.get(
      baseURL + "devises/pays/page/" + id + "/" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;

    localStorage.removeItem("tk");
    commit("setsearchdevisebypays", data);
  },
};
const mutations = {
  setnews(state, adddevise) {
    state.adddevise = adddevise;
  },
  setdevise(state, alldevise) {
    state.alldevise = alldevise;
  },
  settauxechange(state, devisetaux) {
    state.devisetaux = devisetaux;
  },
  setupdatedevise(state, updatedevise) {
    state.updatedevise = updatedevise;
  },
  setdevises(state, devises) {
    state.devises = devises;
  },
  setsearchdevisebypays(state, searchdevisebypays) {
    state.searchdevisebypays = searchdevisebypays;
  },
  setdevisebypays(state, devisebypays) {
    state.devisebypays = devisebypays;
  },
  settauxbydevise(state, tauxechange) {
    state.tauxechange = tauxechange;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
