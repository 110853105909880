import axios from "axios";
import { baseURL } from "../../main";

const state =
{
    agent:null,
}
const getters=
{
    agent:(state)=> state.agent,
   
};

const actions =
{
    async getallagent({ commit }, page) {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "users/agent/page/" + page, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        let data = response.data;
      
        localStorage.removeItem("tk");
        commit("setagent", data);
      },
      async searchagent({ commit }, { page, search }) {
        let token = localStorage.getItem("tk");
        console.log(baseURL + "users/agent/search/page/" + page + "/" + search);
        let response = await axios.get(
          baseURL + "users/agent/search/page/" + page + "/" + search,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        let data = response.data;
        localStorage.removeItem("tk");
        commit("setagent", data);
      },
   
};
const mutations =
{
    setagent(state, agent)
    {
        state.agent = agent
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
}