import Vue from "vue";
import VueI18n from "vue-i18n";
import fr from "./fr/index";
import en from "./en/index";
Vue.use(VueI18n)

const messages = {
  en: en,
  fr: fr,
};

export default  new VueI18n({
  locale: localStorage.getItem("lang"),
  messages,
});
