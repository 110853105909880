<template>
  <main class="container">
    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        center
        absolute
        multi-line
        color="success"
        left
      >
        {{ $t("user.succes") }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-dialog v-model="modif" transition="dialog-top-transition" max-width="550">
      <template v-slot:default="dialog">
        <v-form ref="formupdate" @submit.prevent="update">
          <v-card>
            <v-card-text>
              <div class="form-group">
                <div class="row mb-2">
                  <div class="col-md-12">
                    <div class="form-group">
                      <v-text-field
                        v-model="nom"
                        :rules="[rules.required]"
                        prepend-inner-icon="mdi-account"
                        name="nom"
                        :label="$t('headers.nom')"
                        type="text"
                        clearable
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-12">
                    <div class="form-group">
                      <v-text-field
                        v-model="prenom"
                        :rules="[rules.required]"
                        prepend-inner-icon="mdi-account"
                        name="prenom"
                        :label="$t('headers.prenom')"
                        type="text"
                        clearable
                      ></v-text-field>
                    </div>
                  </div>
                </div>

                <!-- Username -->
                <div class="row mb-2">
                  <div class="col-md-12">
                    <div class="form-group">
                      <v-text-field
                        v-model="username"
                        prepend-inner-icon="mdi-account"
                        name="username"
                        :label="$t('headers.username')"
                        type="text"
                        disabled
                      >
                      </v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-12">
                    <div class="form-group">
                      <v-text-field
                        v-model="email"
                        prepend-inner-icon="mdi-email"
                        name="email"
                        :label="$t('headers.email')"
                        type="email"
                        disabled
                        clearable
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <!-- Telephone -->
                <div class="row mb-2">
                  <div class="col-md-12">
                    <div class="form-group">
                      <v-text-field
                        v-model="tel"
                        prepend-inner-icon="mdi-phone"
                        :rules="[rules.required]"
                        name="tel"
                        :label="$t('headers.telephone')"
                        type="text"
                        disabled
                        clearable
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-12">
                    <div class="form-group">
                      <v-autocomplete
                        v-model="Roles"
                        :rules="[rules.required]"
                        prepend-inner-icon=""
                        name="Role"
                        label="Role"
                        :items="roles"
                        item-value="id"
                        item-text="libelle"
                        return-object
                      >
                      </v-autocomplete>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
            <hr />
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Close</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="yellow accent-6" @click="update">update</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-9 mb-4">
        <div class="col-md-5 mb-4">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-2 row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("user.userlist") }}</v-toolbar-title>
            </v-toolbar>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      id: "",
      nom: "",
      prenom: "",
      tel: "",
      email: "",
      Roles: "",
      username: "",
      modif: false,
      pays: "",
      items: [],
      snackbar: false,
      timeout: 2000,
      loading: false,
      search: null,
      current_page: 1,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Username",
          align: "start",
          sortable: false,
          value: "username",
        },
        { text: this.$t("headers.nom"), align: "start", sortable: false, value: "nom" },
        {
          text: this.$t("headers.prenom"),
          align: "start",
          sortable: false,
          value: "prenom",
        },
        {
          text: this.$t("headers.telephone"),
          align: "start",
          sortable: false,
          value: "tel",
        },
        {
          text: this.$t("headers.pays"),
          align: "start",
          sortable: false,
          value: "pays.nom",
        },
        {
          text: this.$t("headers.email"),
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: this.$t("headers.role"),
          align: "start",
          sortable: false,
          value: "role.libelle",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["users", "token", "roles", "me"]),
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.users && this.users.last_page) {
        return this.users.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.users != null && this.users.data) {
        return this.users.data;
        // return this.users.data.filter((item) => item.pays.id == this.me.pays.id);
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search() {
      if (this.search == "" || this.search.trim() == null) {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getallusers", this.current_page);
      }
    },
  },
  methods: {
    update() {
      if (this.$refs.formupdate.validate()) {
        if (this.Roles.id == undefined) {
          const update = {};
          update.nom = this.nom;
          update.prenom = this.prenom;
          update.tel = this.tel;
          update.email = this.email;
          update.username = this.username;
          update.pays = this.pays;
          update.role = this.Roles;
          localStorage.setItem("tk", this.token);
          this.$store
            .dispatch("updateuser", {
              id: this.id,
              value: update,
            })
            .then(() => {
              const status = localStorage.getItem("status");
              if (status == 200) {
                this.modif = false;
                this.snackbar = true;
                this.id = "";
                this.nom = "";
                this.prenom = "";
                this.tel = "";
                this.email = "";
                this.username = "";
                this.Roles = 0;
                localStorage.setItem("tk", this.token);
                this.$store.dispatch("getallusers", this.current_page);
              }
            });
        } else {
          const update = {};
          update.nom = this.nom;
          update.prenom = this.prenom;
          update.tel = this.tel;
          update.email = this.email;
          update.username = this.username;
          // update.role = this.Roles.id;
          localStorage.setItem("tk", this.token);
          this.$store
            .dispatch("updateuser", {
              id: this.id,
              value: update,
            })
            .then(() => {
              const status = localStorage.getItem("status");
              if (status == 200) {
                this.modif = false;
                this.snackbar = true;
                this.id = "";
                this.nom = "";
                this.prenom = "";
                this.tel = "";
                this.email = "";
                this.username = "";
                this.Roles = 0;
                localStorage.setItem("tk", this.token);
                this.$store.dispatch("getallusers", this.current_page);
              }
            });
        }
      }
    },
    ici(row) {
      this.id = row.id;
      this.nom = row.nom;
      this.prenom = row.prenom;
      this.tel = row.tel;
      this.email = row.email;
      this.username = row.username;
      this.Roles = row.role.id;
      this.pays = row.pays.id;
      this.modif = true;
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getallusers", this.current_page);
      } else {
        this.$store.dispatch("searchusers", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {
      if (this.search) {
        if (this.search.trim() == "") {
          localStorage.setItem("tk", this.token);
          this.current_page = 1;
          this.$store.dispatch("getallusers", this.current_page);
        } else {
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("searchusers", {
            page: this.current_page,
            search: this.search.trim(),
          });
          if (this.$store.state.user.users.data) {
            this.current_page = this.$store.state.user.users.current_page;
          } else this.current_page = 1;
        }
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getme");
    this.$store.dispatch("getallusers", this.current_page);
    this.$store.dispatch("getroles");
  },
  created() {
    // console.log(this.$store.state.user.users.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
</style>
