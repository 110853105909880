<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <div class="col-lg-9 mb-4">
        <div class="col-sm-6 col-md-5">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-2 row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("preuve.preuvelist") }}</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.fichiers`]="{ item }">
            <td v-if="item.preuve">
              <div v-for="(file, i) in item.preuve.fichiers" :key="i">
                <img
                  @click="zoom(file.name)"
                  :src="baseURL + filepath + file.name"
                  alt="preuve"
                  height="60px"
                />
              </div>
            </td>
            <td v-else>
              <p>Vide</p>
            </td>
          </template>

          <template v-slot:[`item.used`]="{ item }">
            <td v-if="item.preuve.used == true">
              <v-icon class="ms-6" color="green" x-large
                >mdi-check-decagram-outline</v-icon
              >
            </td>
            <td v-else>
              <v-btn @click="confirms(item)" color="#277da1" style="color: white">
                Valider</v-btn
              >
            </td>
          </template>

          <template v-slot:[`item.montant`]="{ item }">
            <td v-if="item.tauxEchange">
              {{
                item.montant * item.tauxEchange.taux +
                " " +
                item.tauxEchange.deviseRec.libelle
              }}
            </td>
            <td v-else>
              {{ item.montant }}
            </td>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
        <v-dialog v-model="zooms" transition="dialog-top-transition" max-width="780">
          <template>
            <v-img :src="zoomedImg" v-if="zoomedImg" style="imgStyle"></v-img>
          </template>
        </v-dialog>
        <v-dialog v-model="confirm" transition="dialog-top-transition" max-width="450">
          <template v-slot:default="dialog">
            <v-form ref="formconfirm" @submit.prevent="confirms">
              <v-card>
                <v-card-text>
                  <div class="text-center"></div>
                  <div class="form-group">
                    <div class="mt-2">
                      <v-textarea
                        outlined
                        clearable
                        clear-icon="mdi-close-circle"
                        name="input-7-4"
                        solo
                        auto-grow
                        label="Motifs"
                        rows="4"
                        row-height="40"
                        v-model="motifs"
                      ></v-textarea>
                    </div>
                  </div>
                </v-card-text>
                <div class="d-flex justify-content-between">
                  <v-btn
                    class="mt-2 mb-2 ms-2"
                    style="background-color: #dc2f02; color: white"
                    text
                    @click="dialog.value = false"
                    >Close</v-btn
                  >
                  <v-btn
                    class="mt-2 mb-2 me-2"
                    text
                    style="background-color: #003566; color: white"
                    @click="annuler"
                    >Save</v-btn
                  >
                </div>
              </v-card>
            </v-form>
          </template>
        </v-dialog>
        <v-dialog v-model="finish" transition="dialog-top-transition" max-width="450">
          <template>
            <v-card>
              <v-card-text class="text-center" style="color: #2d2d2d">
                <v-card-title class="headline"> Validation </v-card-title>
                <span class="h3">
                  <p v-if="forvalid">
                    Valider vous la preuve de recharge de :
                    <span class="h2" style="color: #003566; text-transform: uppercase">
                      {{ forvalid.expediteur + " " + one.user.pays.devise.libelle }}
                    </span>
                  </p>
                  <p>
                    D'un montant de: <br />
                    <span class="h2" style="color: green">
                      <!-- {{
                        forvalid.montant * forvaliduser.taux +
                        " " +
                        one.user.pays.devise.libelle
                      }} -->
                      {{ forvalid.montant }}
                    </span>
                  </p>
                  <p>Saisisez votre code pin</p>
                  <v-text-field
                    class="mt-2 ms-2 me-2"
                    v-model="pin"
                    name="indicatif"
                    type="number"
                    label="code pin"
                    ref="clear2"
                    @blur="checkpins"
                    prepend-inner-icon="mdi-numeric"
                    :rules="[rules.required]"
                    :error-messages="messagepin"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                  <v-icon x-large color="white">mdi-check</v-icon>
                </span>
                <div class="text-center d-flex justify-center">
                  <v-btn left style="color: white" color="#2B2E83" @click="valid"
                    >Enregistrer</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-dialog>
        <v-dialog v-model="avert" max-width="400">
          <v-card>
            <v-card-title class="headline">Avertissement</v-card-title>
            <v-card-text>
              Le solde insuffisant pour valider la preuve, veuillez recharger votre
              compte.
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="avert = false">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import { baseURL } from "../../../main";
import Aside from "../../../components/aside.vue";
export default {
  components: { Aside },
  data() {
    return {
      items: [],
      zooms: false,
      snackbar: false,
      confirm: false,
      motifs: null,
      timeout: 2000,
      avert: false,
      pin: null,
      messagepin: null,
      forvalid: {},
      forvaliduser: {},
      id: null,
      filepath: "downloadFile/",
      loading: false,
      baseURL,
      imgStyle: {
        maxWidth: "100%",
        maxHeight: "100%",
      },
      zoomedImg: null,
      search: null,
      current_page: 1,
      finish: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: "Username",
          align: "start",
          sortable: false,
          value: "envoyeur.user.username",
        },
        {
          text: this.$t("headers.nom"),
          align: "start",
          sortable: false,
          value: "expediteur",
        },
        {
          text: this.$t("headers.telephone"),
          align: "start",
          sortable: false,
          value: "envoyeur.user.tel",
        },
        {
          text: this.$t("headers.montant"),
          align: "start",
          sortable: false,
          value: "montant",
        },
        {
          text: this.$t("headers.preuve"),
          align: "start",
          sortable: false,
          value: "fichiers",
        },
        {
          text: this.$t("headers.date"),
          align: "start",
          sortable: false,
          value: "dateOperation",
        },
        {
          text: this.$t("headers.Etat"),
          align: "start",
          sortable: false,
          value: "used",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "transfert",
      "token",
      "one",
      "agencebyuser",
      "user",
      "checkpin",
      "userbywallet",
      "preuveagence",
    ]),
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.preuveagence && this.preuveagence.last_page) {
        return this.preuveagence.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.preuveagence != null && this.preuveagence.data) {
        return this.preuveagence.data;
        // return this.transfert.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search() {
      if (this.search == "" || this.search.trim() == null) {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("gettransfert", {
          id: this.agencebyuser.id,
          page: this.current_page,
        });
      }
    },
  },
  methods: {
    ici(row) {},
    checkpins() {
      localStorage.setItem("tk", this.token);
      const pin = {};
      pin.pin = this.pin;
      this.$store.dispatch("getcheckpin", { id: this.user.id, pin: pin }).then(() => {
        const timer = () => {
          console.log(this.checkpin);
          if (this.checkpin == false) {
            this.messagepin = "Le code pin ne correspond pas";
            this.$refs.clear2.$el.querySelector("input").focus();
          } else {
            this.messagepin = null;
          }
        };
        setTimeout(timer, 0.1 * 100, 0.1);
      });
    },
    valid() {
      if (this.checkpin == true) {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getvalidetransfert", this.id);
        const timer = () => {
          this.finish = false;
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("getoneportefeuille", this.user.id);
          this.$store.dispatch("getpreuvetransfert", this.current_page);
        };
        setTimeout(timer, 0.1 * 100, 0.1);
      }
    },
    confirms(item) {
      if (item.montant > this.one.solde) {
        this.avert = true;
      } else {
        this.id = item.id;
        this.forvaliduser = item.tauxEchange;
        this.forvalid = item;
        this.finish = true;
      }
    },
    reject(item) {
      this.confirm = true;
      this.id = item.id;
    },
    annuler() {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("rejected", { motif: this.motifs, preuve: this.id });
      this.$store.dispatch("getpreuvetransfert", this.current_page);
    },
    date(item) {
      return new Date(item).toLocaleDateString();
    },
    zoom(items) {
      this.zoomedImg = this.baseURL + this.filepath + items;
      this.zooms = true;
    },
    handleResize() {
      if (this.dialog) {
        this.imgStyle.maxWidth = window.innerWidth + "px";
        this.imgStyle.maxHeight = window.innerHeight + "px";
      }
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getpreuvetransfert", this.current_page);
      } else {
        this.$store.dispatch("searchtransfert", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {
      if (this.search) {
        if (this.search.trim() == "") {
          localStorage.setItem("tk", this.token);
          this.current_page = 1;
          this.$store.dispatch("gettransfert", this.current_page);
        } else {
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("searchtransfert", this.current_page);
          if (this.$store.state.preuve.transfert.data) {
            this.current_page = this.$store.state.preuve.transfert.current_page;
          } else this.current_page = 1;
        }
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getclearpin");
    this.$store.dispatch("getagencebyuser", this.user.id);
    this.$store.dispatch("getpreuvetransfert", this.current_page);
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  created() {
    // console.log(this.$store.state.preuve.allpreuve.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
</style>
