<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <div class="col-md-8">
        <v-list class="pa-4 colis-list">
          <h2 class="text-center">
            {{ $t("user.bank") }}
          </h2>
          <div>
            <template v-if="me.banques.length === 0">
              <v-list-item class="colis-list-item">
                <v-list-item-content>
                  <v-list-item-title class="colis-list-item-title"> </v-list-item-title>
                  <v-list-item-subtitle class="colis-list-item-subtitle">
                    {{ $t("headers.intitule") }}:
                    {{ agencebyuser.intituleAgence }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="colis-list-item-subtitle">
                    {{ $t("headers.institution") }}:
                    {{ agencebyuser.institutionAgence }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="colis-list-item-subtitle">
                    {{ $t("headers.numcompte") }}:
                    {{ agencebyuser.numCompteAgence }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="colis-list-item-action">
                  <v-btn
                    class="colis-list-item-action-button"
                    @click="modifier2(agencebyuser)"
                    fab
                    color="warning"
                    small
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
            <template v-else>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  lg="4"
                  v-for="(item, index) in me.banques"
                  :key="index"
                >
                  <v-list-item class="colis-list-item">
                    <v-list-item-content>
                      <v-list-item-title class="colis-list-item-title">
                      </v-list-item-title>
                      <v-list-item-subtitle class="colis-list-item-subtitle">
                        {{ $t("headers.intitule") }}: {{ item.intitule }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="colis-list-item-subtitle">
                        {{ $t("headers.institution") }}: {{ item.institution }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="colis-list-item-subtitle">
                        {{ $t("headers.numcompte") }}: {{ item.numCompte }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="colis-list-item-action">
                      <v-btn
                        class="colis-list-item-action-button"
                        @click="modifier(item)"
                        fab
                        color="warning"
                        small
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
              </v-row>
            </template>
          </div>
        </v-list>
      </div>
      <v-dialog v-model="add" transition="dialog-to-transition" max-width="480">
        <template v-slot:default="dialog">
          <v-form ref="formadd" @submit.prevent="add">
            <v-card>
              <v-card-text>
                <div class="text-center">
                  <v-snackbar
                    v-model="snackbar"
                    :timeout="timeout"
                    center
                    absolute
                    color="#1e824c"
                  >
                    {{ $t("bank.succes") }}

                    <template v-slot:action="{ attrs }">
                      <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                </div>
                <div class="text-center">
                  <v-snackbar
                    v-model="error"
                    :timeout="timeout"
                    absolute
                    bottom
                    color="red"
                    right
                  >
                    {{ $t("devis.error") }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="#cf000f"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                </div>
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <v-text-field
                      v-model="intitule"
                      name="recepteur"
                      type="text"
                      ref="clear"
                      label="Intituler du compte"
                      outlined
                      clearable
                      dense
                    >
                    </v-text-field>
                  </div>
                  <div class="col-md-12">
                    <v-text-field
                      v-model="institution"
                      name="recepteur"
                      type="text"
                      ref="clear1"
                      label="Nom de la banque"
                      outlined
                      clearable
                      dense
                    >
                    </v-text-field>
                  </div>
                  <div class="col-md-12">
                    <v-text-field
                      v-model="numCompte"
                      name="recepteur"
                      type="text"
                      ref="clear2"
                      label="Numero du compte"
                      outlined
                      clearable
                      dense
                    >
                    </v-text-field>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  @click="
                    dialog.value = false;
                    reset1();
                  "
                  >Close</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn style="color: wheat" color="#1A4478" @click="save">Ajouter</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </template>
      </v-dialog>
      <v-dialog v-model="modif" transition="dialog-to-transition" max-width="480">
        <template v-slot:default="dialog">
          <v-form ref="formupdate" @submit.prevent="update">
            <v-card>
              <v-card-text>
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <v-text-field
                      v-model="intitule"
                      name="recepteur"
                      type="text"
                      ref="clear3"
                      label="Intituler du compte"
                      outlined
                      clearable
                      dense
                    >
                    </v-text-field>
                  </div>
                  <div class="col-md-12">
                    <v-text-field
                      v-model="institution"
                      name="recepteur"
                      type="text"
                      ref="clear4"
                      label="Nom de la banque"
                      outlined
                      clearable
                      dense
                    >
                    </v-text-field>
                  </div>
                  <div class="col-md-12">
                    <v-text-field
                      v-model="numCompte"
                      name="recepteur"
                      type="text"
                      ref="clear5"
                      label="Numero du compte"
                      outlined
                      clearable
                      dense
                    >
                    </v-text-field>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  @click="
                    dialog.value = false;
                    reset2();
                  "
                  >Close</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  v-if="me.banques.length === 0"
                  color="yellow accent-9"
                  @click="updatekiosk"
                  >update</v-btn
                >
                <v-btn v-else color="yellow accent-9" @click="update">update</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </template>
      </v-dialog>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="#1A4478"
            x-large
            fab
            dark
            fixed
            bottom
            right
            @click="add = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>bank</span>
      </v-tooltip>
    </div>
  </main>
</template>
<script>
import Aside from "../../../components/aside.vue";
import { mapGetters } from "vuex";

export default {
  components: { Aside },
  data: () => {
    return {
      show2: "",
      old: null,
      news: null,
      confirm: false,
      show1: "",
      show2: "",
      solde: false,
      modif: false,
      oui: false,
      intitule: "",
      institution: "",
      numCompte: "",
      nom: "",
      ville: "",
      username: "",
      pays: "",
      adresse: "",
      id: "",
      add: false,
      eye: false,
      eyes: true,
      snackbar: false,
      error: false,
      timeout: 2000,
      montant: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
    };
  },
  computed: {
    ...mapGetters(["compteadmin", "one", "me", "token", "agencebyuser"]),
    passwordRules() {
      return [
        (v) => !!v || this.$t("require.pass"),
        (v) =>
          /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()–[{}]).{6,40}/.test(v) ||
          this.$t("rule.pass"),
      ];
    },
    bankforkiosk() {
      if (this.agencebyuser) {
        const newData = {
          intitule: this.agencebyuser.intituleAgence,
          numCompte: this.agencebyuser.numCompteAgence,
          institution: this.agencebyuser.nom,
        };
        if (this.$store.state.portefeuille.one.user.banques.length === 0) {
          this.$store.commit("setBanques", [newData]);
        }
        return newData;
      }
    },
  },

  methods: {
    save() {
      if (this.$refs.formadd.validate()) {
        const Data = {
          banques: [
            {
              intitule: this.intitule,
              institution: this.institution,
              numCompte: this.numCompte,
            },
          ],
        };
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("adduserbanq", { id: this.me.id, value: Data }).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.add = false;
            this.reset1();
            this.snackbar = true;
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getme");
          } else if (status === 400) {
            this.error = true;
            localStorage.removeItem("status");
          }
        });
      }
    },
    reset1() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
      this.$refs.clear2.reset();
    },
    reset2() {
      this.$refs.clear3.reset();
      this.$refs.clear4.reset();
      this.$refs.clear5.reset();
    },
    modifier(item) {
      //   console.log(item);
      this.modif = true;
      this.id = item.id;
      this.intitule = item.intitule;
      this.institution = item.institution;
      this.numCompte = item.numCompte;
    },
    modifier2(item) {
      //   console.log(item);
      this.modif = true;
      this.id = item.id;
      this.intitule = item.intituleAgence;
      this.institution = item.institutionAgence;
      this.numCompte = item.numCompteAgence;
    },
    update() {
      const banq = {};
      banq.intitule = this.intitule;
      banq.institution = this.institution;
      banq.numCompte = this.numCompte;
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("updatebanq", { id: this.id, value: banq }).then(() => {
        const status = localStorage.getItem("status");
        if (status == 200) {
          this.modif = false;
          this.reset2();
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("getme");
        }
      });
    },
    updatekiosk() {
      const banq = {};
      banq.nom = this.agencebyuser.nom;
      banq.adresse = this.agencebyuser.adresse;
      banq.telephone = this.agencebyuser.telephone;
      banq.ville = this.agencebyuser.ville.id;
      banq.email = this.me.email;
      banq.pays = this.me.pays.id;
      banq.username = this.me.username;
      banq.intituleAgence = this.intitule;
      banq.institutionAgence = this.institution;
      banq.numCompteAgence = this.numCompte;
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("updatekiosk", { id: this.id, value: banq }).then(() => {
        const status = localStorage.getItem("status");
        if (status == 200) {
          this.modif = false;
          this.reset2();
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("getagencebyuser", this.me.id);
        }
      });
    },
  },
  mounted() {
    localStorage.setItem("tk", this.$store.getters.token);
    this.$store.dispatch("getme");
    if (this.me.role.id == 2) {
      this.$store.dispatch("getdevsportefeuille", this.me.id);
    } else {
      this.$store.dispatch("getoneportefeuille", this.me.id);
      this.$store.dispatch("getagencebyuser", this.me.id);
    }
  },
};
</script>
<style scoped>
.num {
  font-variant-numeric: tabular-nums !important;
  color: #e30613;
}
.container-100 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 100px 0px 0px 0px;
}
.save {
  background-color: #2b2e83;
  color: #eee7ce;
}
.back {
  background-color: whitesmoke;
}
.signup {
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 70px;
}
.colis-list {
  /* background-color: #f8f8f8; */
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.colis-list-item {
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.colis-list-item:hover {
  background-color: #f0f0f0;
  transform: translateY(-2px);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.colis-list-item-title {
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 8px;
}

.colis-list-item-subtitle {
  font-size: 1.6rem;
  color: #888888;
}

.colis-list-item-action {
  display: flex;
  align-items: center;
}
</style>
