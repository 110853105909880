<template>
  <main class="container">
    <div class="row">
      <!-- <div class="col-lg-2"></div> -->
      <Aside :profile="compteadmin" />
      <div class="col-lg-7">
        <v-card class="signup">
          <v-card-text>
            <div class="text-center">
              <v-snackbar
                v-model="success"
                :timeout="timeout"
                absolute
                bottom
                color="#1e824c"
                center
              >
                <span>
                  {{ $t("Transaction.succes") }}
                </span>
                <template v-slot:action="{ attrs }">
                  <v-btn color="#cf000f" text v-bind="attrs" @click="solde = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
            <div class="text-center">
              <v-snackbar
                v-model="error"
                :timeout="timeout"
                absolute
                bottom
                color="red"
                center
              >
                <span>
                  {{ $t("login.error") }}
                </span>
                <template v-slot:action="{ attrs }">
                  <v-btn color="#cf000f" text v-bind="attrs" @click="solde = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
            <v-form @submit.prevent="save" ref="formsaveuser">
              <h2 class="text-center mt-4">
                {{ $t("recharge") }}
              </h2>
              <div class="row mt-16">
                <div class="col-md-12">
                  <div class="form-group">
                    <v-text-field
                      v-model="montant"
                      prepend-inner-icon="mdi-cash"
                      name="nom"
                      ref="clear"
                      :label="$t('montant')"
                      :rules="[rules.required]"
                      type="number"
                      clearable
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </v-form>
            <div class="">
              <div class="form-group">
                <button class="w-100 btn log save" @click="finish = true">
                  {{ $t("Transaction.save") }}
                </button>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-dialog v-model="finish" transition="dialog-top-transition" max-width="450">
          <template>
            <v-card>
              <v-card-text class="text-center" style="color: #2d2d2d">
                <v-card-title class="headline">Saisisez votre code pin</v-card-title>
                <v-text-field
                  class="mt-4 ms-2 me-2"
                  v-model="pin"
                  name="indicatif"
                  type="number"
                  label="code pin"
                  ref="clear1"
                  @blur="checkpins"
                  prepend-inner-icon="mdi-numeric"
                  :rules="[rules.required]"
                  :error-messages="messagepin"
                  outlined
                  clearable
                  dense
                >
                </v-text-field>
                <v-icon x-large color="white">mdi-check</v-icon>
                <div class="text-center d-flex justify-center">
                  <v-btn left style="color: white" color="#2B2E83" @click="save"
                    >Enregistrer</v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
          </template>
        </v-dialog>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import Aside from "../../../components/aside.vue";
import { mapGetters } from "vuex";

export default {
  components: { Aside },
  data: () => {
    return {
      show1: "",
      solde: false,
      eye: false,
      eyes: true,
      snackbar: false,
      error: false,
      timeout: 2000,
      finish: false,
      success: false,
      pin: "",
      messagepin: null,
      montant: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
    };
  },
  computed: {
    ...mapGetters(["compteadmin", "me", "checkpin", "token"]),
    form() {
      return {
        receveur: this.compteadmin.numero,
        typeOperation: 3,
        montant: this.montant,
      };
    },
  },

  methods: {
    show() {
      this.eye = false;
      this.solde = false;
    },
    shows() {
      this.eye = true;
      this.solde = true;
    },
    checkpins() {
      localStorage.setItem("tk", this.token);
      const pin = {};
      pin.pin = this.pin;
      this.$store.dispatch("getcheckpin", { id: this.me.id, pin: pin }).then(() => {
        const timer = () => {
          if (this.checkpin == false) {
            this.messagepin = "Le code pin ne correspond pas";
            this.$refs.clear.$el.querySelector("input").focus();
          } else {
            this.messagepin = null;
          }
        };
        setTimeout(timer, 0.1 * 100, 0.1);
      });
    },
    save() {
      if (this.$refs.formsaveuser.validate()) {
        if (this.checkpin == true) {
          localStorage.setItem("tk", this.$store.getters.token);
          this.$store.dispatch("setactioncompte", this.form).then(() => {
            const status = localStorage.getItem("status");
            if (status == 200) {
              this.finish = false;
              this.$refs.clear.reset();
              localStorage.setItem("tk", this.$store.getters.token);
              this.$store.dispatch("getportefeuilleadmin", this.$store.getters.user.id);
              localStorage.removeItem("status");
              this.success = true;
            } else if (status == 400) {
              this.error = true;
              localStorage.removeItem("status");
            }
          });
        }
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.$store.getters.token);
    this.$store.dispatch("getroles");
    this.$store.dispatch("getme");
    this.$store.dispatch("getportefeuilleadmin", this.me.id);
  },
};
</script>
<style scoped>
.num {
  font-variant-numeric: tabular-nums !important;
  color: #275462;
}
.container-100 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* padding: 100px 0px 0px 0px; */
}
.save {
  background-color: #064439;
  color: #eee7ce;
}
.signup {
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 70px;
}
</style>
