<template>
  <aside class="col-lg-3">
    <div class="back shadow-sm text-center p-3 mb-4">
      <div class="profile-thumb mt-3 mb-4">
        <img
          v-if="profile.user.avatar != null"
          class="rounded-circle mt-5"
          width="150px"
          :src="url + profile.user.avatar"
        />
        <img
          v-else
          class="rounded-circle mt-5"
          width="150px"
          src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
        />
      </div>
      <p class="text-3 fw-500 mb-2">{{ $t("hey") }}, {{ profile.user.username }}</p>
    </div>
    <div class="back shadow-sm rounded text-center p-3 mb-4">
      <div class="text-17 text-light my-3">
        <i class="fas fa-wallet big"></i>
      </div>
      <h3 v-show="solde == false" class="text-9 fw-400">
        {{ profile.solde.toFixed(2) }} {{ profile.user.pays.devise.libelle }}
      </h3>
      <v-icon v-show="eye == true" @click="show()" class="ms-2 me-2">mdi-eye</v-icon>
      <v-icon v-show="eye == false" @click="shows()" class="me-2">mdi-eye-off</v-icon>
      <p class="mb-2 text-muted opacity-8">{{ $t("user.solde") }}</p>
      <div v-if="agencebyuser">
        <h3 v-if="me.role.id == 5 || me.role.id == 4" class="text-9 fw-400">
          {{ agencebyuser.benefice.toFixed(2) }} {{ profile.user.pays.devise.libelle }}
        </h3>
        <p v-if="me.role.id == 5" class="mb-2 text-muted opacity-8">
          {{ $t("user.benefice") }}
        </p>
      </div>
    </div>
  </aside>
</template>
<script>
import { mapGetters } from "vuex";
import { baseURL } from "../main";
export default {
  props: {
    profile: Object,
  },
  data() {
    return {
      eye: true,
      eyes: false,
      solde: false,
      url: "",
    };
  },
  computed: {
    ...mapGetters(["token", "benefice", "me", "agencebyuser"]),
  },
  methods: {
    show() {
      this.eye = false;
      this.solde = true;
    },
    shows() {
      this.eye = true;
      this.solde = false;
    },
  },
  mounted() {
    this.url = baseURL + "downloadFile/";
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getme");
    if (this.me.role.id != 2) {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("getagencebyuser", this.me.id);
    }
  },
};
</script>
<style scoped>
.back {
  background-color: whitesmoke;
}
</style>
