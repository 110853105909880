import Vue from "vue";
import jquery from "jquery";
import App from "./App.vue";
import store from "./store/store";
import router from "./router/router";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import i18n from "./locales/i18n";
const newLocal =
  // 'https://shopymanager.com:8443/s2apay_dev/web/service/';
  "https://shopymanager.com:8443/s2apay/web/service/";

// Axios.defaults.headers.common = {'X-Requested-With': 'XMLHttpRequest'}
export const baseURL = newLocal;
Vue.config.productionTip = false;
axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // store.dispatch('logOut');
    }
  }
});

new Vue({
  render: (h) => h(App),
  vuetify,
  router,
  jquery,
  store,
  i18n,
}).$mount("#app");
