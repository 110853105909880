import axios from "axios";
import { baseURL } from "../../main";

const state =
{
    roles:null
}
const getters=
{
    roles:(state)=> state.roles
};

const actions =
{
   async getroles({commit})
    {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "roles", {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setrole", data);
    }
};
const mutations =
{
    setrole(state, roles)
    {
        state.roles = roles
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
}