import axios from "axios";
import { baseURL } from "../../main";

const state = {
  agences: null,
  oneagence: null,
  typeagence: null,
  onlyagence: null,
  agencebyville: null,
  agencebyuser: null,
  agencebypays: null,
  recup: null,
  newagence: null,
  checkuseragence: null,
  updateagence: null,
  adduseragence: null,
};
const getters = {
  agences: (state) => state.agences,
  oneagence: (state) => state.oneagence,
  typeagence: (state) => state.typeagence,
  onlyagence: (state) => state.onlyagence,
  agencebyville: (state) => state.agencebyville,
  agencebypays: (state) => state.agencebypays,
  agencebyuser: (state) => state.agencebyuser,
  recup: (state) => state.recup,
  newagence: (state) => state.newagence,
  checkuseragence: (state) => state.checkuseragence,
  updateagence: (state) => state.updateagence,
  adduseragence: (state) => state.adduseragence,
};

const actions = {
  async getcheckuseragence({ commit }, id) {
    let token = localStorage.getItem("tk");
    await axios
      .get(baseURL + "check/user/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let data = response;
        commit("setcheckuseragence", data);
      });
  },
  async getoneagence({ commit }, id) {
    let token = localStorage.getItem("tk");
    await axios
      .get(baseURL + "agence/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let data = response.data;
        commit("setoneagence", data);
      });
  },
  async updateagence({ commit }, { id, value }) {
    let token = localStorage.getItem("tk");
    await axios
      .put(baseURL + "agence/" + id, value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setupdateagence", data);
        }
      });
  },
  async addnewagence({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "agence", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          data;
          localStorage.removeItem("tk");
          commit("setnews", data);
        }
      });
  },
  async adduserinagence({ commit }, { A, U }) {
    let token = localStorage.getItem("tk");
    await axios
      .get(baseURL + "agence/user/add/" + A + "/" + U, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          data;
          localStorage.removeItem("tk");
          commit("setadduserinagence", data);
        }
      });
  },
  async removeuserinagence({ commit }, { A, U }) {
    let token = localStorage.getItem("tk");
    await axios
      .delete(baseURL + "agence/user/delete/" + A + "/" + U, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          data;
          localStorage.removeItem("tk");
          commit("setadduserinagence", data);
        }
      });
  },
  async getagencebyuser({ commit }, id) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "agences/user/" + id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setagencebyuser", data);
  },
  // async getonlyagencebycountry({ commit }, id) {
  //   let token = localStorage.getItem("tk");
  //   let response = await axios.get(baseURL + "agences/pays/" + id, {
  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //   });
  //   let data = response.data;
  //   localStorage.removeItem("tk");
  //   commit("setagencebyuser", data);
  // },
  async getallagence({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "agences/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setallagence", data);
  },
  async getalltypeagence({ commit }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "type/agences/", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setalltypeagence", data);
  },
  async getagencebyville({ commit }, id) {
    return await axios.get(baseURL + "auth/agences/" + id).then((response) => {
      let data = response.data;
      commit("setagencebyville", data);
    });
  },
  async getagencebypays({ commit }, id) {
    let token = localStorage.getItem("tk");
    return await axios
      .get(baseURL + "agences/pays/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let data = response.data;
        commit("setagencebypays", data);
      });
  },
  async getagencebypaysdevs({ commit }, id) {
    let token = localStorage.getItem("tk");
    return await axios
      .get(baseURL + "developer/agences/pays/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let data = response.data;
        commit("setagencebypays", data);
      });
  },
  async getagencebypayslistonville({ commit }, id) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "agences/pays/" + id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setagencebyville", data);
  },
  async getallsagence({ commit }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "agences", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setagencebyville", data);
  },
  async getmoyenrecupbypays({ commit }, id) {
    return await axios
      .get(baseURL + "auth/moyen/recuperation/" + id)
      .then((response) => {
        let data = response.data;
        commit("setmoyenrecupbypays", data);
      });
  },
  async searchallagence({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "agences/search/page/" + page + "/" + search,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setallagence", data);
  },
};
const mutations = {
  setville(state, ville) {
    state.ville = ville;
  },
  setoneagence(state, oneagence) {
    state.oneagence = oneagence;
  },
  setadduserinagence(state, adduserinagence) {
    state.adduseragence = adduserinagence;
  },
  setcheckuseragence(state, checkuseragence) {
    state.checkuseragence = checkuseragence;
  },
  setagencebyuser(state, agencebyuser) {
    state.agencebyuser = agencebyuser;
  },
  setnew(state, newagence) {
    state.newagence = newagence;
  },
  setupdateagence(state, updateagence) {
    state.updateagence = updateagence;
  },
  setallagence(state, agences) {
    state.agences = agences;
  },
  setalltypeagence(state, typeagence) {
    state.typeagence = typeagence;
  },
  setagencebyville(state, agencebyville) {
    state.agencebyville = agencebyville;
  },
  setagencebypays(state, agencebypays) {
    state.agencebypays = agencebypays;
  },
  setmoyenrecupbypays(state, recup) {
    state.recup = recup;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
