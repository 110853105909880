<template>
  <main>
    <div id="content">
      <div class="container">
        <v-alert :value="alert" class="backs">
          <v-row align="center">
            <v-col class="grow text-center devise">
              <v-icon color="white" large>mdi-google-play</v-icon>
              {{ $t("android") }}
            </v-col>
            <v-col class="shrink">
              <v-icon @click="alert = false" color="white">mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert :value="alerts" class="backs">
          <v-row align="center">
            <v-col class="grow text-center devise">
              <v-icon color="white" large>mdi-google-play</v-icon>
              {{ $t("ios") }}
            </v-col>
            <v-col class="shrink">
              <v-icon @click="alerts = false" color="white">mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-alert>
        <div class="row">
          <Aside :profile="one" />
          <div class="col-lg-9">
            <div class="back shadow-sm rounded p-4 mb-4">
              <h3 class="text-5 fw-400 d-flex align-items-center mb-4">
                {{ $t("user.info") }}
              </h3>
              <hr class="mx-n4 mb-4" />
              <div class="row gx-3 align-items-center">
                <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                  {{ $t("headers.nom") }}:
                </p>
                <p class="col-sm-9 text-3">{{ one.user.nom }}</p>
              </div>
              <div class="row gx-3 align-items-center">
                <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                  {{ $t("headers.prenom") }}:
                </p>
                <p class="col-sm-9 text-3">{{ one.user.prenom }}</p>
              </div>
              <div class="row gx-3 align-items-baseline">
                <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                  {{ $t("headers.telephone") }}:
                </p>
                <p class="col-sm-9 text-3">
                  {{ one.user.tel }}
                </p>
              </div>
              <div class="row gx-3 align-items-baseline">
                <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                  {{ $t("pays.pays") }}:
                </p>
                <p class="col-sm-9 text-3">
                  {{ one.user.pays.nom }}
                </p>
              </div>
            </div>
            <div
              class="back shadow-sm rounded p-4 mb-4"
              v-if="one.user.banques[0] || bankforkiosk"
            >
              <h3 class="text-5 fw-400 d-flex align-items-center mb-4">
                {{ $t("user.bank") }}
              </h3>
              <hr class="mx-n4 mb-4" />
              <div class="row gx-3 align-items-center">
                <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                  {{ $t("headers.intitule") }}:
                </p>
                <p class="col-sm-9">
                  {{ one.user.banques[0].intitule }}
                </p>
              </div>
              <div class="row gx-3 align-items-center">
                <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                  {{ $t("headers.institution") }}:
                </p>
                <p class="col-sm-9 text-3">
                  {{ one.user.banques[0].institution }}
                </p>
              </div>
              <div class="row gx-3 align-items-baseline">
                <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                  {{ $t("headers.numcompte") }}:
                </p>
                <p class="col-sm-9 text-3">
                  {{ one.user.banques[0].numCompte }}
                </p>
              </div>
            </div>
            <div class="back shadow-sm rounded p-4 mb-4" v-if="usermoyens[0]">
              <h3 class="text-5 fw-400 d-flex align-items-center mb-4">
                {{ $t("user.moyens") }}
              </h3>
              <hr class="mx-n4 mb-4" />
              <div class="row gx-3 align-items-center">
                <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                  {{ $t("headers.moyen") }}:
                </p>
                <p class="col-sm-9">
                  {{ usermoyens[0].moyenRecuperation.libelle }}
                </p>
              </div>
              <div class="row gx-3 align-items-center">
                <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                  {{ $t("headers.detail") }}:
                </p>
                <p class="col-sm-9 text-3">
                  {{ usermoyens[0].recuperation }}
                </p>
              </div>
            </div>
            <v-alert :value="one.user.pin == null" type="info">
              <v-row align="center">
                <v-col class="grow text-center">
                  {{ $t("define") }}
                </v-col>
                <v-col class="shrink">
                  <v-icon @click="pin = false">mdi-close</v-icon>
                </v-col>
              </v-row>
            </v-alert>
            <div class="back shadow-sm rounded p-4 mb-4">
              <h3 class="text-5 fw-400 d-flex align-items-center mb-4">
                {{ $t("user.setting") }}
              </h3>
              <hr class="mx-n4 mb-4" />
              <div class="row gx-3 align-items-center">
                <p class="col-sm-3 text-muted text-sm-end mb-0 mb-sm-3">
                  {{ $t("user.compte") }}:
                </p>

                <input
                  style="width: 500px"
                  class="col-sm-6 text-3 mb-2"
                  type="text"
                  v-model="one.numero"
                />
                <button class="col-sm-2 text-3 mb-2" @click="copyToClipboard">
                  <v-icon>mdi-clipboard-multiple-outline</v-icon>
                </button>
              </div>
              <div class="row gx-3 align-items-center">
                <p class="col-sm-3 text-muted text-sm-end">Pin Status:</p>
                <p class="col-sm-9 text-3">
                  <span
                    v-if="one.user.pin != null"
                    class="bg-success text-white rounded-pill d-inline-block px-2 mb-0"
                    ><i class="fas fa-check-circle"></i> Active</span
                  >
                  <span
                    v-if="one.user.pin == null"
                    class="bg-danger text-white rounded-pill d-inline-block px-2 mb-0"
                    ><i class="fas fa-times-circle"></i> Inactif
                  </span>
                  <v-icon v-if="one.user.pin == null" class="" @click="update()"
                    >mdi-pencil</v-icon
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import Aside from "../../../components/aside.vue";
export default {
  components: { Aside },
  data() {
    return {
      text: "",
      iscopied: false,
      android: "android",
      IOS: "Ios",
      alert: false,
      alerts: false,
      pin: true,
    };
  },
  computed: {
    ...mapGetters(["user", "one", "agencebyuser", "usermoyens"]),
    bankforkiosk() {
      if (this.agencebyuser) {
        console.log(this.agencebyuser);
        const newData = {
          intitule: this.agencebyuser.intituleAgence,
          numCompte: this.agencebyuser.numCompteAgence,
          institution: this.agencebyuser.institutionAgence,
        };
        if (this.$store.state.portefeuille.one.user.banques.length === 0) {
          this.$store.commit("setBanques", [newData]);
        }
        return newData;
      }
    },
  },
  created() {
    // this.bankforkiosk;
    // this.one = this.one;
  },
  mounted() {
    if (this.user.role.id == 2) {
      localStorage.setItem("tk", this.$store.getters.token);
      this.$store.dispatch("getdevsportefeuille", this.user.id);
    } else {
      localStorage.setItem("tk", this.$store.getters.token);
      this.$store.dispatch("getoneportefeuille", this.user.id);
      this.$store.dispatch("getagencebyuser", this.user.id);
      this.$store.dispatch("getallmoyenuser");
    }
    this.userdevices();
  },
  methods: {
    async copyTex(link) {
      try {
        if (navigator?.clipboard?.writeText) {
          await navigator.clipboard.writeText(link);
        }
        this.iscopied = true;
        setTimeout(() => {
          this.iscopied = false;
        }, 15000);
      } catch (e) {
        console.error("e", e);
      }
    },
    update() {
      this.$router.push("/dashboard/pin");
    },
    userdevices() {
      if (/Android/i.test(navigator.userAgent)) {
        this.alert = true;
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        this.alerts = true;
      }
    },
    copyToClipboard() {
      // Créer un élément <textarea>
      const textarea = document.createElement("textarea");
      // Assigner le contenu du champ à l'élément <textarea>
      textarea.value = this.one.numero;
      // Ajouter l'élément <textarea> au document
      document.body.appendChild(textarea);
      // Sélectionner le contenu de l'élément <textarea>
      textarea.select();
      // Copier le contenu sélectionné dans le presse-papier
      document.execCommand("copy");
      // Supprimer l'élément <textarea> du document
      document.body.removeChild(textarea);
    },
  },
};
</script>
<style scoped>
.back {
  background-color: #f2f2f2;
}
.backs {
  background-color: #2b2e83 !important;
}
.devise {
  color: #f2f2f2 !important;
}
.text-muted {
  color: #a9a9e9 !important;
}
</style>
