<template>
  <main>
    <div class="limiter">
      <div class="container-login100">
        <div class="wrap-login100">
          <v-alert :value="alert" class="back">
            <v-row align="center">
              <v-col class="grow text-center devise" style="color: white">
                <v-icon color="white" large>mdi-google-play</v-icon>
                {{ $t("android") }}
              </v-col>
              <v-col class="shrink">
                <v-icon @click="alert = false" color="white">mdi-close</v-icon>
              </v-col>
            </v-row>
          </v-alert>
          <v-alert :value="alerts" class="back">
            <v-row align="center">
              <v-col class="grow text-center devise" style="color: white">
                <v-icon color="white" large>mdi-apple</v-icon>
                {{ $t("ios") }}
              </v-col>
              <v-col class="shrink">
                <v-icon @click="alerts = false" color="white">mdi-close</v-icon>
              </v-col>
            </v-row>
          </v-alert>
          <div
            v-show="error"
            class="alert alert-danger alert-dismissible fade show text-center"
            role="alert"
          >
            {{ $t("login.error") }}
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
          <div class="login100-pic js-tilt mt-8" data-tilt>
            <img src="../../assets/logo.png" alt="IMG" />
          </div>

          <form @submit.prevent="login" class="login100-form validate-form">
            <div class="mb-4 text-center mt-4 pb-4">
              <h3>Connectez-vous</h3>
              <p class="mb-4"></p>
            </div>
            <div class="input-group input-group-lg flex-nowrap">
              <v-text-field
                color="gray"
                v-model="username"
                prepend-inner-icon="mdi-account"
                name="username"
                label="Username"
                outlined
                clearable
                dense
                type="text"
              >
              </v-text-field>
            </div>
            <div class="input-group input-group-lg flex-nowrap">
              <v-text-field
                color="gray"
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                :type="show1 ? 'text' : 'password'"
                prepend-inner-icon="mdi-key"
                name="password"
                label="Mot de passe"
                outlined
                dense
                clearable
              ></v-text-field>
            </div>
            <v-btn
              @click="login"
              value="Connexion"
              class="w-100 btn"
              style="color: white"
              color="#2b2e83"
            >
              Connexion
            </v-btn>
            <span class="d-block text-center mt-2 text-muted"
              >&mdash; Vous n'avez pas de compte ? &mdash;</span
            >
            <router-link to="signup">
              <a class="d-block text-center">Inscrivez vous !</a>
            </router-link>
            <span class="d-block text-center text-muted"
              >&mdash; Mots de passe oublier ? &mdash;</span
            >
            <router-link to="reset">
              <a class="d-block text-center">Cliquez ici !</a>
            </router-link>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      error: false,
      show1: false,
      username: "",
      password: "",
      alert: false,
      alerts: false,
      android: "android",
      IOS: "Ios",
    };
  },
  mounted() {
    this.userdevices();
  },
  computed: {
    ...mapGetters(["user"]),
    log() {
      return {
        username: this.username,
        password: this.password,
      };
    },
  },
  methods: {
    login() {
      this.$store.dispatch("login", this.log).then(() => {
        const status = localStorage.getItem("status");
        if (status == 200) {
          localStorage.removeItem("status");
          if (window.location.pathname == "/login") {
            this.$router.push("/dashboard/profil/me");
          }
        } else if (status == 400);
        {
          localStorage.removeItem("status");
          this.error = true;
          const timer = () => {
            this.error = false;
          };
          setTimeout(timer, 2.5 * 1000, 2.5);
        }
      });
    },
    checkroleandredirect() {
      if (
        this.user.role.id == 2 ||
        this.user.role.id == 3 ||
        this.user.role.id == 5 ||
        this.user.role.id == 6
      ) {
        this.$router.push("/dashboard/profil");
      } else {
        this.$router.push("/dashboard/profil/me");
      }
    },
    userdevices() {
      if (/Android/i.test(navigator.userAgent)) {
        this.alert = true;
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        console.log("ux");
        this.alerts = true;
      }
    },
  },
};
</script>
<style scoped>
.limiter {
  width: 100%;
  margin: 0 auto;
}
.back {
  background-color: #2b2e83 !important;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #2b2e83;
  background: -webkit-linear-gradient(-135deg, #e30619, #2b2e80);
  background: -o-linear-gradient(-135deg, #e30611, #2b2e80);
  background: -moz-linear-gradient(-135deg, #e30611, #2b2e80);
  background: linear-gradient(-135deg, #e30611, #2b2e80);
}

.wrap-login100 {
  width: 960px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 60px 130px 33px 95px;
}

.login100-pic {
  width: 316px;
}

.login100-pic img {
  max-width: 100%;
}
.login100-form {
  width: 290px;
}

.login100-form-title {
  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: center;

  width: 100%;
  display: block;
  padding-bottom: 54px;
}

@media (max-width: 992px) {
  .wrap-login100 {
    padding: 60px 90px 33px 85px;
  }

  .login100-pic {
    width: 35%;
  }

  .login100-form {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .wrap-login100 {
    padding: 100px 80px 33px 80px;
  }

  .login100-pic {
    display: none;
  }

  .login100-form {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 20px 15px 33px 15px;
  }
}
</style>
