<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <div class="col-lg-7">
        <v-card class="signup">
          <v-card-text>
            <div class="text-center">
              <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                center
                absolute
                color="#1e824c"
              >
                {{ $t("ville.update") }}

                <template v-slot:action="{ attrs }">
                  <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
            <div class="text-center">
              <v-snackbar
                v-model="error"
                :timeout="timeout"
                absolute
                bottom
                color="red"
                right
              >
                {{ $t("login.error") }}

                <template v-slot:action="{ attrs }">
                  <v-btn color="#cf000f" text v-bind="attrs" @click="snackbar = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
            <v-form @submit.prevent="save" ref="formsaveuser">
              <h2 class="text-center mt-4">
                {{ $t("admin.defpassword") }}
              </h2>
              <div class="row mt-8">
                <div class="col-md-12">
                  <div class="form-group">
                    <v-text-field
                      v-model="old"
                      :rules="passwordRules"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show2 = !show2"
                      :type="show2 ? 'text' : 'password'"
                      prepend-inner-icon="mdi-key"
                      name="password"
                      outlined
                      ref="clear"
                      dense
                      :label="$t('headers.old')"
                      clearable
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="form-group">
                    <v-text-field
                      v-model="news"
                      :rules="passwordRules"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show1 = !show1"
                      :type="show1 ? 'text' : 'password'"
                      prepend-inner-icon="mdi-key"
                      name="password"
                      outlined
                      ref="clear1"
                      dense
                      :label="$t('headers.news')"
                      clearable
                    ></v-text-field>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="form-group">
                  <button class="w-100 btn log save" type="submit" value="register">
                    {{ $t("admin.def") }}
                  </button>
                </div>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import { baseURL } from "../../../main";
import Aside from "../../../components/aside.vue";
import { mapGetters } from "vuex";

export default {
  components: { Aside },
  data: () => {
    return {
      show2: "",
      old: null,
      news: null,
      confirm: false,
      show1: "",
      show2: "",
      solde: false,
      oui: false,
      eye: false,
      eyes: true,
      snackbar: false,
      error: false,
      timeout: 2000,
      montant: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
    };
  },
  computed: {
    ...mapGetters(["compteadmin", "one"]),
    passwordRules() {
      return [
        (v) => !!v || this.$t("require.pass"),
        (v) =>
          /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()–[{}]).{6,40}/.test(v) ||
          this.$t("rule.pass"),
      ];
    },
    form() {
      return {
        ancien: this.old,
        nouveau: this.news,
      };
    },
  },

  methods: {
    show() {
      this.eye = false;
      this.solde = false;
    },
    shows() {
      this.eye = true;
      this.solde = true;
    },
    valid() {
      this.oui = true;
      this.conf();
    },
    // conf() {
    //   {
    //     localStorage.setItem("tk", this.$store.getters.token);
    //     this.$store
    //       .dispatch("updatepassword", {
    //         old: this.old,
    //         news: this.news,
    //       })
    //       .then(() => {
    //         const status = localStorage.getItem("status");
    //         if (status == 200) {
    //           this.pins = "";
    //           this.snackbar = true;

    //           localStorage.removeItem("status");
    //         } else if (status == 400) {
    //           this.error = true;
    //           localStorage.removeItem("status");
    //         }
    //       });
    //   }
    // },
    save() {
      if (this.$refs.formsaveuser.validate()) {
        localStorage.setItem("tk", this.$store.getters.token);
        this.$store.dispatch("updatepassword", { value: this.form }).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.pins = "";
            this.snackbar = true;

            localStorage.removeItem("status");
          } else if (status == 400) {
            this.error = true;
            localStorage.removeItem("status");
          }
        });
      }
    },
  },
  mounted() {
    // this.$store.dispatch("getroles");
    localStorage.setItem("tk", this.$store.getters.token);
    this.$store.dispatch("getoneportefeuille", this.$store.getters.user.id);
  },
};
</script>
<style scoped>
.num {
  font-variant-numeric: tabular-nums !important;
  color: #e30613;
}
.container-100 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 100px 0px 0px 0px;
}
.save {
  background-color: #2b2e83;
  color: #eee7ce;
}
.back {
  background-color: whitesmoke;
}
.signup {
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 70px;
}
</style>
<Aside :profile="one" />
