<template>
  <main>
    <div class="limiter">
      <div class="container-login100">
        <div class="wrap-login100">
          <div
            v-show="error"
            class="alert alert-danger alert-dismissible fade show text-center"
            role="alert"
          >
            {{ $t("login.error") }}
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <v-form
            ref="formreset"
            @submit.prevent="reset"
            class="login100-form validate-form"
          >
            <div class="mb-4 text-center mt-4 pb-4">
              <router-link to="/login">
                <div class="login100-pic">
                  <img src="../../assets/logo.png" alt="IMG" />
                </div>
              </router-link>
              <h3>{{ $t("reset.new") }}</h3>
            </div>
            <div class="mt-4">
              <v-text-field
                v-model="password"
                class="mt-2"
                :rules="passwordRules"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                :type="show1 ? 'text' : 'password'"
                prepend-inner-icon="mdi-key"
                name="password"
                outlined
                ref="clear"
                dense
                :label="$t('headers.password')"
                clearable
              ></v-text-field>
            </div>
            <div class="mt-4">
              <v-text-field
                v-model="confirmpassword"
                class="mt-2"
                :rules="confirmPasswordValidationRules"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                :type="show1 ? 'text' : 'password'"
                prepend-inner-icon="mdi-key"
                name="password"
                outlined
                ref="clear1"
                dense
                :label="$t('headers.confirmpassword')"
                clearable
              ></v-text-field>
            </div>
            <v-btn
              @click="login"
              value="Connexion"
              class="w-100 btn"
              style="color: white"
              color="#2b2e83"
            >
              Reset
            </v-btn>
          </v-form>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  data() {
    return {
      error: false,
      show1: "",
      email: "",
      password: "",
      password: "",
      confirmpassword: "",
    };
  },
  mounted() {
    // console.log(this.$route.params.key);
  },
  computed: {
    passwordRules() {
      return [
        (v) => !!v || this.$t("require.pass"),
        (v) =>
          /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()–[{}]).{6,40}/.test(v) ||
          this.$t("rule.pass"),
      ];
    },
    confirmPasswordValidationRules() {
      return this.confirmpasswordRules(this.password);
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("require.email"),
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || this.$t("rule.mail"),
      ];
    },
  },
  methods: {
    confirmpasswordRules(password) {
      return [
        (v) => !!v || this.$t("require.pass"),
        (v) =>
          /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()–[{}]).{6,40}/.test(v) ||
          this.$t("rule.pass"),
        (v) => v === password || this.$t("reset.notmatch"),
      ];
    },
    login() {
      if (this.$refs.formreset.validate()) {
        // console.log(this.$route.params.key);
        this.$store
          .dispatch("resetspassword", {
            token: this.$route.params.key,
            value: {
              nouveau: this.password,
            },
          })
          .then(() => {
            const status = localStorage.getItem("status");
            if (status == 200) {
              localStorage.removeItem("status");
              // if (window.location.pathname == "/login") {
              // }
              this.$refs.clear.reset();
              this.$refs.clear1.reset();
              this.$router.push("/login");
            } else if (status == 400);
            {
              localStorage.removeItem("status");
              this.error = true;
              const timer = () => {
                this.error = false;
              };
              setTimeout(timer, 2.5 * 1000, 2.5);
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.limiter {
  width: 100%;
  margin: 0 auto;
}
.back {
  background-color: #2b2e83 !important;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #2b2e83;
  background: -webkit-linear-gradient(-135deg, #e30619, #2b2e80);
  background: -o-linear-gradient(-135deg, #e30611, #2b2e80);
  background: -moz-linear-gradient(-135deg, #e30611, #2b2e80);
  background: linear-gradient(-135deg, #e30611, #2b2e80);
}

.wrap-login100 {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 55px 55px 55px 55px;
}

.login100-pic {
  width: 600px;
}

.login100-pic img {
  max-width: 30%;
}

.login100-form-title {
  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: center;

  width: 100%;
  display: block;
  padding-bottom: 54px;
}

@media (max-width: 992px) {
  .wrap-login100 {
    padding: 55px 55px 55px 55px;
  }
}

@media (max-width: 768px) {
  .wrap-login100 {
    padding: 55px 55px 55px 55px;
  }

  .login100-pic {
    width: 100%;
  }

  .login100-form {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 55px 55px 55px 55px;
  }
}
</style>
