import axios from "axios";
import { baseURL } from "../../main";

const state =
{
    addtaux:null,
    alltaux:null,
    allstaux:null,
    updatetaux:null
 
    
}

const getters=
{
   
  addtaux:(state)=> state.addtaux,
  alltaux:(state)=> state.alltaux,
  allstaux:(state)=> state.allstaux,
  updatetaux:(state)=> state.updatetaux,
 
};

const actions =
{ 
  async updatetaux({commit},{id, value})
	{
		let token = localStorage.getItem("tk");
    await axios
      .put(baseURL + "taux/echange/" + id, value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setupdatetaux", data);
        }
      });
	},
  async addtaux({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "taux/echange", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setnews", data);
        }
      });
  },
  async taux({ commit }) {
    let token = localStorage.getItem("tk");
    let response=await axios
      .get(baseURL + "taux/echanges",{
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      let data = response.data;
      data
      localStorage.removeItem("tk");
      commit("setalltaux", data);
  },
   async getalltaux({commit},id)
    {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "taux/echanges/page/"+id, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          let data = response.data;
          localStorage.removeItem("tk");
          commit("settaux", data);
    },
   async searchtaux({commit},{page,search})
    {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "taux/echanges/search/page/"+page+"/"+search, {
            headers: {
              Authorization: "Bearer " + token,
            },
          });
          let data = response.data;
          localStorage.removeItem("tk");
          commit("settaux", data);
    },
};
const mutations =
{
    setnews(state, addtaux)
    {
        state.addtaux = addtaux
    },
    settaux(state, alltaux)
    {
        state.alltaux = alltaux
    },
    setalltaux(state, allstaux)
    {
        state.allstaux = allstaux
    },
    setupdatetaux(state, updatetaux)
    {
        state.updatetaux = updatetaux
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
}