<template>
  <main>
    <div class="limiter">
      <div class="container-login100">
        <div class="wrap-login100">
          <div
            v-show="error"
            class="alert alert-danger alert-dismissible fade show text-center"
            role="alert"
          >
            {{ $t("login.error") }}
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>

          <v-form
            ref="formreset"
            @submit.prevent="reset"
            class="login100-form validate-form"
          >
            <div class="mb-4 text-center mt-4 pb-4">
              <div class="login100-pic">
                <img src="../../assets/logo.png" alt="IMG" />
              </div>
              <h3>{{ $t("reset.password") }}</h3>
            </div>
            <v-alert :value="info" color="info">
              <v-row align="center">
                <v-col class="grow text-center devise" style="color: white">
                  <v-icon color="white">mdi-alert-circle-outline</v-icon>

                  {{ $t("reset.info") }}
                </v-col>
              </v-row>
            </v-alert>
            <v-alert :value="infos" color="success">
              <v-row align="center">
                <v-col class="grow text-center devise" style="color: white">
                  <v-icon color="white">mdi-check-circle-outline</v-icon>
                  {{ $t("reset.success") }}
                </v-col>
              </v-row>
            </v-alert>
            <v-alert :value="erros" color="error">
              <v-row align="center">
                <v-col class="grow text-center devise" style="color: white">
                  <v-icon color="white">mdi-alert-octagon-outline</v-icon>
                  {{ $t("reset.error") }}
                </v-col>
              </v-row>
            </v-alert>
            <div class="mt-4">
              <v-text-field
                color="gray"
                v-model="email"
                class="mt-2"
                :rules="emailRules"
                prepend-inner-icon="mdi-email"
                name="username"
                :label="$t('headers.email')"
                outlined
                ref="clear"
                clearable
                dense
              >
              </v-text-field>
            </div>
            <v-btn
              @click="login"
              value="Connexion"
              class="w-100 btn"
              style="color: white"
              color="#2b2e83"
            >
              Reset
            </v-btn>

            <span class="d-block text-center text-muted mt-2"
              >&mdash; {{ $t("reset.remember") }} &mdash;</span
            >
            <router-link to="login">
              <a class="d-block text-center">{{ $t("reset.click") }}</a>
            </router-link>
          </v-form>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import { baseURL } from "../../main";
export default {
  data() {
    return {
      error: false,
      show1: false,
      email: "",
      password: "",
      erros: false,
      info: true,
      infos: false,
      messagesemail: null,
    };
  },
  mounted() {},
  computed: {
    emailRules() {
      return [
        (v) => !!v || this.$t("require.email"),
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || this.$t("rule.mail"),
      ];
    },
    checkmail() {
      return this.existemail(this.email);
    },
    log() {
      return {
        username: this.username,
        password: this.password,
      };
    },
  },
  methods: {
    login() {
      if (this.$refs.formreset.validate()) {
        const forgot = {};
        forgot.email = this.email;
        this.$store.dispatch("sendmail", forgot).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            localStorage.removeItem("status");
            this.$refs.clear.reset();
            this.info = false;
            this.infos = true;
            this.erros = false;
          } else if (status == 400) {
            console.log(status);
            localStorage.removeItem("status");
            this.erros = true;
            this.info = false;
            this.infos = false;
          }
        });
      }
    },
    existemail(email) {
      if (email) {
        axios.get(baseURL + "auth/check/email/" + email).then((response) => {
          console.log(response.data);
          if (response.data === false) {
            return [
              (v) => !!v || this.$t("require.email"),
              (v) =>
                /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  v
                ) || this.$t("rule.mail"),
              (v) => v === response.data || "Cet utilisateur n'exisite pas",
            ];
          } else {
            return [
              (v) => !!v || this.$t("require.email"),
              (v) =>
                /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  v
                ) || this.$t("rule.mail"),
            ];
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.limiter {
  width: 100%;
  margin: 0 auto;
}
.back {
  background-color: #2b2e83 !important;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #2b2e83;
  background: -webkit-linear-gradient(-135deg, #e30619, #2b2e80);
  background: -o-linear-gradient(-135deg, #e30611, #2b2e80);
  background: -moz-linear-gradient(-135deg, #e30611, #2b2e80);
  background: linear-gradient(-135deg, #e30611, #2b2e80);
}

.wrap-login100 {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 55px 55px 55px 55px;
}

.login100-pic {
  width: 600px;
}

.login100-pic img {
  max-width: 30%;
}

.login100-form-title {
  font-size: 24px;
  color: #333333;
  line-height: 1.2;
  text-align: center;

  width: 100%;
  display: block;
  padding-bottom: 54px;
}

@media (max-width: 992px) {
  .wrap-login100 {
    padding: 55px 55px 55px 55px;
  }
}

@media (max-width: 768px) {
  .wrap-login100 {
    padding: 55px 55px 55px 55px;
  }

  .login100-pic {
    width: 100%;
  }

  .login100-form {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 55px 55px 55px 55px;
  }
}
</style>
