<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <div class="col-lg-6">
        <v-card class="signup">
          <v-card-text>
            <div class="text-center">
              <v-snackbar
                v-model="snackbar"
                :timeout="timeout"
                center
                absolute
                bottom
                color="#1e824c"
              >
                {{ $t("Transaction.succes") }}

                <template v-slot:action="{ attrs }">
                  <v-btn color="#cf000f" text v-bind="attrs" @click="snackbar = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
            <div class="text-center">
              <v-snackbar
                v-model="error"
                :timeout="timeout"
                absolute
                bottom
                color="red"
                center
              >
                {{ $t("devis.error") }}

                <template v-slot:action="{ attrs }">
                  <v-btn color="#cf000f" text v-bind="attrs" @click="snackbar = false">
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
            <v-form @submit.prevent="save" ref="formsaveuser">
              <h2 class="text-center mt-4">
                {{ $t("user.requestretrait") }}
              </h2>
              <div class="row mt-8">
                <div class="col-md-12">
                  <div class="form-group">
                    <v-text-field
                      v-model="montant"
                      class="ms-2 me-2"
                      type="number"
                      :rules="[rules.required]"
                      prepend-inner-icon="mdi-dialpad"
                      :label="$t('user.montant')"
                      clearable
                      @blur="checkmontant"
                      :error-messages="messagemontant"
                      ref="clear"
                      outlined
                      dense
                    ></v-text-field>
                    <v-autocomplete
                      class="mt-4 ms-2 me-2"
                      v-model="city"
                      prepend-inner-icon="mdi-earth"
                      :items="villeList"
                      item-text="nom"
                      item-value="id"
                      label="Votre ville"
                      ref="clear1"
                      :rules="[rules.required]"
                      return-object
                      outlined
                      dense
                    >
                    </v-autocomplete>
                    <v-autocomplete
                      class="mt-4 ms-2 me-2"
                      v-model="agences"
                      prepend-inner-icon="mdi-earth"
                      :items="agenceList"
                      item-text="nom"
                      item-value="id"
                      label="Les agences"
                      ref="clear2"
                      :rules="[rules.required]"
                      return-object
                      outlined
                      dense
                    >
                    </v-autocomplete>
                    <div class="mt-4 ms-2 me-2">
                      <v-radio-group
                        class=""
                        v-model="selectedField"
                        label="Paiement"
                        row
                      >
                        <v-radio
                          class="col-md-5 ms-4"
                          label="Banque"
                          value="banques"
                        ></v-radio>
                        <v-radio
                          class="col-md-4 me-4"
                          label="Autre moyen de récupération"
                          value="moyenrecups"
                        ></v-radio>
                      </v-radio-group>

                      <v-select
                        class="mt-4"
                        v-model="banque"
                        outlined
                        :items="user.banques || bankforkiosk"
                        item-text="institution"
                        item-value="id"
                        ref="clear3"
                        :rules="[rules.required]"
                        label="Votre banque"
                        prepend-inner-icon="mdi-bank"
                        dense
                        v-if="selectedField === 'banques'"
                      >
                      </v-select>

                      <v-autocomplete
                        class="mt-4"
                        v-model="moyenrecup"
                        prepend-inner-icon="mdi-bank-transfer-out"
                        :items="usermoyens"
                        item-text="moyenRecuperation.libelle"
                        item-value="id"
                        label="Moyen de récupération"
                        ref="clear4"
                        :rules="[rules.required]"
                        return-object
                        outlined
                        dense
                        v-if="selectedField === 'moyenrecups'"
                      >
                      </v-autocomplete>
                    </div>
                    <v-text-field
                      class="mt-4 ms-2 me-2"
                      v-model="pin"
                      name="indicatif"
                      type="number"
                      label="code pin"
                      ref="clear5"
                      @blur="checkpins"
                      prepend-inner-icon="mdi-dialpad"
                      :rules="[rules.required]"
                      :error-messages="messagepin"
                      outlined
                      clearable
                      dense
                    >
                    </v-text-field>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="form-group">
                  <button class="w-100 btn log save" type="submit" value="register">
                    {{ $t("admin.def") }}
                  </button>
                </div>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import { baseURL } from "../../../main";
import Aside from "../../../components/aside.vue";
import { mapGetters } from "vuex";

export default {
  components: { Aside },
  data: () => {
    return {
      show2: "",
      pins: null,
      confirm: false,
      solde: false,
      oui: false,
      banque: "",
      agences: "",
      selectedField: "banque",
      banques: "",
      moyenrecups: "",
      city: "",
      eye: false,
      eyes: true,
      snackbar: false,
      preuve: null,
      error: false,
      timeout: 2000,
      messagepin: null,
      messagemontant: null,
      moyenrecup: "",
      pin: "",
      montant: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
    };
  },
  computed: {
    ...mapGetters([
      "one",
      "agencebyville",
      "agencebyuser",
      "villebypays",
      "me",
      "user",
      "two",
      "checkpin",
      "usermoyens",
      "recup",
    ]),
    agenceList() {
      if (this.agencebyville != null) {
        return this.agencebyville.filter((item) => item.typeAgence.id === 1);
      } else {
        return [];
      }
    },
    // recupList() {
    //   if (this.recup != null) {
    //     return this.recup.filter(
    //       (item) => item.libelle != "VIREMENT BANCAIRE" && item.libelle != "ESPECE"
    //     );
    //   }
    // },
    bankforkiosk() {
      if (this.agencebyuser) {
        const newData = {
          intitule: this.agencebyuser.intituleAgence,
          numCompte: this.agencebyuser.numCompteAgence,
          institution: this.agencebyuser.nom,
        };
        if (this.$store.state.auth.user.banques.length === 0) {
          this.$store.commit("setBanques", [newData]);
        }
        return [newData];
      }
    },
    villeList() {
      if (this.villebypays != null) {
        return this.villebypays;
      } else {
        return [];
      }
    },
  },
  watch: {
    city() {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("getagencebyville", this.city.id);
    },
    agences() {
      localStorage.setItem("tk", this.$store.getters.token);
      this.$store.dispatch("gettwoportefeuille", this.agences.users[0].id);
    },
    // moyenrecup() {
    //   if (this.moyenrecup && this.selectedField === "moyenrecups") {
    //     alert(1);
    //   }
    // },
    // banque() {
    //   if (this.banque && this.selectedField === "banques") {
    //     alert(2);
    //   }
    // },
    selectedField() {
      if (this.selectedField === "banques") {
        this.moyenrecup = "";
      } else {
        this.banque = null;
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.$store.getters.token);
    this.$store.dispatch("getoneportefeuille", this.user.id);
    this.$store.dispatch("getvillebypays", this.user.pays.id);
    this.$store.dispatch("getagencebyville", this.user.pays.id);
    this.$store.dispatch("getagencebyuser", this.user.id);
    this.$store.dispatch("getmoyenrecupbypays", this.user.pays.id);
    this.$store.dispatch("getallmoyenuser");
  },
  methods: {
    checkmontant() {
      if (this.montant > this.one.solde) {
        this.messagemontant = "Solde insufissant";
        this.$refs.clear3.$el.querySelector("input").focus();
      } else if (this.montant <= 0) {
        this.messagemontant = "Montant invalide";
        this.$refs.clear3.$el.querySelector("input").focus();
      } else {
        this.messagemontant = null;
      }
    },
    checkpins() {
      localStorage.setItem("tk", this.$store.getters.token);
      const pin = {};
      pin.pin = this.pin;
      this.$store.dispatch("getcheckpin", { id: this.user.id, pin: pin }).then(() => {
        const timer = () => {
          if (this.checkpin == false) {
            this.messagepin = "Le code pin ne correspond pas";
            this.$refs.clear2.$el.querySelector("input").focus();
          } else {
            this.messagepin = null;
          }
        };
        setTimeout(timer, 0.1 * 100, 0.1);
      });
    },
    reset() {
      if (this.$refs.clear3 == undefined) {
        this.$refs.clear.reset();
        this.$refs.clear1.reset();
        this.$refs.clear2.reset();
        this.$refs.clear4.reset();
        this.$refs.clear5.reset();
      } else {
        this.$refs.clear.reset();
        this.$refs.clear1.reset();
        this.$refs.clear2.reset();
        this.$refs.clear3.reset();
        this.$refs.clear5.reset();
      }
    },
    show() {
      this.eye = false;
      this.solde = false;
    },
    shows() {
      this.eye = true;
      this.solde = true;
    },
    valid() {
      this.oui = true;
      this.conf();
    },

    form() {},
    save() {
      if (this.$refs.formsaveuser.validate()) {
        if (this.messagemontant == null && this.messagepin == null) {
          const request = {};
          if (this.moyenrecup.id != null) {
            request.mesMoyenRecuperation = this.moyenrecup.id;
          } else {
            request.banque = this.banque;
          }
          request.envoyeur = this.one.numero;
          request.montant = this.montant;
          request.agence = this.agences.id;
          request.receveur = this.two.numero;
          request.pin = this.pin;
          request.typeOperation = 6;
          localStorage.setItem("tk", this.$store.getters.token);
          this.reset();
          this.$store.dispatch("setactioncompteuser", request).then(() => {
            const status = localStorage.getItem("status");
            if (status == 200) {
              this.snackbar = true;
              this.reset();
              localStorage.setItem("tk", this.$store.getters.token);
              this.$store.dispatch("getoneportefeuille", this.$store.getters.user.id);
              localStorage.removeItem("status");
            } else if (status == 400) {
              this.error = true;
              localStorage.removeItem("status");
            }
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.num {
  font-variant-numeric: tabular-nums !important;
  color: #e30613;
}
.custom-radio-group {
  border: 1px solid #ccc;
  border-radius: 5px;
}
.fieldset {
  border-collapse: collapse;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
}
.container-100 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 100px 0px 0px 0px;
}
.save {
  background-color: #16453e;
  color: #eee7ce;
}
.back {
  background-color: whitesmoke;
}
.signup {
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 70px;
}
</style>
