<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <v-dialog v-model="confirm" transition="dialog-top-transition" max-width="500">
        <template v-slot:default="dialog">
          <v-form ref="formvalid" @submit.prevent="update">
            <v-card color="#fefbfb">
              <v-card-title class="">{{ $t("confirm.one") }}</v-card-title>
              <v-card-text>
                <hr />
                <p class="confirm">
                  {{ $t("confirm.two") }}
                  <span>
                    {{ totalconfirm }}
                  </span>
                  <br />
                  De:
                  <span class="name">{{ userconfirm }}</span>
                  <!-- <span class="name">{{ userconfirm2 }}</span> -->
                  <br />
                  tel: <span class="name">{{ userconfirm3 }}</span>
                  <span v-if="banques != null">
                    <hr />
                    <span class="name"> A recevoir via : </span>
                    <ul class="list-unstyled">
                      <li class="mt-2">
                        <span class="begin">
                          {{ $t("headers.institution") }}
                        </span>
                        <span class="end float-end">{{ banques.institution }} </span>
                      </li>
                      <li class="mt-2">
                        <span class="begin">
                          {{ $t("headers.intitule") }}
                        </span>
                        <span class="end float-end">{{ banques.intitule }} </span>
                      </li>
                      <li class="mt-2">
                        <span class="begin">
                          {{ $t("headers.numcompte") }}
                          <span class="end float-end">{{ banques.numCompte }} </span>
                        </span>
                      </li>
                    </ul>
                  </span>
                  <span v-if="mesmoyen != null">
                    <hr />
                    <span class="name"> A recevoir via : </span>
                    <ul class="list-unstyled">
                      <li class="mt-2">
                        <span class="begin">
                          {{ $t("headers.recups") }}
                        </span>
                        <span class="end float-end"
                          >{{ mesmoyen.moyenRecuperation.libelle }}
                        </span>
                      </li>
                      <li class="mt-2">
                        <span class="begin">
                          {{ $t("headers.detail") }}
                        </span>
                        <span class="end float-end"> {{ mesmoyen.recuperation }}</span>
                      </li>
                      <li class="mt-2">
                        <span class="begin">
                          Fichier:
                          <span class="end float-end">
                            <a
                              v-if="mesmoyen.fichier"
                              :href="url + mesmoyen.fichier.name"
                              target="_blank"
                              >Fichier</a
                            >
                          </span>
                        </span>
                      </li>
                    </ul>
                  </span>
                </p>
                <hr />
              </v-card-text>
              <div class="d-flex justify-content-between">
                <v-btn
                  class="mt-2 mb-2"
                  color="#e30613"
                  text
                  @click="dialog.value = false"
                  >non</v-btn
                >
                <v-btn @click="valid()" class="mt-2 mb-2" color="#1A4478" text>oui</v-btn>
              </div>
            </v-card>
          </v-form>
        </template>
      </v-dialog>
      <div class="col-lg-9 mb-4">
        <div class="col-sm-6 col-md-5 form-group">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-2 row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("Transaction.Transaction") }}</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <td>{{ date(item.createdAt) }}</td>
          </template>
          <template v-slot:[`item.valider`]="{ item }">
            <td v-if="item.valider == true">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" large color="green"
                    >mdi-check-circle-outline
                  </v-icon>
                </template>
                <span>Effectuer</span>
              </v-tooltip>
            </td>
            <td v-else>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="yellow" large>
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <span>En attente</span>
              </v-tooltip>
            </td>
          </template>
          <template v-slot:[`item.montant`]="{ item }">
            <th style="color: red" v-if="item.envoyeur">
              +
              {{ item.montant + " " + item.envoyeur.user.pays.devise.libelle }}
            </th>
            <th style="color: red" v-else>
              +
              {{ item.montant + " " + item.receveur.user.pays.devise.libelle }}
            </th>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
    </div>
    <v-dialog v-model="details" max-width="720">
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-sm-5 d-flex justify-content-center rounded-start py-4 custom">
              <div class="my-auto text-center">
                <div class="text-17 text-white my-3">
                  <v-icon x-large color="white">mdi-swap-horizontal</v-icon>
                </div>
                <h3 class="text-4 text-white fw-400 my-3" v-if="objdetails.typeOperation">
                  Transaction {{ objdetails.typeOperation.libelle }}
                </h3>
                <div v-if="objdetails.envoyeur" class="money other text-white my-4">
                  {{
                    parseInt(objdetails.montant).toFixed().toString() +
                    " " +
                    objdetails.envoyeur.user.pays.devise.libelle
                  }}
                </div>
                <p class="text-white">
                  {{ date(objdetails.createdAt) }}
                </p>
              </div>
            </div>
            <div class="col-sm-7">
              <h5 class="text-5 fw-400 m-3">Transaction details</h5>
              <hr />
              <div class="px-3">
                <ul class="list-unstyled">
                  <li class="mb-2" v-if="objdetails.envoyeur">
                    Montant
                    <span class="float-end end">{{
                      objdetails.montant +
                      " " +
                      objdetails.envoyeur.user.pays.devise.libelle
                    }}</span>
                  </li>
                </ul>
                <hr class="mb-2" />
                <p class="d-flex align-items-center other mb">
                  Total
                  <span class="end ms-auto" v-if="objdetails.envoyeur">
                    {{
                      parseInt(objdetails.montant).toFixed().toString() +
                      " " +
                      objdetails.envoyeur.user.pays.devise.libelle
                    }}
                  </span>
                </p>
                <hr class="mb-4 mt-2" />
                <ul class="list-unstyled">
                  <li class="other">Destinataire</li>
                  <li class="mt-2">
                    Nom/Prenom
                    <span class="text-muted float-end" v-if="objdetails.envoyeur"
                      >{{
                        objdetails.envoyeur.user.nom +
                        " " +
                        objdetails.envoyeur.user.prenom
                      }}
                    </span>
                  </li>
                  <li class="mt-2">
                    Telephone
                    <span class="text-muted float-end" v-if="objdetails.envoyeur"
                      >{{ objdetails.envoyeur.user.tel }}
                    </span>
                  </li>
                  <li class="mt-2">
                    Pays
                    <span class="text-muted float-end" v-if="objdetails.envoyeur">
                      {{ objdetails.envoyeur.user.pays.nom }}</span
                    >
                  </li>
                </ul>

                <ul class="list-unstyled">
                  <li class="other">Status</li>
                  <li class="text-muted" v-if="objdetails.valider == true">
                    Valider
                    <span class="end ms-1 float-end">
                      <v-icon color="green ">mdi-check-circle-outline</v-icon>
                    </span>
                  </li>
                  <li v-else class="text-muted">
                    En attente
                    <span class="end ms-1 float-end">
                      <v-icon color="yellow"> mdi-dots-horizontal </v-icon>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import Aside from "../../../components/aside.vue";
import { baseURL } from "../../../main";
export default {
  components: { Aside },
  data() {
    return {
      items: [],
      snackbar: false,
      timeout: 2000,
      loading: false,
      confirm: false,
      userconfirm: "",
      userconfirm2: "",
      userconfirm3: "",
      totalconfirm: "",
      banques: "",
      mesmoyen: "",
      details: false,
      objdetails: {},
      id: "",
      url: "",
      search: null,
      current_page: 1,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: this.$t("headers.date"),
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: this.$t("headers.description"),
          align: "start",
          sortable: false,
          value: "typeOperation.libelle",
        },
        {
          text: this.$t("headers.statut"),
          align: "start",
          sortable: false,
          value: "valider",
        },
        {
          text: this.$t("headers.montant"),
          align: "start",
          sortable: false,
          value: "montant",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["receiveoperation", "token", "user", "one"]),
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.receiveoperation && this.receiveoperation.last_page) {
        return this.receiveoperation.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.receiveoperation != null && this.receiveoperation.data) {
        return this.receiveoperation.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search() {
      if (this.search == null || this.search.trim() == "") {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getreceiveoperation", this.current_page);
      } else {
        this.$store.dispatch("searchreceiveoperation", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
  },
  methods: {
    ici(row) {
      this.objdetails = row;
      if (row.valider != true) {
        this.id = row.id;
        this.totalconfirm = row.montant + "" + row.receveur.user.pays.devise.libelle;
        this.userconfirm = row.envoyeur.user.nom + " " + row.envoyeur.user.prenom;
        this.userconfirm2 = row.envoyeur.user.username;
        this.userconfirm3 = row.envoyeur.user.tel;
        this.banques = row.banque;
        this.mesmoyen = row.mesMoyenRecuperation;
        this.confirm = true;
      } else if (this.confirm == false) {
        this.details = true;
      }
    },
    valid() {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("validoperation", this.id).then(() => {
        const status = localStorage.getItem("status");
        if (status == 200) {
          this.confirm = false;
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("getreceiveoperation", this.current_page);
          this.$store.dispatch("getoneportefeuille", this.user.id);
          localStorage.removeItem("status");
        } else if (status == 400) {
          this.error = true;
          localStorage.removeItem("status");
        }
      });
    },
    date(item) {
      const date = new Date(item);
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleDateString("fr-FR", options);
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getreceiveoperation", this.current_page);
      } else {
        this.$store.dispatch("searchreceiveoperation", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {
      if (this.search) {
        if (this.search.trim() == "") {
          localStorage.setItem("tk", this.token);
          this.current_page = 1;
          this.$store.dispatch("getreceiveoperation", this.current_page);
        } else {
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("searchreceiveoperation", {
            page: this.current_page,
            search: this.search.trim(),
          });
          if (this.$store.state.operation.receiveoperation.data) {
            this.current_page = this.$store.state.operation.receiveoperation.current_page;
          } else this.current_page = 1;
        }
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.url = baseURL + "downloadFile/";
    this.$store.dispatch("getreceiveoperation", this.current_page);
    this.$store.dispatch("getoneportefeuille", this.user.id);
  },
  created() {
    // console.log(this.$store.state.portefeuille.receiveoperation.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.custom {
  background-color: #2b2e83;
  color: white;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
.infos {
  color: blue;
  font-size: 24px;
  text-transform: uppercase;
}
.confirm {
  font-size: 1.7rem !important;
  text-transform: uppercase;
  font-weight: 500 !important;
  margin-left: 1rem;
  margin-right: 1rem;
}
.confirm span {
  color: #20742c;
}
.begin {
  color: #0c0c08 !important;
}
.end {
  color: #7c1009 !important;
}
.name {
  color: #2b2e83 !important;
}
ul {
  padding-left: 0 !important;
}
.recap p {
  font-size: 1.25rem !important;
}
.other {
  font-weight: 500 !important;
}
.one {
  color: #2b2e83;
  margin-top: 4px;
}
.details p {
  font-size: 2rem;
}
.details span {
  font-size: 2rem;
  text-transform: uppercase;
}
.somme {
  margin: 4rem 4rem 0rem 0rem;
}
.somme p {
  font-size: 5rem !important;
}
.money {
  font-size: 2rem !important;
}
.small {
  font-size: 1.3125rem !important;
}
.end {
  font-size: 1rem !important;
}
</style>
