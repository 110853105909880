import axios from "axios";
import { baseURL } from "../../main";

const state = {
  moyen: null,
  allmoyen: null,
  allmoyens: null,
  moyenpays: null,
  updatemoyen: null,
  updatemoyenuser: null,
  usermoyen: null,
  usermoyens: null,

};
const getters = {
  moyen: (state) => state.moyen,
  allmoyen: (state) => state.allmoyen,
  allmoyens: (state) => state.allmoyens,
  moyenpays: (state) => state.moyenpays,
  updatemoyen: (state) => state.updatemoyen,
  updatemoyenuser: (state) => state.updatemoyenuser,
  usermoyen: (state) => state.usermoyen,
  usermoyens: (state) => state.usermoyens,
};

const actions = {
  async updatemoyen({ commit }, { id, value }) {
    let token = localStorage.getItem("tk");
    let response = await axios
      .put(baseURL + "moyen/recuperation/" + id, value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setupdatemoyen", data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async updatemoyenuser({ commit }, { id, value }) {
    let token = localStorage.getItem("tk");
    let response = await axios
      .put(baseURL + "mes/moyen/recuperation/" + id, value, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",

        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setupdatemoyenuser", data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async addnewmoyen({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "moyen/recuperation", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setcompte", data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getallmoyen({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "moyen/recuperations/page/" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setmoyen", data);
  },
  async getallmoyens({ commit }, id) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "moyen/recuperations/" + id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setallmoyens", data);
  },
  async getportefeuilleadmin({ commit }, id) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "users/portefeuille/" + id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setportefeuilleadmin", data);
  },
  async searchmoyen({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "moyen/recuperations/search/page/" + page + "/" + search,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setmoyen", data);
  },
  async addmoyenuser({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "mes/moyen/recuperation", value, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setmoyenuser", data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getallmoyenuser({ commit }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "mes/moyen/recuperations/",
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setallmoyenuser", data);
  },
  async removemoyenuser({ commit },id) {
    let token = localStorage.getItem("tk");
    let response = await axios.delete(
      baseURL + "mes/moyen/recuperation/"+id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setmoyenuser", data);
  },
};
const mutations = {
  setmoyen(state, allmoyen) {
    state.allmoyen = allmoyen;
  },
  setmoyenuser(state, usermoyen) {
    state.usermoyen = usermoyen;
  },
  setallmoyenuser(state, usermoyens) {
    state.usermoyens = usermoyens;
  },
  setallmoyens(state, allmoyens) {
    state.allmoyens = allmoyens;
  },
  setupdatemoyen(state, updatemoyen) {
    state.updatemoyen = updatemoyen;
  },
  setupdatemoyenuser(state, updatemoyenuser) {
    state.updatemoyenuser = updatemoyenuser;
  },
  setnew(state, moyen) {
    state.moyen = moyen;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
