import axios from "axios";
import { baseURL } from "../../main";
import store from "../store";
const requestCache = {};
// export const state = initialState;
const state = {
  users: null,
  updateuser: null,
  error: null,
  userrole: null,
  allclient: null,
  me: null,
  checkpin: null,
  updatepass: null,
  banque: null,
};

const getters = {
  users: (state) => state.users,
  allclient: (state) => state.allclient,
  userrole: (state) => state.userrole,
  updateuser: (state) => state.updateuser,
  me: (state) => state.me,
  error: (state) => state.error,
  checkpin: (state) => state.checkpin,
  updatepass: (state) => state.updatepass,
  banque: (state) => state.banque,
};
const actions = {
  async updateuser({ commit }, { id, value }) {
    let token = localStorage.getItem("tk");
    await axios
      .put(baseURL + "user/admin/" + id, value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setupdateuser", data);
        }
      });
  },
  async updatebanq({ commit }, { id, value }) {
    let token = localStorage.getItem("tk");
    await axios
      .put(baseURL + "banque/" + id, value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setupdatebanq", data);
        }
      });
  },
  async updatepassword({ commit }, { value }) {
    try {
      let token = localStorage.getItem("tk");
      const response = await axios.put(baseURL + "update/password", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      console.log(response, response.data);
      if (response.status !== 200) {
        localStorage.setItem("status", response.status);
        throw new Error(response.statusText);
      }

      localStorage.removeItem("tk");
      commit("setupdatepassword", response.data);
      localStorage.setItem("status", 200);
    } catch (error) {
      console.error(error);
      localStorage.setItem("status", 400);
    }
  },
  async adduser({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "user/admin", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setnew", data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async adduserbanq({ commit }, { id, value }) {
    let token = localStorage.getItem("tk");
    await axios
      .put(baseURL + "user/banque/" + id, value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setnewbanq", data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getcheckpin({ commit }, { id, pin }) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "check/user/pin/" + id, pin, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          commit("setcheckpin", data);
          // localStorage.removeItem("tk");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getallusers({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "users/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setusers", data);
  },
  async getallclient({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "users/client/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setclient", data);
  },

  async getme({ commit }) {
    try {
      let token = localStorage.getItem("tk");
      let response = await axios.get(baseURL + "users/me", {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      let data = response.data;
      localStorage.removeItem("tk");
      commit("setme", data);
    } catch (error) {
      store.dispatch('logout')
      console.error("Une erreur s'est produite  :", error);
      
    }
  },
  async getallclient({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "users/client/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setclient", data);
  },
  searchclient({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");
    axios
      .get(baseURL + "users/client/search/page/" + page + "/" + search, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let data = response.data;
        commit("setclient", data);
        localStorage.removeItem("tk");
      })
      .catch((error) => {
        commit("setError", error.message);
      });
  },
  async getuserbyrole({ commit }, id) {
    let token = localStorage.getItem("tk");
    return await axios
      .get(baseURL + "users/role/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let data = response.data;
        localStorage.removeItem("tk");
        commit("setuser", data);
      });
  },
  searchusers({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");
    axios
      .get(baseURL + "users/search/page/" + page + "/" + search, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let data = response.data;
        console.log(response.data);
        commit("setusers", data);
        localStorage.removeItem("tk");
      })
      .catch((error) => {
        commit("setError", error.message);
      });
  },
  getclearpin({ commit }) {
    commit("clearpin");
  },
};
const mutations = {
  setme(state, me) {
    state.me = me;
  },
  setcheckpin(state, checkpin) {
    state.checkpin = checkpin;
  },
  clearpin(state) {
    state.checkpin = null;
  },
  setnew(state, users) {
    state.users = users;
  },
  setnewbanq(state, banque) {
    state.banque = banque;
  },
  setusers(state, users) {
    state.users = users;
  },
  setclient(state, allclient) {
    state.allclient = allclient;
  },
  setuser(state, userrole) {
    state.userrole = userrole;
  },
  setupdateuser(state, updateuser) {
    state.updateuser = updateuser;
  },
  setupdatebanq(state, updatebanq) {
    state.updatebanq = updatebanq;
  },
  setupdatepassword(state, updatepassword) {
    state.updatepassword = updatepassword;
  },
  setError(state, error) {
    state.error = error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
