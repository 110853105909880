<template>
  <main class="container">
    <v-dialog v-model="add" transition="dialog-top-transition" max-width="480">
      <template v-slot:default="dialog">
        <v-form ref="formadd" @submit.prevent="add">
          <v-card>
            <v-card-text>
              <div class="text-center">
                <v-snackbar
                  v-model="addsucces"
                  :timeout="timeout"
                  absolute
                  top
                  color="#1e824c"
                >
                  <span style="color: white">
                    {{ $t("ville.update") }}
                  </span>
                  <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="addsucces = false">
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </div>
              <v-dialog v-model="avert" max-width="400">
                <v-card>
                  <v-card-title class="headline">Avertissement</v-card-title>
                  <v-card-text>
                    Cet utilisateur est déjà affecté à une kiosk.
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="avert = false">OK</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <div class="row">
                <div class="col-md-6">
                  <v-text-field
                    v-model="nom"
                    name="nom"
                    type="text"
                    ref="clear"
                    label="Nom"
                    prepend-inner-icon="mdi-alphabetical"
                    outlined
                    :rules="[rules.required]"
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="adresse"
                    name="adresse"
                    type="text"
                    ref="clear1"
                    label="adresse"
                    prepend-inner-icon="mdi-alphabetical"
                    outlined
                    :rules="[rules.required]"
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="tel"
                    name="telephone"
                    type="text"
                    label="telephone"
                    ref="clear2"
                    prepend-inner-icon="mdi-phone"
                    outlined
                    :rules="[rules.required]"
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="username"
                    :rules="usernameRules"
                    prepend-inner-icon="mdi-account"
                    name="username"
                    :label="$t('headers.username')"
                    type="text"
                    @input="existusername"
                    clearable
                    ref="clear3"
                    outlined
                    dense
                    :error-messages="messages"
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show1 = !show1"
                    :type="show1 ? 'text' : 'password'"
                    prepend-inner-icon="mdi-key"
                    name="password"
                    outlined
                    ref="clear4"
                    dense
                    :label="$t('headers.password')"
                    clearable
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    prepend-inner-icon="mdi-email"
                    name="email"
                    :label="$t('headers.email')"
                    type="email"
                    ref="clear5"
                    outlined
                    :error-messages="messagesemail"
                    @input="existemail"
                    dense
                    clearable
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-autocomplete
                    v-model="city"
                    prepend-inner-icon="mdi-earth"
                    :items="villeList"
                    item-text="nom"
                    item-value="id"
                    ref="clear6"
                    label="ville"
                    :rules="[rules.required]"
                    return-object
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="institution"
                    name="recepteur"
                    type="text"
                    label="Nom de la banque"
                    ref="clear7"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="intitule"
                    name="recepteur"
                    type="text"
                    ref="clear8"
                    label="Intituler du compte"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="numcompte"
                    name="recepteur"
                    type="text"
                    ref="clear9"
                    label="Numero du compte"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div>
                  <v-text-field
                    v-model="pin"
                    name="pin"
                    type="number"
                    ref="clear10"
                    label="code pin"
                    :rules="pinRules"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                @click="
                  dialog.value = false;
                  reset();
                "
                >Close</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="#2b2e83" @click="addkiosk" style="color: white"
                >enregistré</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
    <v-dialog v-model="modif" transition="dialog-top-transition" max-width="480">
      <template v-slot:default="dialog">
        <v-form ref="formupdate" @submit.prevent="update">
          <v-card>
            <v-card-text>
              <div class="row">
                <div class="col-md-6">
                  <v-text-field
                    v-model="nom"
                    name="nom"
                    type="text"
                    ref="clear"
                    label="Nom"
                    prepend-inner-icon="mdi-alphabetical"
                    outlined
                    :rules="[rules.required]"
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="adresse"
                    name="adresse"
                    type="text"
                    ref="clear1"
                    label="adresse"
                    prepend-inner-icon="mdi-alphabetical"
                    outlined
                    :rules="[rules.required]"
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="tel"
                    name="telephone"
                    type="text"
                    label="telephone"
                    prepend-inner-icon="mdi-phone"
                    outlined
                    ref="clear2"
                    :rules="[rules.required]"
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="username"
                    :rules="usernameRules"
                    prepend-inner-icon="mdi-account"
                    name="username"
                    :label="$t('headers.username')"
                    type="text"
                    @input="existusername"
                    clearable
                    ref="clear3"
                    outlined
                    dense
                    :error-messages="messages"
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    prepend-inner-icon="mdi-email"
                    name="email"
                    :label="$t('headers.email')"
                    type="email"
                    ref="clear4"
                    outlined
                    :error-messages="messagesemail"
                    @input="existemail"
                    dense
                    clearable
                  ></v-text-field>
                </div>
                <div class="col-md-6">
                  <v-autocomplete
                    v-model="city"
                    prepend-inner-icon="mdi-earth"
                    :items="villeList"
                    item-text="nom"
                    item-value="id"
                    ref="clear5"
                    label="ville"
                    :rules="[rules.required]"
                    return-object
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="institution"
                    name="recepteur"
                    type="text"
                    label="Nom de la banque"
                    ref="clear6"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="intitule"
                    name="recepteur"
                    type="text"
                    ref="clear7"
                    label="Intituler du compte"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-12">
                  <v-text-field
                    v-model="numcompte"
                    name="recepteur"
                    type="text"
                    ref="clear8"
                    label="Numero du compte"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                @click="
                  dialog.value = false;
                  reset2();
                "
                >Close</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="yellow accent-9" @click="update">update</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-9 mb-4">
        <div class="col-md-5 mb-4">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-1 shadow-sm rounded row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <div class="col-md-4">
                <v-toolbar-title>{{ $t("kiosk.list") }}</v-toolbar-title>
              </div>
              <div class="col-md-8 d-flex mt-4 justify-content-end">
                <v-btn fab small color="#2d2d2d">
                  <!-- <v-btn fab small color="#26a151"> -->
                  <v-icon color="white" @click="add = true"> mdi-plus </v-icon>
                </v-btn>
              </div>
            </v-toolbar>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { baseURL } from "../../../main";
import kiosk from "../../../store/modules/kiosk";

export default {
  data() {
    return {
      id: "",
      nom: "",
      adresse: "",
      city: "",
      tel: "",
      modif: false,
      role: "",
      country: "",
      show1: "",
      avert: false,
      institution: "",
      intitule: "",
      numcompte: "",
      codeCvc: "",
      nom: "",
      Roles: "",
      username: "",
      email: "",
      password: "",
      allpay: "",
      prenom: "",
      tel: "",
      ville: "",
      pin: "",
      messages: null,
      messagestel: null,
      messagesemail: null,
      add: false,
      addsucces: false,
      items: [],
      snackbar: false,
      timeout: 2000,
      loading: false,
      search: null,
      current_page: 1,

      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },

      headers: [
        {
          text: "Nom",
          align: "center",
          sortable: false,
          value: "nom",
        },
        {
          text: this.$t("ville.ville"),
          align: "center",
          sortable: false,
          value: "ville.nom",
        },
        {
          text: this.$t("pays.pays"),
          align: "start",
          sortable: false,
          value: "ville.pays.nom",
        },
        {
          text: this.$t("headers.agent"),
          align: "start",
          sortable: false,
          value: "users[0].nom",
        },
        {
          text: this.$t("headers.intitule"),
          align: "start",
          sortable: false,
          value: "intituleAgence",
        },
        {
          text: this.$t("headers.institution"),
          align: "start",
          sortable: false,
          value: "institutionAgence",
        },
        {
          text: this.$t("headers.numcompte"),
          align: "start",
          sortable: false,
          value: "numCompteAgence",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["token", "kiosk", "villebypays", "allpays", "me", "userrole"]),
    usernameRules() {
      return [
        (v) => !!v || this.$t("require.username"),
        (v) =>
          /^(?=.*[a-zA-Z].*)(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/.test(v) ||
          this.$t("rule.username"),
      ];
    },
    passwordRules() {
      return [
        (v) => !!v || this.$t("require.pass"),
        (v) =>
          /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()–[{}]).{6,40}/.test(v) ||
          this.$t("rule.pass"),
      ];
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("require.email"),
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "Adresse Email non valide",
      ];
    },
    pinRules() {
      return [
        (v) => !!v || this.$t("require.pin"),
        (v) => /^\d{6}$/.test(v) || this.$t("rule.pin"),
      ];
    },
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.kiosks && this.kiosks.last_page) {
        return this.kiosks.last_page;
      } else {
        return 0;
      }
    },
    villeList() {
      if (this.villebypays != null) {
        return this.villebypays;
      } else {
        return [];
      }
    },
    itemList() {
      if (this.kiosk != null && this.kiosk.data) {
        return this.kiosk.data.filter((item) => item.ville.pays.id == this.me.pays.id);
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    country() {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("getvillebypays", this.country.id);
    },
    search() {
      if (this.search == "" || this.search.trim() == null) {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getallkiosk", this.current_page);
      }
    },
  },
  methods: {
    async existemail() {
      if (this.email) {
        await axios.get(baseURL + "auth/check/email/" + this.email).then((response) => {
          if (response.data == true) {
            this.messagesemail = "cet adresse email existe deja";
          } else {
            this.messagesemail = null;
          }
        });
      }
    },
    async existtel() {
      if (this.tel) {
        await axios.get(baseURL + "auth/check/tel/" + this.tel).then((response) => {
          if (response.data == true) {
            this.messagestel = "ce nom  existe deja";
          } else {
            this.messagestel = null;
          }
        });
      }
    },
    async existusername() {
      if (this.username) {
        await axios
          .get(baseURL + "auth/check/username/" + this.username)
          .then((response) => {
            if (response.data == true) {
              this.messages = "ce nom d'utilisateur existe deja";
            } else {
              this.messages = null;
            }
          });
      }
    },
    ici(row) {
      localStorage.setItem("tk", this.token);
      // this.$store.dispatch("getuserbyrole", row.user.role.id);
      this.modif = true;
      this.id = row.id;
      this.nom = row.nom;
      this.adresse = row.adresse;
      this.email = row.users[0].email;
      this.username = row.users[0].username;
      this.tel = row.telephone;
      this.city = row.ville.id;
      this.institution = row.institutionAgence;
      this.intitule = row.intituleAgence;
      this.numcompte = row.numCompteAgence;
    },
    reset() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
      this.$refs.clear2.reset();
      this.$refs.clear3.reset();
      this.$refs.clear4.reset();
      this.$refs.clear5.reset();
      this.$refs.clear6.reset();
      this.$refs.clear7.reset();
      this.$refs.clear8.reset();
      this.$refs.clear9.reset();
      this.add = false;
    },
    reset2() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
      this.$refs.clear2.reset();
      this.$refs.clear3.reset();
      this.$refs.clear4.reset();
      this.$refs.clear5.reset();
      this.$refs.clear6.reset();
      this.$refs.clear7.reset();
      this.$refs.clear8.reset();
      this.add = false;
    },
    displayUser(item) {
      return item.nom + " - " + item.prenom;
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getallkiosk", this.current_page);
      } else {
        this.$store.dispatch("searchallkiosk", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    update() {
      if (this.$refs.formupdate.validate()) {
        if (this.city.id === undefined) {
          const update = {};
          update.nom = this.nom;
          update.adresse = this.adresse;
          update.telephone = this.tel;
          update.ville = this.city;
          update.email = this.email;
          update.pays = this.me.pays.id;
          update.username = this.username;
          update.institutionAgence = this.institution;
          update.intituleAgence = this.intitule;
          update.numCompteAgence = this.numcompte;
          console.log("Toutes les variables sont undefined");
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("updatekiosk", { id: this.id, value: update }).then(() => {
            const status = localStorage.getItem("status");
            if (status == 200) {
              this.modif = false;
              this.reset2();
              localStorage.setItem("tk", this.token);
              this.$store.dispatch("getkiosk", this.current_page);
            }
          });
        } else {
          const update = {};
          update.nom = this.nom;
          update.adresse = this.adresse;
          update.telephone = this.tel;
          update.ville = this.city;
          update.email = this.email;
          update.pays = this.me.pays.id;
          update.username = this.username;
          update.institutionAgence = this.institution;
          update.intituleAgence = this.intitule;
          update.numCompteAgence = this.numcompte;
          update.pin = this.pin;
          console.log("Toutes les variables sont définies");
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("updatekiosk", { id: this.id, value: update }).then(() => {
            const status = localStorage.getItem("status");
            if (status == 200) {
              this.modif = false;
              this.reset2();
              localStorage.setItem("tk", this.token);
              this.$store.dispatch("getkiosk", this.current_page);
            }
          });
        }
      }
    },
    addkiosk() {
      if (this.$refs.formadd.validate()) {
        const add = {};
        add.nom = this.nom;
        add.adresse = this.adresse;
        add.telephone = this.tel;
        add.ville = this.city.id;
        add.email = this.email;
        add.username = this.username;
        add.password = this.password;
        add.pays = this.me.pays.id;
        add.institutionAgence = this.institution;
        add.intituleAgence = this.intitule;
        add.numCompteAgence = this.numcompte;
        add.pin = this.pin;
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("addnewkiosk", add).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.add = false;
            this.reset();
            this.addsucces = false;
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getkiosk", this.current_page);
          }
        });
      }
    },
    searchresult() {
      if (this.search) {
        if (this.search.trim() == "") {
          localStorage.setItem("tk", this.token);
          this.current_page = 1;
          this.$store.dispatch("getallkiosk", this.current_page);
        } else {
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("searchallkiosk", {
            page: this.current_page,
            search: this.search.trim(),
          });
          if (this.$store.state.kiosk.kiosks.data) {
            this.current_page = this.$store.state.kiosk.kiosks.current_page;
          } else this.current_page = 1;
        }
      }
    },
  },
  mounted() {
    // this.$store.state.user.userrole = [];
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getme");
    this.$store.dispatch("getkiosk", this.current_page);
    this.$store.dispatch("getvillebypays", this.me.pays.id);
  },
  created() {
    // console.log(this.$store.state.user.users.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
</style>
