<template>
  <main>
    <div class="container">
      <header
        class="d-flex flex-wrap justify-content-between mb-4 border-bottom"
      >
        <router-link to="/">
          <a class="d-flex align-items-center text-dark text-decoration-none">
            <img src="../assets/logo.png" class="logo" alt="" />
          </a>
        </router-link>
        <div class="text-end">
          <router-link to="/login">
            <button type="button" class="log btn btn-outline me-2 mt-6">
              {{ $t("log") }}
            </button>
          </router-link>
          <select
            style="color: black"
            class="btn me-2 mt-6"
            aria-label="Default select example"
            v-model="langue"
            @change="changelanguage()"
          >
            <option value="fr">FR</option>
            <option value="en">EN</option>
          </select>
        </div>
      </header>
    </div>
    <body class="container">
      <h1>{{ $t("condition.1") }}</h1>
      <h1>{{ $t("condition.titre") }}</h1>
      <h4>Dernière mise à jour: {{ date_jour }}</h4>
      <p>
        {{ $t("condition.x1") }} <br />
        {{ $t("condition.2") }}
      </p>
      <h2>{{ $t("condition.x2") }}</h2>
      <p>{{ $t("condition.x2t") }}</p>
      <ul>
        <li>{{ $t("condition.x2c") }}</li>
        <li>{{ $t("condition.x2c1") }}</li>
      </ul>
      <h2>{{ $t("condition.x3") }}</h2>
      <p>{{ $t("condition.x3t") }}</p>
      <ul>
        <li>{{ $t("condition.x3c") }}</li>
        <li>{{ $t("condition.x3c1") }}</li>
      </ul>

      <h2>{{ $t("condition.x4") }}</h2>
      <p>{{ $t("condition.x4t") }}</p>

      <h2>{{ $t("condition.x5") }}</h2>
      <p>{{ $t("condition.x5t") }}</p>
      <ul>
        <li>{{ $t("condition.x5c") }}</li>
        <li>{{ $t("condition.x5c1") }}</li>
        <li>{{ $t("condition.x5c2") }}</li>
      </ul>

      <h2>{{ $t("condition.x6") }}</h2>
      <p>{{ $t("condition.x6t") }}</p>

      <h2>{{ $t("condition.3") }}</h2>
      <ul>
        <li>
          {{ $t("condition.4") }}
        </li>
        <li>
          {{ $t("condition.5") }}
        </li>
        <li>
          {{ $t("condition.6") }}
        </li>
      </ul>
      <h2>{{ $t("condition.7") }}</h2>
      <p>
        {{ $t("condition.8") }}
      </p>
      <ul>
        <li>
          {{ $t("condition.9") }}
        </li>
        <li>
          {{ $t("condition.10") }}
        </li>
        <li>
          {{ $t("condition.11") }}
        </li>
        <li>
          {{ $t("condition.12") }}
        </li>
      </ul>
      <h2>{{ $t("condition.13") }}</h2>
      <p>
        {{ $t("condition.14") }}
      </p>
      <h2>{{ $t("condition.15") }}</h2>
      <p>
        {{ $t("condition.16") }}
      </p>
      <h2>{{ $t("condition.17") }}</h2>
      <p>
        {{ $t("condition.18") }}
      </p>
      <h2>{{ $t("condition.19") }}</h2>
      <p>
        {{ $t("condition.20") }}
      </p>
      <p>
        {{ $t("condition.21") }}
      </p>
      <p>
        {{ $t("condition.22") }}
      </p>
      <p>
        {{ $t("condition.23") }}
        <a href="mailto:contact@s2atechnology.com">
          contact@s2atechnology.com.
        </a>
      </p>
    </body>
    <Footer />
  </main>
</template>
<script>
import Footer from "../components/Footer.vue";
export default {
  components: { Footer },
  data() {
    return {
      date_jour: "16-08-2024",
    };
  },
  created() {
    if (localStorage.getItem("lang") == null) {
      localStorage.setItem("lang", "fr");
    }
    this.langue = localStorage.getItem("lang");
  },
  methods: {
    changelanguage() {
      localStorage.setItem("lang", this.langue);
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.log {
  background-color: #2b2e83;
  color: white;
}
.logo {
  width: 8rem;
}
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 13px 10px 2px rgba(0, 0, 0, 0.2);
}

h1 {
  font-size: 2.5em;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
  margin-top: 30px;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 20px;
}

ul {
  list-style-type: disc;
  margin: 20px 0;
  padding-left: 20px;
}

ul li {
  margin: 5px 0;
  line-height: 1.5;
}

li {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
