<template>
  <main class="container">
    <v-dialog v-model="modif" transition="dialog-top-transition" max-width="380">
      <template v-slot:default="dialog">
        <v-form ref="formupdate" @submit.prevent="update">
          <v-card>
            <v-card-text>
              <div class="text-center">
                <v-snackbar
                  v-model="updatesucces"
                  :timeout="timeout"
                  absolute
                  top
                  color="#1e824c"
                >
                  {{ $t("pays.modif") }}

                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="black"
                      text
                      v-bind="attrs"
                      @click="updatesucces = false"
                    >
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </div>
              <v-text-field
                v-model="nom"
                name="nom"
                type="text"
                outlined
                clearable
                :rules="[rules.required]"
                dense
              >
              </v-text-field>
              <v-text-field
                v-model="indicatif"
                name="indicatif"
                type="text"
                label="indicatif"
                outlined
                :rules="[rules.required]"
                clearable
                dense
              >
              </v-text-field>
              <v-text-field
                v-model="code"
                name="code"
                type="text"
                label="code"
                outlined
                :rules="[rules.required]"
                clearable
                dense
              >
              </v-text-field>
              <v-autocomplete
                v-model="devise"
                name="devise"
                type="text"
                :items="itemdevise"
                outlined
                :rules="[rules.required]"
                item-text="libelle"
                item-value="id"
                return-object
                label="devise"
                dense
              >
              </v-autocomplete>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="dialog.value = false">Close</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="yellow accent-8" @click="update">update</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-9 mb-4">
        <div class="col-md-5 mb-4">
          <div class="text-center">
            <v-snackbar
              v-model="delsucces"
              :timeout="timeout"
              absolute
              top
              color="#1e824c"
            >
              {{ $t("dialog.delete") }}

              <template v-slot:action="{ attrs }">
                <v-btn color="black" text v-bind="attrs" @click="updatesucces = false">
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </div>
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          class="elevation-2 row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("pays.list") }}</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <td class="ms-2">
              <v-icon color="#fef160" @click="ici(item)">mdi-pencil</v-icon>
            </td>
            <td>
              <v-icon color="red" @click="remove(item)" :disabled="item.devise == null"
                >mdi-eraser</v-icon
              >
            </td>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      id: "",
      nom: "",
      indicatif: "",
      code: "",
      devise: "",
      Roles: "",
      username: "",
      modif: false,
      delsucces: false,
      items: [],
      snackbar: false,
      timeout: 2000,
      loading: false,
      search: null,
      current_page: 1,
      updatesucces: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: "Id",
          align: "center",
          sortable: false,
          value: "id",
        },
        {
          text: this.$t("headers.nom"),
          align: "center",
          sortable: false,
          value: "nom",
        },
        {
          text: this.$t("headers.code"),
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: this.$t("headers.indicatif"),
          align: "start",
          sortable: false,
          value: "indicatif",
        },
        {
          text: this.$t("headers.devise"),
          align: "start",
          sortable: false,
          value: "devise.libelle",
        },
        {
          text: this.$t("headers.action"),
          align: "start",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["token", "pays", "devises"]),
    itemdevise() {
      if (this.devises != null) {
        return this.devises;
      } else {
        return [];
      }
    },
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.pays && this.pays.last_page) {
        return this.pays.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.pays != null && this.pays.data) {
        return this.pays.data;
      } else {
        return [];
      }
    },
    itemListtriedevise() {
      return this.itemList.map((item) => ({
        ...item,
        devise: item.devise || "devise non définie",
      }));
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    // search() {
    //   if (this.search == "" || this.search.trim() == null) {
    //     localStorage.setItem("tk", this.token);
    //     this.$store.dispatch("getpays", this.current_page);
    //   }
    // },
    search(newVal) {
      if (newVal.trim() == "") {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getpays", this.current_page);
      } else {
        localStorage.setItem("tk", this.token);
        this.$store
          .dispatch("searchpays", {
            page: this.current_page,
            search: this.search.trim(),
          })
          .then(() => {
            this.searchresult();
          });
      }
    },
  },
  methods: {
    remove(item) {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("removecountrydevise", item.id).then(() => {
        const statuts = localStorage.getItem("status");
        if (statuts == 200) {
          this.updatesucces = true;
          const timer = () => {
            this.delsucces = true;
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getpays", this.current_page);
          };
          setTimeout(timer, 1 * 1000, 1);
        }
      });
    },
    formatDevises(item) {
      return item.devise ? item.devise : "devise non définie";
    },
    ici(row) {
      this.id = row.id;
      if (row.devise == null) {
        this.modif = true;
        this.nom = row.nom;
        this.indicatif = row.indicatif;
        this.code = row.code;
        this.devise = "";
      } else {
        this.modif = true;
        this.nom = row.nom;
        this.indicatif = row.indicatif;
        this.code = row.code;
        this.devise = row.devise.id;
      }
    },
    update() {
      if (this.$refs.formupdate.validate()) {
        if (this.devise.id == undefined) {
          const update = {};
          update.code = this.code;
          update.nom = this.nom;
          update.indicatif = this.indicatif;
          update.devise = this.devise;
          localStorage.setItem("tk", this.token);
          this.$store
            .dispatch("updatepays", {
              id: this.id,
              value: update,
            })
            .then(() => {
              const statuts = localStorage.getItem("status");
              if (statuts == 200) {
                this.updatesucces = true;
                const timer = () => {
                  this.modif = false;
                  this.code = "";
                  this.nom = "";
                  this.indicatif = "";
                  this.devise = 0;
                  localStorage.setItem("tk", this.token);
                  this.$store.dispatch("getpays", this.current_page);
                };
                setTimeout(timer, 1 * 1000, 1);
              }
            });
        } else {
          const update = {};
          update.code = this.code;
          update.nom = this.nom;
          update.indicatif = this.indicatif;
          update.devise = this.devise.id;
          localStorage.setItem("tk", this.token);
          this.$store
            .dispatch("updatepays", {
              id: this.id,
              value: update,
            })
            .then(() => {
              const statuts = localStorage.getItem("status");
              if (statuts == 200) {
                this.updatesucces = true;
                const timer = () => {
                  this.modif = false;
                  this.code = "";
                  this.nom = "";
                  this.indicatif = "";
                  this.devise = 0;
                  localStorage.setItem("tk", this.token);
                  this.$store.dispatch("getpays", this.current_page);
                };
                setTimeout(timer, 1 * 1000, 1);
              }
            });
        }
      }
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getpays", this.current_page);
      } else {
        this.$store.dispatch("searchpays", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {
      // if (this.search) {
      //   if (this.search.trim() == "") {
      //     localStorage.setItem("tk", this.token);
      //     this.current_page = 1;
      //     this.$store.dispatch("getpays", this.current_page);
      //   } else {
      //     localStorage.setItem("tk", this.token);
      //     this.$store.dispatch("searchpays", {
      //       page: this.current_page,
      //       search: this.search.trim(),
      //     });
      //     if (this.$store.state.pays.pays.data) {
      //       this.current_page = this.$store.state.pays.pays.current_page;
      //     } else this.current_page = 1;
      //   }
      // }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getpays", this.current_page);
    this.$store.dispatch("getdevise");
  },
  created() {
    // console.log(this.$store.state.user.users.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
</style>
