import axios from "axios";
import { baseURL } from "../../main";

const state=
{
    onepreuve:null,
    allpreuve:null,
    preuve:null,
    rejectpreuve:null,
    updatepreuve:null,
    valide:null,
    preuveagence:null,
    validertransfert:null
};

const getters = {
    onepreuve:(state)=> state.onepreuve,
    allpreuve:(state)=> state.allpreuve,
    updatepreuve:(state)=> state.updatepreuve,
    rejectpreuve:(state)=> state.rejectpreuve,
    preuve:(state)=>state.preuve,
    preuveagence:(state)=>state.preuveagence,
    valide:(state)=>state.valide,
    validertransfert:(state)=>state.validertransfert

};

const actions =
{
  async validertransfert({ commit },id) {
    let token = localStorage.getItem("tk");
     await axios.get(baseURL + "valide/preuve/transfert/"+id, {
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setvalidertransfert", data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  },
    async updatepreuve({commit}, id)
    {
        let token = localStorage.getItem("tk");
        let response = await axios.put(baseURL + id ,{
            headers:{
                Authorization: "Bearer " + token,
                'Content-Type': 'multipart/form-data'
              },
        }).then((response) => 
        {
            if (response == undefined) {
                localStorage.setItem("status", 400);
            } else {
              localStorage.setItem("status", 200);
              let data = response.data;
              localStorage.removeItem("tk");
              commit("setupdate", data);
            } 
        })
    },
    async adduserpreuve({ commit }, value) {
      let token = localStorage.getItem("tk");
      await axios
        .post(baseURL + "preuve", value, {
          headers: {
            Authorization: "Bearer " + token,
            'Content-Type': 'multipart/form-data'
          },
        })
        .then((response) => {
          if (response == undefined) {
              localStorage.setItem("status", 400);
          } else {
            localStorage.setItem("status", 200);
            let data = response.data;
            localStorage.removeItem("tk");
            commit("setpreuve", data);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async rejected({ commit }, value) {
      let token = localStorage.getItem("tk");
      await axios
        .post(baseURL + "annulation", value, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          if (response == undefined) {
              localStorage.setItem("status", 400);
          } else {
            localStorage.setItem("status", 200);
            let data = response.data;
            localStorage.removeItem("tk");
            commit("setreject", data);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    async getvalide({commit},id)
    {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "valide/preuve/" + id, {
          headers: {
            Authorization: "Bearer " + token,
          },
        }).then((response)=>
        {
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setvalide", data);   
        })
    },
    async getvalidetransfert({commit},id)
    {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "valide/preuve/transfert/" + id, {
          headers: {
            Authorization: "Bearer " + token,
          },
        }).then((response)=>
        {
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setvalidertransfert", data);   
        })
    },
    async getpreuvetransfert({commit},page)
    {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "transactions/agent/preuve/page/" + page, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setpreuveagence", data);   
    },
    async getallpreuve({commit},{id,page})
    {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "preuves/agence/page/"+id+"/" + page, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setallpreuve", data);   
    },
    async searchallpreuve({commit}, {page, search})
    {
        let token = localStorage.getItem("tk");
        console.log(baseURL + "preuves/search/page/" + page + "/" + search);
        let response = await axios.get(
          baseURL + "preuves/search/page/" + page + "/" + search,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        ).then(response => {
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setallpreuve", data);
        })
    },

};

const mutations =
{
    setupdates(state, updatepreuve)
    {
        state.updatepreuve= updatepreuve
    },
    setallpreuve(state, allpreuve)
    {
        state.allpreuve=allpreuve
    },
    setpreuveagence(state, preuveagence)
    {
        state.preuveagence=preuveagence
    },
    setreject(state,rejectpreuve)
    {
      state.rejectpreuve=rejectpreuve
    },
    setvalide(state,valide)
    {
      state.valide=valide
    },
    setvalidertransfert(state,validertransfert)
    {
      state.validertransfert=validertransfert
    },
    setpreuve(state, preuve)
    {
      state.preuve= preuve
    }
};

export default {
    state,
    getters,
    mutations,
    actions
}