import axios from "axios";
import { baseURL } from "../../main";
import router from "../../router/router";

// export const state = initialState;
const state = {
  resetmail: null,
  resetpassword: null,
  checktoken: null,
};

const getters = {
  resetmail: (state) => state.resetmail,
  resetpassword: (state) => state.resetpassword,
  checktoken: (state) => state.checktoken,
};
const actions = {
  async sendmail({ commit }, value) {
    await axios
      .post(baseURL + "auth/forgot/password", value, {
        timeout: 30000,
      })
      .then((response) => {
        if (response === undefined) {
          console.log(response)
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          commit("setmailsend", data);
          localStorage.removeItem("tk");
        }
      });
  },
  async resetspassword({ commit }, { token, value }) {
    await axios
      .put(baseURL + "auth/update/password/" + token, value, {})
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setresetpassword", data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async checkResetToken({ commit }, { resetKey, next }) {
    await axios
      .get(baseURL + "auth/expire/token/" + resetKey, {})
      .then((response) => {
        if (response.data === false) {
          localStorage.setItem("status", 400);
          next("/reset");
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setchecktoken", data);
          next();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
const mutations = {
  setmailsend(state, resetmail) {
    state.resetmail = resetmail;
  },
  setresetpassword(state, resetpassword) {
    state.resetpassword = resetpassword;
  },
  setchecktoken(state, checktoken) {
    state.checktoken = checktoken;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
