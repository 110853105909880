import axios from "axios";
import { baseURL } from "../../main";

const state = {
  transfertintra: null,
  transfertall: null,
  transfert: null,
  searchalltransfert: null,
  valid: null,
  benefice:null,
};

const getters = {
  transfert: (state) => state.transfert,
  valid: (state) => state.valid,
  transfertall: (state) => state.transfertall,
  transfertintra: (state) => state.transfertintra,
  transfertextra: (state) => state.transfertextra,
  searchalltransfert: (state) => state.searchalltransfert,
  benefice: (state) => state.benefice,
};

const actions = {
  async gettransfert({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "transactions/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("settransfert", data);
  },
  async searchalltransfert({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "transactions/search/page/" + page + "/" + search,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("settransfert", data);
  },
  async getbenefice({ commit }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "transactions/agent/benefice", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setbenefice", data);
  },
  async gettransfertagent({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "transactions/agent/page/" + page,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("settransfertagent", data);
  },
  async searchtransfertagent({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "transactions/agent/search/page/" + page + "/" + search,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("settransfertagent", data);
  },
  async gettransfertclient({ commit }, page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "transactions/user/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("settransfertclient", data);
  },
  async searchtransfertclient({ commit }, { page, search }) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(
      baseURL + "transactions/user/search/page/" + page + "/" + search,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    let data = response.data;
    localStorage.removeItem("tk");
    commit("settransfertclient", data);
  },
  async transfertintraclientrecupbanque({ commit }, value) {
    try {
      let token = localStorage.getItem("tk");
      let response = await axios.post(
        baseURL + "transaction/intrazone/client/banque",
        value,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      if (response === undefined) {
        localStorage.setItem("status", 400);
      } else if (response.status == 500) {
        localStorage.setItem("status", 500);
      } else {
        localStorage.setItem("status", 200);
        let data = response.data;
        localStorage.removeItem("tk");
        commit("settransfertintraclient", data);
      }
    } catch (error) {
      console.log(error.message);
    }
  },
  async transfertintraagent({ commit }, value) {
    try {
      let token = localStorage.getItem("tk");
      let response = await axios.post(
        baseURL + "transaction/intrazone/portefeuille/agent",
        value,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response === undefined) {
        localStorage.setItem("status", 400);
      } else if (response.status == 500) {
        localStorage.setItem("status", 500);
      } else {
        localStorage.setItem("status", 200);
        let data = response.data;
        localStorage.removeItem("tk");
        commit("settransfertintraagent", data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async transfertintraagentbanque({ commit }, value) {
    try {
      let token = localStorage.getItem("tk");
      let response = await axios.post(
        baseURL + "transaction/intrazone/portefeuille/agent",
        value,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response === undefined) {
        localStorage.setItem("status", 400);
      } else if (response.status == 500) {
        localStorage.setItem("status", 500);
      } else {
        localStorage.setItem("status", 200);
        let data = response.data;
        localStorage.removeItem("tk");
        commit("settransfertintraagent", data);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async transfertintraclientbanque({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "transaction/intrazone/portefeuille/client", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("settransfertintraclient", data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  async transfertintraclient({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "transaction/intrazone/portefeuille/client", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("settransfertintraclient", data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  async transfertextraclient({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "transaction/extrazone/portefeuille/client", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("settransfertextraclient", data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  async transfertextraclientbanque({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "transaction/extrazone/portefeuille/client", value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("settransfertextraclient", data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  async transfertextraagent({ commit }, value) {
    try {
      let token = localStorage.getItem("tk");
      let response = await axios.post(
        baseURL + "transaction/extrazone/portefeuille/agent",
        value,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response === undefined) {
        localStorage.setItem("status", 400);
      } else if (response.status == 500) {
        localStorage.setItem("status", 500);
      } else {
        localStorage.setItem("status", 200);
        let data = response.data;
        localStorage.removeItem("tk");
        commit("settransfertextraagent", data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async transfertextraagentbanque({ commit }, value) {
    try {
      let token = localStorage.getItem("tk");
      let response = await axios.post(
        baseURL + "transaction/extrazone/portefeuille/agent",
        value,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response === undefined) {
        localStorage.setItem("status", 400);
      } else if (response.status == 500) {
        localStorage.setItem("status", 500);
      } else {
        localStorage.setItem("status", 200);
        let data = response.data;
        localStorage.removeItem("tk");
        commit("settransfertextraagent", data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async validtransfert({ commit }, id) {
    let token = localStorage.getItem("tk");
    await axios
      .get(baseURL + "transaction/valider/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response == undefined) {
          localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setvalid", data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  async transfertextraclientrecupbanque({ commit }, value) {
    try {
      let token = localStorage.getItem("tk");
      let response = await axios.post(
        baseURL + "transaction/extrazone/client/banque",
        value,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      if (response === undefined) {
        localStorage.setItem("status", 400);
      } else if (response.status == 500) {
        localStorage.setItem("status", 500);
      } else {
        localStorage.setItem("status", 200);
        let data = response.data;
        localStorage.removeItem("tk");
        commit("settransfertextraclient", data);
      }
    } catch (error) {
      console.log(error.message);
    }
  },
  async gettransactionbynum({commit},value)
  {
      let token = localStorage.getItem("tk");
      let response = await axios.get(baseURL + "transaction/find/" + value, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      console.log(baseURL + "transaction/find/" + value)
      let data = response.data;
    
        localStorage.removeItem("tk");
        commit("settransfertagent", data);   
  },
  getcleartransfert({commit}) {
    commit("setcleartransfert");
  },
};

const mutations = {
  setcleartransfert(state) {
    state.transfert = null;
  },
  settransfert(state, transfertall) {
    state.transfertall = transfertall;
  },
  setbenefice(state, benefice) {
    state.benefice = benefice;
  },
  setvalid(state, valid) {
    state.valid = valid;
  },
  settransfertagent(state, transfert) {
    state.transfert = transfert;
  },
  settransfertintraagent(state, transfertintra) {
    state.transfertintra = transfertintra;
  },
  settransfertextraagent(state, transfertextra) {
    state.transfertextra = transfertextra;
  },
  settransfertclient(state, transfert) {
    state.transfert = transfert;
  },
  settransfertintraclient(state, transfert) {
    state.transfert = transfert;
  },
  settransfertextraclient(state, transfert) {
    state.transfert = transfert;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
