<template>
  <main class="container">
    <v-dialog v-model="add" transition="dialog-top-transition" max-width="650">
      <template v-slot:default="dialog">
        <v-form ref="formadd" @submit.prevent="add">
          <v-card>
            <v-card-text>
              <div class="text-center">
                <v-snackbar
                  v-model="addsucces"
                  :timeout="timeout"
                  absolute
                  top
                  color="#1e824c"
                >
                  <span style="color: white">
                    {{ $t("ville.update") }}
                  </span>
                  <template v-slot:action="{ attrs }">
                    <v-btn color="red" text v-bind="attrs" @click="addsucces = false">
                      Close
                    </v-btn>
                  </template>
                </v-snackbar>
              </div>
              <v-dialog v-model="avert" max-width="400">
                <v-card>
                  <v-card-title class="headline">{{ $t("agence.avert") }}</v-card-title>
                  <v-card-text>
                    {{ $t("agence.avert1") }}
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="avert = false">OK</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <div class="row">
                <div class="col-md-6">
                  <v-text-field
                    v-model="nom"
                    name="nom"
                    type="text"
                    ref="clear"
                    label="Nom"
                    prepend-inner-icon="mdi-alphabetical"
                    outlined
                    :rules="[rules.required]"
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="adresse"
                    name="adresse"
                    type="text"
                    ref="clear1"
                    label="adresse"
                    prepend-inner-icon="mdi-alphabetical"
                    outlined
                    :rules="[rules.required]"
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="tel"
                    name="telephone"
                    type="text"
                    ref="clear2"
                    label="telephone"
                    prepend-inner-icon="mdi-phone"
                    outlined
                    :rules="[rules.required]"
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-autocomplete
                    v-model="city"
                    prepend-inner-icon="mdi-earth"
                    :items="itemVille"
                    item-text="nom"
                    item-value="id"
                    ref="clear3"
                    label="ville"
                    :rules="[rules.required]"
                    return-object
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </div>
                <div class="col-md-6">
                  <v-autocomplete
                    v-if="userrole"
                    v-model="role"
                    @change="check"
                    prepend-inner-icon="mdi-earth"
                    :items="itemUser"
                    :item-text="displayUser"
                    item-value="id"
                    ref="clear5"
                    label="Profil utilisateur"
                    :rules="[rules.required]"
                    return-object
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="institution"
                    name="recepteur"
                    type="text"
                    label="Nom de la banque"
                    ref="clear6"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="intitule"
                    name="recepteur"
                    type="text"
                    ref="clear7"
                    label="Intituler du compte"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="numcompte"
                    name="recepteur"
                    type="text"
                    ref="clear8"
                    label="Numero du compte"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                @click="
                  dialog.value = false;
                  reset();
                "
                >Close</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn color="#2b2e83" @click="addagence" style="color: white"
                >{{ $t("agence.save") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
    <v-dialog
      v-model="modif"
      transition="dialog-top-transition"
      max-width="650"
    >
      <template v-slot:default="dialog">
        <v-form ref="formupdate" @submit.prevent="update">
          <v-card>
            <v-card-text>
              <div class="row">
                <div class="col-md-6">
                  <v-text-field
                    v-model="nom"
                    name="nom"
                    type="text"
                    label="Nom"
                    prepend-inner-icon="mdi-alphabetical"
                    outlined
                    ref="clear"
                    :rules="[rules.required]"
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="adresse"
                    name="adresse"
                    type="text"
                    label="adresse"
                    ref="clear1"
                    prepend-inner-icon="mdi-alphabetical"
                    outlined
                    :rules="[rules.required]"
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-text-field
                    v-model="tel"
                    name="telephone"
                    type="text"
                    label="telephone"
                    prepend-inner-icon="mdi-phone"
                    outlined
                    ref="clear2"
                    :rules="[rules.required]"
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div class="col-md-6">
                  <v-autocomplete
                    v-model="city"
                    prepend-inner-icon="mdi-earth"
                    :items="itemVille"
                    item-text="nom"
                    ref="clear3"
                    item-value="id"
                    label="ville"
                    :rules="[rules.required]"
                    return-object
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </div>
                 <div class="col-md-6">
                  <v-text-field
                    v-model="institution"
                    name="recepteur"
                    type="text"
                    label="Nom de la banque"
                    ref="clear4"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                   <div class="col-md-6">
                  <v-text-field
                    v-model="intitule"
                    name="recepteur"
                    type="text"
                    label="Intituler du compte"
                    ref="clear5"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
                <div>
                  <v-text-field
                    v-model="numcompte"
                    name="recepteur"
                    type="text"
                    ref="clear6"
                    label="Numero du compte"
                    outlined
                    clearable
                    dense
                  >
                  </v-text-field>
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn text @click="(dialog.value = false), reset2()">{{ $t("agence.close") }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="yellow accent-9" @click="update">{{ $t("agence.updates") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-9 mb-4">
        <div class="col-md-5 mb-4">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-1 shadow-sm rounded row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <div class="col-md-4">
                <v-toolbar-title class="headline">{{ $t("agence.list") }}</v-toolbar-title>
              </div>
            </v-toolbar>
          </template>
          <template v-slot:[`item.codeCvcAgence`]="{ item }">
            <th>
              <td>
              <v-btn small color="primary" @click="showDetails(item)">
                {{ $t("agence.detail") }}
              </v-btn>
              </td>
            </th>
          </template>
          <template v-slot:[`item.users`]="{ item }">
            <th>
              <td>
                {{ item.users.length }}
              </td>
            </th>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <th>
              <td>
                <v-icon @click="openupdate(item)">mdi-pencil</v-icon>
              </td>
            </th>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="#1A4478"
            x-large
            fab
            dark
            fixed
            bottom
            right
            @click="add = true"
          >
            <v-icon >mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("agence.new") }}</span>
      </v-tooltip>
      <v-dialog v-model="details" transition="dialog-top-transition" max-width="1000">
        <v-card>
          <v-data-table
            hide-default-footer
            :items="userdetails"
            disable-filtering
            disable-pagination
            disable-sort
            mobile-breakpoint="800"
            :headers="headersdetails"
            @click:row="ici"
            class="elevation-1 shadow-sm rounded row-pointer"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <div class="col-md-6 col-sm-8 col-xs-12">
                  <v-toolbar-title class="headline">
                    {{ $t("user.agent") }}
                    </v-toolbar-title>
                </div>
                <div class="col-md-6 col-sm-4 col-xs-6 d-flex mt-4 justify-content-end">
                <v-btn fab small color="#1A4478" @click="adduser=true">
                  <!-- <v-btn fab small color="#26a151"> -->
                  <v-icon color="white"> mdi-plus </v-icon>
                </v-btn>
              </div>
              </v-toolbar>
            </template>
            <template v-slot:[`item.nom`]="{ item }">
              <tr>
                {{ item.nom + "-" + item.prenom }}
              </tr>
          </template>
            <template v-slot:[`item.role`]="{ item }">
              <tr>
                <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                      <v-btn  v-bind="attrs" v-on="on" style="color:white" small  color="#ff5b5b" @click="removeuser(item), deletedDialog=true">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                <span>{{ $t("agence.remove") }}</span>
              </v-tooltip>
              </tr>
          </template>
          </v-data-table>
        </v-card>
      </v-dialog>
      <v-dialog v-model="adduser" transition="dialog-top-transition" max-width="450">
        <v-form ref="formadduser" @submit.prevent="add">
        <v-card>
          <v-card-title class="headline">
              {{$t("agence.user")}}
          </v-card-title>
          <div class="ms-4 me-4 mt-4">
             <v-autocomplete
                    v-if="userrole"
                    v-model="role"
                    @change="check"
                    prepend-inner-icon="mdi-earth"
                    :items="itemUser"
                    :item-text="displayUser"
                    item-value="id"
                    ref="clear5"
                    label="Profil utilisateur"
                    :rules="[rules.required]"
                    return-object
                    outlined
                    dense
                  >
                  </v-autocomplete>
                </div>
        <v-card-actions class="d-flex justify-content-end">
          <v-btn color="#2b2e83" style="color:white" @click="addinguser">{{ $t("action.save") }}</v-btn>
        </v-card-actions>
        </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="deletedDialog"  transition="dialog-top-transition" persistent max-width="450">
      <v-card>
        <v-card-title class="headline">
           <v-icon color="error" >mdi-alert</v-icon>
          {{ $t("agence.confirm") }}
          </v-card-title>
        <v-card-text>
          {{ $t("agence.confirm2") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deletedDialog = false">
            {{ $t("agence.stop") }}
          </v-btn>
          <v-btn color="error" @click="deleted" text>
            {{ $t("agence.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      id: "",
      idagence:"",
      nom: "",
      adresse: "",
      agence: "",
      city: "",
      tel: "",
      adduser:false,
      deletedDialog: false,
      selectedAgence: null,
      detailsHeaders: [
        // vos headers pour le datatable de details
      ],
      modif: false,
      role: "",
      details: false,
      userdetails: {},
      country: "",
      avert: false,
      institution: "",
      intitule: "",
      numcompte: "",
      codeCvc: "",
      add: false,
      addsucces: false,
      items: [],
      snackbar: false,
      timeout: 2000,
      loading: false,
      search: null,
      current_page: 1,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headersdetails: [
        {
          text: this.$t("headers.concat"),
          align: "start",
          sortable: false,
          value: "nom",
        },
        {
          text: this.$t("headers.username"),
          align: "start",
          sortable: false,
          value: "username",
        },
        {
          text: this.$t("headers.telephone"),
          align: "start",
          sortable: false,
          value: "tel",
        },
        {
          text: this.$t("headers.pays"),
          align: "start",
          sortable: false,
          value: "pays.nom",
        },
        {
          text: this.$t("headers.email"),
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: this.$t("headers.actions"),
          align: "start",
          sortable: false,
          value: "role",
        },
      ],
      headers: [
        {
          text: "Nom",
          align: "center",
          sortable: false,
          value: "nom",
        },
        {
          text: this.$t("ville.ville"),
          align: "center",
          sortable: false,
          value: "ville.nom",
        },
        {
          text: this.$t("pays.pays"),
          align: "start",
          sortable: false,
          value: "ville.pays.nom",
        },
        {
          text: this.$t("headers.agent"),
          align: "start",
          sortable: false,
          value: "users",
        },
        {
          text: this.$t("headers.intitule"),
          align: "start",
          sortable: false,
          value: "intituleAgence",
        },
        {
          text: this.$t("headers.institution"),
          align: "start",
          sortable: false,
          value: "institutionAgence",
        },
        {
          text: this.$t("headers.numcompte"),
          align: "start",
          sortable: false,
          value: "numCompteAgence",
        },
        {
          text: this.$t("headers.details"),
          align: "start",
          sortable: false,
          value: "codeCvcAgence",
        },
        {
          text: this.$t("headers.actions"),
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "token",
      "me",
      "agences",
      "villebypays",
      "allpays",
      "userrole",
      "checkuseragence",
    ]),
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.agences && this.agences.last_page) {
        return this.agences.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.agences != null && this.agences.data) {
        return this.agences.data.filter((item) => item.ville.pays.id == this.me.pays.id && item.typeAgence.id==1);
      } else {
        return [];
      }
    },

    itemVille() {
      if (this.villebypays != null) {
        return this.villebypays;
      } else {
        return [];
      }
    },
    itemUser() {
      if (this.userrole != null) {
        return this.userrole.filter((item) => item.pays.id == this.me.pays.id);
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search() {
      if (this.search == "" || this.search.trim() == null) {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getallagence", this.current_page);
      }
    },
  },
  methods: {
    showDetails(item) {
      this.idagence= item.id
      this.userdetails = item.users;
      this.details = true;
      if (this.userdetails != null) {
        return this.userdetails.users;
      } else {
        return [];
      }
    },
    async addinguser()
    {
      const valid= await this.$refs.formadduser.validate();
      if(valid == true){
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("adduserinagence", { A: this.idagence , U:this.role.id}).then(() => {
        const status = localStorage.getItem("status");
        if (status == 200) {
            this.adduser=false
            this.$refs.clear5.reset()
            this.details=false
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getallagence", this.current_page);
             }
      });
    }
    },
    removeuser(item)
    {
      this.id=item.id
    },
    deleted()
    {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("removeuserinagence", { A: this.idagence , U:this.id}).then(() => {
        const status = localStorage.getItem("status");
        if (status == 200) {
            this.deletedDialog=false
            this.details=false
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getallagence", this.current_page);
             }
      });
    },
    ici(row) {
    },

    check() {
      localStorage.setItem("tk", this.token);
      this.$store.dispatch("getcheckuseragence", this.role.id).then(() => {
        const timer = () => {
          if (this.checkuseragence.data == true) {
            this.avert = true;
            this.role = 0;
          }
        };
        setTimeout(timer, 0.1 * 100, 0.1);
      });
    },
    reset() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
      this.$refs.clear2.reset();
      this.$refs.clear3.reset();
      this.$refs.clear4.reset();
      this.$refs.clear5.reset();
      this.$refs.clear6.reset();
      this.$refs.clear8.reset();
      this.$refs.clear9.reset();
      this.add = false;
    },
    reset2() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
      this.$refs.clear2.reset();
      this.$refs.clear3.reset();
      this.$refs.clear4.reset();
      this.$refs.clear5.reset();
      this.$refs.clear6.reset();
    },
    displayUser(item) {
      return item.nom + " " + item.prenom + " / "+ item.username;
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getallagence", this.current_page);
      } else {
        this.$store.dispatch("searchallagence", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    openupdate(item)
    {
      this.modif=true
      this.id=item.id
      this.nom=item.nom
      this.adresse=item.adresse
      this.nom=item.nom;
      this.adresse=item.adresse
      this.tel=item.telephone
      this.city=item.ville.id
      this.institution=item.institutionAgence
      this.intitule=item.intituleAgence
      this.numcompte=item.numCompteAgence

    },
    update() {
      if (this.$refs.formupdate.validate()) {
          const update = {};
          update.nom = this.nom;
          update.adresse = this.adresse;
          update.telephone = this.tel;
          update.ville = this.city;
          update.institutionAgence = this.institution;
          update.intituleAgence = this.intitule;
          update.numCompteAgence = this.numcompte;
          localStorage.setItem("tk", this.token);
          this.$store
            .dispatch("updateagence", { id: this.id, value: update })
            .then(() => {
              const status = localStorage.getItem("status");
              if (status == 200) {
                this.modif = false;
                this.reset2();
                localStorage.setItem("tk", this.token);
                this.$store.dispatch("getallagence", this.current_page);
              }
            });
        }
    },
    addagence() {
      if (this.$refs.formadd.validate()) {
        const add = {};
        add.nom = this.nom;
        add.adresse = this.adresse;
        add.telephone = this.tel;
        add.ville = this.city.id;
        add.user = this.role.id;
        add.typeAgence = 2;
        add.institutionAgence = this.institution;
        add.intituleAgence = this.intitule;
        add.numCompteAgence = this.numcompte;
        add.codeCvcAgence = this.codeCvc;
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("addnewagence", add).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.add = false;
            this.adresse = "";
            this.tel = "";
            this.addsucces = false;
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getallagence", this.current_page);
          }
        });
      }
    },
    searchresult() {
      if (this.search) {
        if (this.search.trim() == "") {
          localStorage.setItem("tk", this.token);
          this.current_page = 1;
          this.$store.dispatch("getallagence", this.current_page);
        } else {
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("searchallagence", {
            page: this.current_page,
            search: this.search.trim(),
          });
          if (this.$store.state.agence.agences.data) {
            this.current_page = this.$store.state.agence.agences.current_page;
          } else this.current_page = 1;
        }
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getuserbyrole", 4);
    this.$store.dispatch("getme");
    this.$store.dispatch("getvillebypays", this.me.pays.id);
    this.$store.dispatch("getallagence", this.current_page);
    this.$store.dispatch("getalltypeagence");
    this.$store.dispatch("getallville");
  },
  created() {
    
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
body {
  font-family: 'SF Pro', sans-serif;
}


/* Dialog */

.dialog h2 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 24px;
}

.dialog p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 24px;
}

</style>
