import axios from "axios";
import { baseURL } from "../../main";
import router from "../../router/router";

// export const state = initialState;
const state = {
  user: null,
  token: null,
  client: null,
  sign:null,
  
};

const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
  client: (state) => state.client,
  sign:(state) =>state.sign
};
const actions = {
  resetState({ commit }) {
    commit("RESET_STATE");
},
  async login({ commit }, value) {
     await axios.post(baseURL + "auth/signin", value, {
      timeout: 30000,
    }).then((response) =>
    {
      if(response == undefined)
      {
        localStorage.setItem("status",400)
      }else
      {
        localStorage.setItem("status",200)
        let data = response.data;
        commit("setuser", data.user);
        commit("settoken", data.token);
      }
    })
  },
  async sign({ commit }, value) {
    let token = localStorage.getItem("tk");
    await axios
      .post(baseURL + "auth/signin/up", value, {
      })
      .then((response) => {
        if (response == undefined) {
            localStorage.setItem("status", 400);
        } else {
          localStorage.setItem("status", 200);
          let data = response.data;
          localStorage.removeItem("tk");
          commit("setsign", data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  async logout({ commit}) {
    if (
      router.currentRoute.matched.some((record) => record.meta.requiresAuth)
    ) {
      commit("settoken", null);
      commit("setuser", null);
      router.push("/");
    }
  },
 
};
const mutations = {
  setuser(state, user) {
    state.user = user;
  },
  setsign(state, sign) {
    state.sign = sign;
  },
  settoken(state, token) {
    state.token = token;
  },
  setclient(state, client) {
    state.client = client;
  },
  RESET_STATE(state) {
    Object.assign(state, {});
},
};

export default {
  state,
  getters,
  actions,
  mutations,
};
