<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <v-dialog v-model="confirm" transition="dialog-top-transition" max-width="420">
        <template v-slot:default="dialog">
          <v-form ref="formvalid" @submit.prevent="update">
            <v-card>
              <v-card-text class="text-center">
                <span class="h1">
                  Valider vous ce <span />
                  <span>transfert ? </span>
                </span>
              </v-card-text>
              <div class="d-flex justify-content-between">
                <v-btn
                  class="mt-2 mb-2"
                  color="#e30613"
                  text
                  @click="dialog.value = false"
                  >non</v-btn
                >
                <v-btn @click="valid()" class="mt-2 mb-2" color="#275462" text>oui</v-btn>
              </div>
            </v-card>
          </v-form>
        </template>
      </v-dialog>
      <v-dialog v-model="add" transition="dialog-top-transition" max-width="720">
        <template v-slot:default="dialog">
          <v-form ref="formadd" @submit.prevent="add">
            <v-card>
              <v-card-text>
                <div class="text-center">
                  <v-snackbar
                    v-model="solde"
                    :timeout="timeout"
                    absolute
                    bottom
                    color="red"
                  >
                    {{ $t("solde.error") }}

                    <template v-slot:action="{ attrs }">
                      <v-btn color="black" text v-bind="attrs" @click="solde = false">
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                </div>
                <div class="text-center">
                  <v-snackbar
                    v-model="pinerror"
                    :timeout="timeout"
                    absolute
                    bottom
                    color="red"
                  >
                    {{ $t("pin.pin") }}

                    <template v-slot:action="{ attrs }">
                      <v-btn color="black" text v-bind="attrs" @click="pinerror = false">
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <v-text-field
                      class="mt-2 ms-2 me-2"
                      v-model="recepteur"
                      name="recepteur"
                      type="text"
                      ref="clear"
                      label="nom et prenom du recepteur"
                      outlined
                      :rules="[rules.required]"
                      clearable
                      dense
                    >
                    </v-text-field>
                  </div>
                  <div class="col-md-6">
                    <v-text-field
                      class="mt-2 ms-2 me-2"
                      v-model="adresse"
                      name="recepteur"
                      type="text"
                      ref="clear1"
                      label="adresse du recepteur"
                      outlined
                      :rules="[rules.required]"
                      clearable
                      dense
                    >
                    </v-text-field>
                  </div>
                  <div class="col-md-6">
                    <v-text-field
                      class="mt-2 ms-2 me-2"
                      v-model="telephone"
                      name="recepteur"
                      type="text"
                      ref="clear2"
                      label="Contact du recepteur"
                      outlined
                      :rules="[rules.required]"
                      clearable
                      dense
                    >
                    </v-text-field>
                  </div>
                  <div class="col-md-6">
                    <v-text-field
                      class="mt-2 ms-2 me-2"
                      v-model="montant"
                      name="indicatif"
                      type="number"
                      ref="clear3"
                      label="Montant"
                      @input="checkmontant"
                      :error-messages="messagemontant"
                      :rules="[rules.required]"
                      step="0.001"
                      outlined
                      clearable
                      dense
                    >
                    </v-text-field>
                  </div>
                </div>
                <div class="row"></div>
                <div class="row">
                  <div class="col-md-6">
                    <v-autocomplete
                      class="mt-2 ms-2 me-2"
                      v-model="provenance"
                      prepend-inner-icon="mdi-earth"
                      :items="villebypays"
                      item-text="nom"
                      item-value="id"
                      ref="clear4"
                      label="Votre ville"
                      :rules="[rules.required]"
                      return-object
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </div>
                  <div class="col-md-6">
                    <v-autocomplete
                      class="mt-2 ms-2 me-2"
                      v-model="paysExp"
                      prepend-inner-icon="mdi-earth"
                      :items="paysList"
                      item-text="nom"
                      ref="clear5"
                      item-value="id"
                      label="Pays du Recepteur"
                      return-object
                      outlined
                      :rules="[rules.required]"
                      dense
                    >
                    </v-autocomplete>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <v-autocomplete
                      class="mt-2 ms-2 me-2"
                      v-model="destination"
                      prepend-inner-icon="mdi-earth"
                      :items="villebypays2"
                      item-text="nom"
                      ref="clear6"
                      item-value="id"
                      label="ville du Recepteur"
                      return-object
                      outlined
                      :rules="[rules.required]"
                      dense
                    >
                    </v-autocomplete>
                  </div>
                  <div class="col-md-6">
                    <v-autocomplete
                      class="mt-2 ms-2 me-2"
                      v-model="moyenrecup"
                      prepend-inner-icon="mdi-earth"
                      :items="recup"
                      item-text="libelle"
                      ref="clear7"
                      item-value="id"
                      label="Moyen de recuperation"
                      return-object
                      outlined
                      dense
                    >
                    </v-autocomplete>
                  </div>
                </div>
                <div class="row" v-show="banc == true">
                  <div class="col-md-6">
                    <v-text-field
                      class="mt-2 ms-2 me-2"
                      v-model="institution"
                      name="recepteur"
                      type="text"
                      label="Nom de la banque"
                      ref="clear8"
                      outlined
                      clearable
                      dense
                    >
                    </v-text-field>
                  </div>
                  <div class="col-md-6">
                    <v-text-field
                      class="mt-2 ms-2 me-2"
                      v-model="intitule"
                      name="recepteur"
                      type="text"
                      ref="clear9"
                      label="Intituler du compte"
                      outlined
                      clearable
                      dense
                    >
                    </v-text-field>
                  </div>
                </div>
                <v-text-field
                  v-show="banc == true"
                  class="mt-2 ms-2 me-2"
                  v-model="numcompte"
                  name="recepteur"
                  type="text"
                  label="Numero du compte"
                  ref="clear10"
                  outlined
                  clearable
                  dense
                >
                </v-text-field>
                <v-text-field
                  class="mt-2 ms-2 me-2"
                  v-model="pin"
                  name="indicatif"
                  type="number"
                  ref="clear11"
                  label="code pin"
                  outlined
                  @blur="checkpins"
                  :error-messages="messagepin"
                  prepend-inner-icon="mdi-numeric"
                  :rules="[rules.required]"
                  clearable
                  dense
                >
                </v-text-field>
                <v-select
                  v-show="moyenrole == true"
                  class="mt-4 ms-2 me-2"
                  dense
                  v-model="selectmoyen"
                  :items="moyenpayment"
                  item-text="libelle"
                  ref="clear12"
                  item-value="id"
                  label="Sélectionner un élément"
                  outlined
                ></v-select>
                <v-autocomplete
                  v-show="banquepayement == true"
                  class="mt-4 ms-2 me-2"
                  v-model="agences"
                  prepend-inner-icon="mdi-earth"
                  :items="agenceList"
                  item-text="nom"
                  item-value="id"
                  ref="clear13"
                  label="Les agences"
                  return-object
                  outlined
                  dense
                >
                </v-autocomplete>
                <v-file-input
                  v-show="banquepayement == true"
                  class="mt-4 ms-2 me-2"
                  accept="image/png, image/jpeg, image/bmp"
                  v-model="preuve"
                  label="preuve"
                  ref="clear14"
                  outlined
                  dense
                ></v-file-input>
              </v-card-text>
              <v-card-actions class="ms-2 ms-2">
                <v-btn text @click="(dialog.value = false), reset()">Close</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="#212e61"
                  style="color: white"
                  @click="addnew"
                  v-show="pay == true"
                  >Enregistrer</v-btn
                >
                <v-btn
                  color="#212e61"
                  style="color: white"
                  @click="addnewwithbanque"
                  v-show="addbanc == true"
                  >Enregistrer</v-btn
                >
                <v-btn
                  color="#212e61"
                  style="color: white"
                  @click="addnewuserwithbanque"
                  v-show="usertransfert == true"
                  >Enregistrer</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
        </template>
      </v-dialog>
      <v-dialog
        v-model="userbanc"
        transition="dialog-bottom-transition"
        persistant
        max-width="320"
      >
        <template v-slot:default="dialog">
          <v-form ref="formuserbanc" @submit.prevent="banc">
            <v-card>
              <v-card-title v-if="agences != null">
                Agence: {{ agences.nom }}
              </v-card-title>
              <v-card-text v-if="agences != null">
                <p>
                  Nom de la banque:
                  <span class="infos">{{ agences.institutionAgence }}</span>
                </p>
                <p>
                  Intitule compte:
                  <span class="infos">{{ agences.intituleAgence }}</span>
                </p>
                <p>
                  Numero du compte:
                  <span class="infos">{{ agences.numCompteAgence }}</span>
                </p>
                <p>
                  Code : <span class="infos">{{ agences.codeCvcAgence }} </span>
                </p>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="dialog.value = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </template>
      </v-dialog>
      <div v-if="one.user.role.id == 3" class="col-lg-9 mb-4">
        <div class="col-sm-6 col-md-5 form-group">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="1600"
          @click:row="ici"
          class="elevation-2 row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("Transaction.Transaction") }}</v-toolbar-title>
              <!-- <div class="col-md-11 d-flex mt-4 justify-content-end">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" fab small color="#2d2d2d">
                      <v-icon @click="popup" color="white"> mdi-bank-transfer </v-icon>
                    </v-btn>
                  </template>
                  <span>Transfert Extrazone</span>
                </v-tooltip>
              </div> -->
            </v-toolbar>
          </template>

          <!-- <template v-slot:[`item`]="{ item }">
            <p>{{ item }}</p>
          </template> -->

          <template v-slot:[`item.dateOperation`]="{ item }">
            <!-- <p v-html="formatDate(item.dateOperation)"></p> -->
            <td>{{ formatDate(item.createdAt) }}</td>
            <!-- <p>{{ formatDate(item.dateOperation).replace(/ /g, '<br />') }}</p> -->
          </template>
          <template v-slot:[`item.typeTransaction.libelle`]="{ item }">
            <!-- <p v-html="formatDate(item.dateOperation)"></p> -->
            <td v-if="item.preuve == null">
              Transaction {{ item.typeTransaction.libelle }} Extrazone
            </td>
            <td v-else>
              Transaction {{ item.typeTransaction.libelle }} Extrazone Bancaire
            </td>
            <!-- <p>{{ formatDate(item.dateOperation).replace(/ /g, '<br />') }}</p> -->
          </template>
          <template v-slot:[`item.valider`]="{ item }">
            <td v-if="item.valider == true">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="green" large>
                    mdi-check-circle-outline
                  </v-icon>
                </template>
                <span>Effectuer </span>
              </v-tooltip>
            </td>
            <td v-else>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="yellow" large>
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <span>En attente </span>
              </v-tooltip>
            </td>
          </template>
          <template v-slot:[`item.montant`]="{ item }">
            <p style="color: red" v-if="item.preuve == null">
              -

              {{ (item.frais + item.montant).toFixed() }}
              {{ item.tauxEchange.deviseExp.libelle }}
            </p>
            <p v-else>
              {{ (item.frais + item.montant).toFixed() }}
              {{ item.tauxEchange.deviseExp.libelle }}
            </p>
          </template>
          <!-- <template v-slot:[`item.numCompte`]="{ item }">
            <th v-if="item.numCompte">
              {{ item.numCompte }}
            </th>
            <td v-else>vide</td>
          </template> -->
          <!--
          <template v-slot:[`item.montant`]="{ item }">
            <td v-if="item.tauxEchange">
              {{
                item.montant * item.tauxEchange.taux +
                " " +
                item.tauxEchange.deviseRec.libelle
              }}
            </td>
            <td v-else>
              {{ item.montant }}
            </td>
          </template>
          <template v-slot:[`item.institution`]="{ item }">
            <th v-if="item.institution">
              {{ item.institution }}
            </th>
            <td v-else>vide</td>
          </template>
          <template v-slot:[`item.intitule`]="{ item }">
            <th v-if="item.intitule">
              {{ item.intitule }}
            </th>
            <td v-else>vide</td>
          </template> -->
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
      <div v-if="one.user.role.id == 4 || one.user.role.id == 5" class="col-lg-9 mb-4">
        <div class="mb-4 d-flex">
          <div class="col-sm-6 col-md-5 form-group">
            <v-toolbar>
              <v-text-field
                class="search"
                v-model.lazy="search"
                single-line
                hide-details
                dense
                label="search"
                @input="searchresult"
                append-icon="mdi-magnify"
              >
              </v-text-field>
            </v-toolbar>
          </div>
          <div v-if="numero" class="col-sm-4 col-md-4 d-flex justify-content-end">
            <v-text-field
              label="Numero de transaction"
              outlined
              readonly
              v-model="numero"
              class="mt-2"
            >
            </v-text-field>
          </div>
        </div>
        <v-card
          color="#2d2d2d"
          dark
          v-if="
            transfert != null &&
            transfert.tauxEchange != null &&
            transfert.destination != undefined &&
            transfert.destination.pays.id == one.user.pays.id
          "
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="">
                <h3>Transaction: Nº{{ transfert.numero }}</h3>

                <h3 class="mb-3">
                  <v-icon large color="#26c281" v-if="transfert.valider == true"
                    >mdi-check-decagram</v-icon
                  >
                  <v-tooltip
                    v-else-if="
                      transfert.valider == false &&
                      transfert.envoyeur.user.id != one.user.id
                    "
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        color="#2B2E83"
                        class="ms-4"
                        fab
                        small
                        right
                      >
                        <v-icon @click="open" color="yellow accent-6" large
                          >mdi-alert-decagram</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Valider le transfert</span>
                  </v-tooltip>
                </h3>
              </v-card-title>
              <v-card-text class="details">
                <p>
                  Expediteur:
                  <span>
                    {{ transfert.expediteur }}
                  </span>
                </p>
                <p>
                  beneficiare:
                  <span>
                    {{ transfert.recepteur }}
                  </span>
                </p>
                <p>
                  Telephone:
                  <span>
                    {{ transfert.telRecepteur }}
                  </span>
                </p>
                <p>
                  Adresse:
                  <span>
                    {{ transfert.adrRecepteur }}
                  </span>
                </p>
                <hr />
                <p>
                  Echange:
                  {{ transfert.tauxEchange.libelle }}
                </p>
                <p>
                  taux:
                  {{ transfert.tauxEchange.taux }}
                </p>
                <hr />
                <div v-if="transfert.institution">
                  <p>
                    Nom de la banque
                    <span>
                      {{ transfert.institution }}
                    </span>
                  </p>
                  <p>
                    Titulaire du compte
                    <span>
                      {{ transfert.intitule }}
                    </span>
                  </p>
                  <p>
                    Numero du compte:
                    <span>
                      {{ transfert.numCompte }}
                    </span>
                  </p>
                </div>
              </v-card-text>
            </div>
            <div class="somme">
              <p>
                {{ transfert.montant * transfert.tauxEchange.taux }}
                {{ transfert.destination.pays.devise.libelle }}
              </p>
            </div>
          </div>
        </v-card>
        <!-- <v-card color="#e6ff6e" v-else-if="transfert != null && transfert.preuve != null">
          <div class="d-flex flex-no-wrap">
            <v-card-title v-show="transfert.preuve.used == false" class="">
            </v-card-title>
            <v-card-text v-show="transfert.preuve.used == false" class="somme">
              <p style="color: #2d2d2d2">Transfert en attente de validation</p>
            </v-card-text>
          </div>
        </v-card> -->
        <v-card color="#2d2d2d" dark v-else-if="transfert == ``">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title> <h3>Transaction: Non trouver</h3></v-card-title>
            </div>
          </div>
        </v-card>
        <v-card color="#2d2d2d" dark v-else-if="transfert == null"> </v-card>
      </div>
      <v-dialog v-model="avert" max-width="400">
        <v-card>
          <v-card-title class="headline">Avertissement</v-card-title>
          <v-card-text> vous ne pouvez pas envoyer de l'argent dans ce pays </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="avert = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="recap" max-width="500" persistent>
        <v-card>
          <v-card-title>
            <h3>INFORMATION PAIEMENT</h3>
          </v-card-title>
          <hr />
          <v-card-text class="recap">
            <p v-show="soldevalid == true" class="lead text-center alert custom">
              Beneficiare: {{ recepteur }}
              <br />
              Adresse: {{ adresse }}
              {{ destination.nom }}
              <br />
              Tel: {{ telephone }}
              <span class="other" v-if="moyenrecup.libelle == `ESPECE`">
                <br />
                A recuperer via Cash
              </span>
              <span v-else>
                <v-icon>mdi-bank</v-icon> {{ institution }}-{{ intitule }}-
                {{ numcompte }}
              </span>
            </p>
            <p v-show="soldevalid == false" class="lead text-center alert danger">
              <v-icon color="white" large>mdi-alert</v-icon>
              <br />
              <span style="color: white"> SOLDE INSUFFISANT </span>
            </p>
            <p class="text-muted" v-if="moyenrecup.pays">
              Frais:
              <span class="float-end d-flex align-items-center">
                {{ (moyenrecup.frais / tauxechange.taux).toFixed(2) }}
                {{ one.user.pays.devise.libelle }}
              </span>
            </p>
            <p class="text-muted" v-if="tauxechange">
              Taux:
              <span class="float-end d-flex align-items-center">
                {{ tauxechange.taux }} {{ tauxechange.libelle }}
              </span>
            </p>
            <p class="one mb-2 mt-4">
              Montant:
              <span class="end float-end">
                {{ montant }} {{ one.user.pays.devise.libelle }}
              </span>
            </p>
            <hr />
            <p class="other" v-if="tauxechange">
              Montant total a recevoir
              <span class="float-end">
                {{ parseFloat(montant) * parseFloat(tauxechange.taux) }}
                {{ tauxechange.deviseRec.libelle }}
              </span>
            </p>
            <p class="other" v-if="tauxechange">
              Montant total debiter
              <span class="float-end">
                {{
                  (
                    parseFloat(montant) +
                    parseFloat(moyenrecup.frais) / parseFloat(tauxechange.taux)
                  ).toFixed()
                }}
                {{ one.user.pays.devise.libelle }}
              </span>
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="(recap = false), (soldevalid = true)"
              >Fermer</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn v-if="one.user.role.id == 3" color="primary" text @click="ok"
              >OK</v-btn
            >
            <v-btn
              v-if="one.user.role.id == 4 || one.user.role.id == 5"
              color="primary"
              text
              @click="okagent"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="recapwithbanq" max-width="500" persistent>
        <v-card>
          <v-card-title>
            <h4>Vous allez transferer</h4>
          </v-card-title>
          <hr />
          <v-card-text class="recap">
            <p v-show="soldevalid == true" class="lead text-center alert custom">
              Beneficiare: {{ recepteur }}
              <br />
              Adresse: {{ adresse }}
              {{ destination.nom }}
              <br />
              Tel: {{ telephone }}
              <span class="other" v-if="moyenrecup.libelle == `ESPECE`">
                <br />
                A recuperer via Cash
              </span>
              <span v-else>
                <br />
                <v-icon color="white">mdi-bank</v-icon> {{ institution }}-{{ intitule }}-
                {{ numcompte }}
              </span>
            </p>
            <p v-show="soldevalid == false" class="lead text-center alert danger">
              <v-icon color="white" large>mdi-alert</v-icon>
              <br />
              <span style="color: white"> SOLDE INSUFFISANT </span>
            </p>
            <p class="text-muted" v-if="moyenrecup.pays">
              Frais:
              <span class="float-end d-flex align-items-center">
                {{ (moyenrecup.frais / tauxechange.taux).toFixed() }}
                {{ one.user.pays.devise.libelle }}
              </span>
            </p>
            <p class="text-muted" v-if="tauxechange">
              Taux:
              <span class="float-end d-flex align-items-center">
                {{ tauxechange.taux }} {{ tauxechange.libelle }}
              </span>
            </p>
            <p class="one mb-2 mt-4">
              Montant:
              <span class="text-3 float-end">
                {{ montant }} {{ one.user.pays.devise.libelle }}
              </span>
            </p>
            <hr />
            <p class="other" v-if="tauxechange">
              Montant total a recevoir
              <span class="float-end">
                {{ parseFloat(montant) * parseFloat(tauxechange.taux) }}
                {{ tauxechange.deviseRec.libelle }}
              </span>
            </p>
            <p class="small other" v-if="tauxechange">
              Montant total a debiter
              <span class="float-end">
                {{
                  (
                    parseFloat(montant) +
                    parseFloat(moyenrecup.frais) / parseFloat(tauxechange.taux)
                  ).toFixed()
                }}
                {{ one.user.pays.devise.libelle }}
              </span>
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="recapwithbanq = false">Fermer</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="one.user.role.id == 3" color="primary" text @click="okbanque"
              >OK</v-btn
            >
            <v-btn
              v-if="one.user.role.id == 4 || one.user.role.id == 5"
              color="primary"
              text
              @click="okbanque"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="recapwithbanquser" max-width="500" persistent>
        <v-card>
          <v-card-title>
            <h4>Vous allez transferer</h4>
          </v-card-title>
          <hr />
          <v-card-text class="recap">
            <p class="lead text-center alert custom">
              Beneficiare: {{ recepteur }}
              <br />
              Adresse: {{ adresse }}
              {{ destination.nom }}
              <br />
              Tel: {{ telephone }}
              <span class="other" v-if="moyenrecup.libelle == `ESPECE`">
                <br />
                A recuperer via Cash
              </span>
              <span v-else>
                <br />
                <v-icon color="white">mdi-bank</v-icon> {{ institution }}-{{ intitule }}-
                {{ numcompte }}
              </span>
            </p>
            <p class="text-muted" v-if="moyenrecup.pays">
              Frais:
              <span class="float-end end d-flex align-items-center">
                {{ (moyenrecup.frais / tauxechange.taux).toFixed() }}
                {{ one.user.pays.devise.libelle }}
              </span>
            </p>
            <p class="text-muted" v-if="tauxechange">
              Taux:
              <span class="float-end d-flex align-items-center">
                {{ tauxechange.taux }} {{ tauxechange.libelle }}
              </span>
            </p>
            <p class="one mb-2 mt-4">
              Montant:
              <span class="end float-end">
                {{ montant }} {{ one.user.pays.devise.libelle }}
              </span>
            </p>
            <hr />
            <p class="other" v-if="tauxechange">
              Montant total a recevoir
              <span class="float-end">
                {{ parseFloat(montant) * parseFloat(tauxechange.taux) }}
                {{ tauxechange.deviseRec.libelle }}
              </span>
            </p>
            <p class="small other" v-if="tauxechange">
              Montant total a debiter
              <span class="float-end">
                {{
                  (
                    parseFloat(montant) +
                    parseFloat(moyenrecup.frais) / parseFloat(tauxechange.taux)
                  ).toFixed()
                }}
                {{ one.user.pays.devise.libelle }}
              </span>
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="recapwithbanquser = false">Fermer</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="one.user.role.id == 3" color="primary" text @click="okuserbanq"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="numtransac" max-width="350" persistent>
        <v-card>
          <v-card-title>
            <h4>Transaction</h4>
          </v-card-title>
          <hr />
          <v-card-text>
            <p>Code de transaction: {{ numero }} a transmetre au beneficiare</p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="numtransac = false">Fermer</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="finisher" transition="dialog-top-transition" max-width="450">
        <template>
          <v-card>
            <v-card-text class="text-center" style="color: #2d2d2d">
              <v-card-title class="headline"> Validation </v-card-title>
              <span class="h3">
                <v-text-field
                  class="mt-2 ms-2 me-2"
                  v-model="pin"
                  name="indicatif"
                  type="number"
                  label="code pin"
                  ref="clear11"
                  @blur="checkpins"
                  prepend-inner-icon="mdi-dialpad"
                  :rules="[rules.required]"
                  :error-messages="messagepin"
                  outlined
                  clearable
                  dense
                >
                </v-text-field>
                <v-icon x-large color="white">mdi-check</v-icon>
              </span>
              <div class="text-center d-flex justify-center">
                <v-btn left style="color: white" color="#2B2E83" @click="valider"
                  >Enregistrer</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog v-model="details" max-width="720">
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text>
            <div class="row">
              <div
                class="col-sm-5 d-flex justify-content-center rounded-start py-4 custom"
              >
                <div class="my-auto text-center">
                  <div class="text-17 text-white my-3">
                    <v-icon x-large color="white">mdi-bank</v-icon>
                  </div>
                  <h3
                    class="text-4 text-white fw-400 my-3"
                    v-if="objdetails.preuve == null && objdetails.typeTransaction"
                  >
                    Transaction {{ objdetails.typeTransaction.libelle }} Extrazone
                  </h3>
                  <h3
                    class="text-4 text-white fw-400 my-3"
                    v-else-if="objdetails.preuve != null && objdetails.typeTransaction"
                  >
                    Transaction
                    {{ objdetails.typeTransaction.libelle }} Extrazone Bancaire
                  </h3>
                  <div
                    v-if="objdetails.moyenRecuperation && objdetails.tauxEchange"
                    class="money other text-white my-4"
                  >
                    {{
                      (
                        parseFloat(objdetails.montant) +
                        parseFloat(objdetails.moyenRecuperation.frais) /
                          parseFloat(objdetails.tauxEchange.taux)
                      )
                        .toFixed()
                        .toString() +
                      " " +
                      objdetails.tauxEchange.deviseExp.libelle
                    }}
                  </div>
                  <p class="text-white">
                    {{ formatDate(objdetails.createdAt) }}
                  </p>
                </div>
              </div>
              <div class="col-sm-7">
                <h5 class="text-5 fw-400 m-3">Transaction details</h5>
                <hr />
                <div class="px-3">
                  <ul class="list-unstyled">
                    <li class="mb-2" v-if="objdetails.tauxEchange">
                      Montant
                      <span class="float-end end">{{
                        objdetails.montant +
                        " " +
                        objdetails.tauxEchange.deviseExp.libelle
                      }}</span>
                    </li>
                    <li
                      class="mb-2"
                      v-if="objdetails.moyenRecuperation && objdetails.tauxEchange"
                    >
                      Frais
                      <span class="float-end end">
                        {{
                          (
                            parseFloat(objdetails.moyenRecuperation.frais) /
                            parseFloat(objdetails.tauxEchange.taux)
                          ).toFixed() +
                          " " +
                          objdetails.tauxEchange.deviseExp.libelle
                        }}
                      </span>
                    </li>
                  </ul>
                  <hr class="mb-2" />
                  <p class="d-flex align-items-center other mb">
                    Total
                    <span
                      class="end ms-auto"
                      v-if="objdetails.moyenRecuperation && objdetails.tauxEchange"
                    >
                      {{
                        (
                          parseFloat(objdetails.montant) +
                          parseFloat(objdetails.moyenRecuperation.frais) /
                            parseFloat(objdetails.tauxEchange.taux)
                        )
                          .toFixed()
                          .toString() +
                        " " +
                        objdetails.tauxEchange.deviseExp.libelle
                      }}
                    </span>
                  </p>
                  <!-- <p
                    v-if="
                      objdetails.moyenRecuperation &&
                      objdetails.tauxEchange &&
                      objdetails.prevue == null
                    "
                    class="d-flex align-items-center fw-500 mb"
                  >
                    Ancien solde
                    <span class="text-3 ms-auto">
                      {{
                        (
                          parseFloat(one.solde) +
                          (parseFloat(objdetails.montant) +
                            parseFloat(objdetails.moyenRecuperation.frais) /
                              parseFloat(objdetails.tauxEchange.taux))
                        ).toFixed()
                      }}
                    </span>
                  </p>
                  <p
                    v-else-if="
                      objdetails.moyenRecuperation &&
                      objdetails.tauxEchange &&
                      objdetails.prevue != null
                    "
                  >
                    Ancien solde
                    <span class="text-3 ms-auto">
                      {{
                        (
                          parseFloat(objdetails.montant) +
                          parseFloat(objdetails.moyenRecuperation.frais) /
                            parseFloat(objdetails.tauxEchange.taux)
                        ).toFixed()
                      }}
                    </span>
                  </p> -->
                  <hr class="mb-4 mt-2" />
                  <ul class="list-unstyled">
                    <li class="other">Numero de transaction</li>
                    <li class="mt-2">
                      <span class="text-muted">{{ objdetails.numero }}</span>
                    </li>
                  </ul>
                  <ul class="list-unstyled">
                    <li class="other">Moyen de paiyement:</li>
                    <li
                      class="text-4 text-white fw-400 my-3"
                      v-if="objdetails.preuve == null"
                    >
                      <span class="text-muted"> Portefeuille Numerique </span>
                    </li>
                    <li
                      class="text-4 text-white fw-400 my-3"
                      v-else-if="objdetails.preuve != null"
                    >
                      <span class="text-muted"> Virement bancaire </span>
                    </li>
                    <li>
                      <!-- <span class="text-muted">{{ objdetails.preuve }}</span> -->
                    </li>
                  </ul>
                  <ul class="list-unstyled">
                    <li class="other">Destinataire</li>
                    <li class="mt-2">
                      Nom/Prenom
                      <span class="text-muted float-end"
                        >{{ objdetails.recepteur }}
                      </span>
                    </li>
                    <li class="mt-2">
                      Telephone
                      <span class="text-muted float-end"
                        >{{ objdetails.telRecepteur }}
                      </span>
                    </li>
                    <li class="mt-2">
                      Adresse
                      <span class="text-muted float-end"
                        >{{ objdetails.adrRecepteur }}
                      </span>
                    </li>
                    <li class="mt-2">
                      Pays/ville
                      <span class="text-muted float-end" v-if="objdetails.destination">
                        {{
                          objdetails.destination.nom +
                          " " +
                          objdetails.destination.pays.nom
                        }}</span
                      >
                    </li>
                  </ul>
                  <ul class="list-unstyled" v-if="objdetails.institution">
                    <li class="other">Information bancaire destinataire</li>
                    <li class="mt-2">
                      Nom banque
                      <span class="text-muted float-end"
                        >{{ objdetails.institution }}
                      </span>
                    </li>
                    <li class="mt-2">
                      titulaire compte
                      <span class="text-muted float-end">{{ objdetails.intitule }} </span>
                    </li>
                    <li class="mt-2">
                      Numero compte
                      <span class="text-muted float-end"
                        >{{ objdetails.numCompte }}
                      </span>
                    </li>
                  </ul>

                  <ul class="list-unstyled">
                    <li class="other">Status</li>
                    <li class="text-muted" v-if="objdetails.valider == true">
                      Valider
                      <span class="end ms-1 float-end">
                        <v-icon color="green ">mdi-check-circle-outline</v-icon>
                      </span>
                    </li>
                    <li v-else class="text-muted">
                      En attente
                      <span class="end ms-1 float-end">
                        <v-icon color="yellow"> mdi-dots-horizontal </v-icon>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="popup"
            v-bind="attrs"
            v-on="on"
            color="#1A4478"
            x-large
            fab
            dark
            fixed
            bottom
            right
          >
            <v-icon>mdi-bank-transfer</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("Transfert.extrazone") }}</span>
      </v-tooltip>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import Aside from "../../../components/aside.vue";
import tauxechange from "../../../store/modules/tauxechange";
import transfert from "../../../store/modules/transfert";
export default {
  components: { Aside },
  data() {
    return {
      items: [],
      snackbar: false,
      timeout: 2000,
      pay: true,
      expediteur: "",
      recepteur: "",
      agences: "",
      numtransac: false,
      solde: false,
      soldevalid: true,
      avert: false,
      adresse: "",
      preuve: null,
      selectmoyen: 0,
      moyenrole: false,
      soldeinsuff: null,
      objdetails: {},
      details: false,
      numtransac: false,
      messagemontant: null,
      finisher: false,
      messagepin: null,
      recap: false,
      recapwithbanq: false,
      recapwithbanquser: false,
      numero: 0,
      moyenpayment: [
        {
          id: 0,
          libelle: "ESPECE",
        },
        {
          id: 1,
          libelle: "VIREMENT BANCAIRE",
        },
      ],
      telephone: "",
      provenance: "",
      destination: "",
      paysExp: "",
      institution: "",
      intitule: "",
      banc: false,
      numcompte: "",
      montant: "",
      files: "",
      pin: "",
      moyenrecup: "",
      id: "",
      addbanc: false,
      banquepayement: false,
      usertransfert: false,
      userbanc: false,
      catchmoyen: null,
      pinerror: false,
      confirm: false,
      loading: false,
      search: null,
      add: false,
      current_page: 1,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: this.$t("headers.date"),
          align: "start",
          sortable: false,
          value: "dateOperation",
        },
        {
          text: this.$t("headers.description"),
          align: "start",
          sortable: false,
          value: "typeTransaction.libelle",
        },
        {
          text: this.$t("headers.statut"),
          align: "start",
          sortable: false,
          value: "valider",
        },
        {
          text: this.$t("headers.montant"),
          align: "end",
          sortable: false,
          value: "montant",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "transfert",
      "transfertextra",
      "token",
      "user",
      "one",
      "allpays",
      "villebypays",
      "villebypays2",
      "recup",
      "tauxechange",
      "agencebyville",
      "checkpin",
    ]),
    rows() {
      if (this.items != null) {
        return this.items.length;
      } else {
        [];
      }
    },
    totalpage() {
      if (this.transfert && this.transfert.last_page) {
        return this.transfert.last_page;
      } else {
        return 0;
      }
    },
    paysList() {
      if (this.allpays != null) {
        return this.allpays;
      } else {
        return 0;
      }
    },
    villeList() {
      if (this.villebypays2 != null) {
        return this.villebypays2;
      } else {
        return [];
      }
    },
    recupList() {
      if (this.recup != null) {
        return this.recup;
      } else {
        return [];
      }
    },
    agenceList() {
      if (this.agencebyville != null) {
        return this.agencebyville;
      } else {
        return [];
      }
    },
    itemList() {
      if (this.transfert != null && this.transfert.data) {
        // return this.transfert.data.filter((item) => item.tauxEchange == null);
        return this.transfert.data.filter((item) => item.tauxEchange != null);
        // return this.transfert.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search() {
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getcleartransfert");
      }
    },
    paysExp() {
      if (this.paysExp.id > 0) {
        this.$store.dispatch("getvillebypays2", this.paysExp.id);
        if (this.one.user.pays.devise != null && this.paysExp.devise != null) {
          this.$store
            .dispatch("gettauxbydevise", {
              send: this.one.user.pays.devise.id,
              receive: this.paysExp.devise.id,
            })
            .then(() => {
              const timer = () => {
                const status = localStorage.getItem("status");
                if (status == 200) {
                  this.$store.dispatch("getmoyenrecupbypays", this.paysExp.id);
                  if (this.tauxechange.taux == undefined) {
                    this.avert = true;
                    this.$refs.clear5.reset();
                  }
                  localStorage.removeItem("status");
                } else if (status == 400) {
                }
              };
              setTimeout(timer, 0.1 * 100, 0.1);
            });
        } else {
          this.avert = true;
          this.$refs.clear5.reset();
        }
      }
      // this.$store.dispatch("getvillebypays2", this.paysExp.id);
    },
    moyenrecup() {
      if (this.moyenrecup.libelle == "VIREMENT BANCAIRE") {
        this.catchmoyen = this.moyenrecup.id;
        this.banc = true;
        this.addbanc = true;
        this.pay = false;
      } else {
        this.catchmoyen = this.moyenrecup.id;
        this.banc = false;
        this.addbanc = false;
        this.pay = true;
      }
    },
    provenance() {
      this.$store.dispatch("getagencebyville", this.provenance.id);
    },
    selectmoyen() {
      if (this.selectmoyen == 1) {
        this.$store.dispatch("getagencebyville", this.provenance.id);
        this.banquepayement = true;
        this.usertransfert = true;
        this.pay = false;
        this.addbanc = false;
      } else {
        console.log(this.moyenrecup.libelle == "VIREMENT BANCAIRE");
        if (this.moyenrecup.libelle == "VIREMENT BANCAIRE") {
          this.banquepayement = true;
          this.usertransfert = false;
          this.pay = false;
        } else this.banquepayement = false;
        this.usertransfert = false;
        this.pay = true;
      }
    },
    agences() {
      this.userbanc = true;
    },
  },
  methods: {
    reset() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
      this.$refs.clear2.reset();
      this.$refs.clear8.reset();
    },
    checkmontant() {
      if (this.montant > this.one.solde) {
        this.messagemontant = "Solde insufissant";
        this.$refs.clear3.$el.querySelector("input").focus();
      } else if (this.montant <= 0) {
        this.messagemontant = "Montant invalide";
        this.$refs.clear3.$el.querySelector("input").focus();
      } else {
        this.messagemontant = null;
      }
    },
    checkpins() {
      localStorage.setItem("tk", this.token);
      const pin = {};
      pin.pin = this.pin;
      this.$store.dispatch("getcheckpin", { id: this.one.user.id, pin: pin }).then(() => {
        const timer = () => {
          if (this.checkpin == false) {
            this.messagepin = "Le code pin ne correspond pas";
            this.$refs.clear11.$el.querySelector("input").focus();
          } else {
            this.messagepin = null;
          }
        };
        setTimeout(timer, 0.1 * 100, 0.1);
      });
    },
    fond() {
      if (this.montant < this.one.solde) {
        this.soldeinsuff = null;
      } else {
        this.soldeinsuff = "Solde insuffisant";
      }
    },
    ici(row) {
      this.objdetails = row;
      this.details = true;
      if (this.one.user.role.id == 4) {
        this.id = row.id;
        if (row.envoyeur.user.id != this.one.user.id && row.preuve == null) {
          this.confirm = true;
        } else if (row.preuve != null && row.preuve.used == true) {
          this.confirm = true;
        } else if (row.preuve != null && row.preuve.used == false) {
          alert("valider dabord la preuve de payement pour valider le transfert");
        }
      }
    },
    open() {
      // console.log(!this.transfert.preuve.used);
      this.finisher = true;
    },
    valider() {
      if (
        this.one.user.role.id == 4 ||
        (this.one.user.role.id == 5 && this.messagepin == null)
      ) {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("validtransfert", this.transfert.id).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.confirm = false;
            if (this.one.user.role.id == 4 || this.one.user.role.id == 5) {
              localStorage.setItem("tk", this.token);
              this.finisher = false;
              this.$refs.clear11.reset();
              this.$store.dispatch("getoneportefeuille", this.user.id);
              this.$store.dispatch("gettransactionbynum", this.search);
            } else {
              localStorage.setItem("tk", this.token);
              this.$store.dispatch("getoneportefeuille", this.user.id);
            }
          }
        });
      }
    },
    popup() {
      this.add = true;
      this.expediteur = this.one.user.nom + " " + this.one.user.prenom;
    },
    bancok() {
      this.userbanc = false;
    },
    addnewuserwithbanque() {
      this.recapwithbanquser = true;
    },
    addnewwithbanque() {
      if (this.$refs.formadd.validate()) {
        if (this.one.user.role.id == 4 || this.one.user.role.id == 5) {
          if (this.messagemontant == null && this.messagepin == null) {
            console.log(
              Number(this.montant) +
                Number(this.moyenrecup.frais) / Number(this.tauxechange.taux)
            );
            this.recapwithbanq = true;
          }
        } else if (this.one.user.role.id == 3) {
          if (this.messagemontant == null && this.messagepin == null) {
            console.log(
              Number(this.montant) +
                Number(this.moyenrecup.frais) / Number(this.tauxechange.taux)
            );
            this.recapwithbanq = true;
          }
        }
      }
    },
    addnew() {
      if (this.$refs.formadd.validate()) {
        if (this.one.user.role.id == 4 || this.one.user.role.id == 5) {
          if (this.messagemontant == null && this.messagepin == null) {
            this.recap = true;
          }
        } else if (this.one.user.role.id == 3) {
          if (this.messagemontant == null && this.messagepin == null) {
            this.recap = true;
          }
        }
      }
    },
    okuserbanq() {
      let intrazone = new FormData();
      intrazone.append("envoyeur  ", this.one.numero);
      intrazone.append("moyenRecuperation  ", this.catchmoyen);
      intrazone.append("expediteur  ", this.expediteur);
      intrazone.append("recepteur  ", this.recepteur);
      intrazone.append("telRecepteur  ", this.telephone);
      intrazone.append("adrRecepteur  ", this.adresse);
      intrazone.append("provenance  ", this.provenance.id);
      intrazone.append("destination  ", this.destination.id);
      intrazone.append("montant  ", this.montant);
      intrazone.append("pin  ", this.pin);
      intrazone.append("agence  ", this.agences.id);
      intrazone.append("images  ", this.preuve, this.preuve.name);
      localStorage.setItem("tk", this.$store.getters.token);
      this.recapwithbanquser = false;
      this.$store.dispatch("transfertextraclientrecupbanque", intrazone).then(() => {
        const status = localStorage.getItem("status");
        if (status == 200) {
          this.add = false;
          this.numero = this.transfert.numero;
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("gettransfertclient", this.current_page);
          this.$store.dispatch("getoneportefeuille", this.one.user.id);
          // this.reset();
          localStorage.removeItem("status");
        } else if (status == 400) {
          this.error = true;
          localStorage.removeItem("status");
        }
      });
    },
    okbanque() {
      if (
        Number(this.montant) +
          Number(this.moyenrecup.frais) / Number(this.tauxechange.taux) >
          this.one.solde ==
        true
      ) {
        this.soldevalid = false;
      } else {
        const intrazone = {};
        intrazone.envoyeur = this.one.numero;
        intrazone.moyenRecuperation = this.catchmoyen;
        intrazone.expediteur = this.expediteur;
        intrazone.recepteur = this.recepteur;
        intrazone.telRecepteur = this.telephone;
        intrazone.adrRecepteur = this.adresse;
        intrazone.provenance = this.provenance.id;
        intrazone.destination = this.destination.id;
        intrazone.institution = this.institution;
        intrazone.intitule = this.intitule;
        intrazone.numCompte = this.numcompte;
        intrazone.montant = this.montant;
        intrazone.pin = this.pin;
        this.recapwithbanq = false;
        this.$store.dispatch("transfertextraagentbanque", intrazone).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.add = false;
            this.numero = this.transfertextra.numero;
            this.numtransac = true;
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getoneportefeuille", this.one.user.id);
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("gettransfertclient", this.current_page);
            // this.reset();
          }
        });
      }
    },
    okbanqueuser() {
      if (
        Number(this.montant) +
          Number(this.moyenrecup.frais) / Number(this.tauxechange.taux) >
          this.one.solde ==
        true
      ) {
        this.soldevalid = false;
      } else {
        const intrazone = {};
        intrazone.envoyeur = this.one.numero;
        intrazone.moyenRecuperation = this.catchmoyen;
        intrazone.expediteur = this.expediteur;
        intrazone.recepteur = this.recepteur;
        intrazone.telRecepteur = this.telephone;
        intrazone.adrRecepteur = this.adresse;
        intrazone.provenance = this.provenance.id;
        intrazone.destination = this.destination.id;
        intrazone.institution = this.institution;
        intrazone.intitule = this.intitule;
        intrazone.numCompte = this.numcompte;
        intrazone.montant = this.montant;
        intrazone.pin = this.pin;
        this.recapwithbanq = false;
        this.$store.dispatch("transfertextraclientbanque", intrazone).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.add = false;
            this.numero = this.transfertextra.numero;
            this.numtransac = true;
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("gettransfertclient", this.current_page);
            this.$store.dispatch("getoneportefeuille", this.one.user.id);
            // this.reset();
          }
        });
      }
    },
    ok() {
      if (
        Number(this.montant) +
          Number(this.moyenrecup.frais) / Number(this.tauxechange.taux) >
          this.one.solde ==
        true
      ) {
        this.soldevalid = false;
      } else {
        const intrazone = {};
        intrazone.envoyeur = this.one.numero;
        intrazone.moyenRecuperation = this.moyenrecup.id;
        intrazone.expediteur = this.expediteur;
        intrazone.recepteur = this.recepteur;
        intrazone.telRecepteur = this.telephone;
        intrazone.adrRecepteur = this.adresse;
        intrazone.provenance = this.provenance.id;
        intrazone.destination = this.destination.id;
        intrazone.montant = this.montant;
        intrazone.pin = this.pin;
        this.recap = false;
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("transfertextraclient", intrazone).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.numero = this.transfert.numero;
            this.add = false;
            this.snackbar = true;
            this.numtransac = true;
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("gettransfertclient", this.current_page);
            this.$store.dispatch("getoneportefeuille", this.one.user.id);
            // this.reset();
          }
        });
      }
    },
    okagent() {
      if (
        Number(this.montant) +
          Number(this.moyenrecup.frais) / Number(this.tauxechange.taux) >
          this.one.solde ==
        true
      ) {
        this.soldevalid = false;
      } else {
        const intrazone = {};
        intrazone.envoyeur = this.one.numero;
        intrazone.moyenRecuperation = this.moyenrecup.id;
        intrazone.expediteur = this.expediteur;
        intrazone.recepteur = this.recepteur;
        intrazone.telRecepteur = this.telephone;
        intrazone.adrRecepteur = this.adresse;
        intrazone.provenance = this.provenance.id;
        intrazone.destination = this.destination.id;
        intrazone.montant = this.montant;
        intrazone.pin = this.pin;
        this.recap = false;
        this.$store.dispatch("transfertextraagent", intrazone).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.numero = this.transfertextra.numero;
            this.add = false;
            this.snackbar = true;
            this.numtransac = true;
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("getoneportefeuille", this.one.user.id);
            // this.reset();
          }
        });
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };

      return date.toLocaleDateString("fr-FR", options);
    },
    // formatDate(date) {
    //   const options = { day: "numeric", month: "long", year: "numeric" };
    //   const dateString = new Date(date).toLocaleDateString("fr-FR", options);
    //   const dateArray = dateString.split(" ");
    //   return dateArray.join("<br>");
    // },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        if (this.one.user.role.id == 4 || this.one.user.role.id == 5) {
          this.moyenrole = false;
          this.$store.dispatch("gettransfertagent", this.current_page);
        } else {
          this.$store.dispatch("gettransfertclient", this.current_page);
          this.moyenrole = true;
        }
      } else {
        this.$store.dispatch("searchtransfert", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {
      if (this.search) {
        if (this.search.trim() == "") {
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("getcleartransfert");
        } else {
          const timer = () => {
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("gettransactionbynum", this.search);
          };
          setTimeout(timer, 0.2 * 200, 0.2);
        }
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getclearpin");
    this.$store.dispatch("getoneportefeuille", this.one.user.id);
    this.$store.dispatch("getallpays");
    if (this.one.user.role.id == 4 || this.one.user.role.id == 5) {
      this.moyenrole = false;
      this.$store.dispatch("getcleartransfert");
    } else {
      this.$store.dispatch("gettransfertclient", this.current_page);
      this.moyenrole = true;
    }
  },
  created() {
    this.$store.dispatch("getvillebypays", this.one.user.pays.id);
    // console.log(this.$store.state.portefeuille.sendoperation.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.custom {
  background-color: #2b2e83;
  color: white;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
.danger {
  background-color: red;
}
.infos {
  color: blue;
  font-size: 24px;
  text-transform: uppercase;
}
ul {
  padding-left: 0 !important;
}
.recap p {
  font-size: 1.25rem !important;
}
.other {
  font-weight: 500 !important;
}
.one {
  color: #2b2e83;
  margin-top: 4px;
}
.details p {
  font-size: 2rem;
}
.details span {
  font-size: 2rem;
  text-transform: uppercase;
}
.somme {
  margin: 4rem 4rem 0rem 0rem;
}
.somme p {
  font-size: 5rem !important;
}
.money {
  font-size: 2rem !important;
}
.small {
  font-size: 1.3125rem !important;
}
.end {
  font-size: 1rem !important;
}
</style>
