<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <div class="col-lg-9 mb-4">
        <div class="col-sm-6 col-md-5 form-group">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          dense
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-1 shadow-sm rounded row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("Transaction.Transaction") }}</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <td>{{ date(item.createdAt) }}</td>
          </template>
          <template v-slot:[`item.valider`]="{ item }">
            <td v-if="item.valider == true">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" large color="green"
                    >mdi-check-circle-outline
                  </v-icon>
                </template>
                <span>Effectuer</span>
              </v-tooltip>
            </td>
            <td v-else>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="yellow" large>
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <span>En attente</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import Aside from "../../../components/aside.vue";
export default {
  components: { Aside },
  data() {
    return {
      items: [],
      snackbar: false,
      timeout: 2000,
      loading: false,
      search: null,
      current_page: 1,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: this.$t("headers.envoyeurN"),
          align: "start",
          sortable: false,
          value: "envoyeur.user.nom",
        },
        {
          text: this.$t("headers.envoyeurP"),
          align: "start",
          sortable: false,
          value: "envoyeur.user.prenom",
        },

        {
          text: this.$t("headers.receiveN"),
          align: "start",
          sortable: false,
          value: "receveur.user.nom",
        },
        {
          text: this.$t("headers.receiveP"),
          align: "start",
          sortable: false,
          value: "receveur.user.prenom",
        },
        {
          text: this.$t("headers.operation"),
          align: "start",
          sortable: false,
          value: "typeOperation.libelle",
        },
        {
          text: this.$t("headers.montant"),
          align: "start",
          sortable: false,
          value: "montant",
        },
        {
          text: this.$t("headers.date"),
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: this.$t("headers.statut"),
          align: "start",
          sortable: false,
          value: "valider",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["alloperation", "token", "user", "one"]),
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.alloperation && this.alloperation.last_page) {
        return this.alloperation.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.alloperation != null && this.alloperation.data) {
        return this.alloperation.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    // search() {
    //   if (this.search == "" || this.search.trim() == null) {
    //     localStorage.setItem("tk", this.token);
    //     this.$store.dispatch("getalloperation", this.current_page);
    //   }
    // },
    search(newVal) {
      if (newVal.trim() == "") {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getalloperation", this.current_page);
      } else {
        localStorage.setItem("tk", this.token);
        this.$store
          .dispatch("searchalloperation", {
            page: this.current_page,
            search: this.search.trim(),
          })
          .then(() => {
            this.searchresult();
          });
      }
    },
  },
  methods: {
    ici(row) {},
    date(item) {
      return new Date(item).toLocaleDateString();
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getalloperation", this.current_page);
      } else {
        this.$store.dispatch("searchalloperation", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {
      // if (this.search) {
      //   if (this.search.trim() == "") {
      //     localStorage.setItem("tk", this.token);
      //     this.current_page = 1;
      //     this.$store.dispatch("getalloperation", this.current_page);
      //   } else {
      //     localStorage.setItem("tk", this.token);
      //     this.$store.dispatch("searchalloperation", {
      //       page: this.current_page,
      //       search: this.search.trim(),
      //     });
      //     if (this.$store.state.operation.searchalloperation.data) {
      //       this.current_page = this.$store.state.operation.alloperation.current_page;
      //     } else this.current_page = 1;
      //   }
      // }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getalloperation", this.current_page);
    this.$store.dispatch("getoneportefeuille", this.user.id);
  },
  created() {
    // console.log(this.$store.state.portefeuille.alloperation.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
.back {
  background-color: whitesmoke;
}
</style>
