import axios from "axios";
import { baseURL } from "../../main";

const state =
{
    reversementkiosk:null,
    reversementbyadmin:null,
}
const getters=
{
    reversementkiosk:(state)=> state.reversementkiosk,
    reversementbyadmin:(state)=> state.reversementbyadmin
};

const actions =
{
    async reversementbykiosk({ commit }, value) {
        try {
          const token = localStorage.getItem("tk");
          const response = await axios.post(baseURL + "transactions/reversement",value,{
              headers: {
                  Authorization: "Bearer " + token
                },
          });
          const data = response.data;
          localStorage.removeItem("tk");
          commit("setreversementkiosk", data);
          localStorage.setItem("status", 200);
        } catch (error) {
          console.error(error);
          localStorage.setItem("status", 400);
          throw error;
        }
      },
    async reversementbyadmin({ commit }, value) {
        try {
          const token = localStorage.getItem("tk");
          const response = await axios.post(baseURL + "admin/transactions/reversement",value,{
              headers: {
                  Authorization: "Bearer " + token
                },
          });
          const data = response.data;
          localStorage.removeItem("tk");
          commit("setreversementadmin", data);
          localStorage.setItem("status", 200);
        } catch (error) {
          console.error(error);
          localStorage.setItem("status", 400);
          throw error;
        }
      }
};
const mutations =
{
    setreversementkiosk(state, reversementkiosk) {
        state.reversementkiosk = { ...state.reversementkiosk, ...reversementkiosk };
      },
    setreversementadmin(state, reversementbyadmin) {
        state.reversementbyadmin = { ...state.reversementbyadmin, ...reversementbyadmin };
      }
};

export default {
    state,
    getters,
    mutations,
    actions,
}