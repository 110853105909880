<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <div class="col-lg-9 mb-4">
        <div class="col-sm-6 col-md-5 form-group">
          <!-- <v-toolbar>
              <v-text-field
                class="search"
                v-model.lazy="search"
                single-line
                hide-details
                label="search"
                @input="searchresult"
                append-icon="mdi-magnify"
              >
              </v-text-field>
            </v-toolbar> -->
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-2 row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("historique.kiosk") }}</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.montant`]="{ item }">
            <td class="text-center" style="color: red" v-if="item.agence">
              - {{ item.montant + " " + item.agence.ville.pays.devise.libelle }}
            </td>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import Aside from "../../../components/aside.vue";
export default {
  components: { Aside },
  data() {
    return {
      items: [],
      id: "",
      search: null,
      current_page: 1,
      loading: false,
      headers: [
        {
          text: this.$t("headers.id"),
          align: "center",
          sortable: false,
          value: "id",
        },
        {
          text: this.$t("headers.montant"),
          align: "center",
          sortable: false,
          value: "montant",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["allbenefice", "token", "user", "one", "me", "agencebyuser"]),
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.allbenefice && this.allbenefice.last_page) {
        return this.allbenefice.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.allbenefice != null && this.allbenefice.data) {
        return this.allbenefice.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search(newVal) {
      if (newVal.trim() == "") {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("gethistoriquebenefice", {
          id: this.agencebyuser.id,
          page: this.current_page,
        });
      } else {
        localStorage.setItem("tk", this.token);
        this.$store
          .dispatch("searchhistoriquebenefice", {
            id: this.agencebyuser.id,
            page: this.current_page,
            search: this.search.trim(),
          })
          .then(() => {
            this.searchresult();
          });
      }
    },
  },
  methods: {
    ici(row) {},
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("gethistoriquebenefice", {
          id: this.agencebyuser.id,
          page: this.current_page,
        });
      } else {
        this.$store.dispatch("searchreceiveoperation", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {},
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getme");
    this.$store.dispatch("getagencebyuser", this.me.id);
    this.$store.dispatch("gethistoriquebenefice", {
      id: this.agencebyuser.id,
      page: this.current_page,
    });
    this.$store.dispatch("getoneportefeuille", this.user.id);
  },
  created() {},
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.custom {
  background-color: #2b2e83;
  color: white;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
.infos {
  color: blue;
  font-size: 24px;
  text-transform: uppercase;
}
ul {
  padding-left: 0 !important;
}
.recap p {
  font-size: 1.25rem !important;
}
.other {
  font-weight: 500 !important;
}
.one {
  color: #2b2e83;
  margin-top: 4px;
}
.details p {
  font-size: 2rem;
}
.details span {
  font-size: 2rem;
  text-transform: uppercase;
}
.somme {
  margin: 4rem 4rem 0rem 0rem;
}
.somme p {
  font-size: 5rem !important;
}
.money {
  font-size: 2rem !important;
}
.small {
  font-size: 1.3125rem !important;
}
.end {
  font-size: 1rem !important;
}
</style>
