<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <v-dialog v-model="add" transition="dialog-top-transition" max-width="520">
        <template v-slot:default="dialog">
          <v-form ref="formadd" @submit.prevent="add">
            <v-card>
              <v-tabs v-model="tab">
                <v-tab>{{ $t("retrait.ligne") }}</v-tab>
                <v-tab>{{ $t("retrait.banque") }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <div class="text-center">
                        <v-snackbar
                          v-model="solde"
                          :timeout="timeout"
                          absolute
                          bottom
                          color="red"
                          right
                        >
                          {{ $t("solde.error") }}

                          <template v-slot:action="{ attrs }">
                            <v-btn
                              color="black"
                              text
                              v-bind="attrs"
                              @click="solde = false"
                            >
                              Close
                            </v-btn>
                          </template>
                        </v-snackbar>
                        <v-snackbar
                          v-model="snackbar"
                          :timeout="timeout"
                          center
                          absolute
                          color="#1e824c"
                        >
                          {{ $t("Transaction.succes") }}

                          <template v-slot:action="{ attrs }">
                            <v-btn
                              color="red"
                              text
                              v-bind="attrs"
                              @click="snackbar = false"
                            >
                              Close
                            </v-btn>
                          </template>
                        </v-snackbar>
                      </div>
                      <div class="text-center">
                        <v-snackbar
                          v-model="error"
                          :timeout="timeout"
                          absolute
                          bottom
                          color="red"
                          right
                        >
                          {{ $t("devis.error") }}

                          <template v-slot:action="{ attrs }">
                            <v-btn
                              color="#cf000f"
                              text
                              v-bind="attrs"
                              @click="snackbar = false"
                            >
                              Close
                            </v-btn>
                          </template>
                        </v-snackbar>
                      </div>
                      <v-text-field
                        class="mt-4 ms-2 me-2"
                        v-model="montant"
                        name="indicatif"
                        type="number"
                        label="Montant"
                        step="0.001"
                        @input="checkmontant"
                        :error-messages="messagemontant"
                        ref="clear"
                        prepend-inner-icon="mdi-cash"
                        :rules="[rules.required]"
                        outlined
                        clearable
                        dense
                      >
                      </v-text-field>
                      <v-text-field
                        class="mt-2 ms-2 me-2"
                        v-model="wallet"
                        name="recepteur"
                        type="text"
                        ref="clear1"
                        prepend-inner-icon="mdi-wallet"
                        label="wallet du receveur"
                        :rules="[rules.required]"
                        outlined
                        @blur="checkwallet"
                        clearable
                        dense
                      >
                      </v-text-field>

                      <div
                        v-if="userbywallet != null"
                        v-show="showinfo == true"
                        class="text-center mb-6 d-flex flex-column align-items-between"
                      >
                        <div>
                          Nom et prenom:
                          <span style="text-transform: uppercase; color: #1e824c">
                            {{ userbywallet.username }}
                          </span>
                        </div>
                        <div>
                          Username:
                          <span style="text-transform: uppercase; color: #1e824c">
                            {{ userbywallet.nom + userbywallet.prenom }}
                          </span>
                        </div>
                      </div>
                      <v-text-field
                        class="mt-2 ms-2 me-2"
                        v-model="pin"
                        name="indicatif"
                        type="number"
                        label="code pin"
                        ref="clear2"
                        @input="checkpins"
                        prepend-inner-icon="mdi-dialpad"
                        :rules="[rules.required]"
                        :error-messages="messagepin"
                        outlined
                        clearable
                        dense
                      >
                      </v-text-field>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <div class="text-center">
                        <v-snackbar
                          v-model="solde"
                          :timeout="timeout"
                          absolute
                          bottom
                          color="red"
                          right
                        >
                          {{ $t("solde.error") }}

                          <template v-slot:action="{ attrs }">
                            <v-btn
                              color="black"
                              text
                              v-bind="attrs"
                              @click="solde = false"
                            >
                              Close
                            </v-btn>
                          </template>
                        </v-snackbar>
                        <v-snackbar
                          v-model="snackbar"
                          :timeout="timeout"
                          center
                          absolute
                          color="#1e824c"
                        >
                          {{ $t("Transaction.succes") }}

                          <template v-slot:action="{ attrs }">
                            <v-btn
                              color="red"
                              text
                              v-bind="attrs"
                              @click="snackbar = false"
                            >
                              Close
                            </v-btn>
                          </template>
                        </v-snackbar>
                      </div>
                      <div class="text-center">
                        <v-snackbar
                          v-model="error"
                          :timeout="timeout"
                          absolute
                          bottom
                          color="red"
                          right
                        >
                          {{ $t("devis.error") }}

                          <template v-slot:action="{ attrs }">
                            <v-btn
                              color="#cf000f"
                              text
                              v-bind="attrs"
                              @click="snackbar = false"
                            >
                              Close
                            </v-btn>
                          </template>
                        </v-snackbar>
                      </div>
                      <v-text-field
                        class="mt-4 ms-2 me-2"
                        v-model="montant"
                        name="indicatif"
                        type="number"
                        label="Montant"
                        step="0.001"
                        @input="checkmontant"
                        :error-messages="messagemontant"
                        ref="clear"
                        prepend-inner-icon="mdi-cash"
                        :rules="[rules.required]"
                        outlined
                        clearable
                        dense
                      >
                      </v-text-field>
                      <v-text-field
                        class="mt-2 ms-2 me-2"
                        v-model="wallet"
                        name="recepteur"
                        type="text"
                        ref="clear1"
                        prepend-inner-icon="mdi-wallet"
                        label="wallet du receveur"
                        :rules="[rules.required]"
                        outlined
                        @blur="checkwallet"
                        clearable
                        dense
                      >
                      </v-text-field>

                      <div
                        v-if="userbywallet != null"
                        v-show="showinfo == true"
                        class="text-center mb-6 d-flex flex-column align-items-between"
                      >
                        <div>
                          Nom et prenom:
                          <span style="text-transform: uppercase; color: #1e824c">
                            {{ userbywallet.username }}
                          </span>
                        </div>
                        <div>
                          Username:
                          <span style="text-transform: uppercase; color: #1e824c">
                            {{ userbywallet.nom + userbywallet.prenom }}
                          </span>
                        </div>
                      </div>
                      <v-select
                        class="mt-4 ms-2 me-2"
                        v-model="banque"
                        outlined
                        :items="me.banques"
                        item-text="institution"
                        item-value="id"
                        ref="clear4"
                        :rules="[rules.required]"
                        label="Votre banque"
                        prepend-inner-icon="mdi-bank"
                        dense
                      >
                      </v-select>
                      <v-autocomplete
                        class="mt-4 ms-2 me-2"
                        v-model="agences"
                        prepend-inner-icon="mdi-earth"
                        :items="agenceList"
                        item-text="nom"
                        item-value="id"
                        label="Les agences"
                        ref="clear3"
                        :rules="[rules.required]"
                        return-object
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <v-text-field
                        class="mt-2 ms-2 me-2"
                        v-model="pin"
                        name="indicatif"
                        type="number"
                        label="code pin"
                        ref="clear2"
                        @input="checkpins"
                        prepend-inner-icon="mdi-dialpad"
                        :rules="[rules.required]"
                        :error-messages="messagepin"
                        outlined
                        clearable
                        dense
                      >
                      </v-text-field>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
              <v-card-actions>
                <v-btn
                  style="color: white"
                  color="red"
                  @click="
                    dialog.value = false;
                    close();
                  "
                  >Ferme</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn style="color: white" color="#2b2e83" @click="addnew"
                  >Enregistrer</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
        </template>
      </v-dialog>
      <div class="col-lg-9 mb-4">
        <div class="col-sm-6 col-md-5 form-group">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-2 row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("Transaction.Transaction") }}</v-toolbar-title>
              <div class="col-md-11 d-flex mt-4 justify-content-end"></div>
            </v-toolbar>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <td>{{ date(item.createdAt) }}</td>
          </template>
          <template v-slot:[`item.valider`]="{ item }">
            <td v-if="item.valider == true">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" large color="green"
                    >mdi-check-circle-outline
                  </v-icon>
                </template>
                <span>Effectuer</span>
              </v-tooltip>
            </td>
            <td v-else>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="yellow" large>
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <span>En attente</span>
              </v-tooltip>
            </td>
          </template>
          <template v-slot:[`item.montant`]="{ item }">
            <th style="color: red">
              -
              {{ item.montant + " " + item.envoyeur.user.pays.devise.libelle }}
            </th>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
          <v-dialog v-model="alert" max-width="400" persistent>
            <v-card>
              <v-card-title class="headline d-flex flex-column" style="color: Red">
                <v-icon color="red" large>mdi-alert</v-icon>
                <span> Avertissement </span>
              </v-card-title>
              <v-card-text style="color: red" class="text-center">
                Vous ne pouvez pas envoyer de l'argent a cet utilisateur
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="red"
                  text
                  @click="
                    alert = false;
                    clear();
                  "
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <v-dialog v-model="details" max-width="720">
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text>
            <div class="row">
              <div
                class="col-sm-5 d-flex justify-content-center rounded-start py-4 custom"
              >
                <div class="my-auto text-center">
                  <div class="text-17 text-white my-3">
                    <v-icon x-large color="white">mdi-swap-horizontal</v-icon>
                  </div>
                  <h3
                    class="text-4 text-white fw-400 my-3"
                    v-if="objdetails.typeOperation"
                  >
                    Transaction {{ objdetails.typeOperation.libelle }}
                  </h3>
                  <div v-if="objdetails.envoyeur" class="money other text-white my-4">
                    {{
                      parseInt(objdetails.montant).toFixed().toString() +
                      " " +
                      objdetails.envoyeur.user.pays.devise.libelle
                    }}
                  </div>
                  <p class="text-white">
                    {{ date(objdetails.createdAt) }}
                  </p>
                </div>
              </div>
              <div class="col-sm-7">
                <h5 class="text-5 fw-400 m-3">Transaction details</h5>
                <hr />
                <div class="px-3">
                  <ul class="list-unstyled">
                    <li class="mb-2" v-if="objdetails.envoyeur">
                      Montant
                      <span class="float-end end">{{
                        objdetails.montant +
                        " " +
                        objdetails.envoyeur.user.pays.devise.libelle
                      }}</span>
                    </li>
                  </ul>
                  <hr class="mb-2" />
                  <p class="d-flex align-items-center other mb">
                    Total
                    <span class="end ms-auto" v-if="objdetails.envoyeur">
                      {{
                        parseInt(objdetails.montant).toFixed().toString() +
                        " " +
                        objdetails.envoyeur.user.pays.devise.libelle
                      }}
                    </span>
                  </p>
                  <hr class="mb-4 mt-2" />
                  <ul class="list-unstyled">
                    <li class="other">Destinataire</li>
                    <li class="mt-2">
                      Nom/Prenom
                      <span class="text-muted float-end" v-if="objdetails.receveur"
                        >{{
                          objdetails.receveur.user.nom +
                          " " +
                          objdetails.receveur.user.prenom
                        }}
                      </span>
                    </li>
                    <li class="mt-2">
                      Telephone
                      <span class="text-muted float-end" v-if="objdetails.receveur"
                        >{{ objdetails.receveur.user.tel }}
                      </span>
                    </li>
                    <li class="mt-2">
                      Pays
                      <span class="text-muted float-end" v-if="objdetails.receveur">
                        {{ objdetails.receveur.user.pays.nom }}</span
                      >
                    </li>
                  </ul>

                  <ul class="list-unstyled">
                    <li class="other">Status</li>
                    <li class="text-muted" v-if="objdetails.valider == true">
                      Valider
                      <span class="end ms-1 float-end">
                        <v-icon color="green ">mdi-check-circle-outline</v-icon>
                      </span>
                    </li>
                    <li v-else class="text-muted">
                      En attente
                      <span class="end ms-1 float-end">
                        <v-icon color="yellow"> mdi-dots-horizontal </v-icon>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="#1A4478"
            x-large
            fab
            dark
            fixed
            bottom
            right
          >
            <v-icon @click="popup">mdi-transfer</v-icon>
          </v-btn>
        </template>
        <span>Transfere de l'argent</span>
      </v-tooltip>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import Aside from "../../../components/aside.vue";
export default {
  components: { Aside },
  data() {
    return {
      items: [],
      snackbar: false,
      error: false,
      timeout: 2000,
      expediteur: "",
      wallet: "",
      adresse: "",
      telephone: "",
      provenance: "",
      destination: "",
      banque: "",
      alert: false,
      agences: "",
      showinfo: false,
      details: false,
      objdetails: {},
      tab: 0,
      fab: true,
      paysExp: "",
      montant: "",
      pin: "",
      row: {},
      messagemontant: null,
      solde: false,
      moyenrecup: "",
      loading: false,
      messagepin: null,
      search: null,
      add: false,
      current_page: 1,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: this.$t("headers.date"),
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: this.$t("headers.description"),
          align: "start",
          sortable: false,
          value: "typeOperation.libelle",
        },
        {
          text: this.$t("headers.statut"),
          align: "start",
          sortable: false,
          value: "valider",
        },
        {
          text: this.$t("headers.montant"),
          align: "start",
          sortable: false,
          value: "montant",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "token",
      "alldevs",
      "one",
      "me",
      "userbywallet",
      "checkpin",
      "agencebypays",
    ]),
    rows() {
      if (this.items != null) {
        return this.items.length;
      } else {
        [];
      }
    },
    agenceList() {
      if (this.agencebypays != null) {
        return this.agencebypays;
      } else {
        return [];
      }
    },
    totalpage() {
      if (this.alldevs && this.alldevs.last_page) {
        return this.alldevs.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.alldevs != null && this.alldevs.data) {
        return this.alldevs.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search() {
      if (this.search == null || this.search.trim() == "") {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getoperationdevs", this.current_page);
      } else {
        this.$store.dispatch("searchalldevs", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
  },
  methods: {
    clear() {
      this.$refs.clear1.reset();
      this.$store.dispatch("getclear");
    },
    reset() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
      this.$refs.clear2.reset();
    },
    close() {
      this.showinfo = false;
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
      this.$refs.clear2.reset();
      this.$store.dispatch("getclear");
    },
    checkmontant() {
      if (this.montant > this.one.solde) {
        this.messagemontant = "Solde insufissant";
        this.$refs.clear.$el.querySelector("input").focus();
      } else if (this.montant <= 0) {
        this.messagemontant = "Montant invalide";
        this.$refs.clear.$el.querySelector("input").focus();
      } else {
        this.messagemontant = null;
      }
    },
    checkwallet() {
      if (this.wallet != "") {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("getuserbywalletdevs", this.wallet).then(() => {
          const timer = () => {
            if (
              this.userbywallet.pays.devise.id != this.one.user.pays.devise.id ||
              this.wallet == this.one.numero ||
              this.userbywallet.role.id != 4
            ) {
              this.alert = true;
            } else {
              // console.log(this.userbywallet);
              this.showinfo = true;
            }
          };
          setTimeout(timer, 0.1 * 100, 0.1);
        });
      }
    },
    checkpins() {
      localStorage.setItem("tk", this.token);
      const pin = {};
      pin.pin = this.pin;
      this.$store.dispatch("getcheckpin", { id: this.me.id, pin: pin }).then(() => {
        const timer = () => {
          console.log(this.checkpin);
          if (this.checkpin == false) {
            this.messagepin = "Le code pin ne correspond pas";
            this.$refs.clear2.$el.querySelector("input").focus();
          } else {
            this.messagepin = null;
          }
        };
        setTimeout(timer, 0.1 * 100, 0.1);
      });
    },
    ici(row) {
      this.objdetails = row;
      this.details = true;
      this.row = row;
    },
    popup() {
      this.add = true;
    },
    async addnew() {
      const valid = await this.$refs.formadd.validate();
      if (valid == true && this.messagepin == null) {
        if (this.one.solde > Number(this.montant) && this.montant != 0) {
          const action = {};
          const action2 = {};
          action.receveur = this.wallet;
          action.montant = this.montant;
          action.pin = this.pin;
          action2.receveur = this.wallet;
          action2.montant = this.montant;
          action2.agence = this.agences.id;
          action2.banque = this.me.banque;
          action2.pin = this.pin;
          if (this.tab == 0) {
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("setretraitdevs", action).then(() => {
              const status = localStorage.getItem("status");
              if (status == 200) {
                this.snackbar = true;
                this.add = false;
                this.reset();
                this.$store.dispatch("getdevsportefeuille", this.me.id);
                this.$store.dispatch("getoperationdevs", this.current_page);
                localStorage.removeItem("status");
              } else if (status == 400) {
                this.error = true;
                localStorage.removeItem("status");
              }
            });
          } else {
            localStorage.setItem("tk", this.token);
            this.$store.dispatch("setretraitdevs", action2).then(() => {
              const status = localStorage.getItem("status");
              if (status == 200) {
                this.snackbar = true;
                this.add = false;
                this.reset();
                this.$store.dispatch("getdevsportefeuille", this.me.id);
                this.$store.dispatch("getoperationdevs", this.current_page);
                localStorage.removeItem("status");
              } else if (status == 400) {
                this.error = true;
                localStorage.removeItem("status");
              }
            });
          }
        } else {
          this.solde = true;
        }
      }
    },
    date(item) {
      const date = new Date(item);
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleDateString("fr-FR", options);
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("getoperationdevs", this.current_page);
      } else {
        this.$store.dispatch("searchalldevs", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {
      if (this.search) {
        if (this.search.trim() == "") {
          localStorage.setItem("tk", this.token);
          this.current_page = 1;
          this.$store.dispatch("getoperationdevs", this.current_page);
        } else {
          localStorage.setItem("tk", this.token);
          this.$store.dispatch("searchalldevs", {
            page: this.current_page,
            search: this.search.trim(),
          });
          if (this.$store.state.operation.alldevs.data) {
            this.current_page = this.$store.state.operation.alldevs.current_page;
          } else this.current_page = 1;
        }
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("getme");
    this.$store.dispatch("getdevsportefeuille", this.me.id);
    this.$store.dispatch("getagencebypaysdevs", this.me.pays.id);
    this.$store.dispatch("getoperationdevs", this.current_page);
    this.$store.dispatch("getclear");
  },
  created() {
    this.$store.dispatch("getvillebypays", this.me.pays.id);
    // console.log(this.$store.state.portefeuille.alldevs.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.custom {
  background-color: #2b2e83;
  color: white;
}
.danger {
  background-color: red;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
.infos {
  color: blue;
  font-size: 24px;
  text-transform: uppercase;
}
ul {
  padding-left: 0 !important;
}
.recap p {
  font-size: 1.25rem !important;
}
.other {
  font-weight: 500 !important;
}
.one {
  color: #2b2e83;
  margin-top: 4px;
}
.details p {
  font-size: 2rem;
}
.details span {
  font-size: 2rem;
  text-transform: uppercase;
}
.somme {
  margin: 4rem 4rem 0rem 0rem;
}
.somme p {
  font-size: 5rem !important;
}
.money {
  font-size: 2rem !important;
}
.small {
  font-size: 1.3125rem !important;
}
.end {
  font-size: 1rem !important;
}
</style>
