import axios from "axios";
import { baseURL } from "../../main";

const state =
{
    compte:null,
    one:null,
    two:null,
    compteadmin:null,
    pin:null,
    userbywallet:null,
    devs:null
}
const getters=
{
  
    compte:(state)=> state.compte,
    compteadmin:(state)=> state.compteadmin,
    one:(state)=> state.one,
    two:(state)=> state.two,
    pin:(state)=> state.pin,
    userbywallet:(state)=> state.userbywallet,
    devs:(state)=> state.devs

};

const actions =
{
    async updatepin({ commit },{id, value}) {
        let token = localStorage.getItem("tk");
        let response = await axios.put(baseURL + "user/pin/" + id, value, {
          headers: {
            Authorization: "Bearer " + token,
          },
        }) .then((response) => {
          if (response == undefined) {
              localStorage.setItem("status", 400);
          } else {
            localStorage.setItem("status", 200);
            let data = response.data;
            localStorage.removeItem("tk");
            commit("setpin", data);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
      },
    async getuserbywallet({ commit }, num) {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "portefeuille/user/" + num, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        let data = response.data;
        localStorage.removeItem("tk");
        commit("setuserbywallet", data);
      },
    async getuserbywalletdevs({ commit }, num) {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "developer/portefeuille/user/" + num, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        let data = response.data;
        localStorage.removeItem("tk");
        commit("setuserbywallet", data);
      },
    async getallportefeuille({ commit }, page) {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "portefeuilles/page/" + page, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        let data = response.data;
        localStorage.removeItem("tk");
        commit("setportefeuille", data);
      },
    async getportefeuillebyrole({ commit },{id, page}) {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "portefeuilles/page/"+id + "/" + page, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        let data = response.data;
        localStorage.removeItem("tk");
        commit("setportefeuille", data);
      },
    async getoneportefeuille({ commit }, id) {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "users/portefeuille/" + id, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        let data = response.data;
        localStorage.removeItem("tk");
        commit("setoneportefeuille", data);
      },

      // pour pas modiffier jai utiliser le setters de one 
    async getdevsportefeuille({ commit }, id) {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "developer/users/portefeuille/" + id, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        let data = response.data;
        localStorage.removeItem("tk");
        commit("setoneportefeuille", data);
      },
    async gettwoportefeuille({ commit }, id) {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "users/portefeuille/" + id, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        let data = response.data;
        localStorage.removeItem("tk");
        commit("settwoportefeuille", data);
      },
    async getportefeuilleadmin({ commit }, id) {
        let token = localStorage.getItem("tk");
        let response = await axios.get(baseURL + "users/portefeuille/" + id, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        let data = response.data;
        localStorage.removeItem("tk");
        commit("setportefeuilleadmin", data);
      },
      async searchcompte({ commit }, { page, search }) {
        let token = localStorage.getItem("tk");
        console.log(baseURL + "portefeuilles/search/page/" + page + "/" + search);
        let response = await axios.get(
          baseURL + "portefeuilles/search/page/" + page + "/" + search,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        let data = response.data;
        localStorage.removeItem("tk");
        commit("setportefeuille", data);

      },
      getclear({commit})
      {
        commit("clearwallet")
      }
   
};
const mutations =
{
  clearwallet(state) {
    state.userbywallet = null;
  },
    setpin(state, pin)
    {
        state.pin = pin
    },
    setportefeuille(state, compte)
    {
        state.compte = compte
    },
    setuserbywallet(state, userbywallet)
    {
        state.userbywallet = userbywallet
    },
    setoneportefeuille(state, one)
    {
        state.one = one
    },
    settwoportefeuille(state, two)
    {
        state.two = two
    },
    setportefeuilleadmin(state, compteadmin)
    {
        state.compteadmin = compteadmin
    },
    setBanques(state, newArray) {
      state.one.user.banques = newArray;
    }
};

export default {
    state,
    getters,
    mutations,
    actions,
}