import axios from "axios";
import { baseURL } from "../../main";

const state = {

  allhistorique:null,
  validerhistorique:null,
  allhistoriquetransfert:null,
  allbenefice:null

};

const getters = {
 
  allhistorique:(state) => state.allhistorique,
  allbenefice:(state) => state.allbenefice,
  validerhistorique:(state) => state.validerhistorique,
  allhistoriquetransfert:(state) => state.allhistoriquetransfert,
}

const actions = {

  async gethistoriqueuser({ commit },page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "operations/user/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("sethistorique", data);
  },
  async gethistoriquedevs({ commit },page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "developer/operations/user/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("sethistorique", data);
  },
  async gethistoriquevalideragent({ commit },page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "transactions/agent/valide/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setvaliderhistorique", data);
  },
  async searchhistoriquevalideragent({ commit },{page,search}) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "transactions/agent/valide/search/page/" + page +"/"+search , {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setvaliderhistorique", data);
  },
  async gethistoriquetransfertagent({ commit },page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "transactions/user/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("sethistoriqueagent", data);
  },
  async gethistoriquetransfertagent({ commit },page) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "transactions/user/page/" + page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("sethistoriqueagent", data);
  },
  async searchhistoriquetransfertagent({ commit },{page,search}) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "transactions/user/search/page/" + page+"/"+search, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("sethistoriqueagent", data);
  },
  async gethistoriqueuserbydate({ commit },{start,end,page}) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "operations/user/date/page/"+start+"/"+end+"/"+page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("sethistorique", data);
  },
  async gethistoriquebenefice({ commit },{id,page}) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "benefices/agent/page/"+id+"/"+page, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setbeneficehistorique", data);
  },
  async searchhistoriquebenefice({ commit },{id,page,search}) {
    let token = localStorage.getItem("tk");
    let response = await axios.get(baseURL + "benefices/agent/search/page/"+id+"/"+page+"/"+search, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    let data = response.data;
    localStorage.removeItem("tk");
    commit("setbeneficehistorique", data);
  },
 
};

const mutations = {
  clearError(state) {
    state.error = null;
  },
  sethistorique(state,allhistorique)
  {
    state.allhistorique= allhistorique
  },
  sethistoriqueagent(state,allhistoriquetransfert)
  {
    state.allhistoriquetransfert= allhistoriquetransfert
  },
  setbeneficehistorique(state,allbenefice)
  {
    state.allbenefice= allbenefice
  },
  setvaliderhistorique(state,validerhistorique)
  {
    state.validerhistorique= validerhistorique
  },

};

export default {
  state,
  getters,
  mutations,
  actions,
};
