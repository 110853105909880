<template>
  <main>
    <div class="container-100">
      <div class="signup border-start border-end">
        <v-form @submit.prevent="save" ref="formsaveuser">
          <div class="text-center">
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
              center
              absolute
              color="#1e824c"
              top
              centered
            >
              {{ $t("user.succes") }}

              <template v-slot:action="{ attrs }">
                <v-btn color="#cf000f" text v-bind="attrs" @click="snackbar = false">
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </div>
          <div class="text-center">
            <v-snackbar
              v-model="error"
              :timeout="timeout"
              absolute
              bottom
              color="red"
              right
            >
              {{ $t("error.error") }}

              <template v-slot:action="{ attrs }">
                <v-btn color="black" text v-bind="attrs" @click="snackbar = false">
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </div>
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">
                {{ $t("step.one") }}</v-stepper-step
              >
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2">{{
                $t("step.two")
              }}</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 3" step="3">{{
                $t("step.tree")
              }}</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form ref="personalInfoForm">
                  <v-card class="px-4 py-5">
                    <v-text-field
                      v-model="nom"
                      :rules="[rules.required]"
                      prepend-inner-icon="mdi-account"
                      name="nom"
                      :label="$t('headers.nom')"
                      type="text"
                      clearable
                      outlined
                      ref="clear"
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="prenom"
                      class="mt-2"
                      :rules="[rules.required]"
                      prepend-inner-icon="mdi-account"
                      name="prenom"
                      :label="$t('headers.prenom')"
                      type="text"
                      ref="clear1"
                      clearable
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      v-model="username"
                      class="mt-2"
                      :rules="usernameRules"
                      prepend-inner-icon="mdi-account"
                      name="username"
                      :label="$t('headers.username')"
                      type="text"
                      @input="existusername"
                      clearable
                      outlined
                      ref="clear2"
                      dense
                      :error-messages="messages"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="email"
                      class="mt-2"
                      :rules="emailRules"
                      prepend-inner-icon="mdi-email"
                      name="email"
                      :label="$t('headers.email')"
                      type="email"
                      :error-messages="messagesemail"
                      @input="existemail"
                      clearable
                      ref="clear3"
                      dense
                      outlined
                    ></v-text-field>
                    <v-text-field
                      v-model="password"
                      class="mt-2"
                      :rules="passwordRules"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show1 = !show1"
                      :type="show1 ? 'text' : 'password'"
                      prepend-inner-icon="mdi-key"
                      name="password"
                      outlined
                      ref="clear4"
                      dense
                      :label="$t('headers.password')"
                      clearable
                    ></v-text-field>
                    <v-text-field
                      v-model="tel"
                      class="mt-2"
                      prepend-inner-icon="mdi-phone"
                      :rules="[rules.required]"
                      name="tel"
                      :label="$t('headers.telephone')"
                      type="text"
                      @input="existtel"
                      :error-messages="messagestel"
                      outlined
                      ref="clear5"
                      dense
                      clearable
                    ></v-text-field>
                    <v-autocomplete
                      v-model="allpay"
                      class="mt-2"
                      prepend-inner-icon="mdi-earth"
                      :rules="[rules.required]"
                      name="pays"
                      :label="$t('headers.pays')"
                      outlined
                      ref="clear6"
                      item-text="nom"
                      item-value="id"
                      :items="allpays"
                      return-object
                      dense
                    ></v-autocomplete>
                    <v-autocomplete
                      v-model="Roles"
                      :rules="[rules.required]"
                      prepend-inner-icon=""
                      name="Role"
                      label="Role"
                      :items="RoleList"
                      item-value="id"
                      ref="clear6"
                      item-text="libelle"
                      outlined
                      dense
                      return-object
                    >
                    </v-autocomplete>
                    <div class="text-right">
                      <v-btn color="#edf3ff" @click="validateStep1()">{{
                        $t("step.next")
                      }}</v-btn>
                    </div>
                  </v-card>
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-form ref="bankInfoForm">
                  <v-text-field
                    class="mt-2"
                    v-model="institution"
                    outlined
                    clearable
                    dense
                    :label="$t(`headers.institution`)"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    v-model="intitule"
                    outlined
                    clearable
                    dense
                    :label="$t('headers.intitule')"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    v-model="numCompte"
                    outlined
                    clearable
                    dense
                    label="Numero de Compte"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-btn color="#edf3ff" @click="validateStep2">
                    {{ $t("step.next") }}</v-btn
                  >
                </v-form>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-form ref="pinInfoForm">
                  <v-text-field
                    class="mt-2"
                    v-model="pin"
                    outlined
                    clearable
                    dense
                    label="Code PIN"
                    :rules="codepinRules"
                  ></v-text-field>
                  <v-btn color="#edf3ff" @click="validateStep3">
                    {{ $t("step.end") }}</v-btn
                  >
                </v-form>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-form>
      </div>
    </div>
  </main>
</template>
<script>
import axios from "axios";
import { baseURL } from "../../../main";
import { mapGetters } from "vuex";

export default {
  data: () => {
    return {
      show1: "",
      snackbar: false,
      error: false,
      timeout: 2000,
      nom: "",
      Roles: "",
      username: "",
      email: "",
      password: "",
      step: 1,
      allpay: "",
      prenom: "",
      tel: "",
      pin: "",
      intitule: "",
      institution: "",
      numCompte: "",
      ville: "",
      messages: null,
      messagestel: null,
      messagesemail: null,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
    };
  },
  computed: {
    ...mapGetters(["roles", "allpays"]),
    usernameRules() {
      return [
        (v) => !!v || this.$t("require.username"),
        (v) =>
          /^(?=.*[a-zA-Z].*)(?=[a-zA-Z0-9._]{3,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/.test(v) ||
          this.$t("rule.username"),
      ];
    },
    passwordRules() {
      return [
        (v) => !!v || this.$t("require.pass"),
        (v) =>
          /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()–[{}]).{6,40}/.test(v) ||
          this.$t("rule.pass"),
      ];
    },
    emailRules() {
      return [
        (v) => !!v || this.$t("require.email"),
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || this.$t("rule.mail"),
      ];
    },
    codepinRules() {
      return [
        (v) => !!v || this.$t("require.pin"),
        (v) => /^\d{6}$/.test(v) || this.$t("rule.pin"),
      ];
    },
    form() {
      return {
        nom: this.nom,
        prenom: this.prenom,
        username: this.username,
        email: this.email,
        tel: this.tel,
        password: this.password,
        role: this.Roles.id,
        pays: this.allpay.id,
        pin: this.pin,
      };
    },
    form1() {
      return {
        nom: this.nom,
        prenom: this.prenom,
        username: this.username,
        email: this.email,
        tel: this.tel,
        password: this.password,
        role: this.Roles.id,
        pays: this.allpay.id,
        institution: this.institution,
        intitule: this.intitule,
        numCompte: this.numCompte,
        pin: this.pin,
      };
    },
    RoleList() {
      if (this.roles != null) {
        return this.roles.filter(
          (item) => item.id == 4 || item.id == 2 || item.id == 1 || item.id == 6
        );
      }
    },
  },

  methods: {
    reset() {
      this.$refs.clear.reset();
      this.$refs.clear1.reset();
      this.$refs.clear2.reset();
      this.$refs.clear3.reset();
      this.$refs.clear4.reset();
      this.$refs.clear5.reset();
      this.$refs.clear6.reset();
    },
    validateStep1() {
      const valid = this.$refs.personalInfoForm.validate();
      if (valid == true && this.Roles.id == 4) {
        this.step = 3;
      } else if (valid == true && this.Roles.id != 4) {
        this.step = 2;
      }
    },
    validateStep2() {
      const valid = this.$refs.bankInfoForm.validate();
      if (valid == true) {
        this.step = 3;
      }
    },
    validateStep3() {
      const valid = this.$refs.pinInfoForm.validate();
      if (valid == true) {
        if (this.Roles.id == 4) {
          localStorage.setItem("tk", this.$store.getters.token);
          this.$store.dispatch("adduser", this.form).then(() => {
            const status = localStorage.getItem("status");
            if (status == 200) {
              this.snackbar = true;
              this.reset();
              localStorage.removeItem("status");
              this.step = 1;
            } else if (status == 400) {
              this.error = true;
              localStorage.removeItem("status");
            }
          });
        } else {
          localStorage.setItem("tk", this.$store.getters.token);
          this.$store.dispatch("adduser", this.form1).then(() => {
            const status = localStorage.getItem("status");
            if (status == 200) {
              this.snackbar = true;
              this.reset();
              localStorage.removeItem("status");
              this.step = 1;
            } else if (status == 400) {
              this.error = true;
              localStorage.removeItem("status");
            }
          });
        }
      }
    },
    nextStep() {},
    async existemail() {
      if (this.email) {
        await axios.get(baseURL + "auth/check/email/" + this.email).then((response) => {
          if (response.data == true) {
            this.messagesemail = "cet adresse email existe deja";
          } else {
            this.messagesemail = null;
          }
        });
      }
    },
    async existtel() {
      if (this.tel) {
        await axios.get(baseURL + "auth/check/tel/" + this.tel).then((response) => {
          if (response.data == true) {
            this.messagestel = "ce nom  existe deja";
          } else {
            this.messagestel = null;
          }
        });
      }
    },
    async existusername() {
      if (this.username) {
        await axios
          .get(baseURL + "auth/check/username/" + this.username)
          .then((response) => {
            if (response.data == true) {
              this.messages = "ce nom d'utilisateur existe deja";
            } else {
              this.messages = null;
            }
          });
      }
    },
    save() {
      if (this.$refs.formsaveuser.validate()) {
        localStorage.setItem("tk", this.$store.getters.token);
        this.$store.dispatch("adduser", this.form).then(() => {
          const status = localStorage.getItem("status");
          if (status == 200) {
            this.snackbar = true;
            this.reset();
            localStorage.removeItem("status");
          } else if (status == 400) {
            this.error = true;
            localStorage.removeItem("status");
          }
        });
      }
    },
  },
  mounted() {
    localStorage.setItem("tk", this.$store.getters.token);
    this.$store.dispatch("getroles");
    this.$store.dispatch("getallpays");
  },
};
</script>
<style scoped>
.container-100 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.signup {
  width: 60rem;
  background-color: white;
  border-radius: 3px;
  overflow: hidden;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 70px;
}
</style>
