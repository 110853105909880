<template>
  <main class="container">
    <div class="row">
      <Aside :profile="one" />
      <div class="col-lg-9 mb-4">
        <div class="col-sm-6 col-md-5 form-group">
          <v-toolbar>
            <v-text-field
              class="search"
              v-model.lazy="search"
              single-line
              hide-details
              label="search"
              @input="searchresult"
              append-icon="mdi-magnify"
            >
            </v-text-field>
          </v-toolbar>
        </div>
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="itemList"
          disable-filtering
          :loading="loading"
          disable-pagination
          disable-sort
          mobile-breakpoint="800"
          @click:row="ici"
          class="elevation-1 shadow-sm rounded row-pointer"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t("Transaction.Transaction") }}</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            <p>{{ date(item.createdAt) }}</p>
          </template>
          <template v-slot:[`item.numCompte`]="{ item }">
            <th v-if="item.numCompte">
              {{ item.numCompte }}
            </th>
            <td v-else>vide</td>
          </template>
          <template v-slot:[`item.institution`]="{ item }">
            <th v-if="item.institution">
              {{ item.institution }}
            </th>
            <td v-else>vide</td>
          </template>
          <template v-slot:[`item.intitule`]="{ item }">
            <th v-if="item.intitule">
              {{ item.intitule }}
            </th>
            <td v-else>vide</td>
          </template>
          <template v-slot:[`item.valider`]="{ item }">
            <td v-if="item.valider == true">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" large color="green"
                    >mdi-check-circle-outline
                  </v-icon>
                </template>
                <span>Effectuer</span>
              </v-tooltip>
            </td>
            <td v-else>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="yellow" large>
                    mdi-dots-horizontal
                  </v-icon>
                </template>
                <span>En attente</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
        <div class="text-center mt-4">
          <v-pagination
            v-model="current_page"
            :total-visible="5"
            :total-rows="rows"
            :length="totalpage"
            circle
          >
          </v-pagination>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
import Aside from "../../../components/aside.vue";
export default {
  components: { Aside },
  data() {
    return {
      items: [],
      snackbar: false,
      timeout: 2000,
      loading: false,
      search: null,
      current_page: 1,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => !!v || "Ce champs est requis",
      },
      headers: [
        {
          text: this.$t("headers.envoyeurN"),
          align: "start",
          sortable: false,
          value: "expediteur",
        },
        {
          text: this.$t("headers.receiveN"),
          align: "start",
          sortable: false,
          value: "recepteur",
        },
        {
          text: this.$t("headers.adresse"),
          align: "start",
          sortable: false,
          value: "adrRecepteur",
        },
        {
          text: this.$t("headers.telephone"),
          align: "start",
          sortable: false,
          value: "telRecepteur",
        },
        {
          text: this.$t("headers.villereception"),
          align: "start",
          sortable: false,
          value: "destination.nom",
        },
        {
          text: this.$t("headers.moyenrecup"),
          align: "start",
          sortable: false,
          value: "moyenRecuperation.libelle",
        },
        {
          text: this.$t("headers.montant"),
          align: "start",
          sortable: false,
          value: "montant",
        },
        {
          text: this.$t("headers.frais"),
          align: "start",
          sortable: false,
          value: "moyenRecuperation.frais",
        },
        {
          text: this.$t("headers.date"),
          align: "start",
          sortable: false,
          value: "dateOperation",
        },
        {
          text: this.$t("headers.intitule"),
          align: "start",
          sortable: false,
          value: "intitule",
        },
        {
          text: this.$t("headers.institution"),
          align: "start",
          sortable: false,
          value: "institution",
        },
        {
          text: this.$t("headers.numcompte"),
          align: "start",
          sortable: false,
          value: "numCompte",
        },
        {
          text: this.$t("headers.numcompte"),
          align: "start",
          sortable: false,
          value: "numero",
        },
        {
          text: this.$t("headers.valider"),
          align: "start",
          sortable: false,
          value: "valider",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["transfertall", "token", "user", "one"]),
    rows() {
      return this.items.length;
    },
    totalpage() {
      if (this.transfertall && this.transfertall.last_page) {
        return this.transfertall.last_page;
      } else {
        return 0;
      }
    },
    itemList() {
      if (this.transfertall != null && this.transfertall.data) {
        return this.transfertall.data;
      } else {
        return [];
      }
    },
  },
  watch: {
    current_page() {
      this.next();
    },
    search(newVal) {
      if (newVal.trim() == "") {
        localStorage.setItem("tk", this.token);
        this.$store.dispatch("gettransfert", this.current_page);
      } else {
        localStorage.setItem("tk", this.token);
        this.$store
          .dispatch("searchalltransfert", {
            page: this.current_page,
            search: this.search.trim(),
          })
          .then(() => {
            this.searchresult();
          });
      }
    },
    // search() {
    //   if (this.search == "" || this.search.trim() == null) {
    //     localStorage.setItem("tk", this.token);
    //     this.$store.dispatch("gettransfert", this.current_page);
    //   } else {
    //     this.$store.dispatch("searchalltransfert", {
    //       page: this.current_page,
    //       search: this.search.trim(),
    //     });
    //   }
    // },
  },
  methods: {
    ici(row) {},
    date(item) {
      return new Date(item).toLocaleDateString();
    },
    next() {
      localStorage.setItem("tk", this.token);
      if (this.search == null || this.search.trim() == "") {
        this.$store.dispatch("gettransfert", this.current_page);
      } else {
        this.$store.dispatch("searchalltransfert", {
          page: this.current_page,
          search: this.search.trim(),
        });
      }
    },
    searchresult() {},
    // searchresult() {
    //   if (this.search) {
    //     if (this.search.trim() == "") {
    //       localStorage.setItem("tk", this.token);
    //       this.current_page = 1;
    //       this.$store.dispatch("gettransfert", this.current_page);
    //     } else {
    //       localStorage.setItem("tk", this.token);
    //       this.$store.dispatch("searchalltransfert", {
    //         page: this.current_page,
    //         search: this.search.trim(),
    //       });
    //       if (this.$store.state.transfert.searchalltransfert.data) {
    //         this.current_page = this.$store.state.transfert.transfertall.current_page;
    //       } else this.current_page = 1;
    //     }
    //   }
    // },
  },
  mounted() {
    localStorage.setItem("tk", this.token);
    this.$store.dispatch("gettransfert", this.current_page);
    this.$store.dispatch("getoneportefeuille", this.one.user.id);
  },
  created() {
    // console.log(this.$store.state.portefeuille.transfertall.data.length);
  },
};
</script>
<style scoped>
.search {
  width: 100%;
  height: 80%;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
  color: red;
}
.back {
  background-color: whitesmoke;
}
</style>
